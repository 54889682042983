import React from 'react';

import './ModalStyle.scss';

// plugin
import { Button, Modal } from 'react-bootstrap';

const ImportantNoticeModal = ({ show, onHide }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>お知らせ / Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="contents-text_only">
          <p>
            11月9日（土）の12:00から、COIASに画像を追加するメンテナンスを行います。
            期間中、COIASをお使いいただけませんのでご了承ください。
          </p>
          <br />
          <p>
            For a maintenance to add images, we will stop COIAS from November
            9th (Sat) 12:00 JST. Thank you for your cooperation in advance.
          </p>
        </Modal.Body>
        <Modal.Footer className="one_button">
          <Button variant="secondary" onClick={onHide}>
            閉じる / Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImportantNoticeModal;
