exports.provisionalCodeInfoList = [
  {
    provisionalCode: '2019 SR229',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.17749,
    eccentricity: 0.0494448,
    inclination: 8.64504,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'さとうきび',
        HNames: 'H207597',
      },
      {
        userName: 'さんしろう',
        HNames: 'H218071, H218303',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219951',
      },
    ],
  },
  {
    provisionalCode: '2019 SS229',
    note: '',
    absoluteMagnitude: 21.42,
    semimajorAxis: 2.15612,
    eccentricity: 0.0988806,
    inclination: 2.06007,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H211087',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215944',
      },
    ],
  },
  {
    provisionalCode: '2019 UW157',
    note: '確定番号(719612)',
    absoluteMagnitude: 18.56,
    semimajorAxis: 2.68468,
    eccentricity: 0.0937435,
    inclination: 5.75898,
    oppositions: 9,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H200969, H215870',
      },
      {
        userName: 'さんしろう',
        HNames: 'H214277',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222335, H223175',
      },
    ],
  },
  {
    provisionalCode: '2019 UF158',
    note: '',
    absoluteMagnitude: 21.41,
    semimajorAxis: 2.24774,
    eccentricity: 0.2352565,
    inclination: 2.50057,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214945',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217569, H217657, H220640',
      },
    ],
  },
  {
    provisionalCode: '2019 UL158',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 2.73116,
    eccentricity: 0.062785,
    inclination: 4.20301,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213884, H213940, H215547',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215308',
      },
    ],
  },
  {
    provisionalCode: '2019 UM158',
    note: 'Hilda',
    absoluteMagnitude: 18.46,
    semimajorAxis: 3.99581,
    eccentricity: 0.1520056,
    inclination: 7.28697,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215625, H215682, H216033',
      },
      {
        userName: 'さんしろう',
        HNames: 'H216552',
      },
    ],
  },
  {
    provisionalCode: '2019 UN158',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.06,
    semimajorAxis: 5.27534,
    eccentricity: 0.0697381,
    inclination: 3.59202,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217376, H218270',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217773, H218270',
      },
    ],
  },
  {
    provisionalCode: '2019 UO158',
    note: '',
    absoluteMagnitude: 21.24,
    semimajorAxis: 2.36295,
    eccentricity: 0.1698586,
    inclination: 4.37532,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H218327',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218921, H218935',
      },
      {
        userName: '42',
        HNames: 'H219573',
      },
    ],
  },
  {
    provisionalCode: '2019 UY159',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.59,
    semimajorAxis: 44.42248,
    eccentricity: 0.1526751,
    inclination: 22.04297,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H231687, H231694, H232834, H232848, H232849',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232849',
      },
    ],
  },
  {
    provisionalCode: '2019 VT40',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.24513,
    eccentricity: 0.1024666,
    inclination: 5.99965,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219692',
      },
    ],
  },
  {
    provisionalCode: '2019 VX41',
    note: '2013 HM56と同定',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.74279,
    eccentricity: 0.0240862,
    inclination: 5.2718,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H215270, H215281, H217596',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217437',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222808, H223730',
      },
    ],
  },
  {
    provisionalCode: '2019 VY41',
    note: '2011 EH107と同定',
    absoluteMagnitude: 19.14,
    semimajorAxis: 2.30324,
    eccentricity: 0.1470834,
    inclination: 2.43749,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H215271, H215282, H217599',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215949',
      },
      {
        userName: 'れお',
        HNames: 'H215949',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222054',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ41',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.39264,
    eccentricity: 0.0671344,
    inclination: 4.28325,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211943, H218285',
      },
      {
        userName: 'devastrotech',
        HNames: 'H211943',
      },
      {
        userName: 'このしろ',
        HNames: 'H215015',
      },
      {
        userName: 'れお',
        HNames: 'H215948',
      },
    ],
  },
  {
    provisionalCode: '2019 VA42',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.57826,
    eccentricity: 0.1158858,
    inclination: 4.74761,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214895, H218865, H229827',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222766',
      },
    ],
  },
  {
    provisionalCode: '2019 VB42',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.74724,
    eccentricity: 0.0361757,
    inclination: 4.32243,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213306, H217338, H218869, H229828',
      },
    ],
  },
  {
    provisionalCode: '2019 VC42',
    note: 'Mars Crosser',
    absoluteMagnitude: 21.21,
    semimajorAxis: 2.38802,
    eccentricity: 0.3658752,
    inclination: 4.73986,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217365',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221660, H223846',
      },
    ],
  },
  {
    provisionalCode: '2019 VD42',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.85615,
    eccentricity: 0.0093596,
    inclination: 2.75873,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214249, H214347',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217336',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222307',
      },
    ],
  },
  {
    provisionalCode: '2019 VE42',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 2.684,
    eccentricity: 0.0421734,
    inclination: 3.91335,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214977',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214977',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217767',
      },
      {
        userName: 'aika',
        HNames: 'H222433',
      },
    ],
  },
  {
    provisionalCode: '2019 VF42',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.18,
    semimajorAxis: 5.18644,
    eccentricity: 0.0340013,
    inclination: 6.86243,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H217571, H222870',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217571, H217688',
      },
    ],
  },
  {
    provisionalCode: '2019 VG42',
    note: '',
    absoluteMagnitude: 18.16,
    semimajorAxis: 2.90291,
    eccentricity: 0.0482864,
    inclination: 5.42189,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214348, H214360',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219630',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223224, H223966',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ42',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.91434,
    eccentricity: 0.194888,
    inclination: 5.76215,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214976',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219747',
      },
      {
        userName: 'うめきち730',
        HNames: 'H233359',
      },
    ],
  },
  {
    provisionalCode: '2019 WT27',
    note: '',
    absoluteMagnitude: 18.69,
    semimajorAxis: 2.55987,
    eccentricity: 0.0698329,
    inclination: 2.58157,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H209869',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H216902, H216915',
      },
    ],
  },
  {
    provisionalCode: '2019 WU27',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 2.73226,
    eccentricity: 0.0726419,
    inclination: 2.84469,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200103',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213804, H214721, H217572',
      },
    ],
  },
  {
    provisionalCode: '2019 WV27',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 2.87916,
    eccentricity: 0.0908467,
    inclination: 2.89696,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200079',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215629, H215683, H216034',
      },
    ],
  },
  {
    provisionalCode: '2019 WW27',
    note: '',
    absoluteMagnitude: 19.77,
    semimajorAxis: 2.76082,
    eccentricity: 0.0550224,
    inclination: 2.32569,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H212446',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214820',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217597',
      },
    ],
  },
  {
    provisionalCode: '2019 WX27',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 3.11932,
    eccentricity: 0.1637884,
    inclination: 2.23758,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H212762, H217746, H218237',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213250',
      },
    ],
  },
  {
    provisionalCode: '2019 WY27',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.72591,
    eccentricity: 0.0581318,
    inclination: 2.12987,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200518',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213307, H218867',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ27',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.1855,
    eccentricity: 0.2254252,
    inclination: 9.12014,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'たくみ丸',
        HNames: 'H214023',
      },
      {
        userName: 'このしろ',
        HNames: 'H215161',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217959',
      },
      {
        userName: 'kn1cht',
        HNames: 'H219589',
      },
    ],
  },
  {
    provisionalCode: '2019 WA28',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 2.57246,
    eccentricity: 0.0830251,
    inclination: 2.39742,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200503',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215272, H215280',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222309',
      },
    ],
  },
  {
    provisionalCode: '2019 WB28',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.2349,
    eccentricity: 0.1476385,
    inclination: 2.35225,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H200063',
      },
      {
        userName: 'kn1cht',
        HNames: 'H200066',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222128',
      },
      {
        userName: 'anna',
        HNames: 'H252463',
      },
    ],
  },
  {
    provisionalCode: '2019 XH17',
    note: '',
    absoluteMagnitude: 18.08,
    semimajorAxis: 2.77314,
    eccentricity: 0.0737564,
    inclination: 4.37201,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209849, H213220, H213843, H214944',
      },
      {
        userName: 'devastrotech',
        HNames: 'H209849',
      },
    ],
  },
  {
    provisionalCode: '2019 XJ17',
    note: '2010 RV120と同定',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.7241,
    eccentricity: 0.1412572,
    inclination: 4.85103,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H209895',
      },
      {
        userName: 'さとうきび',
        HNames: 'H209895',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213107',
      },
      {
        userName: 'さんしろう',
        HNames: 'H213107',
      },
    ],
  },
  {
    provisionalCode: '2019 XK17',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.27,
    semimajorAxis: 5.13402,
    eccentricity: 0.0235821,
    inclination: 4.89776,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217741, H219269',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217741',
      },
    ],
  },
  {
    provisionalCode: '2019 GY173',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 2.61095,
    eccentricity: 0.167874,
    inclination: 5.64696,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H235831',
      },
    ],
  },
  {
    provisionalCode: '2016 QY155',
    note: '',
    absoluteMagnitude: 17.57,
    semimajorAxis: 3.43711,
    eccentricity: 0.1494782,
    inclination: 10.51127,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'しょうたろう',
        HNames: 'H212310',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221954',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251925',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ221',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.38738,
    eccentricity: 0.1245533,
    inclination: 2.11079,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201381, H202216, H202284, H202410',
      },
    ],
  },
  {
    provisionalCode: '2016 YA39',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.12,
    semimajorAxis: 46.93304,
    eccentricity: 0.353593,
    inclination: 11.56398,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242523, H249852, H250467, H251484, H302576',
      },
      {
        userName: 'aika',
        HNames: 'H252528',
      },
    ],
  },
  {
    provisionalCode: '2017 BM223',
    note: '',
    absoluteMagnitude: 17.41,
    semimajorAxis: 3.19779,
    eccentricity: 0.0878951,
    inclination: 7.72291,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250339',
      },
    ],
  },
  {
    provisionalCode: '2017 FF226',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.63,
    semimajorAxis: 61.9094,
    eccentricity: 0.3615343,
    inclination: 16.06562,
    oppositions: 8,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241499, H242391, H242397, H242494, H248303, H249465',
      },
      {
        userName: 'aika',
        HNames: 'H252515',
      },
    ],
  },
  {
    provisionalCode: '2016 YB39',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 55.99476,
    eccentricity: 0.3622483,
    inclination: 22.33664,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242388, H251266, H251380, H251879, H255088',
      },
    ],
  },
  {
    provisionalCode: '2017 BU223',
    note: '',
    absoluteMagnitude: 18.02,
    semimajorAxis: 3.16849,
    eccentricity: 0.0783527,
    inclination: 8.94348,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250057, H260686',
      },
    ],
  },
  {
    provisionalCode: '2017 DF156',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.13,
    semimajorAxis: 47.8733,
    eccentricity: 0.259751,
    inclination: 10.07823,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252859, H252931, H252944, H252988, H253100',
      },
    ],
  },
  {
    provisionalCode: '2017 EY49',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.63,
    semimajorAxis: 43.48892,
    eccentricity: 0.2124769,
    inclination: 10.69617,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241744, H241793, H242005, H242031, H251978, H259514',
      },
    ],
  },
  {
    provisionalCode: '2017 FG226',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.29,
    semimajorAxis: 45.95566,
    eccentricity: 0.1851623,
    inclination: 11.40159,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'arda',
        HNames: 'H251083',
      },
      {
        userName: 'aika',
        HNames: 'H251176, H251617, H252045, H252116, H252133',
      },
    ],
  },
  {
    provisionalCode: '2019 ES6',
    note: '',
    absoluteMagnitude: 17.62,
    semimajorAxis: 3.05235,
    eccentricity: 0.0139834,
    inclination: 7.99699,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'imai',
        HNames: 'H225792',
      },
      {
        userName: 'ほたる',
        HNames: 'H252007',
      },
      {
        userName: 'arda',
        HNames: 'H252424',
      },
    ],
  },
  {
    provisionalCode: '2017 BX223',
    note: '確定番号(717976)',
    absoluteMagnitude: 17.58,
    semimajorAxis: 3.17002,
    eccentricity: 0.0116978,
    inclination: 9.36729,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261081, H264765',
      },
      {
        userName: '42',
        HNames: 'H262705',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ52',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.19418,
    eccentricity: 0.1169769,
    inclination: 6.34399,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202454, H203663',
      },
      {
        userName: 'yakuba',
        HNames: 'H244245',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244245',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ156',
    note: '',
    absoluteMagnitude: 18.09,
    semimajorAxis: 2.88746,
    eccentricity: 0.0260562,
    inclination: 2.98844,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201062',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261449',
      },
    ],
  },
  {
    provisionalCode: '2017 DK156',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 2.68562,
    eccentricity: 0.1396015,
    inclination: 13.53033,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261456',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261456',
      },
    ],
  },
  {
    provisionalCode: '2017 DL156',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.70014,
    eccentricity: 0.0701488,
    inclination: 3.1036,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261037, H261925',
      },
    ],
  },
  {
    provisionalCode: '2017 DM156',
    note: '',
    absoluteMagnitude: 18.63,
    semimajorAxis: 3.02687,
    eccentricity: 0.1463511,
    inclination: 2.74244,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201102',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262378, H262408',
      },
    ],
  },
  {
    provisionalCode: '2017 DN156',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 2.36882,
    eccentricity: 0.0316767,
    inclination: 3.70701,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243521',
      },
    ],
  },
  {
    provisionalCode: '2017 DO156',
    note: '',
    absoluteMagnitude: 18.21,
    semimajorAxis: 3.11359,
    eccentricity: 0.192668,
    inclination: 10.63838,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243385',
      },
    ],
  },
  {
    provisionalCode: '2017 EC50',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 2.7814,
    eccentricity: 0.0671549,
    inclination: 4.85431,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202242, H262453',
      },
      {
        userName: 'ほたる',
        HNames: 'H217574',
      },
    ],
  },
  {
    provisionalCode: '2017 FS226',
    note: '',
    absoluteMagnitude: 18.27,
    semimajorAxis: 2.56667,
    eccentricity: 0.0965522,
    inclination: 8.80788,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241898',
      },
      {
        userName: 'aika',
        HNames: 'H247527, H282334',
      },
    ],
  },
  {
    provisionalCode: '2017 OZ189',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.34426,
    eccentricity: 0.0459614,
    inclination: 7.64556,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'akmt',
        HNames: 'H201796',
      },
      {
        userName: 'さんしろう',
        HNames: 'H208409',
      },
    ],
  },
  {
    provisionalCode: '2018 HK12',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.25423,
    eccentricity: 0.1012684,
    inclination: 6.81642,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H233689, H237296',
      },
    ],
  },
  {
    provisionalCode: '2019 ET6',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.574,
    eccentricity: 0.1060294,
    inclination: 12.97248,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H254241',
      },
      {
        userName: 'ko',
        HNames: 'H262939',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ33',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 2.68591,
    eccentricity: 0.053206,
    inclination: 2.94616,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H219353',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222133, H222864',
      },
    ],
  },
  {
    provisionalCode: '2016 QM157',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.42127,
    eccentricity: 0.1791191,
    inclination: 4.2082,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'コージ',
        HNames: 'H251066',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277654',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278143',
      },
    ],
  },
  {
    provisionalCode: '2016 WT84',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 2.97451,
    eccentricity: 0.1321209,
    inclination: 9.00531,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220782',
      },
      {
        userName: 'zorome',
        HNames: 'H250875',
      },
      {
        userName: 'ms',
        HNames: 'H267160, H267191, H268186, H268283',
      },
    ],
  },
  {
    provisionalCode: '2016 WW84',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.76,
    semimajorAxis: 5.18495,
    eccentricity: 0.1030457,
    inclination: 13.54189,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H269374, H269686, H269891, H269967, H270031',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269533',
      },
    ],
  },
  {
    provisionalCode: '2016 WX84',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 3.25388,
    eccentricity: 0.0432209,
    inclination: 8.45209,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266543',
      },
      {
        userName: 'ms',
        HNames: 'H267164, H272948',
      },
      {
        userName: 'zorome',
        HNames: 'H268477',
      },
    ],
  },
  {
    provisionalCode: '2016 WY84',
    note: '2015 TD54と同定',
    absoluteMagnitude: 19.0,
    semimajorAxis: 2.68451,
    eccentricity: 0.2854095,
    inclination: 8.65762,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220784',
      },
      {
        userName: 'zorome',
        HNames: 'H251813',
      },
      {
        userName: 'ms',
        HNames: 'H267165, H272950, H273548',
      },
    ],
  },
  {
    provisionalCode: '2016 YG39',
    note: '2020 KW45と同定: Hilda',
    absoluteMagnitude: 16.71,
    semimajorAxis: 3.94678,
    eccentricity: 0.1072121,
    inclination: 9.48483,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H274251, H282398',
      },
      {
        userName: 'maria w',
        HNames: 'H278640, H278645',
      },
      {
        userName: 'arda',
        HNames: 'H288006',
      },
    ],
  },
  {
    provisionalCode: '2016 YH39',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.16574,
    eccentricity: 0.0534431,
    inclination: 8.04989,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'なかしん',
        HNames: 'H205398',
      },
      {
        userName: 'nasiar99',
        HNames: 'H205398',
      },
      {
        userName: 'arda',
        HNames: 'H268463',
      },
      {
        userName: 'れお',
        HNames: 'H283830',
      },
    ],
  },
  {
    provisionalCode: '2016 YJ39',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 3.07131,
    eccentricity: 0.0426621,
    inclination: 9.36238,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248512, H250962',
      },
    ],
  },
  {
    provisionalCode: '2016 YK39',
    note: '',
    absoluteMagnitude: 18.83,
    semimajorAxis: 3.20145,
    eccentricity: 0.0734247,
    inclination: 8.1801,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242043, H265489, H277611',
      },
    ],
  },
  {
    provisionalCode: '2016 YL39',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.96265,
    eccentricity: 0.0809404,
    inclination: 9.17127,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250074, H250361',
      },
      {
        userName: '赵经远',
        HNames: 'H250361',
      },
    ],
  },
  {
    provisionalCode: '2016 YM39',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.37,
    semimajorAxis: 5.16643,
    eccentricity: 0.0530359,
    inclination: 8.50333,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H271161',
      },
      {
        userName: 'aika',
        HNames: 'H276529',
      },
      {
        userName: 'arda',
        HNames: 'H278489',
      },
    ],
  },
  {
    provisionalCode: '2016 YN39',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.1299,
    eccentricity: 0.093809,
    inclination: 11.71211,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273454, H277602',
      },
      {
        userName: 'kn1cht',
        HNames: 'H278497',
      },
    ],
  },
  {
    provisionalCode: '2016 YO39',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 2.84357,
    eccentricity: 0.0756806,
    inclination: 8.988,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H277107',
      },
      {
        userName: 'もしもス',
        HNames: 'H277679',
      },
      {
        userName: 'maria w',
        HNames: 'H278644',
      },
    ],
  },
  {
    provisionalCode: '2016 YP39',
    note: '',
    absoluteMagnitude: 18.02,
    semimajorAxis: 3.13955,
    eccentricity: 0.0384759,
    inclination: 8.92512,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266732, H266772, H279002',
      },
    ],
  },
  {
    provisionalCode: '2016 YQ39',
    note: '',
    absoluteMagnitude: 20.89,
    semimajorAxis: 2.94854,
    eccentricity: 0.3193627,
    inclination: 6.6421,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H270801',
      },
      {
        userName: 'ms',
        HNames: 'H279991, H280453, H280509, H280735',
      },
      {
        userName: 'もしもス',
        HNames: 'H279991',
      },
    ],
  },
  {
    provisionalCode: '2017 AD62',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.79,
    semimajorAxis: 41.54253,
    eccentricity: 0.0817743,
    inclination: 23.64713,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'aika',
        HNames: 'H269111, H269632, H270370, H271352, H271367',
      },
      {
        userName: 'arda',
        HNames: 'H269111',
      },
      {
        userName: 'yakuba',
        HNames: 'H269632',
      },
    ],
  },
  {
    provisionalCode: '2017 AM62',
    note: '',
    absoluteMagnitude: 18.32,
    semimajorAxis: 3.09993,
    eccentricity: 0.0887253,
    inclination: 14.50039,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242109, H265539',
      },
    ],
  },
  {
    provisionalCode: '2017 AN62',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 3.19281,
    eccentricity: 0.0416791,
    inclination: 9.35304,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220813',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260193',
      },
      {
        userName: 'arda',
        HNames: 'H275843',
      },
    ],
  },
  {
    provisionalCode: '2017 AP62',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 2.68433,
    eccentricity: 0.2539766,
    inclination: 15.44006,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268062',
      },
      {
        userName: 'nasiar99',
        HNames: 'H276172',
      },
      {
        userName: 'arda',
        HNames: 'H276172',
      },
    ],
  },
  {
    provisionalCode: '2017 BK224',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.25266,
    eccentricity: 0.0589849,
    inclination: 10.49427,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251309, H251976',
      },
      {
        userName: 'ms',
        HNames: 'H267478',
      },
    ],
  },
  {
    provisionalCode: '2017 BN224',
    note: '',
    absoluteMagnitude: 20.66,
    semimajorAxis: 2.41266,
    eccentricity: 0.0384559,
    inclination: 6.32658,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242025, H247878',
      },
    ],
  },
  {
    provisionalCode: '2017 BO224',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.66755,
    eccentricity: 0.1037349,
    inclination: 8.48549,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248428, H250341',
      },
    ],
  },
  {
    provisionalCode: '2017 BP224',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.16,
    semimajorAxis: 5.25424,
    eccentricity: 0.0599017,
    inclination: 8.64871,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241928',
      },
      {
        userName: '42',
        HNames: 'H262662, H262669',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ224',
    note: 'Hilda',
    absoluteMagnitude: 18.34,
    semimajorAxis: 3.91701,
    eccentricity: 0.2454604,
    inclination: 7.7917,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241974, H251838',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260131',
      },
      {
        userName: '42',
        HNames: 'H262615, H263115',
      },
    ],
  },
  {
    provisionalCode: '2017 BS224',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 3.08915,
    eccentricity: 0.1048157,
    inclination: 14.14843,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251737',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262319',
      },
      {
        userName: '42',
        HNames: 'H262319',
      },
    ],
  },
  {
    provisionalCode: '2017 BT224',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 3.05217,
    eccentricity: 0.0598613,
    inclination: 7.55075,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261027, H262344, H264843',
      },
      {
        userName: '42',
        HNames: 'H262344',
      },
    ],
  },
  {
    provisionalCode: '2017 BD225',
    note: '',
    absoluteMagnitude: 20.85,
    semimajorAxis: 2.24285,
    eccentricity: 0.1459607,
    inclination: 5.57034,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249681',
      },
    ],
  },
  {
    provisionalCode: '2017 BE225',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.42,
    semimajorAxis: 5.23248,
    eccentricity: 0.0537223,
    inclination: 29.55562,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251736',
      },
      {
        userName: '42',
        HNames: 'H262600, H263133',
      },
    ],
  },
  {
    provisionalCode: '2017 BF225',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 3.09203,
    eccentricity: 0.1286604,
    inclination: 10.20188,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251776',
      },
      {
        userName: '42',
        HNames: 'H262323, H262658',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262658',
      },
    ],
  },
  {
    provisionalCode: '2017 BG225',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.14,
    semimajorAxis: 5.19448,
    eccentricity: 0.0479919,
    inclination: 10.40314,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248341, H250690',
      },
    ],
  },
  {
    provisionalCode: '2017 BH225',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 3.14085,
    eccentricity: 0.280387,
    inclination: 10.57252,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248377, H251283',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ225',
    note: '',
    absoluteMagnitude: 17.68,
    semimajorAxis: 3.20392,
    eccentricity: 0.1022278,
    inclination: 9.46365,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251285',
      },
      {
        userName: 'kn1cht',
        HNames: 'H251285',
      },
      {
        userName: 'もしもス',
        HNames: 'H274078',
      },
    ],
  },
  {
    provisionalCode: '2017 BV225',
    note: '',
    absoluteMagnitude: 18.07,
    semimajorAxis: 3.02163,
    eccentricity: 0.0959334,
    inclination: 8.51639,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268006, H268494',
      },
    ],
  },
  {
    provisionalCode: '2017 BY225',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.99995,
    eccentricity: 0.0673893,
    inclination: 9.6074,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248337, H251494',
      },
      {
        userName: 'kn1cht',
        HNames: 'H251494',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260063',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ225',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 2.26652,
    eccentricity: 0.1495121,
    inclination: 2.53147,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270876, H271045, H271104',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282352',
      },
    ],
  },
  {
    provisionalCode: '2017 BC226',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.33285,
    eccentricity: 0.0518181,
    inclination: 6.15262,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250518',
      },
      {
        userName: 'kn1cht',
        HNames: 'H250518',
      },
    ],
  },
  {
    provisionalCode: '2017 BE226',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.92,
    semimajorAxis: 47.4068,
    eccentricity: 0.0258733,
    inclination: 14.05613,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273819, H273828, H273835, H273839, H273849, H273862',
      },
    ],
  },
  {
    provisionalCode: '2017 BF226',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.28,
    semimajorAxis: 39.264,
    eccentricity: 0.2087749,
    inclination: 8.37525,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H241242',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273865, H273877, H274168, H274206, H274226, H274242',
      },
    ],
  },
  {
    provisionalCode: '2017 BH226',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.13119,
    eccentricity: 0.0693889,
    inclination: 12.90744,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269123',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269381',
      },
      {
        userName: 'arda',
        HNames: 'H272889',
      },
    ],
  },
  {
    provisionalCode: '2017 BK226',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.74211,
    eccentricity: 0.0442388,
    inclination: 9.05042,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251739',
      },
      {
        userName: 'kn1cht',
        HNames: 'H251739',
      },
      {
        userName: 'aika',
        HNames: 'H271576',
      },
      {
        userName: 'れお',
        HNames: 'H271576',
      },
    ],
  },
  {
    provisionalCode: '2017 BL226',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.81,
    semimajorAxis: 5.1858,
    eccentricity: 0.0315422,
    inclination: 10.33663,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250359, H251972',
      },
    ],
  },
  {
    provisionalCode: '2017 BN226',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.42775,
    eccentricity: 0.1451389,
    inclination: 7.51223,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249759',
      },
      {
        userName: 'ko',
        HNames: 'H264188',
      },
    ],
  },
  {
    provisionalCode: '2017 BP226',
    note: '',
    absoluteMagnitude: 18.03,
    semimajorAxis: 3.21864,
    eccentricity: 0.0732447,
    inclination: 8.69704,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251296, H272010',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267276',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ226',
    note: '',
    absoluteMagnitude: 18.58,
    semimajorAxis: 2.97492,
    eccentricity: 0.0927411,
    inclination: 9.33318,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250901, H252127',
      },
    ],
  },
  {
    provisionalCode: '2017 BR226',
    note: '',
    absoluteMagnitude: 19.46,
    semimajorAxis: 3.13506,
    eccentricity: 0.2236469,
    inclination: 8.16528,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H267178',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279495',
      },
    ],
  },
  {
    provisionalCode: '2017 BT226',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.87,
    semimajorAxis: 5.25014,
    eccentricity: 0.0406396,
    inclination: 16.64056,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H250513',
      },
      {
        userName: 'zorome',
        HNames: 'H250513',
      },
      {
        userName: 'arda',
        HNames: 'H265419',
      },
    ],
  },
  {
    provisionalCode: '2017 BU226',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.63322,
    eccentricity: 0.1474189,
    inclination: 9.97143,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H249883',
      },
      {
        userName: 'arda',
        HNames: 'H272531',
      },
    ],
  },
  {
    provisionalCode: '2017 BV226',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.53,
    semimajorAxis: 5.30512,
    eccentricity: 0.0757343,
    inclination: 26.17426,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248314, H250338',
      },
    ],
  },
  {
    provisionalCode: '2017 BW226',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.18498,
    eccentricity: 0.0622248,
    inclination: 8.47336,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242508, H251774',
      },
    ],
  },
  {
    provisionalCode: '2017 BX226',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.69026,
    eccentricity: 0.1448687,
    inclination: 7.86372,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242355, H247951',
      },
    ],
  },
  {
    provisionalCode: '2017 BY226',
    note: '',
    absoluteMagnitude: 18.46,
    semimajorAxis: 3.18932,
    eccentricity: 0.0752301,
    inclination: 7.95025,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248505, H249818',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ226',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.73279,
    eccentricity: 0.1441855,
    inclination: 3.56498,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202221, H202383',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261787',
      },
    ],
  },
  {
    provisionalCode: '2017 BA227',
    note: '',
    absoluteMagnitude: 20.26,
    semimajorAxis: 2.64406,
    eccentricity: 0.1325197,
    inclination: 7.17499,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250058, H250869',
      },
      {
        userName: 'kn1cht',
        HNames: 'H261791',
      },
    ],
  },
  {
    provisionalCode: '2017 BB227',
    note: '',
    absoluteMagnitude: 20.91,
    semimajorAxis: 2.24581,
    eccentricity: 0.101229,
    inclination: 6.03348,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252198',
      },
      {
        userName: '42',
        HNames: 'H262349, H262584',
      },
    ],
  },
  {
    provisionalCode: '2017 BC227',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 2.73586,
    eccentricity: 0.1720494,
    inclination: 14.76174,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251806',
      },
      {
        userName: '42',
        HNames: 'H262362, H262676',
      },
    ],
  },
  {
    provisionalCode: '2017 BD227',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 3.02509,
    eccentricity: 0.1429143,
    inclination: 7.97365,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241652, H251837',
      },
      {
        userName: '42',
        HNames: 'H262622',
      },
    ],
  },
  {
    provisionalCode: '2017 BE227',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 3.1638,
    eccentricity: 0.0428402,
    inclination: 7.98997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252991',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260130',
      },
      {
        userName: '42',
        HNames: 'H262632',
      },
    ],
  },
  {
    provisionalCode: '2017 BF227',
    note: '',
    absoluteMagnitude: 19.01,
    semimajorAxis: 3.13108,
    eccentricity: 0.1535539,
    inclination: 12.61714,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242354, H250686',
      },
    ],
  },
  {
    provisionalCode: '2017 BG227',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 3.13406,
    eccentricity: 0.180523,
    inclination: 7.00383,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242006, H242030, H247877',
      },
    ],
  },
  {
    provisionalCode: '2017 BH227',
    note: '',
    absoluteMagnitude: 20.36,
    semimajorAxis: 2.30093,
    eccentricity: 0.0579342,
    inclination: 1.86053,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202165, H202359, H202402',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ227',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.05816,
    eccentricity: 0.134097,
    inclination: 9.0178,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252195',
      },
      {
        userName: '42',
        HNames: 'H262556, H263138',
      },
      {
        userName: 'もしもス',
        HNames: 'H263138',
      },
    ],
  },
  {
    provisionalCode: '2017 BK227',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.06138,
    eccentricity: 0.0691559,
    inclination: 8.13106,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249831',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263407',
      },
    ],
  },
  {
    provisionalCode: '2017 BO227',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.18882,
    eccentricity: 0.1443757,
    inclination: 4.71892,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260986',
      },
      {
        userName: 'arda',
        HNames: 'H263567',
      },
    ],
  },
  {
    provisionalCode: '2017 BP227',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.12405,
    eccentricity: 0.0757653,
    inclination: 10.69293,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202382',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261091',
      },
      {
        userName: 'joshua',
        HNames: 'H264123',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ227',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.61162,
    eccentricity: 0.2570074,
    inclination: 11.41217,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241650',
      },
      {
        userName: 'ko',
        HNames: 'H264178',
      },
    ],
  },
  {
    provisionalCode: '2017 BR227',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.04399,
    eccentricity: 0.0715923,
    inclination: 13.87777,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H200472',
      },
      {
        userName: 'arda',
        HNames: 'H258064, H264451',
      },
    ],
  },
  {
    provisionalCode: '2017 BS227',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.49,
    semimajorAxis: 5.20052,
    eccentricity: 0.0605906,
    inclination: 13.1376,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242012',
      },
      {
        userName: 'kn1cht',
        HNames: 'H242012',
      },
      {
        userName: '42',
        HNames: 'H262663',
      },
    ],
  },
  {
    provisionalCode: '2017 BT227',
    note: '',
    absoluteMagnitude: 17.73,
    semimajorAxis: 3.0889,
    eccentricity: 0.0563377,
    inclination: 8.65873,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261025, H264844',
      },
      {
        userName: 'もしもス',
        HNames: 'H261025',
      },
      {
        userName: '42',
        HNames: 'H262341',
      },
    ],
  },
  {
    provisionalCode: '2017 BV227',
    note: '',
    absoluteMagnitude: 21.59,
    semimajorAxis: 2.68444,
    eccentricity: 0.3457753,
    inclination: 6.04599,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'こば',
        HNames: 'H204978',
      },
      {
        userName: '42',
        HNames: 'H265783',
      },
      {
        userName: 'ほたる',
        HNames: 'H280212',
      },
    ],
  },
  {
    provisionalCode: '2017 BW227',
    note: '2017 BO240と同定',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.59497,
    eccentricity: 0.1451181,
    inclination: 10.59383,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233456, H311067',
      },
      {
        userName: '42',
        HNames: 'H262355, H262565',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267751',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H311447',
      },
    ],
  },
  {
    provisionalCode: '2017 BX227',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.88129,
    eccentricity: 0.1845353,
    inclination: 10.37078,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250687, H250765, H268007',
      },
    ],
  },
  {
    provisionalCode: '2017 BY227',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.16932,
    eccentricity: 0.0557121,
    inclination: 7.69761,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250688, H268018',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ227',
    note: '',
    absoluteMagnitude: 18.05,
    semimajorAxis: 3.21945,
    eccentricity: 0.0719466,
    inclination: 10.20711,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248338, H251420',
      },
      {
        userName: 'kn1cht',
        HNames: 'H248338',
      },
    ],
  },
  {
    provisionalCode: '2017 BA228',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.21677,
    eccentricity: 0.0761487,
    inclination: 4.3851,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247992',
      },
      {
        userName: 'joshua',
        HNames: 'H267789, H269344',
      },
    ],
  },
  {
    provisionalCode: '2017 BB228',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 2.86344,
    eccentricity: 0.1071277,
    inclination: 4.69236,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H203364, H203398',
      },
      {
        userName: 'joshua',
        HNames: 'H269361',
      },
    ],
  },
  {
    provisionalCode: '2017 BC228',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.22946,
    eccentricity: 0.1455103,
    inclination: 6.38828,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260737, H267809',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270787',
      },
    ],
  },
  {
    provisionalCode: '2017 BD228',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.14951,
    eccentricity: 0.0832717,
    inclination: 11.70268,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202092',
      },
      {
        userName: 'joshua',
        HNames: 'H267787, H270874',
      },
    ],
  },
  {
    provisionalCode: '2017 BE228',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 3.1392,
    eccentricity: 0.0982008,
    inclination: 7.73669,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265948, H273556',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273370',
      },
    ],
  },
  {
    provisionalCode: '2017 BF228',
    note: '',
    absoluteMagnitude: 20.33,
    semimajorAxis: 2.66511,
    eccentricity: 0.1333289,
    inclination: 5.37238,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220203',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273587',
      },
    ],
  },
  {
    provisionalCode: '2017 BG228',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 2.34633,
    eccentricity: 0.0679877,
    inclination: 6.25803,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267803',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273588',
      },
    ],
  },
  {
    provisionalCode: '2017 BH228',
    note: '',
    absoluteMagnitude: 19.63,
    semimajorAxis: 3.1724,
    eccentricity: 0.0489004,
    inclination: 9.41678,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261067, H267658',
      },
      {
        userName: 'もしもス',
        HNames: 'H261067',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ228',
    note: '',
    absoluteMagnitude: 18.42,
    semimajorAxis: 3.5606,
    eccentricity: 0.0678213,
    inclination: 7.01076,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261068, H262562',
      },
      {
        userName: 'れお',
        HNames: 'H261068',
      },
      {
        userName: 'もしもス',
        HNames: 'H261068',
      },
      {
        userName: '42',
        HNames: 'H262351, H262562',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312039',
      },
    ],
  },
  {
    provisionalCode: '2017 BK228',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.0516,
    eccentricity: 0.1332655,
    inclination: 8.29277,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H268181',
      },
      {
        userName: 'zorome',
        HNames: 'H271116, H274415',
      },
    ],
  },
  {
    provisionalCode: '2017 BL228',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.54,
    semimajorAxis: 5.24289,
    eccentricity: 0.0397775,
    inclination: 11.66031,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260738, H268365',
      },
      {
        userName: 'kn1cht',
        HNames: 'H260738',
      },
    ],
  },
  {
    provisionalCode: '2017 BM228',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 3.06087,
    eccentricity: 0.0244608,
    inclination: 7.6918,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242513, H275574',
      },
    ],
  },
  {
    provisionalCode: '2017 BO228',
    note: '',
    absoluteMagnitude: 20.01,
    semimajorAxis: 3.14846,
    eccentricity: 0.1043462,
    inclination: 8.382,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261024, H262714',
      },
      {
        userName: 'aika',
        HNames: 'H262714',
      },
      {
        userName: '42',
        HNames: 'H262714',
      },
    ],
  },
  {
    provisionalCode: '2017 BP228',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.81767,
    eccentricity: 0.1315639,
    inclination: 7.6756,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249848',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282119',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ228',
    note: '',
    absoluteMagnitude: 19.37,
    semimajorAxis: 2.57326,
    eccentricity: 0.119203,
    inclination: 7.76897,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250068',
      },
      {
        userName: 'kn1cht',
        HNames: 'H250068, H282121',
      },
    ],
  },
  {
    provisionalCode: '2017 BR228',
    note: '',
    absoluteMagnitude: 19.46,
    semimajorAxis: 3.04081,
    eccentricity: 0.1209476,
    inclination: 9.54405,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267682',
      },
      {
        userName: 'もしもス',
        HNames: 'H274109',
      },
      {
        userName: 'aika',
        HNames: 'H277947',
      },
    ],
  },
  {
    provisionalCode: '2017 BS228',
    note: '',
    absoluteMagnitude: 18.63,
    semimajorAxis: 2.69443,
    eccentricity: 0.1334552,
    inclination: 11.14634,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'l10es',
        HNames: 'H204389',
      },
      {
        userName: 'joshua',
        HNames: 'H260286',
      },
      {
        userName: 'maria w',
        HNames: 'H278512',
      },
    ],
  },
  {
    provisionalCode: '2017 BT228',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.03928,
    eccentricity: 0.0946574,
    inclination: 9.76858,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270312, H270536, H278716',
      },
    ],
  },
  {
    provisionalCode: '2017 BU228',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.83562,
    eccentricity: 0.0657013,
    inclination: 17.87769,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270305, H271166, H278721',
      },
    ],
  },
  {
    provisionalCode: '2017 BV228',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 2.98665,
    eccentricity: 0.0711415,
    inclination: 9.5288,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270324, H270541, H278722',
      },
    ],
  },
  {
    provisionalCode: '2017 BW228',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 3.06681,
    eccentricity: 0.0092221,
    inclination: 9.67434,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270321, H270542, H278723',
      },
    ],
  },
  {
    provisionalCode: '2017 BX228',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.72,
    semimajorAxis: 5.21258,
    eccentricity: 0.0421898,
    inclination: 31.46512,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270313, H278365',
      },
    ],
  },
  {
    provisionalCode: '2017 BY228',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.64312,
    eccentricity: 0.2560222,
    inclination: 11.89539,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269557, H278790',
      },
      {
        userName: 'yakuba',
        HNames: 'H276224',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288238, H288703',
      },
    ],
  },
  {
    provisionalCode: '2017 BA229',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.0,
    semimajorAxis: 5.14194,
    eccentricity: 0.0726953,
    inclination: 15.3527,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265415',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265415, H266591, H281443',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311045',
      },
    ],
  },
  {
    provisionalCode: '2017 BB229',
    note: '',
    absoluteMagnitude: 18.8,
    semimajorAxis: 3.02762,
    eccentricity: 0.1171581,
    inclination: 8.8137,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273761, H274522, H279489',
      },
    ],
  },
  {
    provisionalCode: '2017 BC229',
    note: '',
    absoluteMagnitude: 20.71,
    semimajorAxis: 2.4378,
    eccentricity: 0.0679918,
    inclination: 6.35277,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267673',
      },
      {
        userName: 'れお',
        HNames: 'H267673',
      },
      {
        userName: 'arda',
        HNames: 'H278620',
      },
    ],
  },
  {
    provisionalCode: '2017 BD229',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 2.88767,
    eccentricity: 0.0765851,
    inclination: 6.90952,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H237327',
      },
      {
        userName: 'もしもス',
        HNames: 'H237327',
      },
      {
        userName: 'zorome',
        HNames: 'H247879',
      },
    ],
  },
  {
    provisionalCode: '2017 BE229',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 3.18433,
    eccentricity: 0.1939016,
    inclination: 10.05638,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H279990, H280017, H280044, H280105',
      },
      {
        userName: 'もしもス',
        HNames: 'H280017',
      },
    ],
  },
  {
    provisionalCode: '2017 BF229',
    note: '2022 MT9と同定: Jupiter Trojan',
    absoluteMagnitude: 14.7,
    semimajorAxis: 5.26551,
    eccentricity: 0.08959,
    inclination: 21.83905,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H278970, H278978',
      },
      {
        userName: 'aika',
        HNames: 'H279064',
      },
      {
        userName: 'もしもス',
        HNames: 'H279064, H280261',
      },
    ],
  },
  {
    provisionalCode: '2017 BH229',
    note: '',
    absoluteMagnitude: 17.2,
    semimajorAxis: 3.12177,
    eccentricity: 0.2356939,
    inclination: 15.98667,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251518',
      },
      {
        userName: 'ko',
        HNames: 'H280264',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ229',
    note: 'NEO (Amor)',
    absoluteMagnitude: 27.53,
    semimajorAxis: 1.22385,
    eccentricity: 0.1327942,
    inclination: 2.01663,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249752',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280402, H280418',
      },
    ],
  },
  {
    provisionalCode: '2017 CL52',
    note: 'Hilda',
    absoluteMagnitude: 17.47,
    semimajorAxis: 3.95984,
    eccentricity: 0.2346554,
    inclination: 14.36385,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H219876, H248379',
      },
      {
        userName: 'けんたろうのすけ',
        HNames: 'H219876',
      },
    ],
  },
  {
    provisionalCode: '2017 CN52',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 3.04237,
    eccentricity: 0.0064469,
    inclination: 10.68011,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262367',
      },
      {
        userName: '42',
        HNames: 'H262367',
      },
      {
        userName: 'arda',
        HNames: 'H282656',
      },
    ],
  },
  {
    provisionalCode: '2017 CO52',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.01,
    semimajorAxis: 5.17652,
    eccentricity: 0.1046803,
    inclination: 16.81371,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251726',
      },
      {
        userName: 'れお',
        HNames: 'H281640',
      },
    ],
  },
  {
    provisionalCode: '2017 CR52',
    note: '',
    absoluteMagnitude: 17.55,
    semimajorAxis: 3.17258,
    eccentricity: 0.0172662,
    inclination: 8.55314,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265759, H273938',
      },
    ],
  },
  {
    provisionalCode: '2017 CS52',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 2.67503,
    eccentricity: 0.0471504,
    inclination: 11.22526,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271121, H275904',
      },
      {
        userName: 'arda',
        HNames: 'H272539',
      },
    ],
  },
  {
    provisionalCode: '2017 CT52',
    note: '',
    absoluteMagnitude: 18.0,
    semimajorAxis: 2.97802,
    eccentricity: 0.1095011,
    inclination: 8.27616,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273319',
      },
      {
        userName: 'れお',
        HNames: 'H278562',
      },
      {
        userName: 'arda',
        HNames: 'H278562',
      },
    ],
  },
  {
    provisionalCode: '2017 CV52',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.56135,
    eccentricity: 0.1065158,
    inclination: 12.24284,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270540, H278702',
      },
      {
        userName: 'ko',
        HNames: 'H275691',
      },
    ],
  },
  {
    provisionalCode: '2017 CW52',
    note: '',
    absoluteMagnitude: 18.09,
    semimajorAxis: 3.14575,
    eccentricity: 0.0580199,
    inclination: 9.32629,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242316',
      },
    ],
  },
  {
    provisionalCode: '2017 CY52',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 2.98183,
    eccentricity: 0.0816791,
    inclination: 8.79246,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248304',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ52',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 2.40766,
    eccentricity: 0.1368579,
    inclination: 2.05752,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201378, H204954, H205199',
      },
    ],
  },
  {
    provisionalCode: '2017 CA53',
    note: '',
    absoluteMagnitude: 18.46,
    semimajorAxis: 3.05767,
    eccentricity: 0.0527313,
    inclination: 10.91728,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: '42',
        HNames: 'H262728, H263156',
      },
      {
        userName: 'ko',
        HNames: 'H262922',
      },
    ],
  },
  {
    provisionalCode: '2017 CC53',
    note: '',
    absoluteMagnitude: 20.98,
    semimajorAxis: 2.28728,
    eccentricity: 0.0472052,
    inclination: 2.15145,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261054, H261128',
      },
      {
        userName: 'joshua',
        HNames: 'H269431',
      },
    ],
  },
  {
    provisionalCode: '2017 CD53',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.99574,
    eccentricity: 0.1247221,
    inclination: 2.58876,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261030',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261570',
      },
      {
        userName: 'joshua',
        HNames: 'H269447',
      },
    ],
  },
  {
    provisionalCode: '2017 CE53',
    note: '',
    absoluteMagnitude: 20.81,
    semimajorAxis: 2.34706,
    eccentricity: 0.1547566,
    inclination: 3.02036,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264617, H264753',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271430',
      },
    ],
  },
  {
    provisionalCode: '2017 CF53',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 3.03349,
    eccentricity: 0.0959356,
    inclination: 8.42821,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241591, H272030',
      },
    ],
  },
  {
    provisionalCode: '2017 CG53',
    note: '2014 SV29と同定',
    absoluteMagnitude: 18.64,
    semimajorAxis: 2.76213,
    eccentricity: 0.0952588,
    inclination: 3.8571,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H211731',
      },
      {
        userName: 'arda',
        HNames: 'H264182',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276748',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ53',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 3.03985,
    eccentricity: 0.0755618,
    inclination: 9.49842,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273891, H278394, H282279',
      },
    ],
  },
  {
    provisionalCode: '2017 CK53',
    note: '',
    absoluteMagnitude: 19.98,
    semimajorAxis: 3.20879,
    eccentricity: 0.0306541,
    inclination: 11.33299,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262324, H262677',
      },
      {
        userName: 'arda',
        HNames: 'H278624',
      },
    ],
  },
  {
    provisionalCode: '2017 CL53',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.5887,
    eccentricity: 0.105909,
    inclination: 8.0647,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271158, H271464, H278700',
      },
    ],
  },
  {
    provisionalCode: '2017 CM53',
    note: '',
    absoluteMagnitude: 20.72,
    semimajorAxis: 2.25738,
    eccentricity: 0.1578341,
    inclination: 2.42085,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H212356',
      },
      {
        userName: 'arda',
        HNames: 'H261600, H261604',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276188',
      },
    ],
  },
  {
    provisionalCode: '2017 CN53',
    note: '',
    absoluteMagnitude: 22.04,
    semimajorAxis: 2.28049,
    eccentricity: 0.2525517,
    inclination: 6.98403,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263857, H266185',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H265642',
      },
    ],
  },
  {
    provisionalCode: '2017 CO53',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.70086,
    eccentricity: 0.0525901,
    inclination: 3.08313,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261566',
      },
      {
        userName: 'joshua',
        HNames: 'H269430',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271367',
      },
    ],
  },
  {
    provisionalCode: '2017 CP53',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 3.04786,
    eccentricity: 0.0667927,
    inclination: 10.60291,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273033',
      },
      {
        userName: 'arda',
        HNames: 'H278468',
      },
      {
        userName: 'もしもス',
        HNames: 'H278478',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ53',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.72721,
    eccentricity: 0.1802014,
    inclination: 6.34994,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H263165, H265873',
      },
      {
        userName: 'ほたる',
        HNames: 'H280211',
      },
    ],
  },
  {
    provisionalCode: '2017 CR53',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 2.25768,
    eccentricity: 0.1325067,
    inclination: 6.89419,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '42',
        HNames: 'H263166, H265784',
      },
      {
        userName: 'arda',
        HNames: 'H266004',
      },
    ],
  },
  {
    provisionalCode: '2017 CS53',
    note: '2017 BM240と同定',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.0111,
    eccentricity: 0.0644069,
    inclination: 10.29322,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H263171',
      },
      {
        userName: '42',
        HNames: 'H263171',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273242',
      },
    ],
  },
  {
    provisionalCode: '2017 CT53',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 3.07571,
    eccentricity: 0.1598729,
    inclination: 7.60486,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252254',
      },
      {
        userName: '42',
        HNames: 'H262644, H263112',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263112',
      },
    ],
  },
  {
    provisionalCode: '2017 CU53',
    note: '',
    absoluteMagnitude: 21.34,
    semimajorAxis: 2.2577,
    eccentricity: 0.1194318,
    inclination: 5.93616,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H262648, H263114',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263114',
      },
    ],
  },
  {
    provisionalCode: '2017 DS156',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.33797,
    eccentricity: 0.1307333,
    inclination: 3.11824,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H206090',
      },
      {
        userName: 'zorome',
        HNames: 'H247054',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261484, H261534',
      },
    ],
  },
  {
    provisionalCode: '2017 DT156',
    note: '',
    absoluteMagnitude: 21.14,
    semimajorAxis: 2.31611,
    eccentricity: 0.1480877,
    inclination: 2.79942,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H211478, H212272',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261850, H261885',
      },
    ],
  },
  {
    provisionalCode: '2017 DX156',
    note: '',
    absoluteMagnitude: 18.2,
    semimajorAxis: 3.01685,
    eccentricity: 0.1693829,
    inclination: 17.86171,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260774',
      },
    ],
  },
  {
    provisionalCode: '2017 DE157',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.05,
    semimajorAxis: 80.2108,
    eccentricity: 0.5884292,
    inclination: 17.52908,
    oppositions: 4,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269890',
      },
      {
        userName: 'aika',
        HNames: 'H271473, H271496, H271567, H271580, H271600, H271712, H271779',
      },
      {
        userName: 'もしもス',
        HNames: 'H271580',
      },
    ],
  },
  {
    provisionalCode: '2017 DH157',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 2.73709,
    eccentricity: 0.0229533,
    inclination: 4.60276,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243704',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275432',
      },
    ],
  },
  {
    provisionalCode: '2017 DK157',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.35015,
    eccentricity: 0.0958897,
    inclination: 5.23444,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271463, H275422, H275441',
      },
      {
        userName: 'joshua',
        HNames: 'H282407',
      },
    ],
  },
  {
    provisionalCode: '2017 DL157',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 3.08218,
    eccentricity: 0.1175665,
    inclination: 13.40574,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H235332',
      },
      {
        userName: 'もしもス',
        HNames: 'H268144',
      },
      {
        userName: 'arda',
        HNames: 'H268144',
      },
      {
        userName: 'aika',
        HNames: 'H268144',
      },
      {
        userName: 'nasiar99',
        HNames: 'H275325',
      },
    ],
  },
  {
    provisionalCode: '2017 DM157',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 3.0926,
    eccentricity: 0.1792778,
    inclination: 8.33249,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241734',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241734',
      },
    ],
  },
  {
    provisionalCode: '2017 DN157',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.08401,
    eccentricity: 0.0127749,
    inclination: 9.28382,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241738',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241738',
      },
    ],
  },
  {
    provisionalCode: '2017 DR157',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.86676,
    eccentricity: 0.0198697,
    inclination: 2.71757,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202085',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261064, H261848',
      },
    ],
  },
  {
    provisionalCode: '2017 DS157',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.59676,
    eccentricity: 0.2127009,
    inclination: 5.79161,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H256737',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261943, H262050',
      },
    ],
  },
  {
    provisionalCode: '2017 DT157',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.08877,
    eccentricity: 0.2017285,
    inclination: 16.0407,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201615',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262046, H262047',
      },
    ],
  },
  {
    provisionalCode: '2017 DU157',
    note: '',
    absoluteMagnitude: 20.87,
    semimajorAxis: 2.73189,
    eccentricity: 0.1334162,
    inclination: 3.57691,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202398',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262286, H262403',
      },
    ],
  },
  {
    provisionalCode: '2017 DV157',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.90174,
    eccentricity: 0.1607498,
    inclination: 2.84853,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202013',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262058, H262308',
      },
    ],
  },
  {
    provisionalCode: '2017 DW157',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 2.92417,
    eccentricity: 0.0847614,
    inclination: 2.68761,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202184',
      },
      {
        userName: 'joshua',
        HNames: 'H255711',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262377, H262409',
      },
    ],
  },
  {
    provisionalCode: '2017 DX157',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.11329,
    eccentricity: 0.1415664,
    inclination: 2.27773,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202095',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262442, H262506',
      },
    ],
  },
  {
    provisionalCode: '2017 DY157',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.0652,
    eccentricity: 0.0522807,
    inclination: 11.6145,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H258016',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262796',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ157',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 2.96751,
    eccentricity: 0.0824916,
    inclination: 8.0098,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H205180',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263956, H263969',
      },
    ],
  },
  {
    provisionalCode: '2017 DA158',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.62083,
    eccentricity: 0.1479577,
    inclination: 9.80208,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201379',
      },
      {
        userName: 'joshua',
        HNames: 'H268372, H268785',
      },
    ],
  },
  {
    provisionalCode: '2017 DD158',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 3.03355,
    eccentricity: 0.0706104,
    inclination: 9.10812,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272729, H275554, H275565',
      },
    ],
  },
  {
    provisionalCode: '2017 DE158',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.72291,
    eccentricity: 0.0431355,
    inclination: 5.79307,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H269292, H282413',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275977',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ158',
    note: '2018 LZ51と同定',
    absoluteMagnitude: 18.08,
    semimajorAxis: 3.14828,
    eccentricity: 0.1580952,
    inclination: 7.97855,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'arda',
        HNames: 'H260851',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261830',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263796',
      },
    ],
  },
  {
    provisionalCode: '2017 DK158',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.53,
    semimajorAxis: 5.21173,
    eccentricity: 0.1137276,
    inclination: 18.73598,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241571, H248176',
      },
    ],
  },
  {
    provisionalCode: '2017 DL158',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.62914,
    eccentricity: 0.1585516,
    inclination: 5.21971,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'arda',
        HNames: 'H260842, H260850',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261503',
      },
    ],
  },
  {
    provisionalCode: '2017 DN158',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.48,
    semimajorAxis: 5.17997,
    eccentricity: 0.079782,
    inclination: 18.63417,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250729, H282288',
      },
    ],
  },
  {
    provisionalCode: '2017 DO158',
    note: '2014 OB130と同定',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.7425,
    eccentricity: 0.1477657,
    inclination: 16.53175,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249487',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H250476',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ158',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 2.99818,
    eccentricity: 0.080854,
    inclination: 2.96722,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H212394',
      },
      {
        userName: 'zorome',
        HNames: 'H243633',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243633',
      },
    ],
  },
  {
    provisionalCode: '2017 DR158',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.33866,
    eccentricity: 0.056343,
    inclination: 8.45494,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243486',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247851',
      },
    ],
  },
  {
    provisionalCode: '2017 DS158',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 2.71814,
    eccentricity: 0.0561399,
    inclination: 3.64973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243110',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260883',
      },
    ],
  },
  {
    provisionalCode: '2017 DT158',
    note: '',
    absoluteMagnitude: 18.19,
    semimajorAxis: 3.11758,
    eccentricity: 0.0572385,
    inclination: 10.84367,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243220',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263767',
      },
    ],
  },
  {
    provisionalCode: '2017 DV158',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.59704,
    eccentricity: 0.1741593,
    inclination: 3.02639,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247364',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H266198, H266409',
      },
    ],
  },
  {
    provisionalCode: '2017 DW158',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.75065,
    eccentricity: 0.1288297,
    inclination: 3.51449,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ないとう',
        HNames: 'H230121',
      },
      {
        userName: 'arda',
        HNames: 'H255185',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267474',
      },
    ],
  },
  {
    provisionalCode: '2017 DX158',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.63638,
    eccentricity: 0.0952405,
    inclination: 3.0074,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244006, H260884',
      },
      {
        userName: 'zorome',
        HNames: 'H244006',
      },
    ],
  },
  {
    provisionalCode: '2017 DY158',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.6955,
    eccentricity: 0.1084301,
    inclination: 3.78569,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245311',
      },
      {
        userName: 'zorome',
        HNames: 'H245311',
      },
      {
        userName: 'arda',
        HNames: 'H253613',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ158',
    note: '',
    absoluteMagnitude: 21.2,
    semimajorAxis: 2.27372,
    eccentricity: 0.153125,
    inclination: 2.64723,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243678, H246790',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272994',
      },
    ],
  },
  {
    provisionalCode: '2017 DA159',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 2.56538,
    eccentricity: 0.1359766,
    inclination: 8.9533,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243545',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275133',
      },
    ],
  },
  {
    provisionalCode: '2017 DB159',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 2.85564,
    eccentricity: 0.0455968,
    inclination: 3.23493,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246785, H275176',
      },
      {
        userName: 'zorome',
        HNames: 'H246785',
      },
    ],
  },
  {
    provisionalCode: '2017 DC159',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.87248,
    eccentricity: 0.0770335,
    inclination: 3.21,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246786, H275177',
      },
      {
        userName: 'zorome',
        HNames: 'H246786',
      },
    ],
  },
  {
    provisionalCode: '2017 DD159',
    note: '',
    absoluteMagnitude: 18.92,
    semimajorAxis: 2.75402,
    eccentricity: 0.0457397,
    inclination: 4.35173,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H206049',
      },
      {
        userName: 'zorome',
        HNames: 'H243709',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275544',
      },
    ],
  },
  {
    provisionalCode: '2017 DE159',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.11433,
    eccentricity: 0.0427251,
    inclination: 6.0881,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H212476, H244601',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275581',
      },
    ],
  },
  {
    provisionalCode: '2017 DF159',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 2.46825,
    eccentricity: 0.0463426,
    inclination: 8.71409,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243443',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276106',
      },
    ],
  },
  {
    provisionalCode: '2017 DG159',
    note: '',
    absoluteMagnitude: 19.94,
    semimajorAxis: 2.55859,
    eccentricity: 0.0843174,
    inclination: 6.61002,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242313',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279411',
      },
    ],
  },
  {
    provisionalCode: '2017 DH159',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 2.76576,
    eccentricity: 0.1640724,
    inclination: 7.30931,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250730, H272400',
      },
      {
        userName: 'yakuba',
        HNames: 'H274569',
      },
    ],
  },
  {
    provisionalCode: '2017 EF50',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.37027,
    eccentricity: 0.1256076,
    inclination: 2.08837,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H211958',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260900, H261571',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261571',
      },
    ],
  },
  {
    provisionalCode: '2017 EO50',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.23,
    semimajorAxis: 43.26906,
    eccentricity: 0.0636437,
    inclination: 10.58808,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H241845',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267341, H267773, H267812, H267825, H267866, H267871',
      },
    ],
  },
  {
    provisionalCode: '2017 EP50',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.12359,
    eccentricity: 0.1886636,
    inclination: 9.78187,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ryo-k',
        HNames: 'H218208',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H250523',
      },
      {
        userName: 'zorome',
        HNames: 'H265793',
      },
    ],
  },
  {
    provisionalCode: '2017 EQ50',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 3.00887,
    eccentricity: 0.1794212,
    inclination: 3.87685,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H242051',
      },
      {
        userName: 'arda',
        HNames: 'H256736',
      },
    ],
  },
  {
    provisionalCode: '2017 ER50',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 3.06056,
    eccentricity: 0.080785,
    inclination: 2.6917,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202129',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H260946, H261497',
      },
    ],
  },
  {
    provisionalCode: '2017 ES50',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 2.39851,
    eccentricity: 0.1341018,
    inclination: 2.14756,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H212559',
      },
      {
        userName: 'zorome',
        HNames: 'H243409',
      },
    ],
  },
  {
    provisionalCode: '2017 ET50',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.85279,
    eccentricity: 0.0580672,
    inclination: 3.09099,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H206018',
      },
      {
        userName: 'zorome',
        HNames: 'H243169',
      },
    ],
  },
  {
    provisionalCode: '2017 EU50',
    note: 'Phocaea',
    absoluteMagnitude: 19.0,
    semimajorAxis: 2.32557,
    eccentricity: 0.2287641,
    inclination: 22.96564,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H211403',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246428',
      },
      {
        userName: 'zorome',
        HNames: 'H246428',
      },
    ],
  },
  {
    provisionalCode: '2017 EV50',
    note: '',
    absoluteMagnitude: 21.03,
    semimajorAxis: 2.17641,
    eccentricity: 0.1589045,
    inclination: 5.56708,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263873',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268209',
      },
    ],
  },
  {
    provisionalCode: '2017 EW50',
    note: '',
    absoluteMagnitude: 21.45,
    semimajorAxis: 2.31363,
    eccentricity: 0.1804256,
    inclination: 2.93313,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H266389, H268548, H268559',
      },
    ],
  },
  {
    provisionalCode: '2017 EX50',
    note: '2014 NN92と同定',
    absoluteMagnitude: 19.14,
    semimajorAxis: 2.56589,
    eccentricity: 0.1407569,
    inclination: 5.31501,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247357',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H268684',
      },
    ],
  },
  {
    provisionalCode: '2017 EY50',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.45312,
    eccentricity: 0.1317843,
    inclination: 3.71958,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H266229, H268723',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268723',
      },
    ],
  },
  {
    provisionalCode: '2017 EZ50',
    note: '',
    absoluteMagnitude: 20.84,
    semimajorAxis: 2.21388,
    eccentricity: 0.116194,
    inclination: 3.01052,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267564',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H269691',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ227',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 3.38974,
    eccentricity: 0.014021,
    inclination: 8.80974,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251892',
      },
      {
        userName: 'aika',
        HNames: 'H260823',
      },
      {
        userName: 'もしもス',
        HNames: 'H262769',
      },
    ],
  },
  {
    provisionalCode: '2017 FP227',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 2.67403,
    eccentricity: 0.1193917,
    inclination: 6.93608,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262925, H268891',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ227',
    note: '',
    absoluteMagnitude: 20.21,
    semimajorAxis: 2.72784,
    eccentricity: 0.1935842,
    inclination: 7.10353,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259693',
      },
    ],
  },
  {
    provisionalCode: '2017 FR227',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 2.67156,
    eccentricity: 0.0954335,
    inclination: 9.65219,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H258026',
      },
    ],
  },
  {
    provisionalCode: '2017 FS227',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 3.17204,
    eccentricity: 0.0945538,
    inclination: 11.05291,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247303, H256605, H267158',
      },
    ],
  },
  {
    provisionalCode: '2017 FT227',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 3.1091,
    eccentricity: 0.0354368,
    inclination: 7.60739,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257462',
      },
      {
        userName: 'arda',
        HNames: 'H266026',
      },
      {
        userName: 'ko',
        HNames: 'H270856',
      },
    ],
  },
  {
    provisionalCode: '2017 FA228',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.56759,
    eccentricity: 0.0767946,
    inclination: 9.14278,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263411',
      },
      {
        userName: 'kn1cht',
        HNames: 'H268839',
      },
      {
        userName: 'ko',
        HNames: 'H270861',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H270861',
      },
    ],
  },
  {
    provisionalCode: '2017 FB228',
    note: '',
    absoluteMagnitude: 18.11,
    semimajorAxis: 3.1704,
    eccentricity: 0.0561625,
    inclination: 8.17789,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243104, H243214, H243705',
      },
    ],
  },
  {
    provisionalCode: '2017 FC228',
    note: 'NEO (Amor)',
    absoluteMagnitude: 24.74,
    semimajorAxis: 1.7261,
    eccentricity: 0.3311623,
    inclination: 6.27642,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244474',
      },
      {
        userName: 'aika',
        HNames: 'H249485',
      },
    ],
  },
  {
    provisionalCode: '2017 FD228',
    note: '',
    absoluteMagnitude: 20.71,
    semimajorAxis: 2.4745,
    eccentricity: 0.0415236,
    inclination: 8.67866,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H221437, H250978',
      },
      {
        userName: 'もしもス',
        HNames: 'H221437',
      },
      {
        userName: 'ms',
        HNames: 'H274270',
      },
    ],
  },
  {
    provisionalCode: '2017 FE228',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.80324,
    eccentricity: 0.2296214,
    inclination: 6.65685,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244450, H244451',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244451',
      },
      {
        userName: 'ms',
        HNames: 'H274518, H274541, H274706, H274745',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ228',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 3.06893,
    eccentricity: 0.101185,
    inclination: 7.58701,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242659',
      },
      {
        userName: 'aika',
        HNames: 'H243920',
      },
      {
        userName: 'ms',
        HNames: 'H274539, H274746, H275938',
      },
    ],
  },
  {
    provisionalCode: '2017 FK228',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.99585,
    eccentricity: 0.0820098,
    inclination: 9.44803,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243921',
      },
      {
        userName: 'ms',
        HNames: 'H274705, H276154, H276197, H276248',
      },
    ],
  },
  {
    provisionalCode: '2017 FO228',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.84543,
    eccentricity: 0.0860568,
    inclination: 7.92979,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247526, H250455, H256292, H267143',
      },
      {
        userName: 'ko',
        HNames: 'H270857',
      },
    ],
  },
  {
    provisionalCode: '2017 FP228',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 2.69662,
    eccentricity: 0.0751269,
    inclination: 10.44308,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250454, H251079, H267142',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267142',
      },
      {
        userName: 'ko',
        HNames: 'H270855',
      },
    ],
  },
  {
    provisionalCode: '2017 FS228',
    note: '2015 FM37と同定: Mars Crosser',
    absoluteMagnitude: 21.25,
    semimajorAxis: 1.64512,
    eccentricity: 0.0987373,
    inclination: 24.81027,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249300',
      },
    ],
  },
  {
    provisionalCode: '2017 FT228',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 3.18135,
    eccentricity: 0.0471557,
    inclination: 8.70396,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H242657',
      },
      {
        userName: 'もしもス',
        HNames: 'H242657',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H256092, H274744',
      },
      {
        userName: 'kn1cht',
        HNames: 'H256092',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H256092',
      },
      {
        userName: 'ms',
        HNames: 'H274744',
      },
    ],
  },
  {
    provisionalCode: '2017 FU228',
    note: '',
    absoluteMagnitude: 21.26,
    semimajorAxis: 2.59213,
    eccentricity: 0.2401749,
    inclination: 31.5379,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256604',
      },
    ],
  },
  {
    provisionalCode: '2017 FV228',
    note: '',
    absoluteMagnitude: 19.89,
    semimajorAxis: 3.20266,
    eccentricity: 0.0793626,
    inclination: 7.60205,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H266081, H267231',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267231',
      },
    ],
  },
  {
    provisionalCode: '2017 FW228',
    note: '',
    absoluteMagnitude: 18.07,
    semimajorAxis: 3.21861,
    eccentricity: 0.205364,
    inclination: 25.52093,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259733',
      },
      {
        userName: 'れお',
        HNames: 'H259733, H279573',
      },
    ],
  },
  {
    provisionalCode: '2017 FX228',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 2.54532,
    eccentricity: 0.0917996,
    inclination: 14.25942,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245457',
      },
    ],
  },
  {
    provisionalCode: '2017 FY228',
    note: '',
    absoluteMagnitude: 21.17,
    semimajorAxis: 2.18222,
    eccentricity: 0.1388724,
    inclination: 5.04425,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244908, H245462, H279717',
      },
      {
        userName: 'aika',
        HNames: 'H245462',
      },
    ],
  },
  {
    provisionalCode: '2017 FH229',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 2.99554,
    eccentricity: 0.0343826,
    inclination: 8.6465,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250468',
      },
      {
        userName: 'zorome',
        HNames: 'H252230',
      },
      {
        userName: 'もしもス',
        HNames: 'H255684',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ229',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.27101,
    eccentricity: 0.0329149,
    inclination: 6.0182,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251899',
      },
      {
        userName: 'aika',
        HNames: 'H256320, H259572',
      },
    ],
  },
  {
    provisionalCode: '2017 FK229',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 2.62765,
    eccentricity: 0.2453598,
    inclination: 15.84299,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243497, H257517',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263390',
      },
    ],
  },
  {
    provisionalCode: '2017 FL229',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 3.20162,
    eccentricity: 0.0629125,
    inclination: 8.1455,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243192, H267125',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267125',
      },
    ],
  },
  {
    provisionalCode: '2017 FM229',
    note: '',
    absoluteMagnitude: 19.92,
    semimajorAxis: 3.0281,
    eccentricity: 0.2491983,
    inclination: 19.13613,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241899',
      },
      {
        userName: 'aika',
        HNames: 'H245909, H267145',
      },
    ],
  },
  {
    provisionalCode: '2017 FN229',
    note: '',
    absoluteMagnitude: 19.46,
    semimajorAxis: 2.99118,
    eccentricity: 0.0869474,
    inclination: 9.26538,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H224506, H267686',
      },
      {
        userName: 'kn1cht',
        HNames: 'H224506',
      },
    ],
  },
  {
    provisionalCode: '2017 FO229',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 2.68069,
    eccentricity: 0.0840357,
    inclination: 18.34005,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247300',
      },
      {
        userName: '赵经远',
        HNames: 'H262482',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270066',
      },
    ],
  },
  {
    provisionalCode: '2017 FP229',
    note: '',
    absoluteMagnitude: 19.41,
    semimajorAxis: 2.69293,
    eccentricity: 0.0695686,
    inclination: 7.19135,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241357, H248368',
      },
      {
        userName: 'arda',
        HNames: 'H270292',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ229',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.27285,
    eccentricity: 0.0599305,
    inclination: 6.50882,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244442',
      },
      {
        userName: 'もしもス',
        HNames: 'H270720',
      },
    ],
  },
  {
    provisionalCode: '2017 FR229',
    note: '2023 TM153と同定',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.55594,
    eccentricity: 0.1802482,
    inclination: 7.51842,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266027',
      },
      {
        userName: 'ko',
        HNames: 'H270853',
      },
      {
        userName: 'aika',
        HNames: 'H282333',
      },
    ],
  },
  {
    provisionalCode: '2017 FS229',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.56397,
    eccentricity: 0.1518737,
    inclination: 8.45578,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242081',
      },
      {
        userName: 'arda',
        HNames: 'H266024',
      },
      {
        userName: 'ko',
        HNames: 'H266024',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282075',
      },
    ],
  },
  {
    provisionalCode: '2017 FT229',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 3.12021,
    eccentricity: 0.2272297,
    inclination: 9.68875,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249471, H290920',
      },
      {
        userName: 'ko',
        HNames: 'H249471',
      },
      {
        userName: 'れお',
        HNames: 'H249471',
      },
      {
        userName: 'arda',
        HNames: 'H262189',
      },
    ],
  },
  {
    provisionalCode: '2017 FU229',
    note: '',
    absoluteMagnitude: 18.54,
    semimajorAxis: 3.01272,
    eccentricity: 0.0243984,
    inclination: 7.0761,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252091',
      },
      {
        userName: 'ほたる',
        HNames: 'H259543',
      },
      {
        userName: 'aika',
        HNames: 'H259543',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271196',
      },
    ],
  },
  {
    provisionalCode: '2017 FV229',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 3.04813,
    eccentricity: 0.1125373,
    inclination: 10.27498,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220779',
      },
      {
        userName: 'aika',
        HNames: 'H249745',
      },
    ],
  },
  {
    provisionalCode: '2017 FW229',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.60476,
    eccentricity: 0.1813618,
    inclination: 13.16697,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H263515',
      },
      {
        userName: 'aika',
        HNames: 'H264751, H267898',
      },
      {
        userName: 'ko',
        HNames: 'H264751',
      },
      {
        userName: 'nasiar99',
        HNames: 'H264751',
      },
    ],
  },
  {
    provisionalCode: '2017 FX229',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.97949,
    eccentricity: 0.0095087,
    inclination: 10.78893,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250977, H262948',
      },
      {
        userName: 'ms',
        HNames: 'H274272',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ229',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 2.78419,
    eccentricity: 0.1174342,
    inclination: 20.20168,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246396, H274948',
      },
    ],
  },
  {
    provisionalCode: '2017 FB230',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 2.31754,
    eccentricity: 0.1320709,
    inclination: 7.70329,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250717',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269558',
      },
      {
        userName: 'kn1cht',
        HNames: 'H277746',
      },
    ],
  },
  {
    provisionalCode: '2017 FD230',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 3.14842,
    eccentricity: 0.0715947,
    inclination: 8.44769,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242389, H242419',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279451',
      },
    ],
  },
  {
    provisionalCode: '2017 FE230',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.70293,
    eccentricity: 0.1377991,
    inclination: 6.45139,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H224510',
      },
      {
        userName: 'aika',
        HNames: 'H244413, H267685',
      },
    ],
  },
  {
    provisionalCode: '2017 FF230',
    note: '',
    absoluteMagnitude: 18.81,
    semimajorAxis: 3.17715,
    eccentricity: 0.0772109,
    inclination: 8.07552,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243151, H244110, H244514, H267706',
      },
      {
        userName: 'apollo18',
        HNames: 'H244514',
      },
    ],
  },
  {
    provisionalCode: '2017 FH230',
    note: '',
    absoluteMagnitude: 18.8,
    semimajorAxis: 3.16765,
    eccentricity: 0.0600559,
    inclination: 7.28018,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262924, H267233',
      },
    ],
  },
  {
    provisionalCode: '2017 GM34',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 3.39795,
    eccentricity: 0.0345655,
    inclination: 8.18407,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247530, H267140',
      },
      {
        userName: 'ms',
        HNames: 'H276538',
      },
    ],
  },
  {
    provisionalCode: '2017 GO34',
    note: '',
    absoluteMagnitude: 19.8,
    semimajorAxis: 2.92469,
    eccentricity: 0.0877244,
    inclination: 11.58659,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242114',
      },
      {
        userName: 'aika',
        HNames: 'H247315',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282073',
      },
    ],
  },
  {
    provisionalCode: '2017 GP34',
    note: '2014 QQ87と同定',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.69555,
    eccentricity: 0.2084334,
    inclination: 7.11685,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243111, H243204, H243648',
      },
    ],
  },
  {
    provisionalCode: '2017 GQ34',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 3.14134,
    eccentricity: 0.0578164,
    inclination: 7.3182,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243191, H243207, H250463, H267129',
      },
      {
        userName: 'yakuba',
        HNames: 'H243191',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ106',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.17493,
    eccentricity: 0.0514666,
    inclination: 7.65406,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'うめきち730',
        HNames: 'H225857',
      },
      {
        userName: 'もしもス',
        HNames: 'H243075',
      },
      {
        userName: 'zorome',
        HNames: 'H252277',
      },
    ],
  },
  {
    provisionalCode: '2017 HR106',
    note: '2023 WL19と同定',
    absoluteMagnitude: 20.16,
    semimajorAxis: 2.38205,
    eccentricity: 0.2619646,
    inclination: 6.86833,
    oppositions: 2,
    uncertainty: 2,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243076',
      },
      {
        userName: 'zorome',
        HNames: 'H250670, H268120',
      },
    ],
  },
  {
    provisionalCode: '2017 HC107',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 3.12412,
    eccentricity: 0.1629707,
    inclination: 8.49308,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242794',
      },
      {
        userName: 'zorome',
        HNames: 'H273391',
      },
      {
        userName: 'ms',
        HNames: 'H276892, H277652, H277703, H277906',
      },
    ],
  },
  {
    provisionalCode: '2017 HD107',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.72654,
    eccentricity: 0.1438677,
    inclination: 7.76169,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H276893, H278477, H278517, H278549',
      },
    ],
  },
  {
    provisionalCode: '2017 HE107',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 2.72614,
    eccentricity: 0.0326511,
    inclination: 5.62802,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'win',
        HNames: 'H216611',
      },
      {
        userName: 'nasiar99',
        HNames: 'H255063',
      },
      {
        userName: 'ほたる',
        HNames: 'H276705, H279183',
      },
    ],
  },
  {
    provisionalCode: '2017 HF107',
    note: 'Mars Crosser',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.9353,
    eccentricity: 0.4337071,
    inclination: 12.39616,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H224464',
      },
      {
        userName: 'もしもス',
        HNames: 'H242806, H255683',
      },
    ],
  },
  {
    provisionalCode: '2017 QT196',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 2.60065,
    eccentricity: 0.1500278,
    inclination: 12.86895,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'polaris',
        HNames: 'H209256',
      },
      {
        userName: 'もしもス',
        HNames: 'H229926',
      },
      {
        userName: 'imai',
        HNames: 'H237461',
      },
    ],
  },
  {
    provisionalCode: '2019 AU138',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.69,
    semimajorAxis: 31.06217,
    eccentricity: 0.023894,
    inclination: 18.37814,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H253643, H254691, H255249, H261524',
      },
    ],
  },
  {
    provisionalCode: '2019 GZ177',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 2.80899,
    eccentricity: 0.0893856,
    inclination: 8.4704,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H267633',
      },
    ],
  },
  {
    provisionalCode: '2019 SF240',
    note: 'Hilda',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.9616,
    eccentricity: 0.2698818,
    inclination: 6.47643,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H216492, H217930, H227168',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229153',
      },
    ],
  },
  {
    provisionalCode: '2019 SJ240',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.70936,
    eccentricity: 0.259514,
    inclination: 9.15468,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219726, H219736',
      },
      {
        userName: 'aika',
        HNames: 'H222181',
      },
      {
        userName: 'bny',
        HNames: 'H231544',
      },
    ],
  },
  {
    provisionalCode: '2019 SL240',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 2.68285,
    eccentricity: 0.1755969,
    inclination: 12.95096,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H204550, H213231, H216030',
      },
      {
        userName: 'さんしろう',
        HNames: 'H225226',
      },
    ],
  },
  {
    provisionalCode: '2019 SN240',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.15657,
    eccentricity: 0.0805858,
    inclination: 8.69014,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さとうきび',
        HNames: 'H207149',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222459, H225311',
      },
    ],
  },
  {
    provisionalCode: '2019 SO240',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 3.16345,
    eccentricity: 0.0543385,
    inclination: 7.56227,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H217416, H223906',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217416',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248051',
      },
    ],
  },
  {
    provisionalCode: '2019 SS240',
    note: '',
    absoluteMagnitude: 20.46,
    semimajorAxis: 2.602,
    eccentricity: 0.1059966,
    inclination: 3.08834,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226747',
      },
      {
        userName: '赵经远',
        HNames: 'H227853',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229155',
      },
      {
        userName: 'さんしろう',
        HNames: 'H234340',
      },
    ],
  },
  {
    provisionalCode: '2019 SZ240',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 2.77723,
    eccentricity: 0.0957954,
    inclination: 2.69271,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224901',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H225835',
      },
      {
        userName: 'てるてる',
        HNames: 'H231060',
      },
    ],
  },
  {
    provisionalCode: '2019 SC241',
    note: '',
    absoluteMagnitude: 20.63,
    semimajorAxis: 2.27164,
    eccentricity: 0.1547196,
    inclination: 5.41567,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H215091',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219823, H248650',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229802',
      },
    ],
  },
  {
    provisionalCode: '2019 SH241',
    note: '',
    absoluteMagnitude: 20.76,
    semimajorAxis: 2.63999,
    eccentricity: 0.1785096,
    inclination: 5.1854,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217524, H219250',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223060',
      },
      {
        userName: 'うめきち730',
        HNames: 'H233357',
      },
    ],
  },
  {
    provisionalCode: '2019 SK241',
    note: '',
    absoluteMagnitude: 21.75,
    semimajorAxis: 2.64637,
    eccentricity: 0.2246117,
    inclination: 6.37743,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H214641',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214641',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222424',
      },
      {
        userName: 'さんしろう',
        HNames: 'H228274',
      },
    ],
  },
  {
    provisionalCode: '2019 ST241',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.70597,
    eccentricity: 0.0879488,
    inclination: 2.711,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214974',
      },
      {
        userName: 'さんしろう',
        HNames: 'H216544, H236497',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218358, H228645',
      },
    ],
  },
  {
    provisionalCode: '2019 UF170',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.89434,
    eccentricity: 0.1368359,
    inclination: 7.70342,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222114, H222850',
      },
      {
        userName: 'さんしろう',
        HNames: 'H229554',
      },
    ],
  },
  {
    provisionalCode: '2019 UG170',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 2.60444,
    eccentricity: 0.171837,
    inclination: 6.51502,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213102, H215731',
      },
      {
        userName: 'さんしろう',
        HNames: 'H231205',
      },
    ],
  },
  {
    provisionalCode: '2019 UH170',
    note: '',
    absoluteMagnitude: 20.81,
    semimajorAxis: 2.31631,
    eccentricity: 0.1255972,
    inclination: 3.56884,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217709, H217712, H218238, H238022',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218866',
      },
    ],
  },
  {
    provisionalCode: '2019 UJ170',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.74121,
    eccentricity: 0.1698452,
    inclination: 7.44432,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214931, H215621, H223269',
      },
      {
        userName: 'さんしろう',
        HNames: 'H218242',
      },
    ],
  },
  {
    provisionalCode: '2019 UK170',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 2.75962,
    eccentricity: 0.0351223,
    inclination: 6.03148,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209903, H218334, H221015, H229880',
      },
    ],
  },
  {
    provisionalCode: '2019 UL170',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 2.75061,
    eccentricity: 0.0799536,
    inclination: 2.94687,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222851, H223731, H224314',
      },
    ],
  },
  {
    provisionalCode: '2019 UM170',
    note: '',
    absoluteMagnitude: 20.98,
    semimajorAxis: 2.37799,
    eccentricity: 0.0799545,
    inclination: 2.40752,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223138, H224700',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226807',
      },
    ],
  },
  {
    provisionalCode: '2019 UN170',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.10286,
    eccentricity: 0.0932171,
    inclination: 4.71141,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214382',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219761, H229874',
      },
    ],
  },
  {
    provisionalCode: '2019 UH172',
    note: '',
    absoluteMagnitude: 23.03,
    semimajorAxis: 2.13343,
    eccentricity: 0.1595417,
    inclination: 2.35477,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222072, H227970',
      },
      {
        userName: 'aika',
        HNames: 'H222072',
      },
    ],
  },
  {
    provisionalCode: '2019 UL172',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 2.91245,
    eccentricity: 0.0149974,
    inclination: 12.78353,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230717, H230841',
      },
      {
        userName: 's.ozawa',
        HNames: 'H237921, H275705',
      },
      {
        userName: 'れお',
        HNames: 'H256440',
      },
    ],
  },
  {
    provisionalCode: '2019 UM172',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 2.76103,
    eccentricity: 0.2904198,
    inclination: 16.91878,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H238884',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H240079',
      },
      {
        userName: 'もしもス',
        HNames: 'H240079',
      },
      {
        userName: 'kn1cht',
        HNames: 'H240079',
      },
    ],
  },
  {
    provisionalCode: '2019 UN172',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.15789,
    eccentricity: 0.1734095,
    inclination: 10.43258,
    oppositions: 1,
    uncertainty: 4,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217771, H231051',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223210, H224847',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H227485',
      },
    ],
  },
  {
    provisionalCode: '2019 UP172',
    note: '',
    absoluteMagnitude: 21.08,
    semimajorAxis: 2.22446,
    eccentricity: 0.0490265,
    inclination: 6.96747,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209908, H229846',
      },
    ],
  },
  {
    provisionalCode: '2019 UQ172',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 3.0507,
    eccentricity: 0.1934241,
    inclination: 1.92341,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217441, H280530',
      },
      {
        userName: 'さんしろう',
        HNames: 'H218331',
      },
    ],
  },
  {
    provisionalCode: '2019 UT172',
    note: '',
    absoluteMagnitude: 20.61,
    semimajorAxis: 3.04693,
    eccentricity: 0.1871153,
    inclination: 10.50239,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222312, H281230',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228594',
      },
    ],
  },
  {
    provisionalCode: '2019 UU172',
    note: '2019 VZ48と同定',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.62472,
    eccentricity: 0.0727745,
    inclination: 12.71922,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H229546',
      },
      {
        userName: 's.ozawa',
        HNames: 'H249343, H272023, H272046',
      },
    ],
  },
  {
    provisionalCode: '2019 VC47',
    note: '',
    absoluteMagnitude: 17.81,
    semimajorAxis: 3.13175,
    eccentricity: 0.0564389,
    inclination: 14.60543,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243877',
      },
      {
        userName: 'れお',
        HNames: 'H265929, H267168',
      },
    ],
  },
  {
    provisionalCode: '2019 VF47',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 3.0663,
    eccentricity: 0.0968297,
    inclination: 10.86262,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H227393',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H227930, H229120',
      },
      {
        userName: 'さんしろう',
        HNames: 'H228877',
      },
    ],
  },
  {
    provisionalCode: '2019 VG47',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 3.02421,
    eccentricity: 0.1434611,
    inclination: 6.6094,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213380, H226752',
      },
      {
        userName: 'しょうたろう',
        HNames: 'H213380',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248015',
      },
    ],
  },
  {
    provisionalCode: '2019 VH47',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 2.55145,
    eccentricity: 0.086204,
    inclination: 2.40633,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H217085',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221768',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226276',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248637',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ47',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 2.8386,
    eccentricity: 0.1477142,
    inclination: 3.26115,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H219843',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223486',
      },
      {
        userName: 'さんしろう',
        HNames: 'H231055',
      },
    ],
  },
  {
    provisionalCode: '2019 VK47',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 3.10117,
    eccentricity: 0.06009,
    inclination: 9.09137,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H214964',
      },
      {
        userName: 'このしろ',
        HNames: 'H214973',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219856, H229974',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228554',
      },
    ],
  },
  {
    provisionalCode: '2019 VL47',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.68198,
    eccentricity: 0.104808,
    inclination: 4.16361,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H215905, H231097, H234250',
      },
      {
        userName: 'aika',
        HNames: 'H215905',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218978, H228553',
      },
    ],
  },
  {
    provisionalCode: '2019 VM47',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 2.56159,
    eccentricity: 0.1040197,
    inclination: 10.50702,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209524, H211287, H214664, H228475',
      },
    ],
  },
  {
    provisionalCode: '2019 VN47',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 3.27052,
    eccentricity: 0.0540676,
    inclination: 7.83057,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217568, H217580',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H221215',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223936',
      },
    ],
  },
  {
    provisionalCode: '2019 VO47',
    note: '',
    absoluteMagnitude: 19.81,
    semimajorAxis: 2.55229,
    eccentricity: 0.126547,
    inclination: 11.14807,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214975',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215211',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228818',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229352',
      },
    ],
  },
  {
    provisionalCode: '2019 VP47',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.77022,
    eccentricity: 0.1632593,
    inclination: 2.33362,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200506',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215319',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215319',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222155',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ47',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.94345,
    eccentricity: 0.0805539,
    inclination: 9.5477,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214381',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H219418',
      },
      {
        userName: 'aika',
        HNames: 'H219418',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229879',
      },
    ],
  },
  {
    provisionalCode: '2019 VC48',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.42078,
    eccentricity: 0.109876,
    inclination: 1.88355,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213095, H218526, H274356, H274369',
      },
    ],
  },
  {
    provisionalCode: '2019 VD48',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 2.48981,
    eccentricity: 0.1612345,
    inclination: 3.03232,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209906, H209960',
      },
    ],
  },
  {
    provisionalCode: '2019 VF48',
    note: '',
    absoluteMagnitude: 23.17,
    semimajorAxis: 2.22596,
    eccentricity: 0.1719804,
    inclination: 1.56172,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H217309, H274967',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217309',
      },
    ],
  },
  {
    provisionalCode: '2019 VG48',
    note: '',
    absoluteMagnitude: 21.85,
    semimajorAxis: 2.74432,
    eccentricity: 0.2101899,
    inclination: 2.28975,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222391, H275058',
      },
      {
        userName: 'aika',
        HNames: 'H222391',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ48',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.69586,
    eccentricity: 0.1784471,
    inclination: 12.89025,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230718, H230842',
      },
      {
        userName: 'nasiar99',
        HNames: 'H251830',
      },
      {
        userName: 's.ozawa',
        HNames: 'H275706',
      },
    ],
  },
  {
    provisionalCode: '2019 VK48',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.72067,
    eccentricity: 0.1622193,
    inclination: 11.78836,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200091',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217092',
      },
    ],
  },
  {
    provisionalCode: '2019 VL48',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 2.85279,
    eccentricity: 0.2451002,
    inclination: 12.20956,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200092',
      },
      {
        userName: 'ほたる',
        HNames: 'H214551',
      },
    ],
  },
  {
    provisionalCode: '2019 VM48',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 3.14226,
    eccentricity: 0.1563664,
    inclination: 13.20435,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H200104',
      },
      {
        userName: 'belleequipe',
        HNames: 'H200104',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279611',
      },
    ],
  },
  {
    provisionalCode: '2019 VN48',
    note: '',
    absoluteMagnitude: 19.81,
    semimajorAxis: 2.38077,
    eccentricity: 0.1324391,
    inclination: 1.64129,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209907, H280081, H280085',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219894',
      },
    ],
  },
  {
    provisionalCode: '2019 VO48',
    note: 'Phocaea',
    absoluteMagnitude: 21.91,
    semimajorAxis: 2.32029,
    eccentricity: 0.2037308,
    inclination: 12.28945,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H210068',
      },
      {
        userName: 'aika',
        HNames: 'H228884',
      },
    ],
  },
  {
    provisionalCode: '2019 VP48',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.06057,
    eccentricity: 0.1018872,
    inclination: 2.16116,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209905, H219793',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217715',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ48',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.49396,
    eccentricity: 0.1806048,
    inclination: 4.38285,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211759',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217720',
      },
    ],
  },
  {
    provisionalCode: '2019 VR48',
    note: '',
    absoluteMagnitude: 21.97,
    semimajorAxis: 2.45593,
    eccentricity: 0.2109786,
    inclination: 5.71665,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209959, H211760',
      },
    ],
  },
  {
    provisionalCode: '2019 VS48',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.68945,
    eccentricity: 0.1074314,
    inclination: 2.7612,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213093, H280692',
      },
    ],
  },
  {
    provisionalCode: '2019 VT48',
    note: '',
    absoluteMagnitude: 21.01,
    semimajorAxis: 2.72778,
    eccentricity: 0.2051325,
    inclination: 12.67491,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213309',
      },
      {
        userName: 'aika',
        HNames: 'H222412',
      },
    ],
  },
  {
    provisionalCode: '2019 VU48',
    note: '',
    absoluteMagnitude: 20.33,
    semimajorAxis: 2.63699,
    eccentricity: 0.129801,
    inclination: 2.01166,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213310, H218868',
      },
    ],
  },
  {
    provisionalCode: '2019 VV48',
    note: '',
    absoluteMagnitude: 20.18,
    semimajorAxis: 2.70826,
    eccentricity: 0.0655396,
    inclination: 3.10831,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223855, H281161',
      },
      {
        userName: 'れお',
        HNames: 'H253979',
      },
    ],
  },
  {
    provisionalCode: '2019 VW48',
    note: '',
    absoluteMagnitude: 21.12,
    semimajorAxis: 2.52107,
    eccentricity: 0.0358748,
    inclination: 4.32949,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228055, H281208',
      },
      {
        userName: 'さんしろう',
        HNames: 'H229552',
      },
    ],
  },
  {
    provisionalCode: '2019 VX48',
    note: '',
    absoluteMagnitude: 20.46,
    semimajorAxis: 2.7547,
    eccentricity: 0.088207,
    inclination: 3.62917,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229217',
      },
      {
        userName: 'さんしろう',
        HNames: 'H236004',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281598',
      },
    ],
  },
  {
    provisionalCode: '2019 VY48',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 2.69319,
    eccentricity: 0.0490445,
    inclination: 11.50876,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H230022, H254981, H281625',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230022',
      },
    ],
  },
  {
    provisionalCode: '2019 VA49',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.17783,
    eccentricity: 0.0685055,
    inclination: 11.96423,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H229547',
      },
      {
        userName: 'れお',
        HNames: 'H275488',
      },
    ],
  },
  {
    provisionalCode: '2019 VB49',
    note: '',
    absoluteMagnitude: 22.36,
    semimajorAxis: 2.6409,
    eccentricity: 0.2776481,
    inclination: 13.81407,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H230024',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230024, H232084',
      },
      {
        userName: 'れお',
        HNames: 'H265337',
      },
    ],
  },
  {
    provisionalCode: '2019 VC49',
    note: '',
    absoluteMagnitude: 21.26,
    semimajorAxis: 2.55688,
    eccentricity: 0.190217,
    inclination: 13.57303,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232081',
      },
      {
        userName: 'mitarushi',
        HNames: 'H232081',
      },
      {
        userName: 'れお',
        HNames: 'H266548',
      },
    ],
  },
  {
    provisionalCode: '2019 WM33',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 3.07186,
    eccentricity: 0.0649179,
    inclination: 9.2477,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H214700',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224371',
      },
      {
        userName: 'さんしろう',
        HNames: 'H228270',
      },
    ],
  },
  {
    provisionalCode: '2019 WN33',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.07409,
    eccentricity: 0.1187895,
    inclination: 4.3098,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H215351',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224641',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226778',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248830',
      },
    ],
  },
  {
    provisionalCode: '2019 WO33',
    note: '',
    absoluteMagnitude: 20.87,
    semimajorAxis: 2.22191,
    eccentricity: 0.0844889,
    inclination: 6.06972,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214719, H215034',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222838',
      },
    ],
  },
  {
    provisionalCode: '2019 WP33',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 3.16478,
    eccentricity: 0.0521595,
    inclination: 10.23772,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214818, H218527, H227482',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215341, H218330',
      },
    ],
  },
  {
    provisionalCode: '2019 WQ33',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.37391,
    eccentricity: 0.2346534,
    inclination: 5.01215,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H204536, H214678, H215016',
      },
      {
        userName: 'このしろ',
        HNames: 'H215016',
      },
    ],
  },
  {
    provisionalCode: '2019 WR33',
    note: '',
    absoluteMagnitude: 19.94,
    semimajorAxis: 2.74802,
    eccentricity: 0.0507639,
    inclination: 4.5291,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223935, H224326, H228133',
      },
    ],
  },
  {
    provisionalCode: '2019 WS33',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 3.31653,
    eccentricity: 0.0931676,
    inclination: 4.2058,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222333, H228196, H229219',
      },
    ],
  },
  {
    provisionalCode: '2019 WX33',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.89898,
    eccentricity: 0.0643896,
    inclination: 12.78475,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225160',
      },
      {
        userName: 'れお',
        HNames: 'H263979',
      },
      {
        userName: 'ms',
        HNames: 'H271410, H271572',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ33',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 2.58865,
    eccentricity: 0.2177969,
    inclination: 15.19288,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H253472',
      },
      {
        userName: 'ms',
        HNames: 'H271409, H272458, H272691',
      },
    ],
  },
  {
    provisionalCode: '2019 WC34',
    note: '',
    absoluteMagnitude: 20.15,
    semimajorAxis: 3.11669,
    eccentricity: 0.1883305,
    inclination: 12.12555,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H225163',
      },
      {
        userName: 'zorome',
        HNames: 'H225163',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H278344',
      },
    ],
  },
  {
    provisionalCode: '2019 WD34',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 3.07104,
    eccentricity: 0.4197589,
    inclination: 13.49097,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225159',
      },
      {
        userName: 'もしもス',
        HNames: 'H225159',
      },
      {
        userName: 'mitarushi',
        HNames: 'H226208',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226208',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278804, H278806, H278809',
      },
    ],
  },
  {
    provisionalCode: '2019 WE34',
    note: '',
    absoluteMagnitude: 21.53,
    semimajorAxis: 2.35832,
    eccentricity: 0.1177431,
    inclination: 4.01737,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222769, H229220',
      },
    ],
  },
  {
    provisionalCode: '2019 XJ20',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 2.57663,
    eccentricity: 0.1251191,
    inclination: 16.613,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'm.n.',
        HNames: 'H203420',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238633, H243869, H244002',
      },
    ],
  },
  {
    provisionalCode: '2016 WZ84',
    note: '',
    absoluteMagnitude: 17.34,
    semimajorAxis: 3.18391,
    eccentricity: 0.0424901,
    inclination: 8.8287,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'れお',
        HNames: 'H280164',
      },
      {
        userName: 'yakuba',
        HNames: 'H283142',
      },
    ],
  },
  {
    provisionalCode: '2016 YS39',
    note: '',
    absoluteMagnitude: 20.89,
    semimajorAxis: 2.30798,
    eccentricity: 0.0898359,
    inclination: 5.79404,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H272512',
      },
      {
        userName: 'nasiar99',
        HNames: 'H281184',
      },
      {
        userName: 'れお',
        HNames: 'H281256, H281293',
      },
    ],
  },
  {
    provisionalCode: '2016 YT39',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.69,
    semimajorAxis: 5.20071,
    eccentricity: 0.0757223,
    inclination: 8.72984,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H274269, H281185',
      },
      {
        userName: 'れお',
        HNames: 'H279216',
      },
      {
        userName: 'arda',
        HNames: 'H279216',
      },
    ],
  },
  {
    provisionalCode: '2016 YV39',
    note: '',
    absoluteMagnitude: 17.69,
    semimajorAxis: 3.06398,
    eccentricity: 0.0984559,
    inclination: 8.57712,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242201, H248343, H285053',
      },
      {
        userName: 'ほたる',
        HNames: 'H287058, H287165',
      },
    ],
  },
  {
    provisionalCode: '2017 AV62',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.34037,
    eccentricity: 0.0612657,
    inclination: 8.7986,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H270217',
      },
      {
        userName: 'ms',
        HNames: 'H273550',
      },
      {
        userName: 'もしもス',
        HNames: 'H277774',
      },
    ],
  },
  {
    provisionalCode: '2017 AW62',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.86134,
    eccentricity: 0.0637253,
    inclination: 12.20653,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H273137',
      },
      {
        userName: 'もしもス',
        HNames: 'H276873',
      },
      {
        userName: 'れお',
        HNames: 'H281255',
      },
    ],
  },
  {
    provisionalCode: '2017 AX62',
    note: '',
    absoluteMagnitude: 20.02,
    semimajorAxis: 3.16096,
    eccentricity: 0.1595181,
    inclination: 10.47567,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242387, H242418, H251515',
      },
      {
        userName: 'れお',
        HNames: 'H251515',
      },
    ],
  },
  {
    provisionalCode: '2017 BK229',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.91,
    semimajorAxis: 45.1215,
    eccentricity: 0.0821261,
    inclination: 9.95196,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241924, H241929, H242042, H259537, H259558, H259580',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H259580',
      },
    ],
  },
  {
    provisionalCode: '2017 BL229',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 3.07115,
    eccentricity: 0.093225,
    inclination: 9.4729,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H246360',
      },
      {
        userName: 'もしもス',
        HNames: 'H246360',
      },
      {
        userName: 'arda',
        HNames: 'H274801',
      },
    ],
  },
  {
    provisionalCode: '2017 BM229',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 3.06575,
    eccentricity: 0.0626614,
    inclination: 9.04537,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249251, H275575',
      },
    ],
  },
  {
    provisionalCode: '2017 BO229',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 3.13929,
    eccentricity: 0.0359294,
    inclination: 10.84619,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250689, H268017',
      },
      {
        userName: 'kn1cht',
        HNames: 'H250689',
      },
      {
        userName: 'aika',
        HNames: 'H271668',
      },
    ],
  },
  {
    provisionalCode: '2017 BP229',
    note: '',
    absoluteMagnitude: 18.8,
    semimajorAxis: 3.21349,
    eccentricity: 0.0686946,
    inclination: 8.88892,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H247975',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247975',
      },
      {
        userName: 'ほたる',
        HNames: 'H279590',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282742',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ229',
    note: '',
    absoluteMagnitude: 17.66,
    semimajorAxis: 3.20161,
    eccentricity: 0.0882076,
    inclination: 15.23595,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H271670',
      },
      {
        userName: 'ko',
        HNames: 'H274176',
      },
      {
        userName: 'nasiar99',
        HNames: 'H281891',
      },
      {
        userName: 'arda',
        HNames: 'H282449',
      },
    ],
  },
  {
    provisionalCode: '2017 BR229',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.05397,
    eccentricity: 0.0633127,
    inclination: 9.29203,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242415, H251284',
      },
      {
        userName: 'れお',
        HNames: 'H251284',
      },
      {
        userName: 'ほたる',
        HNames: 'H279635',
      },
    ],
  },
  {
    provisionalCode: '2017 BT229',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 2.91001,
    eccentricity: 0.079678,
    inclination: 10.31685,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250519',
      },
      {
        userName: 'ko',
        HNames: 'H281355',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282711',
      },
    ],
  },
  {
    provisionalCode: '2017 BU229',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 3.12418,
    eccentricity: 0.195562,
    inclination: 24.43438,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H251777',
      },
      {
        userName: 'zorome',
        HNames: 'H251777',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262358',
      },
      {
        userName: '42',
        HNames: 'H262358, H282174',
      },
    ],
  },
  {
    provisionalCode: '2017 BV229',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 3.41999,
    eccentricity: 0.0531761,
    inclination: 18.5535,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242009, H250956',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282775',
      },
    ],
  },
  {
    provisionalCode: '2017 BW229',
    note: 'Hilda',
    absoluteMagnitude: 16.57,
    semimajorAxis: 3.98628,
    eccentricity: 0.0994744,
    inclination: 10.80132,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220810',
      },
      {
        userName: 'nasiar99',
        HNames: 'H220810, H274134',
      },
      {
        userName: 'ほたる',
        HNames: 'H281112',
      },
    ],
  },
  {
    provisionalCode: '2017 BY229',
    note: '',
    absoluteMagnitude: 17.76,
    semimajorAxis: 3.13335,
    eccentricity: 0.0339569,
    inclination: 9.18066,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269567, H283520',
      },
    ],
  },
  {
    provisionalCode: '2017 BC230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.83,
    semimajorAxis: 41.61648,
    eccentricity: 0.2243913,
    inclination: 10.4669,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H277448, H277505, H277517, H277688',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277688, H281043, H281086',
      },
    ],
  },
  {
    provisionalCode: '2017 BD230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.36,
    semimajorAxis: 53.61098,
    eccentricity: 0.3725786,
    inclination: 12.82055,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H240029',
      },
      {
        userName: 'もしもス',
        HNames: 'H281062, H281131, H281188, H281221',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281062',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287205, H287225',
      },
    ],
  },
  {
    provisionalCode: '2017 BE230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.83,
    semimajorAxis: 5.23306,
    eccentricity: 0.0028834,
    inclination: 17.58437,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H277073',
      },
      {
        userName: 'zorome',
        HNames: 'H277073',
      },
      {
        userName: 'もしもス',
        HNames: 'H278690',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280389',
      },
      {
        userName: 'ko',
        HNames: 'H281371',
      },
    ],
  },
  {
    provisionalCode: '2017 BG230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.95,
    semimajorAxis: 45.96348,
    eccentricity: 0.1180156,
    inclination: 23.84944,
    oppositions: 5,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276839, H280500',
      },
      {
        userName: 'もしもス',
        HNames: 'H276839',
      },
    ],
  },
  {
    provisionalCode: '2017 BH230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.18,
    semimajorAxis: 47.12809,
    eccentricity: 0.0866199,
    inclination: 31.78954,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251172, H251288',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251288, H282027, H282028',
      },
      {
        userName: 'もしもス',
        HNames: 'H258650',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.06,
    semimajorAxis: 42.96035,
    eccentricity: 0.1742651,
    inclination: 29.13683,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H225636',
      },
      {
        userName: 'zorome',
        HNames: 'H241383, H242186, H242320, H267612, H267625',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267625',
      },
    ],
  },
  {
    provisionalCode: '2017 BK230',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.6,
    semimajorAxis: 45.99771,
    eccentricity: 0.3128574,
    inclination: 13.40163,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H247972',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247972',
      },
    ],
  },
  {
    provisionalCode: '2017 BL230',
    note: '',
    absoluteMagnitude: 17.87,
    semimajorAxis: 3.1297,
    eccentricity: 0.1230681,
    inclination: 10.316,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H284874, H286885',
      },
      {
        userName: 'zorome',
        HNames: 'H285517',
      },
    ],
  },
  {
    provisionalCode: '2017 BM230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.73,
    semimajorAxis: 5.22938,
    eccentricity: 0.0700672,
    inclination: 34.43515,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268458',
      },
      {
        userName: 'arda',
        HNames: 'H268458',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268458, H279520',
      },
      {
        userName: 'れお',
        HNames: 'H279520, H279524',
      },
    ],
  },
  {
    provisionalCode: '2017 CV53',
    note: '',
    absoluteMagnitude: 18.1,
    semimajorAxis: 3.09845,
    eccentricity: 0.1447298,
    inclination: 14.52719,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271249, H278944, H281896',
      },
    ],
  },
  {
    provisionalCode: '2017 CW53',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 3.01632,
    eccentricity: 0.0456709,
    inclination: 9.18216,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262612, H263130',
      },
      {
        userName: 'もしもス',
        HNames: 'H276930',
      },
      {
        userName: 'ko',
        HNames: 'H281782',
      },
    ],
  },
  {
    provisionalCode: '2017 CX53',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.81,
    semimajorAxis: 5.25512,
    eccentricity: 0.0440179,
    inclination: 13.3312,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242430, H251735',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H242430',
      },
    ],
  },
  {
    provisionalCode: '2017 CY53',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.12219,
    eccentricity: 0.1573937,
    inclination: 6.48081,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262709, H281442',
      },
      {
        userName: '42',
        HNames: 'H262709',
      },
      {
        userName: 'ko',
        HNames: 'H265416',
      },
      {
        userName: 'aika',
        HNames: 'H277944',
      },
      {
        userName: 'ほたる',
        HNames: 'H281158',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ53',
    note: '',
    absoluteMagnitude: 18.51,
    semimajorAxis: 2.73859,
    eccentricity: 0.2100886,
    inclination: 7.71922,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'ryo-k',
        HNames: 'H218165',
      },
      {
        userName: 'zorome',
        HNames: 'H278732, H281658',
      },
    ],
  },
  {
    provisionalCode: '2017 CC54',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.04754,
    eccentricity: 0.1713188,
    inclination: 9.84928,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266069, H274427, H285912',
      },
    ],
  },
  {
    provisionalCode: '2017 CE54',
    note: '',
    absoluteMagnitude: 18.06,
    semimajorAxis: 3.08583,
    eccentricity: 0.0454,
    inclination: 8.20082,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H239228',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H250899',
      },
      {
        userName: 'yakuba',
        HNames: 'H286878',
      },
    ],
  },
  {
    provisionalCode: '2017 DP159',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.7018,
    eccentricity: 0.0216134,
    inclination: 6.89716,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'みらい',
        HNames: 'H210335',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279384',
      },
      {
        userName: 'れお',
        HNames: 'H283791',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ159',
    note: '',
    absoluteMagnitude: 18.19,
    semimajorAxis: 3.11755,
    eccentricity: 0.1342797,
    inclination: 2.24415,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202134',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262048',
      },
      {
        userName: 'joshua',
        HNames: 'H269248',
      },
    ],
  },
  {
    provisionalCode: '2017 DU159',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.32,
    semimajorAxis: 43.97792,
    eccentricity: 0.1070079,
    inclination: 9.14026,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H276220, H276321, H276535, H277108, H277186, H277194',
      },
      {
        userName: 'もしもス',
        HNames: 'H276220, H276321, H277186',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276535, H277108',
      },
    ],
  },
  {
    provisionalCode: '2017 DV159',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.21,
    semimajorAxis: 50.21401,
    eccentricity: 0.3152336,
    inclination: 33.61956,
    oppositions: 4,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241352, H242321, H267645, H267653, H267722',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H242321, H267653, H282062',
      },
    ],
  },
  {
    provisionalCode: '2017 DW159',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.86,
    semimajorAxis: 28.97008,
    eccentricity: 0.2491352,
    inclination: 29.54471,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275319, H283056, H283081, H283112',
      },
      {
        userName: 'arda',
        HNames: 'H275319',
      },
    ],
  },
  {
    provisionalCode: '2017 DX159',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.97,
    semimajorAxis: 5.29811,
    eccentricity: 0.0570165,
    inclination: 7.89646,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H251608, H286529, H286532',
      },
      {
        userName: 'arda',
        HNames: 'H270889',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ159',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.03,
    semimajorAxis: 52.70499,
    eccentricity: 0.2599736,
    inclination: 28.33438,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267263, H279077, H279608',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287167, H288506',
      },
    ],
  },
  {
    provisionalCode: '2017 DA160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 42.84726,
    eccentricity: 0.0343942,
    inclination: 4.90268,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H268563',
      },
    ],
  },
  {
    provisionalCode: '2017 DB160',
    note: '',
    absoluteMagnitude: 17.96,
    semimajorAxis: 2.8394,
    eccentricity: 0.0089146,
    inclination: 3.31757,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H269804',
      },
      {
        userName: 'k96e',
        HNames: 'H286823',
      },
    ],
  },
  {
    provisionalCode: '2017 EB51',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 2.59054,
    eccentricity: 0.1703048,
    inclination: 6.75879,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246367, H282978',
      },
    ],
  },
  {
    provisionalCode: '2017 EC51',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 2.68936,
    eccentricity: 0.1360976,
    inclination: 7.63073,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246374, H282976',
      },
    ],
  },
  {
    provisionalCode: '2017 EG51',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.17539,
    eccentricity: 0.1041449,
    inclination: 6.95773,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252848',
      },
      {
        userName: 'ko',
        HNames: 'H264144',
      },
      {
        userName: 'nasiar99',
        HNames: 'H283492',
      },
    ],
  },
  {
    provisionalCode: '2017 EM51',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.84,
    semimajorAxis: 5.20704,
    eccentricity: 0.0942908,
    inclination: 7.673,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H204253, H285863',
      },
      {
        userName: 'daiath',
        HNames: 'H204253',
      },
      {
        userName: 'nasiar99',
        HNames: 'H285863',
      },
    ],
  },
  {
    provisionalCode: '2017 EN51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.99,
    semimajorAxis: 45.6148,
    eccentricity: 0.1720727,
    inclination: 17.65966,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H241421, H286021',
      },
      {
        userName: 'zorome',
        HNames: 'H241421, H261034',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261034, H280922, H287181',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ230',
    note: '2017 FK230と同定',
    absoluteMagnitude: 20.28,
    semimajorAxis: 2.55384,
    eccentricity: 0.1367,
    inclination: 6.87239,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263410, H264152',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263410',
      },
      {
        userName: 'れお',
        HNames: 'H279578',
      },
    ],
  },
  {
    provisionalCode: '2017 FM230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.02,
    semimajorAxis: 5.24708,
    eccentricity: 0.058838,
    inclination: 17.9008,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259526',
      },
      {
        userName: 'zorome',
        HNames: 'H259526',
      },
      {
        userName: 'arda',
        HNames: 'H280785',
      },
    ],
  },
  {
    provisionalCode: '2017 FO230',
    note: '',
    absoluteMagnitude: 20.61,
    semimajorAxis: 2.6412,
    eccentricity: 0.2484349,
    inclination: 11.38859,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245465, H245470',
      },
      {
        userName: 'aika',
        HNames: 'H245470',
      },
      {
        userName: 'ko',
        HNames: 'H245470',
      },
    ],
  },
  {
    provisionalCode: '2017 FP230',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.32497,
    eccentricity: 0.1414232,
    inclination: 6.46617,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H240695, H243189, H257515',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ230',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.12479,
    eccentricity: 0.1029652,
    inclination: 8.03843,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241993',
      },
      {
        userName: 'aika',
        HNames: 'H243112, H243210, H257520',
      },
      {
        userName: 'がっくん',
        HNames: 'H259715',
      },
    ],
  },
  {
    provisionalCode: '2017 FR230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.12,
    semimajorAxis: 5.29571,
    eccentricity: 0.046972,
    inclination: 11.63738,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H249439',
      },
      {
        userName: 'zorome',
        HNames: 'H249439',
      },
      {
        userName: 'aika',
        HNames: 'H249746, H251026',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249746',
      },
    ],
  },
  {
    provisionalCode: '2017 FS230',
    note: '',
    absoluteMagnitude: 21.93,
    semimajorAxis: 2.42464,
    eccentricity: 0.0570606,
    inclination: 8.39534,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242080',
      },
      {
        userName: 'aika',
        HNames: 'H247284, H263379',
      },
    ],
  },
  {
    provisionalCode: '2017 FT230',
    note: '',
    absoluteMagnitude: 18.64,
    semimajorAxis: 3.15866,
    eccentricity: 0.0719303,
    inclination: 26.60696,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H242697, H257463, H263380',
      },
      {
        userName: 'もしもス',
        HNames: 'H242697',
      },
    ],
  },
  {
    provisionalCode: '2017 FU230',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.57331,
    eccentricity: 0.0994784,
    inclination: 6.62842,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250975, H260316, H267225',
      },
    ],
  },
  {
    provisionalCode: '2017 FV230',
    note: '',
    absoluteMagnitude: 18.18,
    semimajorAxis: 3.19699,
    eccentricity: 0.0461794,
    inclination: 15.30949,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H224526, H267708',
      },
      {
        userName: 'kn1cht',
        HNames: 'H224526',
      },
      {
        userName: 'apollo18',
        HNames: 'H244515',
      },
    ],
  },
  {
    provisionalCode: '2017 FW230',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.21195,
    eccentricity: 0.1705169,
    inclination: 5.42833,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244443',
      },
      {
        userName: 'apollo18',
        HNames: 'H244519',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270202',
      },
    ],
  },
  {
    provisionalCode: '2017 FX230',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 2.95622,
    eccentricity: 0.1517946,
    inclination: 6.17703,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H221320, H244472',
      },
      {
        userName: 'apollo18',
        HNames: 'H221320',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270798',
      },
    ],
  },
  {
    provisionalCode: '2017 FY230',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.98956,
    eccentricity: 0.0264973,
    inclination: 9.30062,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250979, H263358, H267224',
      },
      {
        userName: 'ko',
        HNames: 'H250979',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263358',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ230',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.08527,
    eccentricity: 0.1356231,
    inclination: 7.72371,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220769',
      },
      {
        userName: 'kn1cht',
        HNames: 'H242641',
      },
      {
        userName: 'aika',
        HNames: 'H242641, H250179',
      },
      {
        userName: 'もしもス',
        HNames: 'H242641',
      },
    ],
  },
  {
    provisionalCode: '2017 FA231',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 2.78686,
    eccentricity: 0.260234,
    inclination: 7.28515,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244473, H244893',
      },
    ],
  },
  {
    provisionalCode: '2017 FB231',
    note: '',
    absoluteMagnitude: 18.69,
    semimajorAxis: 3.08094,
    eccentricity: 0.0653019,
    inclination: 8.69195,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249688, H250976',
      },
      {
        userName: 'ms',
        HNames: 'H274271',
      },
    ],
  },
  {
    provisionalCode: '2017 FC231',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 2.87885,
    eccentricity: 0.0662028,
    inclination: 7.8032,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244900, H251040',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244900',
      },
      {
        userName: 'もしもス',
        HNames: 'H244900',
      },
    ],
  },
  {
    provisionalCode: '2017 FD231',
    note: '',
    absoluteMagnitude: 19.83,
    semimajorAxis: 3.09932,
    eccentricity: 0.1084319,
    inclination: 9.48997,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266025',
      },
      {
        userName: 'aika',
        HNames: 'H267147',
      },
      {
        userName: 'ko',
        HNames: 'H270859',
      },
      {
        userName: 'ms',
        HNames: 'H276198, H276247',
      },
    ],
  },
  {
    provisionalCode: '2017 FE231',
    note: '2017 HR108と同定',
    absoluteMagnitude: 19.17,
    semimajorAxis: 3.01182,
    eccentricity: 0.0870362,
    inclination: 8.8218,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250469, H256347',
      },
      {
        userName: 'zorome',
        HNames: 'H272008',
      },
      {
        userName: 'arda',
        HNames: 'H278503',
      },
      {
        userName: 'ms',
        HNames: 'H291493, H292069, H292105, H292111',
      },
    ],
  },
  {
    provisionalCode: '2017 FF231',
    note: '',
    absoluteMagnitude: 18.12,
    semimajorAxis: 3.15418,
    eccentricity: 0.0667272,
    inclination: 8.14988,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252092',
      },
      {
        userName: 'ほたる',
        HNames: 'H259542',
      },
      {
        userName: 'aika',
        HNames: 'H259542, H260822',
      },
    ],
  },
  {
    provisionalCode: '2017 FG231',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.4364,
    eccentricity: 0.0284755,
    inclination: 10.67849,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250514, H263412',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H280928',
      },
    ],
  },
  {
    provisionalCode: '2017 FH231',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.60251,
    eccentricity: 0.0614007,
    inclination: 7.95346,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243152, H244109, H257514',
      },
      {
        userName: 'yakuba',
        HNames: 'H243152',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263391',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ231',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.18422,
    eccentricity: 0.1322841,
    inclination: 13.80113,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246398, H259692, H267920',
      },
    ],
  },
  {
    provisionalCode: '2017 FK231',
    note: '',
    absoluteMagnitude: 18.88,
    semimajorAxis: 3.00127,
    eccentricity: 0.0604461,
    inclination: 9.81321,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247327, H249913, H251471',
      },
      {
        userName: 'kn1cht',
        HNames: 'H249913',
      },
    ],
  },
  {
    provisionalCode: '2017 FL231',
    note: '',
    absoluteMagnitude: 20.63,
    semimajorAxis: 2.23722,
    eccentricity: 0.1950293,
    inclination: 6.60575,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250980, H257464',
      },
      {
        userName: 'ko',
        HNames: 'H250980',
      },
      {
        userName: 'れお',
        HNames: 'H250980',
      },
      {
        userName: 'ms',
        HNames: 'H276249',
      },
    ],
  },
  {
    provisionalCode: '2017 FM231',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.73776,
    eccentricity: 0.1657141,
    inclination: 7.86345,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262942',
      },
      {
        userName: 'kn1cht',
        HNames: 'H283954',
      },
      {
        userName: 's.ozawa',
        HNames: 'H314881',
      },
    ],
  },
  {
    provisionalCode: '2017 FS231',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.08407,
    eccentricity: 0.1738991,
    inclination: 10.84745,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H249416',
      },
      {
        userName: 'zorome',
        HNames: 'H249416',
      },
      {
        userName: 'aika',
        HNames: 'H249747',
      },
    ],
  },
  {
    provisionalCode: '2017 FT231',
    note: '',
    absoluteMagnitude: 18.23,
    semimajorAxis: 3.09891,
    eccentricity: 0.0190853,
    inclination: 9.51609,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244459',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H269980, H270067',
      },
    ],
  },
  {
    provisionalCode: '2017 FU231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.78,
    semimajorAxis: 44.41282,
    eccentricity: 0.0440116,
    inclination: 27.45217,
    oppositions: 4,
    uncertainty: 3,
    detail: [
      {
        userName: 'ms',
        HNames:
          'H276250, H276539, H276552, H276610, H276633, H276737, H276894, H277060, H277075, H277100, H277239, H277345',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288508',
      },
    ],
  },
  {
    provisionalCode: '2017 FV231',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 2.88241,
    eccentricity: 0.242442,
    inclination: 11.105,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245467',
      },
      {
        userName: 'aika',
        HNames: 'H245467',
      },
    ],
  },
  {
    provisionalCode: '2017 FY231',
    note: '',
    absoluteMagnitude: 17.95,
    semimajorAxis: 3.2086,
    eccentricity: 0.1979511,
    inclination: 17.71301,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245469, H287068',
      },
      {
        userName: 'aika',
        HNames: 'H245469',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ231',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 2.88463,
    eccentricity: 0.0591985,
    inclination: 9.12155,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245468, H287400',
      },
      {
        userName: 'nasiar99',
        HNames: 'H285862',
      },
    ],
  },
  {
    provisionalCode: '2017 FB232',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 3.20969,
    eccentricity: 0.2690211,
    inclination: 24.02009,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H224507, H287450, H287453',
      },
      {
        userName: 'aika',
        HNames: 'H224507',
      },
    ],
  },
  {
    provisionalCode: '2017 GW34',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 2.72588,
    eccentricity: 0.1834421,
    inclination: 6.79109,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220937',
      },
      {
        userName: 'aika',
        HNames: 'H243206, H256304',
      },
      {
        userName: 'yakuba',
        HNames: 'H263514',
      },
    ],
  },
  {
    provisionalCode: '2017 GX34',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.00496,
    eccentricity: 0.0829983,
    inclination: 8.72582,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243190, H267139',
      },
      {
        userName: '赵经远',
        HNames: 'H249444',
      },
    ],
  },
  {
    provisionalCode: '2017 GY34',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.97639,
    eccentricity: 0.0956041,
    inclination: 8.09224,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244416, H247510',
      },
      {
        userName: 'ko',
        HNames: 'H264748',
      },
      {
        userName: 'もしもス',
        HNames: 'H270719',
      },
    ],
  },
  {
    provisionalCode: '2017 GD35',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.17658,
    eccentricity: 0.2577236,
    inclination: 14.53609,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247529, H267093, H267899',
      },
      {
        userName: 'kn1cht',
        HNames: 'H247529',
      },
    ],
  },
  {
    provisionalCode: '2017 HH107',
    note: '',
    absoluteMagnitude: 18.42,
    semimajorAxis: 3.03943,
    eccentricity: 0.1001919,
    inclination: 9.17477,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H201073',
      },
      {
        userName: 'ms',
        HNames: 'H282914, H282944, H282969, H283049, H283069',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ107',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 3.17478,
    eccentricity: 0.0565654,
    inclination: 8.5511,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H282945, H283050, H283861, H283943',
      },
    ],
  },
  {
    provisionalCode: '2017 HL107',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.16092,
    eccentricity: 0.1874154,
    inclination: 18.41429,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H231462',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245248',
      },
      {
        userName: 'zorome',
        HNames: 'H275068',
      },
    ],
  },
  {
    provisionalCode: '2017 HP107',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.12314,
    eccentricity: 0.0679535,
    inclination: 7.66437,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H282948, H283945, H286514, H286576',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ107',
    note: '',
    absoluteMagnitude: 19.56,
    semimajorAxis: 3.14872,
    eccentricity: 0.0203667,
    inclination: 9.44944,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H278518, H283944, H287011, H287030',
      },
    ],
  },
  {
    provisionalCode: '2017 HS107',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 2.58371,
    eccentricity: 0.0402965,
    inclination: 8.15602,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H283052, H283863, H287238, H287295, H287403',
      },
      {
        userName: 'kn1cht',
        HNames: 'H287370',
      },
    ],
  },
  {
    provisionalCode: '2017 HT107',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 2.59805,
    eccentricity: 0.0621942,
    inclination: 7.10614,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ms',
        HNames: 'H287298, H287404, H287950, H288059',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288499',
      },
    ],
  },
  {
    provisionalCode: '2019 AL139',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 3.14234,
    eccentricity: 0.1707883,
    inclination: 8.65003,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H223406, H228496',
      },
      {
        userName: 'yakuba',
        HNames: 'H258905',
      },
      {
        userName: 'sasaki',
        HNames: 'H264363',
      },
    ],
  },
  {
    provisionalCode: '2019 EV6',
    note: '',
    absoluteMagnitude: 17.87,
    semimajorAxis: 2.89852,
    eccentricity: 0.0979046,
    inclination: 11.05914,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'コージ',
        HNames: 'H259157',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H270736',
      },
      {
        userName: 'まとりょーしか',
        HNames: 'H282825',
      },
    ],
  },
  {
    provisionalCode: '2019 GJ180',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 2.81274,
    eccentricity: 0.1711555,
    inclination: 6.62121,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H227774, H237702, H238808',
      },
      {
        userName: 'bny',
        HNames: 'H235519',
      },
      {
        userName: 'apollo18',
        HNames: 'H237702',
      },
    ],
  },
  {
    provisionalCode: '2019 GV180',
    note: '',
    absoluteMagnitude: 18.3,
    semimajorAxis: 3.24806,
    eccentricity: 0.105146,
    inclination: 5.90237,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H238132',
      },
      {
        userName: 'bny',
        HNames: 'H241816',
      },
    ],
  },
  {
    provisionalCode: '2019 GX180',
    note: '',
    absoluteMagnitude: 18.18,
    semimajorAxis: 2.73746,
    eccentricity: 0.0305783,
    inclination: 6.73541,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H256896, H281524',
      },
    ],
  },
  {
    provisionalCode: '2019 KX75',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.09417,
    eccentricity: 0.1113903,
    inclination: 7.04821,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225650',
      },
      {
        userName: 'うめきち730',
        HNames: 'H234886, H257860',
      },
      {
        userName: '南川',
        HNames: 'H259525',
      },
    ],
  },
  {
    provisionalCode: '2019 SQ243',
    note: '',
    absoluteMagnitude: 18.15,
    semimajorAxis: 3.02494,
    eccentricity: 0.0695856,
    inclination: 8.96356,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217440',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217713, H218239',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H235228',
      },
    ],
  },
  {
    provisionalCode: '2019 SS243',
    note: '',
    absoluteMagnitude: 20.58,
    semimajorAxis: 2.69836,
    eccentricity: 0.0898288,
    inclination: 2.63111,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224686, H281167',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248639',
      },
    ],
  },
  {
    provisionalCode: '2019 SV243',
    note: '',
    absoluteMagnitude: 18.31,
    semimajorAxis: 2.7855,
    eccentricity: 0.0057035,
    inclination: 3.36305,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'さとうきび',
        HNames: 'H207148',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223419',
      },
    ],
  },
  {
    provisionalCode: '2019 UY172',
    note: '',
    absoluteMagnitude: 20.95,
    semimajorAxis: 2.30174,
    eccentricity: 0.1130285,
    inclination: 5.21864,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H200033',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217955, H218328',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222057',
      },
    ],
  },
  {
    provisionalCode: '2019 UZ172',
    note: '',
    absoluteMagnitude: 20.84,
    semimajorAxis: 2.43446,
    eccentricity: 0.1830758,
    inclination: 2.15486,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217525',
      },
      {
        userName: 'さんしろう',
        HNames: 'H218271, H218304',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222058',
      },
    ],
  },
  {
    provisionalCode: '2019 UA173',
    note: '',
    absoluteMagnitude: 19.7,
    semimajorAxis: 3.22222,
    eccentricity: 0.163713,
    inclination: 12.235,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'うめきち730',
        HNames: 'H229386',
      },
      {
        userName: 'もしもス',
        HNames: 'H229386',
      },
      {
        userName: 'れお',
        HNames: 'H265840, H267200',
      },
    ],
  },
  {
    provisionalCode: '2019 UB173',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.57809,
    eccentricity: 0.1629537,
    inclination: 15.27205,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H227122',
      },
      {
        userName: 'mitarushi',
        HNames: 'H227122',
      },
      {
        userName: 'れお',
        HNames: 'H264757, H271251',
      },
    ],
  },
  {
    provisionalCode: '2019 UD173',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 3.12516,
    eccentricity: 0.1322893,
    inclination: 12.16608,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H227906, H278279',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229836',
      },
      {
        userName: 'zorome',
        HNames: 'H280059',
      },
    ],
  },
  {
    provisionalCode: '2019 UF173',
    note: '',
    absoluteMagnitude: 18.32,
    semimajorAxis: 3.11972,
    eccentricity: 0.0340241,
    inclination: 12.44162,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232764, H247374, H282803, H283234',
      },
      {
        userName: 'れお',
        HNames: 'H265320',
      },
    ],
  },
  {
    provisionalCode: '2019 UG173',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 2.72314,
    eccentricity: 0.1097256,
    inclination: 12.20483,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230902, H231683, H232791',
      },
      {
        userName: 'れお',
        HNames: 'H264739, H264741',
      },
      {
        userName: 'もしもス',
        HNames: 'H267111',
      },
    ],
  },
  {
    provisionalCode: '2019 UH173',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.10311,
    eccentricity: 0.2734326,
    inclination: 11.90204,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H214905',
      },
      {
        userName: 'このしろ',
        HNames: 'H214905',
      },
      {
        userName: 's.ozawa',
        HNames: 'H247443',
      },
      {
        userName: 'れお',
        HNames: 'H263486, H275430',
      },
    ],
  },
  {
    provisionalCode: '2019 UJ173',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.05838,
    eccentricity: 0.1296734,
    inclination: 12.11081,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230021, H230084, H232082',
      },
      {
        userName: 's.ozawa',
        HNames: 'H237922',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278888',
      },
    ],
  },
  {
    provisionalCode: '2019 UK173',
    note: '',
    absoluteMagnitude: 18.33,
    semimajorAxis: 3.20071,
    eccentricity: 0.0220972,
    inclination: 17.52459,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'やまささ',
        HNames: 'H216285',
      },
      {
        userName: 'mitarushi',
        HNames: 'H227150, H227151',
      },
      {
        userName: 'れお',
        HNames: 'H267606',
      },
    ],
  },
  {
    provisionalCode: '2019 UL173',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 3.09157,
    eccentricity: 0.0824441,
    inclination: 11.9715,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H227178',
      },
      {
        userName: 'れお',
        HNames: 'H256040, H265317',
      },
      {
        userName: 'kn1cht',
        HNames: 'H284522, H284523',
      },
    ],
  },
  {
    provisionalCode: '2019 UM173',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.68,
    semimajorAxis: 41.61367,
    eccentricity: 0.1527789,
    inclination: 30.59851,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225071, H261154, H261165, H261174, H261182, H261188',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261188, H263980, H278811, H278818',
      },
      {
        userName: 'れお',
        HNames: 'H263980',
      },
    ],
  },
  {
    provisionalCode: '2019 UP173',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.89311,
    eccentricity: 0.1323189,
    inclination: 6.93918,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219723, H229867',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H219723',
      },
    ],
  },
  {
    provisionalCode: '2019 UQ173',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 3.13867,
    eccentricity: 0.190481,
    inclination: 16.41349,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H239502, H249183, H251875',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251875',
      },
    ],
  },
  {
    provisionalCode: '2019 US173',
    note: '',
    absoluteMagnitude: 20.7,
    semimajorAxis: 2.59368,
    eccentricity: 0.1990055,
    inclination: 10.83537,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H215702',
      },
      {
        userName: 's.ozawa',
        HNames: 'H241042',
      },
      {
        userName: 'れお',
        HNames: 'H267450',
      },
    ],
  },
  {
    provisionalCode: '2019 UT173',
    note: '',
    absoluteMagnitude: 20.73,
    semimajorAxis: 3.2004,
    eccentricity: 0.1533074,
    inclination: 15.32528,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H242358',
      },
      {
        userName: 'れお',
        HNames: 'H268818',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286204',
      },
    ],
  },
  {
    provisionalCode: '2019 UU173',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.37,
    semimajorAxis: 53.38442,
    eccentricity: 0.2524755,
    inclination: 25.43883,
    oppositions: 5,
    uncertainty: 3,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H234902, H235086, H241335',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H234902, H256271, H284809',
      },
      {
        userName: 'れお',
        HNames: 'H256271',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H284809, H285031',
      },
    ],
  },
  {
    provisionalCode: '2019 UV173',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.57,
    semimajorAxis: 81.1959,
    eccentricity: 0.5447149,
    inclination: 26.40378,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267108, H286052, H286070, H286073',
      },
      {
        userName: 'れお',
        HNames: 'H267108',
      },
    ],
  },
  {
    provisionalCode: '2019 UW173',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.73,
    semimajorAxis: 42.61512,
    eccentricity: 0.147732,
    inclination: 21.25328,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266458, H286978, H286990',
      },
      {
        userName: 'れお',
        HNames: 'H266458',
      },
    ],
  },
  {
    provisionalCode: '2019 UY173',
    note: '',
    absoluteMagnitude: 20.29,
    semimajorAxis: 2.76119,
    eccentricity: 0.1584742,
    inclination: 11.05759,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H238882',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H256438, H288019',
      },
      {
        userName: 'れお',
        HNames: 'H256438',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H256438',
      },
    ],
  },
  {
    provisionalCode: '2019 VD49',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.02511,
    eccentricity: 0.0543042,
    inclination: 12.9639,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H234357, H283202',
      },
      {
        userName: 'zorome',
        HNames: 'H234357',
      },
      {
        userName: 'mitarushi',
        HNames: 'H234357',
      },
      {
        userName: 'れお',
        HNames: 'H266550',
      },
    ],
  },
  {
    provisionalCode: '2019 VE49',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 3.13026,
    eccentricity: 0.2704205,
    inclination: 15.04222,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H261109, H266541',
      },
      {
        userName: 'nasiar99',
        HNames: 'H261109',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283221',
      },
    ],
  },
  {
    provisionalCode: '2019 VF49',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.86677,
    eccentricity: 0.0882459,
    inclination: 12.89209,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H261113',
      },
      {
        userName: 'nasiar99',
        HNames: 'H261113',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283205, H283210',
      },
    ],
  },
  {
    provisionalCode: '2019 VG49',
    note: '',
    absoluteMagnitude: 20.66,
    semimajorAxis: 3.06943,
    eccentricity: 0.1376206,
    inclination: 12.01435,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H255531, H283241',
      },
      {
        userName: 'れお',
        HNames: 'H255531, H266549',
      },
    ],
  },
  {
    provisionalCode: '2019 VH49',
    note: '',
    absoluteMagnitude: 20.86,
    semimajorAxis: 2.26354,
    eccentricity: 0.1248863,
    inclination: 3.90273,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214240, H214248, H229579',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223287',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ49',
    note: '',
    absoluteMagnitude: 20.9,
    semimajorAxis: 2.72758,
    eccentricity: 0.2246586,
    inclination: 14.81597,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H231690',
      },
      {
        userName: 'nasiar99',
        HNames: 'H251829',
      },
      {
        userName: 'legohasiri',
        HNames: 'H274180',
      },
    ],
  },
  {
    provisionalCode: '2019 VK49',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.0033,
    eccentricity: 0.0431641,
    inclination: 11.94791,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H231689',
      },
      {
        userName: 's.ozawa',
        HNames: 'H234903, H272022',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H272022',
      },
    ],
  },
  {
    provisionalCode: '2019 VL49',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.87975,
    eccentricity: 0.1116632,
    inclination: 12.01325,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H222174',
      },
      {
        userName: 'kn1cht',
        HNames: 'H222174',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238881',
      },
    ],
  },
  {
    provisionalCode: '2019 VM49',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.79092,
    eccentricity: 0.1638101,
    inclination: 4.35406,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217439',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217940, H217958, H218329',
      },
    ],
  },
  {
    provisionalCode: '2019 VN49',
    note: '',
    absoluteMagnitude: 20.29,
    semimajorAxis: 2.33099,
    eccentricity: 0.0399872,
    inclination: 9.96894,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H222939, H265335',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222939, H223200',
      },
    ],
  },
  {
    provisionalCode: '2019 VO49',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 3.06597,
    eccentricity: 0.0856116,
    inclination: 12.33932,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222957, H222959',
      },
      {
        userName: 'れお',
        HNames: 'H267047, H272453',
      },
    ],
  },
  {
    provisionalCode: '2019 VP49',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 3.10775,
    eccentricity: 0.1902851,
    inclination: 11.84331,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H227120, H227149',
      },
      {
        userName: 'もしもス',
        HNames: 'H249992',
      },
      {
        userName: 'れお',
        HNames: 'H268294',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ49',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 2.59499,
    eccentricity: 0.2156002,
    inclination: 10.57788,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H240023',
      },
      {
        userName: 's.ozawa',
        HNames: 'H251881',
      },
      {
        userName: 'aika',
        HNames: 'H271450',
      },
    ],
  },
  {
    provisionalCode: '2019 VR49',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 2.67663,
    eccentricity: 0.0474325,
    inclination: 12.34361,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H226892',
      },
      {
        userName: 'もしもス',
        HNames: 'H226892',
      },
      {
        userName: 's.ozawa',
        HNames: 'H249378',
      },
      {
        userName: 'kn1cht',
        HNames: 'H279472',
      },
    ],
  },
  {
    provisionalCode: '2019 VS49',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 3.04677,
    eccentricity: 0.0162427,
    inclination: 11.89135,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H228800',
      },
      {
        userName: 'れお',
        HNames: 'H255512, H264738, H271364',
      },
    ],
  },
  {
    provisionalCode: '2019 VT49',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.13915,
    eccentricity: 0.1282844,
    inclination: 13.74298,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H227177',
      },
      {
        userName: 'れお',
        HNames: 'H265318',
      },
      {
        userName: 'kn1cht',
        HNames: 'H283828, H283853',
      },
    ],
  },
  {
    provisionalCode: '2019 VU49',
    note: '',
    absoluteMagnitude: 22.71,
    semimajorAxis: 2.21469,
    eccentricity: 0.2301123,
    inclination: 3.29296,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215317, H280554',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215317',
      },
    ],
  },
  {
    provisionalCode: '2019 VV49',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 3.08756,
    eccentricity: 0.0697567,
    inclination: 4.9931,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H220172, H284381',
      },
    ],
  },
  {
    provisionalCode: '2019 VY49',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.38229,
    eccentricity: 0.1251901,
    inclination: 10.40024,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213937, H216914',
      },
      {
        userName: 'さんしろう',
        HNames: 'H228282',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ49',
    note: '',
    absoluteMagnitude: 21.62,
    semimajorAxis: 2.62427,
    eccentricity: 0.083675,
    inclination: 12.44107,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222403',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226811',
      },
    ],
  },
  {
    provisionalCode: '2019 VA50',
    note: '',
    absoluteMagnitude: 21.06,
    semimajorAxis: 2.62681,
    eccentricity: 0.1990829,
    inclination: 14.4842,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H231691',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285812',
      },
    ],
  },
  {
    provisionalCode: '2019 VB50',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.55567,
    eccentricity: 0.0685996,
    inclination: 17.54277,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H239515',
      },
      {
        userName: 'もしもス',
        HNames: 'H247118',
      },
      {
        userName: 'れお',
        HNames: 'H267451',
      },
    ],
  },
  {
    provisionalCode: '2019 VD50',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 3.11126,
    eccentricity: 0.0583956,
    inclination: 11.58644,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H223199',
      },
      {
        userName: 'れお',
        HNames: 'H265388, H266569',
      },
    ],
  },
  {
    provisionalCode: '2019 VE50',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 11.49,
    semimajorAxis: 20.21592,
    eccentricity: 0.3085802,
    inclination: 5.01797,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H220411',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H274430, H274449',
      },
    ],
  },
  {
    provisionalCode: '2019 VF50',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.26849,
    eccentricity: 0.0453845,
    inclination: 3.24366,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H222775, H282549',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222775',
      },
    ],
  },
  {
    provisionalCode: '2019 VG50',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.00098,
    eccentricity: 0.07955,
    inclination: 11.93622,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222885, H222926',
      },
      {
        userName: 'もしもス',
        HNames: 'H222926',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287345',
      },
    ],
  },
  {
    provisionalCode: '2019 VH50',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.89634,
    eccentricity: 0.0303429,
    inclination: 11.47455,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222927',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287342',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ50',
    note: '2019 VK50と同定',
    absoluteMagnitude: 20.23,
    semimajorAxis: 3.13138,
    eccentricity: 0.2015624,
    inclination: 11.82225,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H230719, H230844, H287690',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230719, H230844',
      },
      {
        userName: 'れお',
        HNames: 'H256441',
      },
    ],
  },
  {
    provisionalCode: '2019 VL50',
    note: '2019 VM50と同定',
    absoluteMagnitude: 21.32,
    semimajorAxis: 2.57714,
    eccentricity: 0.1745589,
    inclination: 9.93517,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232086, H232898, H287697, H287698',
      },
      {
        userName: 'mitarushi',
        HNames: 'H232086, H232898',
      },
    ],
  },
  {
    provisionalCode: '2019 VN50',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 3.0508,
    eccentricity: 0.1268482,
    inclination: 12.26334,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H228328',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230716, H230839',
      },
      {
        userName: 'れお',
        HNames: 'H257934',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287707',
      },
    ],
  },
  {
    provisionalCode: '2019 VO50',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.79832,
    eccentricity: 0.1162018,
    inclination: 1.49963,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H219581',
      },
      {
        userName: 'さんしろう',
        HNames: 'H220374',
      },
    ],
  },
  {
    provisionalCode: '2019 VP50',
    note: '',
    absoluteMagnitude: 20.63,
    semimajorAxis: 3.01533,
    eccentricity: 0.1496404,
    inclination: 12.35507,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'bny',
        HNames: 'H227734',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H230840',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230840',
      },
      {
        userName: 'れお',
        HNames: 'H256483',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ50',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 3.07193,
    eccentricity: 0.1523554,
    inclination: 12.31693,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230843, H232897',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238084',
      },
      {
        userName: 'れお',
        HNames: 'H256459',
      },
    ],
  },
  {
    provisionalCode: '2019 VR50',
    note: '2019 VS50と同定',
    absoluteMagnitude: 18.69,
    semimajorAxis: 3.0921,
    eccentricity: 0.1679612,
    inclination: 12.16802,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230700',
      },
      {
        userName: 'れお',
        HNames: 'H265241',
      },
      {
        userName: 'もしもス',
        HNames: 'H267112',
      },
      {
        userName: 's.ozawa',
        HNames: 'H282801',
      },
    ],
  },
  {
    provisionalCode: '2019 VT50',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 3.06457,
    eccentricity: 0.0500807,
    inclination: 11.73471,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228803, H283215',
      },
      {
        userName: 'れお',
        HNames: 'H228803',
      },
      {
        userName: 'mitarushi',
        HNames: 'H228803',
      },
    ],
  },
  {
    provisionalCode: '2019 WG34',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.56013,
    eccentricity: 0.2217404,
    inclination: 10.17845,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H251816',
      },
      {
        userName: 's.ozawa',
        HNames: 'H252441, H253623',
      },
      {
        userName: 'れお',
        HNames: 'H271330',
      },
    ],
  },
  {
    provisionalCode: '2019 WH34',
    note: '',
    absoluteMagnitude: 21.55,
    semimajorAxis: 2.34363,
    eccentricity: 0.2692735,
    inclination: 8.82232,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H252458',
      },
      {
        userName: 'kn1cht',
        HNames: 'H252458',
      },
      {
        userName: 'れお',
        HNames: 'H272832',
      },
      {
        userName: 's.ozawa',
        HNames: 'H273181',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ34',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 3.00432,
    eccentricity: 0.1633985,
    inclination: 12.22444,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H231495, H255165',
      },
      {
        userName: 'aika',
        HNames: 'H231495',
      },
      {
        userName: 'ms',
        HNames: 'H272692',
      },
    ],
  },
  {
    provisionalCode: '2019 WK34',
    note: '',
    absoluteMagnitude: 21.65,
    semimajorAxis: 2.34511,
    eccentricity: 0.2579887,
    inclination: 8.98224,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H252429, H252430',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278408, H278603, H278604',
      },
      {
        userName: 'れお',
        HNames: 'H279351',
      },
    ],
  },
  {
    provisionalCode: '2019 WL34',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.28,
    semimajorAxis: 39.89717,
    eccentricity: 0.2094942,
    inclination: 17.89135,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263981, H278811, H278887, H278898, H278938',
      },
      {
        userName: 'れお',
        HNames: 'H263981',
      },
    ],
  },
  {
    provisionalCode: '2019 WM34',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 2.86184,
    eccentricity: 0.1083472,
    inclination: 19.089,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226206',
      },
      {
        userName: 'れお',
        HNames: 'H268306, H272172',
      },
      {
        userName: 'kn1cht',
        HNames: 'H271573',
      },
      {
        userName: 'ms',
        HNames: 'H271573',
      },
    ],
  },
  {
    provisionalCode: '2019 WN34',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 3.02552,
    eccentricity: 0.1123935,
    inclination: 12.1973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226210',
      },
      {
        userName: '赵经远',
        HNames: 'H252187',
      },
      {
        userName: 'れお',
        HNames: 'H255552',
      },
      {
        userName: 'kn1cht',
        HNames: 'H284919',
      },
    ],
  },
  {
    provisionalCode: '2019 WO34',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 2.96825,
    eccentricity: 0.1024251,
    inclination: 13.67024,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226207',
      },
      {
        userName: 'れお',
        HNames: 'H255940, H275469',
      },
      {
        userName: 'kn1cht',
        HNames: 'H275469',
      },
    ],
  },
  {
    provisionalCode: '2019 WP34',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.11927,
    eccentricity: 0.156386,
    inclination: 15.41769,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H240049, H240052',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H286487',
      },
    ],
  },
  {
    provisionalCode: '2016 YW39',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.91,
    semimajorAxis: 5.22589,
    eccentricity: 0.127364,
    inclination: 14.86486,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269799, H272140, H283731, H284377',
      },
    ],
  },
  {
    provisionalCode: '2017 AB63',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 2.79609,
    eccentricity: 0.1432245,
    inclination: 7.39725,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267102, H281418',
      },
      {
        userName: 'arda',
        HNames: 'H267102',
      },
      {
        userName: 'れお',
        HNames: 'H283058',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283649',
      },
    ],
  },
  {
    provisionalCode: '2017 BN230',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.00344,
    eccentricity: 0.0894131,
    inclination: 10.235,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249353',
      },
      {
        userName: 'ほたる',
        HNames: 'H287064, H287196',
      },
    ],
  },
  {
    provisionalCode: '2017 BO230',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.0721,
    eccentricity: 0.0655199,
    inclination: 9.63515,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268495',
      },
      {
        userName: 'ほたる',
        HNames: 'H279515, H287146',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279515',
      },
    ],
  },
  {
    provisionalCode: '2017 BP230',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.8827,
    eccentricity: 0.0398503,
    inclination: 7.23781,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251173, H287971',
      },
      {
        userName: 'zorome',
        HNames: 'H251173',
      },
      {
        userName: 'arda',
        HNames: 'H265421',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ230',
    note: '',
    absoluteMagnitude: 18.92,
    semimajorAxis: 3.04097,
    eccentricity: 0.1589956,
    inclination: 11.79827,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252866',
      },
      {
        userName: '赵经远',
        HNames: 'H252866',
      },
      {
        userName: 'もしもス',
        HNames: 'H276874',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288033',
      },
    ],
  },
  {
    provisionalCode: '2017 BR230',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 3.11676,
    eccentricity: 0.1798022,
    inclination: 14.13613,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H259048',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269377',
      },
      {
        userName: 'れお',
        HNames: 'H285303',
      },
    ],
  },
  {
    provisionalCode: '2017 BS230',
    note: '',
    absoluteMagnitude: 20.11,
    semimajorAxis: 2.56954,
    eccentricity: 0.176827,
    inclination: 8.9125,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265617',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268466',
      },
      {
        userName: 'aika',
        HNames: 'H268466',
      },
      {
        userName: 'arda',
        HNames: 'H268466',
      },
      {
        userName: 'nasiar99',
        HNames: 'H284427',
      },
    ],
  },
  {
    provisionalCode: '2017 BT230',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 2.78918,
    eccentricity: 0.1035355,
    inclination: 7.76741,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265633, H285043',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279603',
      },
      {
        userName: 'k96e',
        HNames: 'H285700',
      },
    ],
  },
  {
    provisionalCode: '2017 BU230',
    note: '',
    absoluteMagnitude: 18.51,
    semimajorAxis: 3.12279,
    eccentricity: 0.1218407,
    inclination: 11.11383,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268269',
      },
      {
        userName: 'arda',
        HNames: 'H272532',
      },
      {
        userName: 'アルプス',
        HNames: 'H287246',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288020',
      },
    ],
  },
  {
    provisionalCode: '2017 BV230',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.13571,
    eccentricity: 0.0845134,
    inclination: 8.64296,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269166',
      },
      {
        userName: 'アルプス',
        HNames: 'H287247',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287747',
      },
    ],
  },
  {
    provisionalCode: '2017 BW230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.6,
    semimajorAxis: 5.33382,
    eccentricity: 0.0749985,
    inclination: 27.46159,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H275854, H283970',
      },
      {
        userName: 'ほたる',
        HNames: 'H280231',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282766',
      },
    ],
  },
  {
    provisionalCode: '2017 BX230',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.19,
    semimajorAxis: 5.26713,
    eccentricity: 0.0377536,
    inclination: 9.09882,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H281299, H287754',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288015',
      },
    ],
  },
  {
    provisionalCode: '2017 BY230',
    note: '',
    absoluteMagnitude: 21.45,
    semimajorAxis: 2.24555,
    eccentricity: 0.1020428,
    inclination: 5.99981,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H283006',
      },
      {
        userName: 'arda',
        HNames: 'H283972',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288013',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ230',
    note: '',
    absoluteMagnitude: 19.7,
    semimajorAxis: 2.73448,
    eccentricity: 0.1391018,
    inclination: 10.50572,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242193',
      },
      {
        userName: 'kn1cht',
        HNames: 'H242193',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H281998',
      },
      {
        userName: 'れお',
        HNames: 'H281998',
      },
    ],
  },
  {
    provisionalCode: '2017 BA231',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.09905,
    eccentricity: 0.1992784,
    inclination: 16.9892,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H240751',
      },
      {
        userName: 'れお',
        HNames: 'H240751',
      },
      {
        userName: 'zorome',
        HNames: 'H242190',
      },
    ],
  },
  {
    provisionalCode: '2017 BB231',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.97,
    semimajorAxis: 5.24898,
    eccentricity: 0.078642,
    inclination: 23.921,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H259088',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259088',
      },
      {
        userName: 'aika',
        HNames: 'H259088',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H259088',
      },
      {
        userName: 'れお',
        HNames: 'H283186',
      },
      {
        userName: 'kn1cht',
        HNames: 'H290887',
      },
    ],
  },
  {
    provisionalCode: '2017 BC231',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.15,
    semimajorAxis: 5.13088,
    eccentricity: 0.1169242,
    inclination: 19.429,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H266908',
      },
      {
        userName: 'れお',
        HNames: 'H283917',
      },
    ],
  },
  {
    provisionalCode: '2017 BD231',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.09485,
    eccentricity: 0.0822012,
    inclination: 8.6834,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268454',
      },
      {
        userName: 'arda',
        HNames: 'H268454',
      },
      {
        userName: 'れお',
        HNames: 'H283864',
      },
    ],
  },
  {
    provisionalCode: '2017 BE231',
    note: '',
    absoluteMagnitude: 17.45,
    semimajorAxis: 3.08092,
    eccentricity: 0.085622,
    inclination: 8.0148,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H259471',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259471',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283884',
      },
      {
        userName: 'れお',
        HNames: 'H283884',
      },
    ],
  },
  {
    provisionalCode: '2017 CF54',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.45,
    semimajorAxis: 5.31604,
    eccentricity: 0.0569467,
    inclination: 25.85008,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H239688',
      },
      {
        userName: 'aika',
        HNames: 'H262782',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262782',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H262782, H279413',
      },
    ],
  },
  {
    provisionalCode: '2017 DF160',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 3.01512,
    eccentricity: 0.0922527,
    inclination: 8.86289,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272359, H275947',
      },
      {
        userName: 'れお',
        HNames: 'H275947',
      },
    ],
  },
  {
    provisionalCode: '2017 EO51',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 3.03421,
    eccentricity: 0.1297404,
    inclination: 2.03661,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201111, H202257',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H264536, H264555',
      },
    ],
  },
  {
    provisionalCode: '2017 EP51',
    note: 'Mars Crosser',
    absoluteMagnitude: 25.25,
    semimajorAxis: 1.90056,
    eccentricity: 0.3006927,
    inclination: 3.15573,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262819, H289548, H289549',
      },
      {
        userName: 'ko',
        HNames: 'H262819',
      },
    ],
  },
  {
    provisionalCode: '2017 FD232',
    note: '',
    absoluteMagnitude: 18.81,
    semimajorAxis: 3.12189,
    eccentricity: 0.1280205,
    inclination: 9.94792,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244457',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244457, H244470',
      },
    ],
  },
  {
    provisionalCode: '2017 FE232',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 2.69362,
    eccentricity: 0.062853,
    inclination: 8.25187,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233442',
      },
      {
        userName: 'arda',
        HNames: 'H270804',
      },
      {
        userName: 'aika',
        HNames: 'H279661',
      },
      {
        userName: 'ほたる',
        HNames: 'H279661',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ232',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.16315,
    eccentricity: 0.0462104,
    inclination: 8.04194,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H248849, H289682',
      },
      {
        userName: 'arda',
        HNames: 'H269562',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269562',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288537',
      },
    ],
  },
  {
    provisionalCode: '2017 FL232',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.20256,
    eccentricity: 0.0542804,
    inclination: 8.07652,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H242079, H289358',
      },
      {
        userName: 'zorome',
        HNames: 'H242079',
      },
      {
        userName: 'aika',
        HNames: 'H263049',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282077, H289358',
      },
    ],
  },
  {
    provisionalCode: '2017 FM232',
    note: '',
    absoluteMagnitude: 20.08,
    semimajorAxis: 2.72445,
    eccentricity: 0.1766074,
    inclination: 7.33097,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256606, H267157',
      },
      {
        userName: '赵经远',
        HNames: 'H289359',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289359',
      },
    ],
  },
  {
    provisionalCode: '2017 FN232',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.15474,
    eccentricity: 0.0759502,
    inclination: 9.9832,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250981, H264151, H289962',
      },
      {
        userName: 'ko',
        HNames: 'H250981',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H250981',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H264151',
      },
    ],
  },
  {
    provisionalCode: '2017 FO232',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.59163,
    eccentricity: 0.1118053,
    inclination: 7.64856,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H251041, H275499',
      },
      {
        userName: 'ほたる',
        HNames: 'H251041',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275499, H290535',
      },
    ],
  },
  {
    provisionalCode: '2017 FP232',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 2.81085,
    eccentricity: 0.0770232,
    inclination: 9.07507,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'まとりょーしか',
        HNames: 'H219864',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H272150, H290682',
      },
      {
        userName: 'aika',
        HNames: 'H272150',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H290537',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ232',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 2.73377,
    eccentricity: 0.056242,
    inclination: 13.87054,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H272151',
      },
      {
        userName: 'aika',
        HNames: 'H272151',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H290536',
      },
    ],
  },
  {
    provisionalCode: '2017 FR232',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.28,
    semimajorAxis: 5.24978,
    eccentricity: 0.1103964,
    inclination: 8.49235,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263359, H263397, H263415',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289680',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289680',
      },
    ],
  },
  {
    provisionalCode: '2017 HU107',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.70718,
    eccentricity: 0.150203,
    inclination: 7.45586,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H286516, H286577, H288321, H288350',
      },
    ],
  },
  {
    provisionalCode: '2017 HV107',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.16678,
    eccentricity: 0.1844382,
    inclination: 9.22791,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H248975, H288388',
      },
      {
        userName: 'もしもス',
        HNames: 'H248975',
      },
    ],
  },
  {
    provisionalCode: '2017 HW107',
    note: '',
    absoluteMagnitude: 18.24,
    semimajorAxis: 3.11182,
    eccentricity: 0.0827322,
    inclination: 8.42181,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248972',
      },
      {
        userName: 'zorome',
        HNames: 'H273389',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288385',
      },
    ],
  },
  {
    provisionalCode: '2017 HX107',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.16597,
    eccentricity: 0.0499664,
    inclination: 7.54493,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248971',
      },
      {
        userName: 'kn1cht',
        HNames: 'H287954, H288494, H288505',
      },
      {
        userName: 'ms',
        HNames: 'H287954',
      },
    ],
  },
  {
    provisionalCode: '2017 HY107',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 3.11861,
    eccentricity: 0.0819782,
    inclination: 7.87333,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H278521, H286578, H288527, H288535',
      },
    ],
  },
  {
    provisionalCode: '2017 HZ107',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 2.93267,
    eccentricity: 0.0686408,
    inclination: 7.86149,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H229140, H229181',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229140',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229181, H288517',
      },
    ],
  },
  {
    provisionalCode: '2017 HA108',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 2.7915,
    eccentricity: 0.1488081,
    inclination: 7.20429,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229142, H288554',
      },
      {
        userName: '赵经远',
        HNames: 'H229142, H229182, H229399',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229399',
      },
    ],
  },
  {
    provisionalCode: '2017 HB108',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.7566,
    eccentricity: 0.1669975,
    inclination: 7.57956,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243660, H243662',
      },
      {
        userName: 'もしもス',
        HNames: 'H243660, H243662',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243660',
      },
      {
        userName: 'zorome',
        HNames: 'H249235, H249774',
      },
    ],
  },
  {
    provisionalCode: '2017 HC108',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.5635,
    eccentricity: 0.0612063,
    inclination: 11.09633,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243871',
      },
      {
        userName: 'もしもス',
        HNames: 'H243871',
      },
      {
        userName: 'aika',
        HNames: 'H256305',
      },
      {
        userName: 'arda',
        HNames: 'H270293',
      },
      {
        userName: 'ほたる',
        HNames: 'H284911',
      },
    ],
  },
  {
    provisionalCode: '2017 HD108',
    note: '',
    absoluteMagnitude: 17.48,
    semimajorAxis: 2.93095,
    eccentricity: 0.3060195,
    inclination: 7.67374,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H246993',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271026',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288495, H288722',
      },
    ],
  },
  {
    provisionalCode: '2017 HE108',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 3.16217,
    eccentricity: 0.0598823,
    inclination: 8.28474,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H286579, H288758, H288777, H288812, H288848, H288887',
      },
    ],
  },
  {
    provisionalCode: '2017 HF108',
    note: '',
    absoluteMagnitude: 18.15,
    semimajorAxis: 3.16519,
    eccentricity: 0.076504,
    inclination: 7.97176,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'ms',
        HNames: 'H286517, H288353, H288847',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288322',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288500',
      },
    ],
  },
  {
    provisionalCode: '2017 HH108',
    note: '',
    absoluteMagnitude: 18.89,
    semimajorAxis: 3.09732,
    eccentricity: 0.1015851,
    inclination: 12.20071,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H267362',
      },
      {
        userName: 'arda',
        HNames: 'H272175',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289031, H289466',
      },
      {
        userName: 'れお',
        HNames: 'H289031',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ108',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.21,
    semimajorAxis: 5.27657,
    eccentricity: 0.0604306,
    inclination: 12.95736,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H282950, H286580, H289255, H289389',
      },
    ],
  },
  {
    provisionalCode: '2017 HK108',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 3.18441,
    eccentricity: 0.1392139,
    inclination: 17.67788,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H283051, H289657, H289672, H289700, H289726',
      },
    ],
  },
  {
    provisionalCode: '2017 HL108',
    note: '2020 RB76と同定',
    absoluteMagnitude: 17.67,
    semimajorAxis: 3.21824,
    eccentricity: 0.0607261,
    inclination: 8.33295,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248097',
      },
      {
        userName: 'ms',
        HNames: 'H290348, H290368, H290473',
      },
    ],
  },
  {
    provisionalCode: '2017 HM108',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.69417,
    eccentricity: 0.2411043,
    inclination: 23.70209,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H269391, H289048',
      },
    ],
  },
  {
    provisionalCode: '2019 UZ173',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.87,
    semimajorAxis: 47.92775,
    eccentricity: 0.2641957,
    inclination: 18.99987,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H219073',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260161',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275004, H275010, H275029',
      },
    ],
  },
  {
    provisionalCode: '2019 VU50',
    note: '',
    absoluteMagnitude: 21.13,
    semimajorAxis: 2.43816,
    eccentricity: 0.1320441,
    inclination: 4.18937,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218853',
      },
      {
        userName: 'ngc',
        HNames: 'H230773',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279600',
      },
    ],
  },
  {
    provisionalCode: '2019 VV50',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 3.1725,
    eccentricity: 0.0834197,
    inclination: 8.70062,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219262, H228826',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H219262, H228826',
      },
      {
        userName: 'さんしろう',
        HNames: 'H228287',
      },
    ],
  },
  {
    provisionalCode: '2019 VW50',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 3.1884,
    eccentricity: 0.2232579,
    inclination: 16.65772,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H223201, H266510',
      },
      {
        userName: 'mitarushi',
        HNames: 'H223201',
      },
      {
        userName: 'nasiar99',
        HNames: 'H261108',
      },
    ],
  },
  {
    provisionalCode: '2019 VX50',
    note: '',
    absoluteMagnitude: 21.62,
    semimajorAxis: 2.29211,
    eccentricity: 0.110155,
    inclination: 5.62635,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214415',
      },
      {
        userName: 'このしろ',
        HNames: 'H214415',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222787',
      },
    ],
  },
  {
    provisionalCode: '2019 VY50',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.3289,
    eccentricity: 0.2424633,
    inclination: 1.84332,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H218072, H218225, H218332',
      },
    ],
  },
  {
    provisionalCode: '2016 PF294',
    note: '',
    absoluteMagnitude: 17.57,
    semimajorAxis: 2.97713,
    eccentricity: 0.1120167,
    inclination: 8.25066,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H270043',
      },
      {
        userName: 'はんぺん',
        HNames: 'H295129',
      },
    ],
  },
  {
    provisionalCode: '2016 RB100',
    note: '',
    absoluteMagnitude: 18.01,
    semimajorAxis: 3.01516,
    eccentricity: 0.1229211,
    inclination: 6.1518,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H257855, H266568',
      },
    ],
  },
  {
    provisionalCode: '2016 WA85',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 3.17953,
    eccentricity: 0.0370755,
    inclination: 9.97886,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260330, H281909',
      },
      {
        userName: 'zorome',
        HNames: 'H267969',
      },
      {
        userName: 'arda',
        HNames: 'H294793',
      },
    ],
  },
  {
    provisionalCode: '2016 WD85',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.1818,
    eccentricity: 0.0660475,
    inclination: 8.31912,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266544, H293634',
      },
      {
        userName: 'ms',
        HNames: 'H267162, H300698',
      },
      {
        userName: 'zorome',
        HNames: 'H267883',
      },
    ],
  },
  {
    provisionalCode: '2016 WE85',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 2.67175,
    eccentricity: 0.0080502,
    inclination: 8.68027,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220869',
      },
      {
        userName: 'ms',
        HNames: 'H267195, H268849',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H268849',
      },
    ],
  },
  {
    provisionalCode: '2016 WF85',
    note: '',
    absoluteMagnitude: 18.67,
    semimajorAxis: 3.17704,
    eccentricity: 0.0504214,
    inclination: 8.47511,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'ms',
        HNames: 'H267192, H267609',
      },
      {
        userName: 'れお',
        HNames: 'H267192',
      },
      {
        userName: 'zorome',
        HNames: 'H267876',
      },
      {
        userName: 'nasiar99',
        HNames: 'H293209',
      },
    ],
  },
  {
    provisionalCode: '2016 YX39',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 42.18716,
    eccentricity: 0.2315089,
    inclination: 28.8922,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H280367, H280372, H280374',
      },
    ],
  },
  {
    provisionalCode: '2016 YY39',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.05,
    semimajorAxis: 28.92003,
    eccentricity: 0.2792703,
    inclination: 13.36592,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H279939, H281572, H281579',
      },
    ],
  },
  {
    provisionalCode: '2016 YZ39',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 3.14225,
    eccentricity: 0.2000427,
    inclination: 10.21611,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H279058, H288247',
      },
      {
        userName: 'arda',
        HNames: 'H288005',
      },
    ],
  },
  {
    provisionalCode: '2016 YA40',
    note: '',
    absoluteMagnitude: 17.83,
    semimajorAxis: 3.01328,
    eccentricity: 0.0501013,
    inclination: 9.30098,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251785, H265474',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265474',
      },
    ],
  },
  {
    provisionalCode: '2017 AE63',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.63417,
    eccentricity: 0.3033002,
    inclination: 12.66183,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H282757, H288272',
      },
      {
        userName: 'ほたる',
        HNames: 'H287197',
      },
    ],
  },
  {
    provisionalCode: '2017 AF63',
    note: '',
    absoluteMagnitude: 17.6,
    semimajorAxis: 3.16357,
    eccentricity: 0.0903088,
    inclination: 8.00702,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267847, H290387',
      },
      {
        userName: 'れお',
        HNames: 'H281313',
      },
    ],
  },
  {
    provisionalCode: '2017 BG231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.11,
    semimajorAxis: 46.69993,
    eccentricity: 0.1935019,
    inclination: 30.038,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275454, H275468, H275475, H275477',
      },
    ],
  },
  {
    provisionalCode: '2017 BH231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.34,
    semimajorAxis: 43.49077,
    eccentricity: 0.1350316,
    inclination: 9.6511,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H286968',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.73,
    semimajorAxis: 47.62225,
    eccentricity: 0.1863501,
    inclination: 9.05811,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281690, H289844, H289853',
      },
      {
        userName: 'れお',
        HNames: 'H281690',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289853, H290060',
      },
    ],
  },
  {
    provisionalCode: '2017 BK231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.66,
    semimajorAxis: 46.84018,
    eccentricity: 0.2069662,
    inclination: 10.46043,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247891, H281480, H281560, H281573',
      },
      {
        userName: 'zorome',
        HNames: 'H247891, H247966',
      },
    ],
  },
  {
    provisionalCode: '2017 BL231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.5,
    semimajorAxis: 69.17605,
    eccentricity: 0.464112,
    inclination: 29.61663,
    oppositions: 4,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270380, H270409, H282685',
      },
    ],
  },
  {
    provisionalCode: '2017 BM231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.39,
    semimajorAxis: 36.41303,
    eccentricity: 0.1268724,
    inclination: 15.01841,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270331, H281429, H281437',
      },
    ],
  },
  {
    provisionalCode: '2017 BN231',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.36,
    semimajorAxis: 5.18261,
    eccentricity: 0.0795189,
    inclination: 8.14582,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268510',
      },
      {
        userName: 'aika',
        HNames: 'H268510',
      },
      {
        userName: 'kn1cht',
        HNames: 'H268510, H294331',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287967',
      },
    ],
  },
  {
    provisionalCode: '2017 BO231',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.95,
    semimajorAxis: 43.6919,
    eccentricity: 0.2109819,
    inclination: 8.53157,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260739, H275015, H282390',
      },
    ],
  },
  {
    provisionalCode: '2017 BP231',
    note: '',
    absoluteMagnitude: 17.98,
    semimajorAxis: 3.13551,
    eccentricity: 0.1180836,
    inclination: 11.65038,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H259478',
      },
      {
        userName: 'ko',
        HNames: 'H275757',
      },
      {
        userName: 'ほたる',
        HNames: 'H282244, H284884',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ231',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.01648,
    eccentricity: 0.034021,
    inclination: 7.36241,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251513',
      },
      {
        userName: 'れお',
        HNames: 'H251513',
      },
      {
        userName: 'ko',
        HNames: 'H280265',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280376, H290490',
      },
    ],
  },
  {
    provisionalCode: '2017 BR231',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.55391,
    eccentricity: 0.1231144,
    inclination: 9.50806,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275108, H276619',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281565',
      },
      {
        userName: 'ほたる',
        HNames: 'H284917',
      },
    ],
  },
  {
    provisionalCode: '2017 BS231',
    note: '',
    absoluteMagnitude: 19.41,
    semimajorAxis: 2.78099,
    eccentricity: 0.1519083,
    inclination: 11.43196,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259043, H259069',
      },
      {
        userName: 'もしもス',
        HNames: 'H259043, H280244',
      },
      {
        userName: 'れお',
        HNames: 'H259043',
      },
    ],
  },
  {
    provisionalCode: '2017 BT231',
    note: '',
    absoluteMagnitude: 18.85,
    semimajorAxis: 3.02049,
    eccentricity: 0.0378749,
    inclination: 8.78222,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249494',
      },
      {
        userName: 'zorome',
        HNames: 'H249494',
      },
      {
        userName: 'kn1cht',
        HNames: 'H269641',
      },
      {
        userName: 'arda',
        HNames: 'H292963, H294790',
      },
    ],
  },
  {
    provisionalCode: '2017 BV231',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.65,
    semimajorAxis: 5.27934,
    eccentricity: 0.0232575,
    inclination: 27.48893,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273702, H280652',
      },
    ],
  },
  {
    provisionalCode: '2017 BW231',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.14001,
    eccentricity: 0.1000733,
    inclination: 15.04738,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H280124',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283883',
      },
      {
        userName: 'れお',
        HNames: 'H283883',
      },
    ],
  },
  {
    provisionalCode: '2017 BX231',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 2.63013,
    eccentricity: 0.1869988,
    inclination: 5.7767,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H280018',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280018',
      },
      {
        userName: 'nasiar99',
        HNames: 'H289939',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ231',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.73763,
    eccentricity: 0.3121109,
    inclination: 6.45386,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251516, H295009',
      },
      {
        userName: 'れお',
        HNames: 'H251516',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282767',
      },
    ],
  },
  {
    provisionalCode: '2017 BA232',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 3.09865,
    eccentricity: 0.1100076,
    inclination: 14.48998,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269194',
      },
      {
        userName: 'れお',
        HNames: 'H288917',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288917',
      },
    ],
  },
  {
    provisionalCode: '2017 BC232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.64,
    semimajorAxis: 45.08073,
    eccentricity: 0.0185996,
    inclination: 1.6457,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294497',
      },
    ],
  },
  {
    provisionalCode: '2017 BD232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.06,
    semimajorAxis: 44.89523,
    eccentricity: 0.0537272,
    inclination: 2.35747,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294299',
      },
    ],
  },
  {
    provisionalCode: '2017 BE232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.58,
    semimajorAxis: 42.12184,
    eccentricity: 0.159876,
    inclination: 3.31349,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294310',
      },
    ],
  },
  {
    provisionalCode: '2017 BF232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.5,
    semimajorAxis: 44.0261,
    eccentricity: 0.0948263,
    inclination: 1.60309,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294858',
      },
    ],
  },
  {
    provisionalCode: '2017 BG232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.67,
    semimajorAxis: 42.96918,
    eccentricity: 0.0592745,
    inclination: 5.16582,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294306',
      },
    ],
  },
  {
    provisionalCode: '2017 BH232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.23,
    semimajorAxis: 43.61324,
    eccentricity: 0.0297271,
    inclination: 2.13034,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294598',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.86,
    semimajorAxis: 41.96396,
    eccentricity: 0.2004558,
    inclination: 4.8379,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H293973',
      },
      {
        userName: 'kn1cht',
        HNames: 'H304558',
      },
    ],
  },
  {
    provisionalCode: '2017 BK232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.37,
    semimajorAxis: 46.20978,
    eccentricity: 0.1101343,
    inclination: 17.93779,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294654',
      },
    ],
  },
  {
    provisionalCode: '2017 BL232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.29,
    semimajorAxis: 57.7128,
    eccentricity: 0.3831116,
    inclination: 5.79476,
    oppositions: 4,
    uncertainty: 6,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201895',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294522',
      },
    ],
  },
  {
    provisionalCode: '2017 BM232',
    note: '2000 CS105と同定: TNO, Centaur, etc.',
    absoluteMagnitude: 7.82,
    semimajorAxis: 38.98875,
    eccentricity: 0.0139807,
    inclination: 5.31916,
    oppositions: 6,
    uncertainty: 4,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H295403',
      },
    ],
  },
  {
    provisionalCode: '2017 BO232',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.62865,
    eccentricity: 0.0901023,
    inclination: 6.4932,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279407',
      },
      {
        userName: 'れお',
        HNames: 'H279407, H280696',
      },
    ],
  },
  {
    provisionalCode: '2017 BP232',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 2.99873,
    eccentricity: 0.0679406,
    inclination: 9.61987,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268410',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268410',
      },
      {
        userName: 'aika',
        HNames: 'H268410',
      },
      {
        userName: 'れお',
        HNames: 'H268410',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ232',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 3.33621,
    eccentricity: 0.1207065,
    inclination: 8.84595,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268409',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268409',
      },
      {
        userName: 'aika',
        HNames: 'H268409',
      },
    ],
  },
  {
    provisionalCode: '2017 BS232',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 2.98725,
    eccentricity: 0.1775206,
    inclination: 2.71781,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202091',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H270591, H296269',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295818',
      },
    ],
  },
  {
    provisionalCode: '2017 BT232',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 2.39162,
    eccentricity: 0.2384434,
    inclination: 5.60606,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H280205',
      },
      {
        userName: 'arda',
        HNames: 'H281003',
      },
      {
        userName: 'aika',
        HNames: 'H296417',
      },
    ],
  },
  {
    provisionalCode: '2017 BU232',
    note: '',
    absoluteMagnitude: 20.78,
    semimajorAxis: 2.32767,
    eccentricity: 0.1349082,
    inclination: 2.18,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202183',
      },
      {
        userName: 'joshua',
        HNames: 'H266415',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276002',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297559, H297631',
      },
    ],
  },
  {
    provisionalCode: '2017 BV232',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 3.03548,
    eccentricity: 0.0971782,
    inclination: 9.19109,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H251939',
      },
      {
        userName: 'ほたる',
        HNames: 'H280573',
      },
    ],
  },
  {
    provisionalCode: '2017 BX232',
    note: '確定番号(697402)',
    absoluteMagnitude: 17.09,
    semimajorAxis: 3.22934,
    eccentricity: 0.0895423,
    inclination: 8.94156,
    oppositions: 10,
    uncertainty: 0,
    detail: [
      {
        userName: 'arda',
        HNames: 'H275846, H283278',
      },
      {
        userName: 'れお',
        HNames: 'H275846',
      },
      {
        userName: 'ほたる',
        HNames: 'H281115',
      },
    ],
  },
  {
    provisionalCode: '2017 BY232',
    note: '',
    absoluteMagnitude: 17.89,
    semimajorAxis: 2.77604,
    eccentricity: 0.1389374,
    inclination: 11.6774,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278725, H281518',
      },
      {
        userName: 'kn1cht',
        HNames: 'H295941',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.55,
    semimajorAxis: 67.90376,
    eccentricity: 0.4299076,
    inclination: 45.63179,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278332, H278341, H278346, H278355',
      },
    ],
  },
  {
    provisionalCode: '2017 BA233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.36,
    semimajorAxis: 45.8118,
    eccentricity: 0.2042066,
    inclination: 13.50722,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278377, H278396, H278410',
      },
    ],
  },
  {
    provisionalCode: '2017 BB233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.97,
    semimajorAxis: 67.52699,
    eccentricity: 0.4219839,
    inclination: 14.3264,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H283439, H283441, H283451',
      },
    ],
  },
  {
    provisionalCode: '2017 BC233',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.69491,
    eccentricity: 0.0856105,
    inclination: 5.32904,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202430',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294194',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299759',
      },
    ],
  },
  {
    provisionalCode: '2017 CG54',
    note: '',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.13432,
    eccentricity: 0.1338302,
    inclination: 23.06133,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220775',
      },
      {
        userName: 'aika',
        HNames: 'H265775',
      },
      {
        userName: '42',
        HNames: 'H265775',
      },
    ],
  },
  {
    provisionalCode: '2017 CH54',
    note: '',
    absoluteMagnitude: 18.24,
    semimajorAxis: 2.93784,
    eccentricity: 0.0157987,
    inclination: 8.57148,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262356',
      },
      {
        userName: '42',
        HNames: 'H262356, H262363',
      },
      {
        userName: 'arda',
        HNames: 'H282867',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ54',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 3.18408,
    eccentricity: 0.0888797,
    inclination: 10.4981,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H262368, H274107',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262368',
      },
      {
        userName: '42',
        HNames: 'H262368',
      },
      {
        userName: 'arda',
        HNames: 'H291149',
      },
    ],
  },
  {
    provisionalCode: '2017 CK54',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.35,
    semimajorAxis: 5.2296,
    eccentricity: 0.0454197,
    inclination: 31.84947,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H233528',
      },
      {
        userName: 'aika',
        HNames: 'H233528',
      },
      {
        userName: '42',
        HNames: 'H265861',
      },
    ],
  },
  {
    provisionalCode: '2017 CL54',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.7,
    semimajorAxis: 210.92817,
    eccentricity: 0.829365,
    inclination: 14.91642,
    oppositions: 6,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266799, H266873, H266874, H266886, H266998',
      },
    ],
  },
  {
    provisionalCode: '2017 CM54',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.22239,
    eccentricity: 0.0786915,
    inclination: 10.44001,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H263107',
      },
      {
        userName: 'ほたる',
        HNames: 'H285329',
      },
      {
        userName: 'arda',
        HNames: 'H292268',
      },
    ],
  },
  {
    provisionalCode: '2017 CN54',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.84,
    semimajorAxis: 47.93773,
    eccentricity: 0.1350989,
    inclination: 14.09662,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266801, H266890, H266905, H266909',
      },
    ],
  },
  {
    provisionalCode: '2017 CO54',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.77,
    semimajorAxis: 43.64212,
    eccentricity: 0.1088649,
    inclination: 13.34209,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250328, H250342, H250868',
      },
      {
        userName: 'kn1cht',
        HNames: 'H264942',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ54',
    note: '',
    absoluteMagnitude: 18.27,
    semimajorAxis: 3.18618,
    eccentricity: 0.0273008,
    inclination: 9.91204,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261026',
      },
      {
        userName: 'もしもス',
        HNames: 'H261026, H277504',
      },
      {
        userName: '42',
        HNames: 'H262343',
      },
      {
        userName: 'れお',
        HNames: 'H262343',
      },
    ],
  },
  {
    provisionalCode: '2017 CT54',
    note: '',
    absoluteMagnitude: 17.91,
    semimajorAxis: 3.02749,
    eccentricity: 0.0798907,
    inclination: 2.19885,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263783, H263798',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297613',
      },
    ],
  },
  {
    provisionalCode: '2017 CU54',
    note: '2017 CM58と同定',
    absoluteMagnitude: 20.17,
    semimajorAxis: 3.01294,
    eccentricity: 0.3087283,
    inclination: 9.43838,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H267665',
      },
      {
        userName: 'えでぃ',
        HNames: 'H267665',
      },
      {
        userName: 'zorome',
        HNames: 'H296093',
      },
    ],
  },
  {
    provisionalCode: '2017 CW54',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 2.93722,
    eccentricity: 0.0442106,
    inclination: 7.61603,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262558, H262604',
      },
      {
        userName: 'aika',
        HNames: 'H262604',
      },
      {
        userName: 'ko',
        HNames: 'H281781',
      },
    ],
  },
  {
    provisionalCode: '2017 CX54',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.1675,
    eccentricity: 0.0525103,
    inclination: 8.1017,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262583',
      },
      {
        userName: '42',
        HNames: 'H262583, H262684',
      },
      {
        userName: 'れお',
        HNames: 'H285219',
      },
    ],
  },
  {
    provisionalCode: '2017 CY54',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.86654,
    eccentricity: 0.1040471,
    inclination: 7.0472,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H271105',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296748, H296750, H297649',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ54',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.44734,
    eccentricity: 0.0760484,
    inclination: 5.45194,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202122',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296674',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297104, H297938',
      },
    ],
  },
  {
    provisionalCode: '2017 CA55',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 2.70644,
    eccentricity: 0.0965233,
    inclination: 7.05881,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202253',
      },
      {
        userName: 'joshua',
        HNames: 'H266441',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298306',
      },
    ],
  },
  {
    provisionalCode: '2017 CB55',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.91465,
    eccentricity: 0.0461267,
    inclination: 13.6199,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H267885',
      },
      {
        userName: 'れお',
        HNames: 'H267885',
      },
      {
        userName: '42',
        HNames: 'H267885',
      },
    ],
  },
  {
    provisionalCode: '2017 CC55',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.89207,
    eccentricity: 0.0867474,
    inclination: 3.95292,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'れお',
        HNames: 'H270862',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297638, H298371, H298430',
      },
    ],
  },
  {
    provisionalCode: '2017 CD55',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.85,
    semimajorAxis: 5.24039,
    eccentricity: 0.095017,
    inclination: 23.14371,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'としちゃん',
        HNames: 'H224876',
      },
      {
        userName: 'zorome',
        HNames: 'H266152, H275061, H285520',
      },
    ],
  },
  {
    provisionalCode: '2017 CE55',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.15514,
    eccentricity: 0.0900836,
    inclination: 8.77775,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273771, H285534',
      },
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H282476',
      },
    ],
  },
  {
    provisionalCode: '2017 DG160',
    note: '',
    absoluteMagnitude: 17.91,
    semimajorAxis: 3.17546,
    eccentricity: 0.0487056,
    inclination: 7.98528,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220868',
      },
      {
        userName: 'えでぃ',
        HNames: 'H220868',
      },
      {
        userName: 'apollo18',
        HNames: 'H220868',
      },
      {
        userName: 'arda',
        HNames: 'H274784',
      },
    ],
  },
  {
    provisionalCode: '2017 DH160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 36.34904,
    eccentricity: 0.0753733,
    inclination: 5.54182,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H245050',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266945',
      },
      {
        userName: '井上 康',
        HNames: 'H299275',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.65,
    semimajorAxis: 47.1314,
    eccentricity: 0.1911578,
    inclination: 12.50487,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268063',
      },
    ],
  },
  {
    provisionalCode: '2017 DK160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.05,
    semimajorAxis: 55.96428,
    eccentricity: 0.3642667,
    inclination: 17.35589,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H242382',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266944',
      },
    ],
  },
  {
    provisionalCode: '2017 DL160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.0,
    semimajorAxis: 43.20235,
    eccentricity: 0.0756406,
    inclination: 5.53597,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H268530',
      },
    ],
  },
  {
    provisionalCode: '2017 DM160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.15,
    semimajorAxis: 43.70225,
    eccentricity: 0.0299091,
    inclination: 5.56531,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246815, H268710',
      },
    ],
  },
  {
    provisionalCode: '2017 DN160',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.16,
    semimajorAxis: 44.534,
    eccentricity: 0.0693518,
    inclination: 5.2028,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266871',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ160',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.19302,
    eccentricity: 0.1682865,
    inclination: 14.54233,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H233765',
      },
      {
        userName: 'aika',
        HNames: 'H233765',
      },
      {
        userName: 'ほたる',
        HNames: 'H233765',
      },
      {
        userName: 'れお',
        HNames: 'H233765',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267301',
      },
    ],
  },
  {
    provisionalCode: '2017 DT160',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 2.89859,
    eccentricity: 0.138396,
    inclination: 13.8384,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250953, H285433',
      },
      {
        userName: 'nasiar99',
        HNames: 'H250953',
      },
      {
        userName: 'arda',
        HNames: 'H292314',
      },
    ],
  },
  {
    provisionalCode: '2017 DU160',
    note: '',
    absoluteMagnitude: 18.32,
    semimajorAxis: 3.15714,
    eccentricity: 0.0718387,
    inclination: 11.3409,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260259',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267244, H267308',
      },
      {
        userName: 'arda',
        HNames: 'H267244',
      },
    ],
  },
  {
    provisionalCode: '2017 DW160',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.78637,
    eccentricity: 0.1912367,
    inclination: 7.08432,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H252798',
      },
      {
        userName: 'aika',
        HNames: 'H253571, H286428',
      },
      {
        userName: 'れお',
        HNames: 'H253571',
      },
    ],
  },
  {
    provisionalCode: '2017 DX160',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.92,
    semimajorAxis: 5.3056,
    eccentricity: 0.0118426,
    inclination: 9.65582,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275948',
      },
      {
        userName: 'aika',
        HNames: 'H275948',
      },
      {
        userName: 'れお',
        HNames: 'H275948, H282573',
      },
    ],
  },
  {
    provisionalCode: '2017 DY160',
    note: '',
    absoluteMagnitude: 18.01,
    semimajorAxis: 3.18822,
    eccentricity: 0.1267478,
    inclination: 18.07033,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246138',
      },
      {
        userName: '42',
        HNames: 'H261418',
      },
      {
        userName: 'ほたる',
        HNames: 'H288693',
      },
      {
        userName: 'れお',
        HNames: 'H291043',
      },
    ],
  },
  {
    provisionalCode: '2017 DC161',
    note: '',
    absoluteMagnitude: 17.9,
    semimajorAxis: 2.97775,
    eccentricity: 0.0454164,
    inclination: 2.94302,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H266035',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296639, H297549, H297552',
      },
    ],
  },
  {
    provisionalCode: '2017 DD161',
    note: '',
    absoluteMagnitude: 21.17,
    semimajorAxis: 2.64403,
    eccentricity: 0.2234476,
    inclination: 11.12434,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262777',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262777',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H262777',
      },
    ],
  },
  {
    provisionalCode: '2017 DE161',
    note: '',
    absoluteMagnitude: 19.8,
    semimajorAxis: 3.16685,
    eccentricity: 0.0540761,
    inclination: 7.40991,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266450',
      },
      {
        userName: 'arda',
        HNames: 'H266450',
      },
      {
        userName: 'もしもス',
        HNames: 'H266450',
      },
    ],
  },
  {
    provisionalCode: '2017 DF161',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.7607,
    eccentricity: 0.1983109,
    inclination: 7.77551,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H279819',
      },
      {
        userName: 'aika',
        HNames: 'H279819',
      },
    ],
  },
  {
    provisionalCode: '2017 DG161',
    note: '',
    absoluteMagnitude: 18.32,
    semimajorAxis: 3.03562,
    eccentricity: 0.0716838,
    inclination: 8.4128,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286223',
      },
      {
        userName: 'ほたる',
        HNames: 'H286223',
      },
    ],
  },
  {
    provisionalCode: '2017 DH161',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.00087,
    eccentricity: 0.1688247,
    inclination: 9.70372,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'arda',
        HNames: 'H284908',
      },
      {
        userName: 'れお',
        HNames: 'H284908',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ161',
    note: '',
    absoluteMagnitude: 17.86,
    semimajorAxis: 4.40026,
    eccentricity: 0.2109591,
    inclination: 29.46676,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262775, H282852',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282852',
      },
      {
        userName: 'ほたる',
        HNames: 'H282852',
      },
    ],
  },
  {
    provisionalCode: '2017 DK161',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 3.02191,
    eccentricity: 0.0868305,
    inclination: 8.26161,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H280938',
      },
      {
        userName: 'えでぃ',
        HNames: 'H280938, H299556',
      },
      {
        userName: 'aika',
        HNames: 'H280938',
      },
    ],
  },
  {
    provisionalCode: '2017 DL161',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.6196,
    eccentricity: 0.1770199,
    inclination: 14.5661,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278559',
      },
      {
        userName: 'もしもス',
        HNames: 'H278559',
      },
      {
        userName: 'れお',
        HNames: 'H278559',
      },
    ],
  },
  {
    provisionalCode: '2017 DM161',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 3.13295,
    eccentricity: 0.0704997,
    inclination: 8.93622,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H275864',
      },
      {
        userName: 'aika',
        HNames: 'H275864',
      },
    ],
  },
  {
    provisionalCode: '2017 DN161',
    note: '',
    absoluteMagnitude: 19.46,
    semimajorAxis: 2.67799,
    eccentricity: 0.3048401,
    inclination: 7.90719,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275671',
      },
      {
        userName: 'aika',
        HNames: 'H275671',
      },
      {
        userName: 'れお',
        HNames: 'H275671',
      },
    ],
  },
  {
    provisionalCode: '2017 DO161',
    note: '',
    absoluteMagnitude: 18.74,
    semimajorAxis: 3.12716,
    eccentricity: 0.067595,
    inclination: 13.10603,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H272657',
      },
      {
        userName: 'れお',
        HNames: 'H272657',
      },
      {
        userName: 'aika',
        HNames: 'H272657',
      },
    ],
  },
  {
    provisionalCode: '2017 DP161',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 3.17491,
    eccentricity: 0.0740738,
    inclination: 8.04468,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H272338',
      },
      {
        userName: 'aika',
        HNames: 'H272338',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280043',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ161',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.97626,
    eccentricity: 0.0481596,
    inclination: 9.51116,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272168',
      },
      {
        userName: 'れお',
        HNames: 'H272168',
      },
    ],
  },
  {
    provisionalCode: '2017 DR161',
    note: '',
    absoluteMagnitude: 18.03,
    semimajorAxis: 3.10786,
    eccentricity: 0.1846673,
    inclination: 7.04492,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272166',
      },
      {
        userName: 'れお',
        HNames: 'H272166',
      },
    ],
  },
  {
    provisionalCode: '2017 DS161',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.17841,
    eccentricity: 0.0672332,
    inclination: 9.5407,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266451, H292577',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266451',
      },
      {
        userName: 'ko',
        HNames: 'H266451',
      },
    ],
  },
  {
    provisionalCode: '2017 DT161',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 3.01562,
    eccentricity: 0.1194755,
    inclination: 8.22628,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H266529',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266529',
      },
    ],
  },
  {
    provisionalCode: '2017 DU161',
    note: '',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.01054,
    eccentricity: 0.0545817,
    inclination: 7.4099,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267241',
      },
      {
        userName: 'arda',
        HNames: 'H267241',
      },
      {
        userName: 'aika',
        HNames: 'H267241',
      },
      {
        userName: 'れお',
        HNames: 'H290901, H291083',
      },
    ],
  },
  {
    provisionalCode: '2017 DV161',
    note: '',
    absoluteMagnitude: 19.01,
    semimajorAxis: 3.14409,
    eccentricity: 0.077582,
    inclination: 9.36973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267638',
      },
      {
        userName: 'arda',
        HNames: 'H267638',
      },
      {
        userName: 'aika',
        HNames: 'H267638',
      },
    ],
  },
  {
    provisionalCode: '2017 DW161',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.33212,
    eccentricity: 0.0440328,
    inclination: 8.91739,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267646',
      },
      {
        userName: 'arda',
        HNames: 'H267646',
      },
      {
        userName: 'もしもス',
        HNames: 'H267646',
      },
    ],
  },
  {
    provisionalCode: '2017 DX161',
    note: '',
    absoluteMagnitude: 19.27,
    semimajorAxis: 3.22369,
    eccentricity: 0.0553164,
    inclination: 7.63044,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H268145',
      },
      {
        userName: 'arda',
        HNames: 'H268145, H268148',
      },
      {
        userName: 'れお',
        HNames: 'H291463',
      },
    ],
  },
  {
    provisionalCode: '2017 DY161',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.0404,
    eccentricity: 0.1441999,
    inclination: 8.0106,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268321',
      },
      {
        userName: 'aika',
        HNames: 'H268321',
      },
      {
        userName: 'れお',
        HNames: 'H268321',
      },
      {
        userName: 'arda',
        HNames: 'H280750',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ161',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.38874,
    eccentricity: 0.119714,
    inclination: 8.36629,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201889, H202198',
      },
      {
        userName: 'joshua',
        HNames: 'H270037',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296751',
      },
    ],
  },
  {
    provisionalCode: '2017 DA162',
    note: '',
    absoluteMagnitude: 20.73,
    semimajorAxis: 2.61899,
    eccentricity: 0.1353079,
    inclination: 3.00572,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295804, H296707, H296752, H297488',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297151',
      },
    ],
  },
  {
    provisionalCode: '2017 DC162',
    note: '',
    absoluteMagnitude: 19.81,
    semimajorAxis: 2.38235,
    eccentricity: 0.1364622,
    inclination: 5.93275,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252096',
      },
      {
        userName: 'aika',
        HNames: 'H286270',
      },
      {
        userName: 'れお',
        HNames: 'H288293',
      },
    ],
  },
  {
    provisionalCode: '2017 DD162',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.94,
    semimajorAxis: 42.96093,
    eccentricity: 0.0156543,
    inclination: 3.86112,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225096',
      },
      {
        userName: 'ないとう',
        HNames: 'H225096',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297906, H297913',
      },
    ],
  },
  {
    provisionalCode: '2017 DE162',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.68998,
    eccentricity: 0.0709805,
    inclination: 3.11015,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H265042, H297142',
      },
      {
        userName: 'joshua',
        HNames: 'H271852',
      },
    ],
  },
  {
    provisionalCode: '2017 DK162',
    note: '',
    absoluteMagnitude: 20.21,
    semimajorAxis: 2.18214,
    eccentricity: 0.1775071,
    inclination: 2.13121,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202362',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294887, H301624',
      },
    ],
  },
  {
    provisionalCode: '2017 ER51',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.67,
    semimajorAxis: 5.17296,
    eccentricity: 0.1026113,
    inclination: 8.75477,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H251177',
      },
      {
        userName: 'れお',
        HNames: 'H279574',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279574',
      },
    ],
  },
  {
    provisionalCode: '2017 ES51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.07,
    semimajorAxis: 71.32195,
    eccentricity: 0.5928052,
    inclination: 13.71543,
    oppositions: 4,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270318, H270369, H275545',
      },
    ],
  },
  {
    provisionalCode: '2017 EV51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 44.39494,
    eccentricity: 0.1813485,
    inclination: 20.5472,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H258818',
      },
      {
        userName: 'arda',
        HNames: 'H286649, H290371',
      },
    ],
  },
  {
    provisionalCode: '2017 EW51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.47,
    semimajorAxis: 40.63391,
    eccentricity: 0.0697951,
    inclination: 15.06248,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249661, H250181, H250268, H260688, H260703',
      },
    ],
  },
  {
    provisionalCode: '2017 EC52',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 3.13854,
    eccentricity: 0.1809987,
    inclination: 9.48989,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246373',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246373',
      },
    ],
  },
  {
    provisionalCode: '2017 ED52',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.98,
    semimajorAxis: 114.59713,
    eccentricity: 0.6615494,
    inclination: 22.97916,
    oppositions: 4,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H219997, H220987',
      },
    ],
  },
  {
    provisionalCode: '2017 FS232',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.63386,
    eccentricity: 0.1667895,
    inclination: 11.05594,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H270064',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270064',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H270064',
      },
    ],
  },
  {
    provisionalCode: '2017 FT232',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.66,
    semimajorAxis: 45.35472,
    eccentricity: 0.1632125,
    inclination: 14.12514,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251788, H260626, H260640, H261372',
      },
    ],
  },
  {
    provisionalCode: '2017 FU232',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 2.52728,
    eccentricity: 0.0481746,
    inclination: 8.01601,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H250029',
      },
      {
        userName: 'aika',
        HNames: 'H250029',
      },
      {
        userName: 'arda',
        HNames: 'H250029',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H250029',
      },
    ],
  },
  {
    provisionalCode: '2017 FV232',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.02202,
    eccentricity: 0.0907982,
    inclination: 8.25125,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242696',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247299, H249754',
      },
      {
        userName: 'aika',
        HNames: 'H247299, H249754',
      },
    ],
  },
  {
    provisionalCode: '2017 FW232',
    note: '',
    absoluteMagnitude: 21.35,
    semimajorAxis: 2.38884,
    eccentricity: 0.0859216,
    inclination: 10.22206,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267144',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267144',
      },
      {
        userName: 'aika',
        HNames: 'H267144',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289422, H289441',
      },
    ],
  },
  {
    provisionalCode: '2017 FX232',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 3.00567,
    eccentricity: 0.1305948,
    inclination: 8.75688,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249911',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249911, H291853',
      },
    ],
  },
  {
    provisionalCode: '2017 FY232',
    note: '',
    absoluteMagnitude: 20.27,
    semimajorAxis: 2.55961,
    eccentricity: 0.2038052,
    inclination: 18.24581,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242149',
      },
      {
        userName: 'aika',
        HNames: 'H247302',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247302',
      },
      {
        userName: 'ほたる',
        HNames: 'H247302',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H292197',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ232',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.41,
    semimajorAxis: 5.21688,
    eccentricity: 0.0724842,
    inclination: 21.06232,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249377',
      },
      {
        userName: 'zorome',
        HNames: 'H249377',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H290292',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290292',
      },
    ],
  },
  {
    provisionalCode: '2017 FA233',
    note: '',
    absoluteMagnitude: 22.17,
    semimajorAxis: 2.33353,
    eccentricity: 0.0642575,
    inclination: 6.22611,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242660',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245930, H274748',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245930',
      },
      {
        userName: 'aika',
        HNames: 'H245930',
      },
      {
        userName: 'ms',
        HNames: 'H274748',
      },
    ],
  },
  {
    provisionalCode: '2017 FB233',
    note: '',
    absoluteMagnitude: 21.14,
    semimajorAxis: 2.76166,
    eccentricity: 0.1242423,
    inclination: 7.80889,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259694, H263413',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289357',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289669',
      },
      {
        userName: 'sasaki',
        HNames: 'H310274',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312602',
      },
    ],
  },
  {
    provisionalCode: '2017 FC233',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 3.1757,
    eccentricity: 0.0696819,
    inclination: 7.44043,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244446, H270199',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244446, H270199',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244446',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270199',
      },
    ],
  },
  {
    provisionalCode: '2017 FD233',
    note: '',
    absoluteMagnitude: 21.62,
    semimajorAxis: 2.3647,
    eccentricity: 0.0883621,
    inclination: 5.9291,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244452, H274542',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244452, H293123',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244452',
      },
      {
        userName: 'ms',
        HNames: 'H274542',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ233',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 2.98105,
    eccentricity: 0.1160044,
    inclination: 6.82596,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H263489',
      },
      {
        userName: 'kn1cht',
        HNames: 'H270806, H294016',
      },
      {
        userName: 'arda',
        HNames: 'H270806',
      },
    ],
  },
  {
    provisionalCode: '2017 FK233',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.10293,
    eccentricity: 0.0336785,
    inclination: 8.7812,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222083',
      },
      {
        userName: 'ほたる',
        HNames: 'H270161',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270161',
      },
      {
        userName: 'kn1cht',
        HNames: 'H270161',
      },
    ],
  },
  {
    provisionalCode: '2017 FN233',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.17111,
    eccentricity: 0.0481632,
    inclination: 8.80792,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243496, H267705',
      },
      {
        userName: 'aika',
        HNames: 'H243496, H267705',
      },
    ],
  },
  {
    provisionalCode: '2017 FO233',
    note: '',
    absoluteMagnitude: 20.97,
    semimajorAxis: 3.17698,
    eccentricity: 0.2462736,
    inclination: 13.6482,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244108, H263378',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244108',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294318',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ233',
    note: '',
    absoluteMagnitude: 20.95,
    semimajorAxis: 2.79691,
    eccentricity: 0.051002,
    inclination: 6.9598,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243193, H256294',
      },
      {
        userName: 'aika',
        HNames: 'H243193, H256294, H267126',
      },
    ],
  },
  {
    provisionalCode: '2017 FS233',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.15112,
    eccentricity: 0.0457257,
    inclination: 9.9087,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H241179',
      },
      {
        userName: 'もしもス',
        HNames: 'H241179',
      },
      {
        userName: '赵经远',
        HNames: 'H241179',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H295436',
      },
    ],
  },
  {
    provisionalCode: '2017 FT233',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 3.17039,
    eccentricity: 0.0494619,
    inclination: 8.32644,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H245471',
      },
      {
        userName: 'kn1cht',
        HNames: 'H245471',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H295438',
      },
    ],
  },
  {
    provisionalCode: '2017 FV233',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.75,
    semimajorAxis: 5.24899,
    eccentricity: 0.0772905,
    inclination: 19.17682,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244467',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244467',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244467',
      },
    ],
  },
  {
    provisionalCode: '2017 FW233',
    note: '',
    absoluteMagnitude: 20.4,
    semimajorAxis: 3.22543,
    eccentricity: 0.0785205,
    inclination: 9.18848,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244901',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244901',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244901',
      },
    ],
  },
  {
    provisionalCode: '2017 FX233',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 3.14543,
    eccentricity: 0.0320508,
    inclination: 7.31804,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H264351',
      },
      {
        userName: 'kn1cht',
        HNames: 'H270162',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270162',
      },
    ],
  },
  {
    provisionalCode: '2017 FY233',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.7924,
    eccentricity: 0.1980579,
    inclination: 11.4935,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241368',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241368',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295864',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ233',
    note: '確定番号(718492)',
    absoluteMagnitude: 17.58,
    semimajorAxis: 3.17561,
    eccentricity: 0.0571223,
    inclination: 8.24207,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H262900',
      },
      {
        userName: 'aika',
        HNames: 'H262900',
      },
      {
        userName: 'arda',
        HNames: 'H262900',
      },
    ],
  },
  {
    provisionalCode: '2017 FA234',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 3.03957,
    eccentricity: 0.0462335,
    inclination: 9.26386,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249256, H250027',
      },
      {
        userName: 'nasiar99',
        HNames: 'H249256',
      },
      {
        userName: 'れお',
        HNames: 'H250027, H292203',
      },
    ],
  },
  {
    provisionalCode: '2017 FB234',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.15924,
    eccentricity: 0.0363248,
    inclination: 7.02116,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H249258, H292207',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249258',
      },
      {
        userName: 'nasiar99',
        HNames: 'H249258',
      },
      {
        userName: 'aika',
        HNames: 'H249258',
      },
    ],
  },
  {
    provisionalCode: '2017 FC234',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 2.88168,
    eccentricity: 0.2665777,
    inclination: 6.78213,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249473',
      },
      {
        userName: 'ko',
        HNames: 'H249473',
      },
      {
        userName: 'れお',
        HNames: 'H249473',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290786',
      },
    ],
  },
  {
    provisionalCode: '2017 FD234',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 3.05012,
    eccentricity: 0.0168642,
    inclination: 11.26194,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249154',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249154',
      },
      {
        userName: 'ko',
        HNames: 'H249154',
      },
    ],
  },
  {
    provisionalCode: '2017 FH234',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.79,
    semimajorAxis: 5.24781,
    eccentricity: 0.0503442,
    inclination: 28.44496,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250470',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251895',
      },
      {
        userName: 'zorome',
        HNames: 'H251895',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ234',
    note: 'Phocaea',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.41163,
    eccentricity: 0.142317,
    inclination: 14.12354,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H219696, H221316',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219696',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282092',
      },
    ],
  },
  {
    provisionalCode: '2017 FK234',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 2.9287,
    eccentricity: 0.0639107,
    inclination: 11.62362,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251787',
      },
      {
        userName: 'aika',
        HNames: 'H258023, H259575',
      },
    ],
  },
  {
    provisionalCode: '2017 FL234',
    note: '',
    absoluteMagnitude: 18.83,
    semimajorAxis: 3.04962,
    eccentricity: 0.0156578,
    inclination: 8.72981,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'うめきち730',
        HNames: 'H225855',
      },
      {
        userName: 'zorome',
        HNames: 'H252232',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288992, H289014',
      },
    ],
  },
  {
    provisionalCode: '2017 FN234',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 3.15441,
    eccentricity: 0.1504972,
    inclination: 13.33491,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H216030',
      },
      {
        userName: 'このしろ',
        HNames: 'H216030',
      },
      {
        userName: 'aika',
        HNames: 'H216030',
      },
      {
        userName: 'apollo18',
        HNames: 'H220770',
      },
      {
        userName: 'れお',
        HNames: 'H292204',
      },
    ],
  },
  {
    provisionalCode: '2017 FO234',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 2.97531,
    eccentricity: 0.042192,
    inclination: 9.15441,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H263393',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263393, H289386',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289788',
      },
      {
        userName: 'aika',
        HNames: 'H293522',
      },
      {
        userName: 'れお',
        HNames: 'H300195',
      },
    ],
  },
  {
    provisionalCode: '2017 FP234',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.16835,
    eccentricity: 0.0423671,
    inclination: 7.46106,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241360',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241360',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H241360',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263405',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287339',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ234',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.15191,
    eccentricity: 0.0785828,
    inclination: 7.89771,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242326',
      },
      {
        userName: 'aika',
        HNames: 'H261312',
      },
      {
        userName: 'arda',
        HNames: 'H270289',
      },
      {
        userName: 'maria w',
        HNames: 'H295603',
      },
    ],
  },
  {
    provisionalCode: '2017 FR234',
    note: '',
    absoluteMagnitude: 20.49,
    semimajorAxis: 2.98784,
    eccentricity: 0.0634808,
    inclination: 8.99467,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243153, H263392',
      },
      {
        userName: 'aika',
        HNames: 'H243153, H257519',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263392',
      },
    ],
  },
  {
    provisionalCode: '2017 FS234',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.4504,
    eccentricity: 0.0922476,
    inclination: 7.39583,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244906',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244906',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244906',
      },
    ],
  },
  {
    provisionalCode: '2017 FT234',
    note: '',
    absoluteMagnitude: 20.16,
    semimajorAxis: 3.03697,
    eccentricity: 0.1900165,
    inclination: 10.89485,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H237685',
      },
      {
        userName: 'apollo18',
        HNames: 'H237685',
      },
      {
        userName: 'もしもス',
        HNames: 'H249021',
      },
      {
        userName: 'ms',
        HNames: 'H296580, H296664, H296670',
      },
    ],
  },
  {
    provisionalCode: '2017 FX234',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.26,
    semimajorAxis: 46.30753,
    eccentricity: 0.200773,
    inclination: 21.00137,
    oppositions: 6,
    uncertainty: 4,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233314, H233318, H241840',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H280293',
      },
      {
        userName: 'もしもス',
        HNames: 'H292552',
      },
      {
        userName: 'ms',
        HNames: 'H292649, H292702',
      },
    ],
  },
  {
    provisionalCode: '2017 FY234',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.99882,
    eccentricity: 0.1370057,
    inclination: 11.31591,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H200438',
      },
      {
        userName: 'aika',
        HNames: 'H243102',
      },
      {
        userName: 'kn1cht',
        HNames: 'H297321',
      },
    ],
  },
  {
    provisionalCode: '2017 FA235',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.68336,
    eccentricity: 0.2422181,
    inclination: 8.18087,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H230326, H299534',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288392',
      },
      {
        userName: 'ms',
        HNames: 'H289703, H297017',
      },
    ],
  },
  {
    provisionalCode: '2017 FD235',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.22351,
    eccentricity: 0.0772043,
    inclination: 8.71941,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H259527',
      },
      {
        userName: 'zorome',
        HNames: 'H259527',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259527',
      },
      {
        userName: 'aika',
        HNames: 'H259527',
      },
    ],
  },
  {
    provisionalCode: '2017 FE235',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.25,
    semimajorAxis: 5.18298,
    eccentricity: 0.0817661,
    inclination: 28.20866,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242721',
      },
      {
        userName: 'zorome',
        HNames: 'H252155',
      },
      {
        userName: 'aika',
        HNames: 'H280068',
      },
      {
        userName: 'nasiar99',
        HNames: 'H280068',
      },
    ],
  },
  {
    provisionalCode: '2017 FF235',
    note: '',
    absoluteMagnitude: 20.46,
    semimajorAxis: 3.09702,
    eccentricity: 0.0466985,
    inclination: 7.12102,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H279372',
      },
      {
        userName: 'ほたる',
        HNames: 'H279372',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279372',
      },
    ],
  },
  {
    provisionalCode: '2017 FG235',
    note: '',
    absoluteMagnitude: 21.26,
    semimajorAxis: 2.59898,
    eccentricity: 0.2249133,
    inclination: 12.26732,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267124',
      },
      {
        userName: 'aika',
        HNames: 'H267124, H293523',
      },
      {
        userName: 'もしもス',
        HNames: 'H267124',
      },
    ],
  },
  {
    provisionalCode: '2017 FN235',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 3.08949,
    eccentricity: 0.2163613,
    inclination: 19.42619,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H217639',
      },
      {
        userName: 'aika',
        HNames: 'H217639',
      },
      {
        userName: 'れお',
        HNames: 'H217639',
      },
      {
        userName: 'kn1cht',
        HNames: 'H217639',
      },
      {
        userName: 'うめきち730',
        HNames: 'H217639',
      },
    ],
  },
  {
    provisionalCode: '2017 FP235',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.08667,
    eccentricity: 0.1760558,
    inclination: 10.44781,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249057',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249057',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ235',
    note: '',
    absoluteMagnitude: 19.35,
    semimajorAxis: 3.03307,
    eccentricity: 0.0910975,
    inclination: 8.06064,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249436',
      },
      {
        userName: 'aika',
        HNames: 'H249436',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249436',
      },
      {
        userName: 'zorome',
        HNames: 'H249436',
      },
    ],
  },
  {
    provisionalCode: '2017 FR235',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 3.07816,
    eccentricity: 0.1187879,
    inclination: 8.4321,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244454, H298962',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244454',
      },
    ],
  },
  {
    provisionalCode: '2017 GK35',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.74719,
    eccentricity: 0.0970799,
    inclination: 7.49724,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243483',
      },
      {
        userName: 'zorome',
        HNames: 'H272996',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H272996',
      },
      {
        userName: 'kn1cht',
        HNames: 'H297315',
      },
    ],
  },
  {
    provisionalCode: '2017 GL35',
    note: '',
    absoluteMagnitude: 19.8,
    semimajorAxis: 3.28656,
    eccentricity: 0.2954523,
    inclination: 12.66224,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H272158',
      },
      {
        userName: 'aika',
        HNames: 'H272158',
      },
      {
        userName: 'arda',
        HNames: 'H272158',
      },
    ],
  },
  {
    provisionalCode: '2017 GO35',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 2.96647,
    eccentricity: 0.1185325,
    inclination: 8.51398,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H233914, H299611',
      },
      {
        userName: 'zorome',
        HNames: 'H274493',
      },
      {
        userName: 'ほたる',
        HNames: 'H284903',
      },
      {
        userName: 'arda',
        HNames: 'H284903',
      },
    ],
  },
  {
    provisionalCode: '2017 GP35',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 3.08284,
    eccentricity: 0.0640933,
    inclination: 10.76151,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243203, H291088',
      },
      {
        userName: 'maria w',
        HNames: 'H295597, H295601, H295602',
      },
      {
        userName: 'kn1cht',
        HNames: 'H297323',
      },
    ],
  },
  {
    provisionalCode: '2017 GQ35',
    note: '',
    absoluteMagnitude: 20.02,
    semimajorAxis: 3.1152,
    eccentricity: 0.1768755,
    inclination: 15.38986,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259574',
      },
      {
        userName: 'kn1cht',
        HNames: 'H259574, H272177',
      },
      {
        userName: 'arda',
        HNames: 'H272177',
      },
    ],
  },
  {
    provisionalCode: '2017 GS35',
    note: '',
    absoluteMagnitude: 17.71,
    semimajorAxis: 3.09327,
    eccentricity: 0.0253238,
    inclination: 10.5843,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H273291',
      },
      {
        userName: 's.ozawa',
        HNames: 'H299698',
      },
    ],
  },
  {
    provisionalCode: '2017 HN108',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.60208,
    eccentricity: 0.0975637,
    inclination: 17.57457,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288328',
      },
      {
        userName: 'ms',
        HNames: 'H290841, H290849, H290850',
      },
    ],
  },
  {
    provisionalCode: '2017 HO108',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.75826,
    eccentricity: 0.1274884,
    inclination: 8.56962,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243077',
      },
      {
        userName: 'ms',
        HNames: 'H291439, H291491, H291504',
      },
    ],
  },
  {
    provisionalCode: '2017 HP108',
    note: '2023 JG29と同定',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.02001,
    eccentricity: 0.1499178,
    inclination: 7.44833,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243074',
      },
      {
        userName: 'kn1cht',
        HNames: 'H272468',
      },
      {
        userName: 'ms',
        HNames: 'H291440, H291730',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ108',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.34,
    semimajorAxis: 73.25202,
    eccentricity: 0.5680897,
    inclination: 9.54026,
    oppositions: 5,
    uncertainty: 5,
    detail: [
      {
        userName: 'ko',
        HNames: 'H262810',
      },
      {
        userName: 'zorome',
        HNames: 'H272983, H272989, H272995',
      },
    ],
  },
  {
    provisionalCode: '2017 HS108',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.1165,
    eccentricity: 0.0735629,
    inclination: 7.93701,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H289701, H292647',
      },
      {
        userName: 'もしもス',
        HNames: 'H292554',
      },
    ],
  },
  {
    provisionalCode: '2017 HT108',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.19981,
    eccentricity: 0.2797781,
    inclination: 11.61437,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H290349, H290650, H290653, H290663',
      },
    ],
  },
  {
    provisionalCode: '2017 HX108',
    note: '',
    absoluteMagnitude: 19.89,
    semimajorAxis: 2.64577,
    eccentricity: 0.1313547,
    inclination: 10.95894,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H231444',
      },
      {
        userName: 'nasiar99',
        HNames: 'H277118',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288497, H289026',
      },
      {
        userName: 'ms',
        HNames: 'H292943',
      },
    ],
  },
  {
    provisionalCode: '2017 HY108',
    note: '',
    absoluteMagnitude: 18.74,
    semimajorAxis: 3.12643,
    eccentricity: 0.2248071,
    inclination: 8.2116,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H283053, H287299, H295268, H295279, H295308',
      },
    ],
  },
  {
    provisionalCode: '2017 HA109',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.06942,
    eccentricity: 0.0846013,
    inclination: 9.28832,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H272494',
      },
      {
        userName: 'arda',
        HNames: 'H273830',
      },
      {
        userName: 'ms',
        HNames: 'H294203, H295514, H295718',
      },
    ],
  },
  {
    provisionalCode: '2017 HB109',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.14538,
    eccentricity: 0.0368979,
    inclination: 9.0574,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H201074',
      },
      {
        userName: 'ms',
        HNames: 'H287297, H288889, H289390',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288496',
      },
    ],
  },
  {
    provisionalCode: '2017 HC109',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.15129,
    eccentricity: 0.2711059,
    inclination: 12.23113,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H294204, H296201, H296213, H296259',
      },
    ],
  },
  {
    provisionalCode: '2017 HE109',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.22952,
    eccentricity: 0.1352977,
    inclination: 6.29387,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'ms',
        HNames: 'H289702, H297018, H297031',
      },
    ],
  },
  {
    provisionalCode: '2017 HF109',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 2.90543,
    eccentricity: 0.0240824,
    inclination: 11.55149,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H270822',
      },
      {
        userName: 'arda',
        HNames: 'H270822',
      },
    ],
  },
  {
    provisionalCode: '2017 HH109',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.636,
    eccentricity: 0.1963803,
    inclination: 10.70842,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248970',
      },
      {
        userName: 'zorome',
        HNames: 'H274416',
      },
      {
        userName: 'ms',
        HNames: 'H288060, H289388',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ109',
    note: '',
    absoluteMagnitude: 19.63,
    semimajorAxis: 2.74953,
    eccentricity: 0.1449727,
    inclination: 7.24689,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H243156, H289683',
      },
      {
        userName: 'もしもス',
        HNames: 'H243156',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H269562',
      },
      {
        userName: 'ほたる',
        HNames: 'H284899',
      },
    ],
  },
  {
    provisionalCode: '2017 HK109',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 3.39127,
    eccentricity: 0.0186777,
    inclination: 8.13696,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H298404, H298474, H298485, H298533, H298603',
      },
    ],
  },
  {
    provisionalCode: '2017 HL109',
    note: '',
    absoluteMagnitude: 20.82,
    semimajorAxis: 2.21377,
    eccentricity: 0.0954234,
    inclination: 6.64026,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H246992',
      },
      {
        userName: 'ms',
        HNames: 'H287951, H288061',
      },
    ],
  },
  {
    provisionalCode: '2017 HN109',
    note: '2021 GO200と同定',
    absoluteMagnitude: 19.23,
    semimajorAxis: 2.5203,
    eccentricity: 0.0841547,
    inclination: 14.04958,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H229141',
      },
      {
        userName: 'ms',
        HNames: 'H288759, H298690, H298732, H298801',
      },
    ],
  },
  {
    provisionalCode: '2017 HO109',
    note: '',
    absoluteMagnitude: 19.69,
    semimajorAxis: 3.13644,
    eccentricity: 0.0331231,
    inclination: 10.78977,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H249063',
      },
      {
        userName: 'ms',
        HNames: 'H287296, H288062, H298289',
      },
    ],
  },
  {
    provisionalCode: '2017 HP109',
    note: 'Mars Crosser',
    absoluteMagnitude: 21.24,
    semimajorAxis: 2.1506,
    eccentricity: 0.2289795,
    inclination: 6.90625,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243661',
      },
      {
        userName: 'ms',
        HNames: 'H298475, H298486',
      },
    ],
  },
  {
    provisionalCode: '2017 HS109',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 4.06137,
    eccentricity: 0.3710972,
    inclination: 8.35402,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243661',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288542',
      },
      {
        userName: 'ms',
        HNames: 'H298476',
      },
    ],
  },
  {
    provisionalCode: '2017 HT109',
    note: '',
    absoluteMagnitude: 19.37,
    semimajorAxis: 3.14759,
    eccentricity: 0.0156926,
    inclination: 8.5694,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243657',
      },
      {
        userName: 'もしもス',
        HNames: 'H243657',
      },
      {
        userName: 'ms',
        HNames: 'H298477, H299691',
      },
    ],
  },
  {
    provisionalCode: '2017 HU109',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 3.18784,
    eccentricity: 0.0501775,
    inclination: 7.46545,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H296581, H300314, H300340',
      },
    ],
  },
  {
    provisionalCode: '2017 MZ39',
    note: '',
    absoluteMagnitude: 18.4,
    semimajorAxis: 2.68515,
    eccentricity: 0.0941984,
    inclination: 12.83767,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'alberto76',
        HNames: 'H261342',
      },
      {
        userName: 'むくもく',
        HNames: 'H294350',
      },
      {
        userName: 'うめきち730',
        HNames: 'H298671',
      },
    ],
  },
  {
    provisionalCode: '2017 ST379',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 2.65473,
    eccentricity: 0.0957158,
    inclination: 4.67648,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H216047',
      },
      {
        userName: 'imai',
        HNames: 'H241438',
      },
    ],
  },
  {
    provisionalCode: '2019 AM140',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 2.30013,
    eccentricity: 0.1754563,
    inclination: 5.38731,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249302',
      },
      {
        userName: 'sadap',
        HNames: 'H298082',
      },
    ],
  },
  {
    provisionalCode: '2019 AN140',
    note: '',
    absoluteMagnitude: 18.02,
    semimajorAxis: 3.0108,
    eccentricity: 0.1146782,
    inclination: 7.04008,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H280861',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H300016',
      },
    ],
  },
  {
    provisionalCode: '2019 GS182',
    note: '',
    absoluteMagnitude: 17.41,
    semimajorAxis: 3.43301,
    eccentricity: 0.0893197,
    inclination: 6.26105,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H260460, H288193',
      },
    ],
  },
  {
    provisionalCode: '2019 GT182',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 2.56212,
    eccentricity: 0.1838486,
    inclination: 4.33048,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H256927, H279862',
      },
    ],
  },
  {
    provisionalCode: '2019 SX244',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 3.11562,
    eccentricity: 0.1168258,
    inclination: 8.51062,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221678, H221720, H287334',
      },
      {
        userName: 'さんしろう',
        HNames: 'H247605',
      },
    ],
  },
  {
    provisionalCode: '2019 SY244',
    note: '',
    absoluteMagnitude: 17.72,
    semimajorAxis: 3.25424,
    eccentricity: 0.0075681,
    inclination: 12.81945,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240973, H241009',
      },
      {
        userName: 'yakuba',
        HNames: 'H240973',
      },
      {
        userName: 'れお',
        HNames: 'H262066',
      },
      {
        userName: 's.ozawa',
        HNames: 'H281615',
      },
    ],
  },
  {
    provisionalCode: '2019 UD174',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.96,
    semimajorAxis: 43.90877,
    eccentricity: 0.1263003,
    inclination: 18.21984,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222928, H229335, H287388',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222928',
      },
      {
        userName: 'zorome',
        HNames: 'H229335',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229335',
      },
    ],
  },
  {
    provisionalCode: '2019 UE174',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.99,
    semimajorAxis: 49.16528,
    eccentricity: 0.2662418,
    inclination: 27.45925,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222953, H223099',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223099, H288978',
      },
    ],
  },
  {
    provisionalCode: '2019 UL174',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 3.36268,
    eccentricity: 0.0813016,
    inclination: 7.23667,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214362',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222056, H222310, H224199',
      },
    ],
  },
  {
    provisionalCode: '2019 UM174',
    note: '',
    absoluteMagnitude: 21.1,
    semimajorAxis: 2.41493,
    eccentricity: 0.1309224,
    inclination: 11.22186,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H222175',
      },
      {
        userName: '赵经远',
        HNames: 'H222175',
      },
      {
        userName: 'kn1cht',
        HNames: 'H222175',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238650',
      },
    ],
  },
  {
    provisionalCode: '2019 UW174',
    note: '',
    absoluteMagnitude: 20.93,
    semimajorAxis: 2.84881,
    eccentricity: 0.0969323,
    inclination: 11.3619,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H266525, H267056',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267056',
      },
    ],
  },
  {
    provisionalCode: '2019 UX174',
    note: '',
    absoluteMagnitude: 20.68,
    semimajorAxis: 2.97264,
    eccentricity: 0.1099746,
    inclination: 11.05762,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H266524, H267057, H267171',
      },
    ],
  },
  {
    provisionalCode: '2019 UY174',
    note: '',
    absoluteMagnitude: 21.93,
    semimajorAxis: 2.77107,
    eccentricity: 0.2958487,
    inclination: 13.45983,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H247372',
      },
      {
        userName: 'nasiar99',
        HNames: 'H278886',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278886',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H278886',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ50',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.65,
    semimajorAxis: 45.72155,
    eccentricity: 0.2470406,
    inclination: 5.30381,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H215904',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215904',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238308, H274474, H274486',
      },
    ],
  },
  {
    provisionalCode: '2019 VC51',
    note: '',
    absoluteMagnitude: 21.31,
    semimajorAxis: 2.2519,
    eccentricity: 0.0987174,
    inclination: 3.82978,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214821, H223417, H296790, H296800',
      },
    ],
  },
  {
    provisionalCode: '2019 VD51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.65,
    semimajorAxis: 45.30542,
    eccentricity: 0.0295557,
    inclination: 2.99177,
    oppositions: 5,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229514, H296811, H297029, H297037, H297039, H297049',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296811',
      },
    ],
  },
  {
    provisionalCode: '2019 VE51',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.09,
    semimajorAxis: 47.07149,
    eccentricity: 0.3982494,
    inclination: 20.52486,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217654, H296807, H296820',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217654',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296820',
      },
    ],
  },
  {
    provisionalCode: '2019 VF51',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 3.00431,
    eccentricity: 0.1158576,
    inclination: 5.12518,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213249, H229803, H229813',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H273760',
      },
    ],
  },
  {
    provisionalCode: '2019 VH51',
    note: '',
    absoluteMagnitude: 19.57,
    semimajorAxis: 2.85755,
    eccentricity: 0.0699631,
    inclination: 12.94382,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H226224',
      },
      {
        userName: 'mitarushi',
        HNames: 'H226224',
      },
      {
        userName: 'ngc',
        HNames: 'H227714',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286847',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ51',
    note: '',
    absoluteMagnitude: 20.9,
    semimajorAxis: 2.38409,
    eccentricity: 0.0854136,
    inclination: 10.52484,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232457, H234879, H236491, H239513',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232457',
      },
    ],
  },
  {
    provisionalCode: '2019 VK51',
    note: '',
    absoluteMagnitude: 21.66,
    semimajorAxis: 2.56373,
    eccentricity: 0.1715858,
    inclination: 9.66543,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251874, H301252',
      },
      {
        userName: 's.ozawa',
        HNames: 'H251874',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H301696',
      },
    ],
  },
  {
    provisionalCode: '2019 VL51',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 3.05168,
    eccentricity: 0.0622706,
    inclination: 11.62839,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H249130',
      },
      {
        userName: 'れお',
        HNames: 'H265238, H267060',
      },
    ],
  },
  {
    provisionalCode: '2019 VM51',
    note: '',
    absoluteMagnitude: 21.58,
    semimajorAxis: 2.64004,
    eccentricity: 0.2740682,
    inclination: 11.76249,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H222952',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222952',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224195',
      },
      {
        userName: 'starolite',
        HNames: 'H224195',
      },
    ],
  },
  {
    provisionalCode: '2019 VN51',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 3.10631,
    eccentricity: 0.0928533,
    inclination: 11.79519,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H222958',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222958',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H302261',
      },
    ],
  },
  {
    provisionalCode: '2019 WS34',
    note: '',
    absoluteMagnitude: 20.72,
    semimajorAxis: 2.52504,
    eccentricity: 0.1681979,
    inclination: 16.99282,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H252257',
      },
      {
        userName: 'れお',
        HNames: 'H255550, H255982, H292714',
      },
      {
        userName: 'kn1cht',
        HNames: 'H294362, H295115, H295134',
      },
    ],
  },
  {
    provisionalCode: '2019 WU34',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 3.16731,
    eccentricity: 0.1739023,
    inclination: 17.76402,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H228315, H298221',
      },
      {
        userName: 'zorome',
        HNames: 'H228315',
      },
    ],
  },
  {
    provisionalCode: '2019 WV34',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 3.21977,
    eccentricity: 0.0729823,
    inclination: 13.26825,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214239, H215340',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224958, H289265',
      },
    ],
  },
  {
    provisionalCode: '2019 WW34',
    note: '',
    absoluteMagnitude: 20.28,
    semimajorAxis: 3.07573,
    eccentricity: 0.2370491,
    inclination: 11.49536,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H216019',
      },
      {
        userName: 'れお',
        HNames: 'H216019',
      },
      {
        userName: 'ctaka',
        HNames: 'H237132',
      },
    ],
  },
  {
    provisionalCode: '2019 WX34',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.92595,
    eccentricity: 0.048967,
    inclination: 12.78565,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'hirata',
        HNames: 'H236340',
      },
      {
        userName: 's.ozawa',
        HNames: 'H289012, H289768, H296643',
      },
      {
        userName: 'れお',
        HNames: 'H297735',
      },
    ],
  },
  {
    provisionalCode: '2020 BD155',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.93,
    semimajorAxis: 42.47119,
    eccentricity: 0.0298422,
    inclination: 18.80543,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217684, H240026, H278956, H279049, H287621, H287634, H287665',
      },
      {
        userName: 'mitarushi',
        HNames: 'H217684',
      },
      {
        userName: 'もしもス',
        HNames: 'H240026',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278956, H279049',
      },
    ],
  },
  {
    provisionalCode: '2005 TQ226',
    note: '',
    absoluteMagnitude: 17.61,
    semimajorAxis: 3.08094,
    eccentricity: 0.0792565,
    inclination: 12.42909,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H279426, H293398',
      },
    ],
  },
  {
    provisionalCode: '2016 PK294',
    note: '',
    absoluteMagnitude: 18.44,
    semimajorAxis: 2.62845,
    eccentricity: 0.1680026,
    inclination: 12.6455,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H229487',
      },
      {
        userName: 'zorome',
        HNames: 'H238826',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H260233',
      },
      {
        userName: 'joshua',
        HNames: 'H290016',
      },
    ],
  },
  {
    provisionalCode: '2016 WG85',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 3.12199,
    eccentricity: 0.0592366,
    inclination: 12.8122,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267521',
      },
      {
        userName: 'ms',
        HNames: 'H267521, H302103',
      },
      {
        userName: 'れお',
        HNames: 'H278058',
      },
      {
        userName: 'yakuba',
        HNames: 'H278058',
      },
      {
        userName: 'ほたる',
        HNames: 'H287432',
      },
    ],
  },
  {
    provisionalCode: '2016 WH85',
    note: '',
    absoluteMagnitude: 17.05,
    semimajorAxis: 3.34949,
    eccentricity: 0.0136571,
    inclination: 9.10804,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'ms',
        HNames: 'H267477',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267477',
      },
      {
        userName: 'zorome',
        HNames: 'H282292',
      },
      {
        userName: 'ほたる',
        HNames: 'H287431',
      },
    ],
  },
  {
    provisionalCode: '2016 WJ85',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.98661,
    eccentricity: 0.1320325,
    inclination: 8.64994,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ms',
        HNames: 'H267610, H270994',
      },
      {
        userName: 'もしもス',
        HNames: 'H270994',
      },
      {
        userName: 'zorome',
        HNames: 'H273820, H274622',
      },
    ],
  },
  {
    provisionalCode: '2016 WK85',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.26208,
    eccentricity: 0.1334281,
    inclination: 9.16216,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H269035',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279531',
      },
      {
        userName: 'ほたる',
        HNames: 'H284847',
      },
    ],
  },
  {
    provisionalCode: '2016 WL85',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.11287,
    eccentricity: 0.1026669,
    inclination: 9.26493,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ms',
        HNames: 'H269036',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279532',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279532',
      },
    ],
  },
  {
    provisionalCode: '2016 WM85',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 2.40129,
    eccentricity: 0.1220867,
    inclination: 8.43597,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220783',
      },
      {
        userName: 'ms',
        HNames: 'H270993',
      },
      {
        userName: 'もしもス',
        HNames: 'H270993',
      },
    ],
  },
  {
    provisionalCode: '2016 YB40',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.69,
    semimajorAxis: 52.68742,
    eccentricity: 0.1797786,
    inclination: 25.86251,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H288240',
      },
    ],
  },
  {
    provisionalCode: '2016 YD40',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.58902,
    eccentricity: 0.0703742,
    inclination: 13.33028,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H282919, H286159, H296962, H297433',
      },
    ],
  },
  {
    provisionalCode: '2016 YE40',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.01579,
    eccentricity: 0.1455022,
    inclination: 7.78773,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H269379, H298266',
      },
      {
        userName: 'れお',
        HNames: 'H285304',
      },
      {
        userName: 'もしもス',
        HNames: 'H286601',
      },
    ],
  },
  {
    provisionalCode: '2016 YG40',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 2.5629,
    eccentricity: 0.1149888,
    inclination: 16.75486,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241940',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281556',
      },
      {
        userName: 'nasiar99',
        HNames: 'H290403',
      },
    ],
  },
  {
    provisionalCode: '2016 YH40',
    note: '',
    absoluteMagnitude: 18.32,
    semimajorAxis: 3.09611,
    eccentricity: 0.0887793,
    inclination: 7.9991,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H279071, H287757',
      },
      {
        userName: 'arda',
        HNames: 'H295979',
      },
    ],
  },
  {
    provisionalCode: '2016 YJ40',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 2.98448,
    eccentricity: 0.1017628,
    inclination: 10.12006,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259681, H279641',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280736',
      },
      {
        userName: 'ms',
        HNames: 'H280736',
      },
    ],
  },
  {
    provisionalCode: '2016 YK40',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.54905,
    eccentricity: 0.1340969,
    inclination: 16.75204,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H288066, H303875, H305384',
      },
    ],
  },
  {
    provisionalCode: '2017 AP63',
    note: '',
    absoluteMagnitude: 20.43,
    semimajorAxis: 2.67429,
    eccentricity: 0.2784547,
    inclination: 7.25457,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266015',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266015',
      },
      {
        userName: 'zorome',
        HNames: 'H268551, H277566',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287945',
      },
    ],
  },
  {
    provisionalCode: '2017 AQ63',
    note: '',
    absoluteMagnitude: 19.7,
    semimajorAxis: 3.15125,
    eccentricity: 0.1784077,
    inclination: 8.87638,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280377',
      },
      {
        userName: 'れお',
        HNames: 'H283172',
      },
    ],
  },
  {
    provisionalCode: '2017 AR63',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.0317,
    eccentricity: 0.1342469,
    inclination: 10.16959,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'arda',
        HNames: 'H268462',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279420, H304367',
      },
    ],
  },
  {
    provisionalCode: '2017 AS63',
    note: '',
    absoluteMagnitude: 18.02,
    semimajorAxis: 3.16995,
    eccentricity: 0.0527535,
    inclination: 11.1231,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H281182, H287942, H289516, H304366',
      },
    ],
  },
  {
    provisionalCode: '2017 AT63',
    note: '',
    absoluteMagnitude: 18.16,
    semimajorAxis: 2.98791,
    eccentricity: 0.1252748,
    inclination: 9.77975,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H272888',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287746, H303139',
      },
      {
        userName: 'zorome',
        HNames: 'H294580',
      },
    ],
  },
  {
    provisionalCode: '2017 AU63',
    note: '',
    absoluteMagnitude: 18.25,
    semimajorAxis: 3.1636,
    eccentricity: 0.0763656,
    inclination: 8.46822,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248146',
      },
      {
        userName: 'aika',
        HNames: 'H271015',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271015',
      },
    ],
  },
  {
    provisionalCode: '2017 AV63',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.36,
    semimajorAxis: 5.29462,
    eccentricity: 0.0362053,
    inclination: 9.72909,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248147',
      },
      {
        userName: 'aika',
        HNames: 'H271014',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271014',
      },
    ],
  },
  {
    provisionalCode: '2017 AW63',
    note: '',
    absoluteMagnitude: 20.96,
    semimajorAxis: 2.54343,
    eccentricity: 0.0773405,
    inclination: 7.13078,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242464, H265624',
      },
      {
        userName: 'arda',
        HNames: 'H285171',
      },
      {
        userName: 'nasiar99',
        HNames: 'H285171',
      },
    ],
  },
  {
    provisionalCode: '2017 AX63',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.14143,
    eccentricity: 0.0878263,
    inclination: 10.72838,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H272877',
      },
      {
        userName: 'arda',
        HNames: 'H272877',
      },
      {
        userName: 'ほたる',
        HNames: 'H287602',
      },
    ],
  },
  {
    provisionalCode: '2017 AY63',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 2.75737,
    eccentricity: 0.2109683,
    inclination: 7.63454,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H272890',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287748, H290577',
      },
    ],
  },
  {
    provisionalCode: '2017 AZ63',
    note: '',
    absoluteMagnitude: 21.53,
    semimajorAxis: 2.41657,
    eccentricity: 0.205313,
    inclination: 5.59111,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H279642, H285376, H290388',
      },
    ],
  },
  {
    provisionalCode: '2017 AA64',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.12405,
    eccentricity: 0.0401742,
    inclination: 7.87077,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281504',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287590',
      },
    ],
  },
  {
    provisionalCode: '2017 BG233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.62,
    semimajorAxis: 41.44047,
    eccentricity: 0.0934502,
    inclination: 16.76267,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276076, H297888, H297892, H297899',
      },
      {
        userName: 'もしもス',
        HNames: 'H276076',
      },
    ],
  },
  {
    provisionalCode: '2017 BH233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.54,
    semimajorAxis: 44.30755,
    eccentricity: 0.0662778,
    inclination: 4.15471,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296277',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.38,
    semimajorAxis: 45.03009,
    eccentricity: 0.1547479,
    inclination: 6.53937,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297080',
      },
    ],
  },
  {
    provisionalCode: '2017 BK233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.72,
    semimajorAxis: 47.01827,
    eccentricity: 0.115305,
    inclination: 6.49636,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297114',
      },
    ],
  },
  {
    provisionalCode: '2017 BL233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 43.41574,
    eccentricity: 0.0435736,
    inclination: 2.86585,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298290',
      },
    ],
  },
  {
    provisionalCode: '2017 BM233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.84,
    semimajorAxis: 42.65383,
    eccentricity: 0.0211842,
    inclination: 3.03924,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296336',
      },
    ],
  },
  {
    provisionalCode: '2017 BN233',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.39,
    semimajorAxis: 46.91865,
    eccentricity: 0.2078749,
    inclination: 11.69987,
    oppositions: 6,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265606, H265607, H265702',
      },
    ],
  },
  {
    provisionalCode: '2017 BO233',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.698,
    eccentricity: 0.2047722,
    inclination: 9.83768,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275991, H282275',
      },
      {
        userName: 'れお',
        HNames: 'H288918',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288918',
      },
    ],
  },
  {
    provisionalCode: '2017 BP233',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 2.57102,
    eccentricity: 0.0870282,
    inclination: 11.77196,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H267077',
      },
      {
        userName: 'zorome',
        HNames: 'H278083',
      },
      {
        userName: 'ほたる',
        HNames: 'H290032',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ233',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 3.12416,
    eccentricity: 0.1732149,
    inclination: 6.95813,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251297',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251297',
      },
      {
        userName: 'arda',
        HNames: 'H266016',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267277',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273233',
      },
    ],
  },
  {
    provisionalCode: '2017 BR233',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 3.07541,
    eccentricity: 0.0813229,
    inclination: 9.9924,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250874',
      },
      {
        userName: 'ms',
        HNames: 'H268187, H268284',
      },
    ],
  },
  {
    provisionalCode: '2017 BS233',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 3.04997,
    eccentricity: 0.1052381,
    inclination: 7.97511,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245750',
      },
      {
        userName: 'zorome',
        HNames: 'H245750, H250740',
      },
      {
        userName: 'nasiar99',
        HNames: 'H250740',
      },
    ],
  },
  {
    provisionalCode: '2017 BU233',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 3.12801,
    eccentricity: 0.2099244,
    inclination: 10.08727,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268037',
      },
      {
        userName: 'ms',
        HNames: 'H268285, H303337, H303345',
      },
    ],
  },
  {
    provisionalCode: '2017 BX233',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.42,
    semimajorAxis: 5.23753,
    eccentricity: 0.0926235,
    inclination: 16.64889,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268478',
      },
      {
        userName: 'nasiar99',
        HNames: 'H299047',
      },
    ],
  },
  {
    provisionalCode: '2017 BY233',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 2.79587,
    eccentricity: 0.1193552,
    inclination: 14.86408,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271139, H271238, H281632, H297881',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ233',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 2.27252,
    eccentricity: 0.1078707,
    inclination: 6.50438,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H278639',
      },
      {
        userName: 'れお',
        HNames: 'H283789',
      },
      {
        userName: 'ほたる',
        HNames: 'H287562',
      },
      {
        userName: 'arda',
        HNames: 'H291544',
      },
    ],
  },
  {
    provisionalCode: '2017 BA234',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.78673,
    eccentricity: 0.0960453,
    inclination: 9.2618,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H282965',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288069',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297884',
      },
    ],
  },
  {
    provisionalCode: '2017 BB234',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 3.11347,
    eccentricity: 0.1744286,
    inclination: 8.52998,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H265504',
      },
      {
        userName: 'zorome',
        HNames: 'H265504',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281555',
      },
      {
        userName: 'もしもス',
        HNames: 'H288022',
      },
    ],
  },
  {
    provisionalCode: '2017 BC234',
    note: '',
    absoluteMagnitude: 20.66,
    semimajorAxis: 2.33219,
    eccentricity: 0.1743816,
    inclination: 8.95736,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251438',
      },
      {
        userName: 'kn1cht',
        HNames: 'H274656',
      },
      {
        userName: 'ko',
        HNames: 'H281358',
      },
      {
        userName: 'nasiar99',
        HNames: 'H299007',
      },
    ],
  },
  {
    provisionalCode: '2017 BD234',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 2.77821,
    eccentricity: 0.0579486,
    inclination: 10.11832,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H266901',
      },
      {
        userName: 'zorome',
        HNames: 'H266901',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287968',
      },
      {
        userName: 'nasiar99',
        HNames: 'H299002',
      },
    ],
  },
  {
    provisionalCode: '2017 BE234',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.10502,
    eccentricity: 0.2163417,
    inclination: 3.92669,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202015, H203831',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298764, H299860',
      },
    ],
  },
  {
    provisionalCode: '2017 BF234',
    note: '',
    absoluteMagnitude: 20.76,
    semimajorAxis: 2.42159,
    eccentricity: 0.1296456,
    inclination: 2.36264,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201607, H202176',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H300470',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301135',
      },
    ],
  },
  {
    provisionalCode: '2017 BG234',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 2.25948,
    eccentricity: 0.018114,
    inclination: 2.62581,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H200603',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294193, H301628',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301036',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ234',
    note: '',
    absoluteMagnitude: 21.17,
    semimajorAxis: 2.24383,
    eccentricity: 0.1038594,
    inclination: 5.98125,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268432',
      },
      {
        userName: 'arda',
        HNames: 'H268432',
      },
      {
        userName: 'ko',
        HNames: 'H281800',
      },
    ],
  },
  {
    provisionalCode: '2017 BK234',
    note: '',
    absoluteMagnitude: 18.18,
    semimajorAxis: 3.15985,
    eccentricity: 0.0358891,
    inclination: 8.2162,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267804, H273700',
      },
    ],
  },
  {
    provisionalCode: '2017 BL234',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.14121,
    eccentricity: 0.2049629,
    inclination: 9.41242,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266619',
      },
      {
        userName: 'aika',
        HNames: 'H278977',
      },
      {
        userName: 'ほたる',
        HNames: 'H280617',
      },
    ],
  },
  {
    provisionalCode: '2017 BM234',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.7003,
    eccentricity: 0.1070599,
    inclination: 8.12668,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H276298',
      },
      {
        userName: 'nasiar99',
        HNames: 'H276298',
      },
      {
        userName: 'れお',
        HNames: 'H281322',
      },
    ],
  },
  {
    provisionalCode: '2017 BN234',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 3.22107,
    eccentricity: 0.0776501,
    inclination: 13.3616,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267794',
      },
      {
        userName: 'もしもス',
        HNames: 'H280022',
      },
    ],
  },
  {
    provisionalCode: '2017 BO234',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 3.11436,
    eccentricity: 0.0956663,
    inclination: 20.77545,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248123',
      },
      {
        userName: 'れお',
        HNames: 'H248123',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273372',
      },
    ],
  },
  {
    provisionalCode: '2017 BP234',
    note: '',
    absoluteMagnitude: 21.05,
    semimajorAxis: 2.2437,
    eccentricity: 0.0953003,
    inclination: 6.06912,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268946',
      },
      {
        userName: 'arda',
        HNames: 'H268946',
      },
      {
        userName: 'れお',
        HNames: 'H283833',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ234',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.10754,
    eccentricity: 0.1117463,
    inclination: 15.29675,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267805, H281936',
      },
    ],
  },
  {
    provisionalCode: '2017 BR234',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 2.92167,
    eccentricity: 0.0660273,
    inclination: 8.26632,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245674',
      },
      {
        userName: 'nasiar99',
        HNames: 'H245674, H280651',
      },
      {
        userName: 'yakuba',
        HNames: 'H258313',
      },
    ],
  },
  {
    provisionalCode: '2017 BS234',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.09045,
    eccentricity: 0.0945343,
    inclination: 8.69629,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268424',
      },
      {
        userName: 'arda',
        HNames: 'H268424, H268426',
      },
      {
        userName: 'れお',
        HNames: 'H284863',
      },
    ],
  },
  {
    provisionalCode: '2017 BT234',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 3.02354,
    eccentricity: 0.0783518,
    inclination: 7.53997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267791, H277849, H284929, H284942',
      },
    ],
  },
  {
    provisionalCode: '2017 BU234',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.33,
    semimajorAxis: 5.26804,
    eccentricity: 0.0269908,
    inclination: 27.86401,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H266705',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266705',
      },
      {
        userName: 'aika',
        HNames: 'H266705',
      },
      {
        userName: 'れお',
        HNames: 'H280694',
      },
    ],
  },
  {
    provisionalCode: '2017 BV234',
    note: '2020 PJ99と同定',
    absoluteMagnitude: 18.49,
    semimajorAxis: 3.15068,
    eccentricity: 0.1743779,
    inclination: 8.18858,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'arda',
        HNames: 'H265423',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265423',
      },
      {
        userName: 'k96e',
        HNames: 'H286575',
      },
    ],
  },
  {
    provisionalCode: '2017 BW234',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 3.10348,
    eccentricity: 0.0828492,
    inclination: 10.9232,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272230',
      },
      {
        userName: 'れお',
        HNames: 'H283834',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311213',
      },
    ],
  },
  {
    provisionalCode: '2017 BX234',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 2.99615,
    eccentricity: 0.0509204,
    inclination: 10.30883,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233450',
      },
      {
        userName: 'aika',
        HNames: 'H267916',
      },
      {
        userName: 'もしもス',
        HNames: 'H267916',
      },
      {
        userName: '42',
        HNames: 'H267916',
      },
    ],
  },
  {
    provisionalCode: '2017 BY234',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 2.26438,
    eccentricity: 0.1529592,
    inclination: 6.49759,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259675',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259675, H288298',
      },
      {
        userName: 'もしもス',
        HNames: 'H259675',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ234',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.09405,
    eccentricity: 0.0572003,
    inclination: 7.82669,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273722, H289445',
      },
    ],
  },
  {
    provisionalCode: '2017 BA235',
    note: '',
    absoluteMagnitude: 20.74,
    semimajorAxis: 2.67439,
    eccentricity: 0.0796622,
    inclination: 5.23407,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H284179, H289870',
      },
    ],
  },
  {
    provisionalCode: '2017 BB235',
    note: '',
    absoluteMagnitude: 19.27,
    semimajorAxis: 3.18079,
    eccentricity: 0.0788071,
    inclination: 8.78741,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268408',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268408',
      },
      {
        userName: 'aika',
        HNames: 'H268408',
      },
      {
        userName: 'れお',
        HNames: 'H291110',
      },
    ],
  },
  {
    provisionalCode: '2017 BC235',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 3.09261,
    eccentricity: 0.1677127,
    inclination: 10.84819,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273723',
      },
      {
        userName: 'れお',
        HNames: 'H293999',
      },
    ],
  },
  {
    provisionalCode: '2017 BD235',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.11761,
    eccentricity: 0.0941343,
    inclination: 9.09187,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259674',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259674',
      },
      {
        userName: 'もしもス',
        HNames: 'H259674',
      },
      {
        userName: 'れお',
        HNames: 'H294007',
      },
    ],
  },
  {
    provisionalCode: '2017 BE235',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 3.05268,
    eccentricity: 0.2378694,
    inclination: 14.25317,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H247976',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247976',
      },
      {
        userName: 'nasiar99',
        HNames: 'H299003',
      },
      {
        userName: 'ko',
        HNames: 'H304461',
      },
    ],
  },
  {
    provisionalCode: '2017 BF235',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 2.8644,
    eccentricity: 0.1564878,
    inclination: 18.7127,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H251171',
      },
      {
        userName: 'zorome',
        HNames: 'H251171',
      },
      {
        userName: 'nasiar99',
        HNames: 'H299006',
      },
      {
        userName: 'ko',
        HNames: 'H304460',
      },
    ],
  },
  {
    provisionalCode: '2017 BK235',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.59,
    semimajorAxis: 64.86861,
    eccentricity: 0.4341674,
    inclination: 11.88405,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240058, H281460, H303154',
      },
      {
        userName: 'zorome',
        HNames: 'H240058',
      },
      {
        userName: 'nasiar99',
        HNames: 'H281460',
      },
    ],
  },
  {
    provisionalCode: '2017 BL235',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.55,
    semimajorAxis: 42.71026,
    eccentricity: 0.0486511,
    inclination: 2.572,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299835',
      },
    ],
  },
  {
    provisionalCode: '2017 BM235',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.07,
    semimajorAxis: 45.3034,
    eccentricity: 0.1202719,
    inclination: 3.77571,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299836',
      },
    ],
  },
  {
    provisionalCode: '2017 BN235',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.87,
    semimajorAxis: 44.29971,
    eccentricity: 0.0538531,
    inclination: 3.08192,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H300952',
      },
    ],
  },
  {
    provisionalCode: '2017 BO235',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 2.35153,
    eccentricity: 0.0085155,
    inclination: 5.34809,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266620, H291785',
      },
      {
        userName: 'arda',
        HNames: 'H291785',
      },
    ],
  },
  {
    provisionalCode: '2017 BP235',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 2.56314,
    eccentricity: 0.1507428,
    inclination: 6.97664,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H268324, H280125',
      },
      {
        userName: 'もしもス',
        HNames: 'H280125',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ235',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 2.55666,
    eccentricity: 0.102404,
    inclination: 12.54431,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248124',
      },
      {
        userName: 'もしもス',
        HNames: 'H279637',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279637',
      },
    ],
  },
  {
    provisionalCode: '2017 BR235',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.34099,
    eccentricity: 0.0817766,
    inclination: 5.00765,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H273583',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H273583',
      },
      {
        userName: 'れお',
        HNames: 'H292236',
      },
      {
        userName: 'nasiar99',
        HNames: 'H304706',
      },
    ],
  },
  {
    provisionalCode: '2017 BS235',
    note: '',
    absoluteMagnitude: 19.92,
    semimajorAxis: 2.76022,
    eccentricity: 0.1939697,
    inclination: 11.44576,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H252861',
      },
      {
        userName: 'aika',
        HNames: 'H270357',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H270357',
      },
    ],
  },
  {
    provisionalCode: '2017 BT235',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.97,
    semimajorAxis: 5.25914,
    eccentricity: 0.0458198,
    inclination: 19.99912,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H267173',
      },
      {
        userName: 'aika',
        HNames: 'H270358',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H270358',
      },
    ],
  },
  {
    provisionalCode: '2017 BU235',
    note: '',
    absoluteMagnitude: 20.02,
    semimajorAxis: 3.13711,
    eccentricity: 0.183631,
    inclination: 9.05047,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269117',
      },
      {
        userName: 'ko',
        HNames: 'H275711',
      },
      {
        userName: 'れお',
        HNames: 'H275711',
      },
    ],
  },
  {
    provisionalCode: '2017 BV235',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.88693,
    eccentricity: 0.0685667,
    inclination: 9.14317,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269590',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288073',
      },
    ],
  },
  {
    provisionalCode: '2017 BW235',
    note: '',
    absoluteMagnitude: 20.85,
    semimajorAxis: 2.24607,
    eccentricity: 0.099889,
    inclination: 6.04389,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H274174',
      },
      {
        userName: 'れお',
        HNames: 'H274174',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279383',
      },
      {
        userName: 'arda',
        HNames: 'H282450',
      },
    ],
  },
  {
    provisionalCode: '2017 BX235',
    note: '',
    absoluteMagnitude: 18.05,
    semimajorAxis: 3.19744,
    eccentricity: 0.1089789,
    inclination: 12.24025,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272420',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279648',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H279648',
      },
      {
        userName: 'ほたる',
        HNames: 'H279648, H292526',
      },
    ],
  },
  {
    provisionalCode: '2017 BY235',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 2.85663,
    eccentricity: 0.1651922,
    inclination: 10.08849,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H262893',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263155',
      },
      {
        userName: '42',
        HNames: 'H263155',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ235',
    note: '',
    absoluteMagnitude: 18.33,
    semimajorAxis: 3.14827,
    eccentricity: 0.0820472,
    inclination: 8.07837,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268944',
      },
      {
        userName: 'arda',
        HNames: 'H268944',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268944',
      },
      {
        userName: 'れお',
        HNames: 'H283187',
      },
    ],
  },
  {
    provisionalCode: '2017 BA236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.5,
    semimajorAxis: 5.2064,
    eccentricity: 0.0118722,
    inclination: 31.69604,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259673',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259673',
      },
      {
        userName: 'もしもス',
        HNames: 'H259673',
      },
      {
        userName: 'れお',
        HNames: 'H279597',
      },
    ],
  },
  {
    provisionalCode: '2017 BB236',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 3.01747,
    eccentricity: 0.1082972,
    inclination: 9.985,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H266686',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266686, H288289',
      },
    ],
  },
  {
    provisionalCode: '2017 BC236',
    note: '',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.31253,
    eccentricity: 0.1289524,
    inclination: 10.51356,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H250762',
      },
      {
        userName: '赵经远',
        HNames: 'H250762, H251274',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305203',
      },
    ],
  },
  {
    provisionalCode: '2017 BH236',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 2.78899,
    eccentricity: 0.2201468,
    inclination: 7.63272,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241923',
      },
      {
        userName: '42',
        HNames: 'H262650',
      },
      {
        userName: 'arda',
        HNames: 'H292269',
      },
    ],
  },
  {
    provisionalCode: '2017 CH55',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.28,
    semimajorAxis: 43.6321,
    eccentricity: 0.1952965,
    inclination: 12.40829,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H289861',
      },
      {
        userName: 'zorome',
        HNames: 'H302018, H302019, H302020',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ55',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.14,
    semimajorAxis: 43.80115,
    eccentricity: 0.0460326,
    inclination: 3.00919,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261090, H297575',
      },
    ],
  },
  {
    provisionalCode: '2017 CK55',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.17968,
    eccentricity: 0.1936273,
    inclination: 8.71942,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H255620, H265835',
      },
      {
        userName: 'ko',
        HNames: 'H276206',
      },
      {
        userName: 'ほたる',
        HNames: 'H284873',
      },
      {
        userName: 'zorome',
        HNames: 'H285751',
      },
    ],
  },
  {
    provisionalCode: '2017 CL55',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.74,
    semimajorAxis: 5.29444,
    eccentricity: 0.0870182,
    inclination: 13.02431,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H272153, H284103, H284379, H297928',
      },
    ],
  },
  {
    provisionalCode: '2017 CM55',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.38922,
    eccentricity: 0.1261652,
    inclination: 2.67421,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202379, H202441',
      },
      {
        userName: 'joshua',
        HNames: 'H271077',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298773',
      },
    ],
  },
  {
    provisionalCode: '2017 CN55',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.32669,
    eccentricity: 0.1569549,
    inclination: 2.1864,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202380',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296196, H298808',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298594',
      },
    ],
  },
  {
    provisionalCode: '2017 CO55',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 3.15151,
    eccentricity: 0.0718604,
    inclination: 11.71267,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273301',
      },
      {
        userName: 'nasiar99',
        HNames: 'H274796',
      },
      {
        userName: 'arda',
        HNames: 'H274796',
      },
    ],
  },
  {
    provisionalCode: '2017 CP55',
    note: '',
    absoluteMagnitude: 18.17,
    semimajorAxis: 3.07982,
    eccentricity: 0.0287914,
    inclination: 11.66556,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261853',
      },
      {
        userName: '42',
        HNames: 'H263154',
      },
      {
        userName: 'ko',
        HNames: 'H281780',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ55',
    note: '',
    absoluteMagnitude: 20.18,
    semimajorAxis: 3.14721,
    eccentricity: 0.0972289,
    inclination: 15.53798,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H262586, H304959',
      },
      {
        userName: 'aika',
        HNames: 'H262586',
      },
      {
        userName: '42',
        HNames: 'H262586',
      },
    ],
  },
  {
    provisionalCode: '2017 CR55',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 2.88533,
    eccentricity: 0.0698276,
    inclination: 2.99955,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H212354',
      },
      {
        userName: 'joshua',
        HNames: 'H265447',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H303821',
      },
    ],
  },
  {
    provisionalCode: '2017 CS55',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.00184,
    eccentricity: 0.0991691,
    inclination: 9.85092,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220767',
      },
      {
        userName: 'apollo18',
        HNames: 'H220767',
      },
      {
        userName: 'arda',
        HNames: 'H282869',
      },
    ],
  },
  {
    provisionalCode: '2017 CU55',
    note: '',
    absoluteMagnitude: 18.19,
    semimajorAxis: 3.21107,
    eccentricity: 0.0280587,
    inclination: 7.02456,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262346',
      },
      {
        userName: '42',
        HNames: 'H262346',
      },
      {
        userName: 'れお',
        HNames: 'H262346',
      },
      {
        userName: 'もしもス',
        HNames: 'H274106',
      },
    ],
  },
  {
    provisionalCode: '2017 CV55',
    note: '',
    absoluteMagnitude: 19.63,
    semimajorAxis: 3.18714,
    eccentricity: 0.1728283,
    inclination: 16.3158,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262687',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282889, H285844',
      },
      {
        userName: 'もしもス',
        HNames: 'H285844',
      },
    ],
  },
  {
    provisionalCode: '2017 CX55',
    note: 'Hungaria',
    absoluteMagnitude: 21.26,
    semimajorAxis: 1.96918,
    eccentricity: 0.0849453,
    inclination: 17.43042,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H284489',
      },
      {
        userName: 'kn1cht',
        HNames: 'H290886',
      },
    ],
  },
  {
    provisionalCode: '2017 DM162',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.07,
    semimajorAxis: 44.27394,
    eccentricity: 0.1233619,
    inclination: 8.98258,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297239',
      },
    ],
  },
  {
    provisionalCode: '2017 DN162',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.98,
    semimajorAxis: 46.8907,
    eccentricity: 0.0741124,
    inclination: 4.67724,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270835',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296172',
      },
    ],
  },
  {
    provisionalCode: '2017 DO162',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.57,
    semimajorAxis: 43.77528,
    eccentricity: 0.0989191,
    inclination: 5.45298,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H272248',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296356',
      },
    ],
  },
  {
    provisionalCode: '2017 DR162',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 3.05944,
    eccentricity: 0.190656,
    inclination: 2.40401,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270226',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298374, H298431, H298498',
      },
    ],
  },
  {
    provisionalCode: '2017 DS162',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 3.09845,
    eccentricity: 0.1434799,
    inclination: 2.42434,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272730, H297636, H298370, H298464',
      },
    ],
  },
  {
    provisionalCode: '2017 DT162',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 2.86612,
    eccentricity: 0.0208782,
    inclination: 3.07394,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H267820',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298123, H298126',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298300',
      },
    ],
  },
  {
    provisionalCode: '2017 DU162',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.0654,
    eccentricity: 0.1550717,
    inclination: 2.21739,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270228',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298301, H298305, H298314',
      },
    ],
  },
  {
    provisionalCode: '2017 DV162',
    note: '',
    absoluteMagnitude: 20.63,
    semimajorAxis: 2.19884,
    eccentricity: 0.0387224,
    inclination: 7.42419,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202449',
      },
      {
        userName: 'apollo18',
        HNames: 'H242069',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299823, H301037',
      },
    ],
  },
  {
    provisionalCode: '2017 DW162',
    note: '',
    absoluteMagnitude: 20.29,
    semimajorAxis: 2.92467,
    eccentricity: 0.0751665,
    inclination: 2.7846,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H292853, H301073',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H300260, H300467',
      },
    ],
  },
  {
    provisionalCode: '2017 DX162',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 3.08908,
    eccentricity: 0.1161589,
    inclination: 2.64104,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201377',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301035',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H301401, H301416',
      },
    ],
  },
  {
    provisionalCode: '2017 DY162',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.98081,
    eccentricity: 0.0888841,
    inclination: 10.86906,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252857',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279007',
      },
      {
        userName: 'arda',
        HNames: 'H279007',
      },
      {
        userName: 'れお',
        HNames: 'H282574',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H302754',
      },
    ],
  },
  {
    provisionalCode: '2017 DA163',
    note: '2017 DN167と同定',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.71966,
    eccentricity: 0.1765713,
    inclination: 8.68427,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H267287',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267287',
      },
      {
        userName: 'yakuba',
        HNames: 'H291886',
      },
    ],
  },
  {
    provisionalCode: '2017 DB163',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 3.05563,
    eccentricity: 0.2002611,
    inclination: 15.3359,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220175',
      },
      {
        userName: 'apollo18',
        HNames: 'H220175',
      },
      {
        userName: 'arda',
        HNames: 'H292316',
      },
    ],
  },
  {
    provisionalCode: '2017 DC163',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.17117,
    eccentricity: 0.1340624,
    inclination: 8.90533,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H276219',
      },
      {
        userName: 'nasiar99',
        HNames: 'H276219',
      },
      {
        userName: 'aika',
        HNames: 'H276219',
      },
      {
        userName: 'arda',
        HNames: 'H292317',
      },
    ],
  },
  {
    provisionalCode: '2017 DD163',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 2.96159,
    eccentricity: 0.044767,
    inclination: 10.36575,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257270',
      },
      {
        userName: 'nasiar99',
        HNames: 'H257270',
      },
    ],
  },
  {
    provisionalCode: '2017 DE163',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.77538,
    eccentricity: 0.0695989,
    inclination: 5.90901,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260800',
      },
      {
        userName: 'arda',
        HNames: 'H267317',
      },
      {
        userName: 'aika',
        HNames: 'H267317',
      },
      {
        userName: 'れお',
        HNames: 'H267317',
      },
    ],
  },
  {
    provisionalCode: '2017 DF163',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 2.98854,
    eccentricity: 0.0675293,
    inclination: 8.60678,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H267328',
      },
      {
        userName: 'aika',
        HNames: 'H267328',
      },
      {
        userName: 'arda',
        HNames: 'H267328',
      },
      {
        userName: 'ほたる',
        HNames: 'H267328',
      },
    ],
  },
  {
    provisionalCode: '2017 DH163',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.48,
    semimajorAxis: 44.19813,
    eccentricity: 0.1592254,
    inclination: 23.43036,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H283962, H284521, H284524',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H284521, H284524, H303157',
      },
      {
        userName: 'もしもス',
        HNames: 'H286527',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ163',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.09,
    semimajorAxis: 44.61475,
    eccentricity: 0.0966032,
    inclination: 2.78444,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H293466',
      },
    ],
  },
  {
    provisionalCode: '2017 DK163',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.01,
    semimajorAxis: 68.60961,
    eccentricity: 0.4679671,
    inclination: 11.59513,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H255751',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275238, H303104',
      },
      {
        userName: 'nasiar99',
        HNames: 'H275238',
      },
      {
        userName: 'arda',
        HNames: 'H275238',
      },
    ],
  },
  {
    provisionalCode: '2017 DL163',
    note: '',
    absoluteMagnitude: 20.47,
    semimajorAxis: 2.1993,
    eccentricity: 0.1926322,
    inclination: 4.93708,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H263016',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304279, H304282',
      },
    ],
  },
  {
    provisionalCode: '2017 DM163',
    note: '2010 AC145と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275774',
      },
      {
        userName: 'aika',
        HNames: 'H275774',
      },
      {
        userName: 'zorome',
        HNames: 'H302894',
      },
    ],
  },
  {
    provisionalCode: '2017 DN163',
    note: '2023 RJ113と同定',
    absoluteMagnitude: 18.93,
    semimajorAxis: 2.69784,
    eccentricity: 0.1665398,
    inclination: 12.78733,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256267',
      },
      {
        userName: 'れお',
        HNames: 'H256267',
      },
      {
        userName: 'zorome',
        HNames: 'H302887',
      },
    ],
  },
  {
    provisionalCode: '2017 DP163',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.07975,
    eccentricity: 0.0611443,
    inclination: 9.96228,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H267326, H304100',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267326',
      },
      {
        userName: 'ほたる',
        HNames: 'H267326',
      },
      {
        userName: 'aika',
        HNames: 'H272360',
      },
    ],
  },
  {
    provisionalCode: '2017 DR163',
    note: '',
    absoluteMagnitude: 20.82,
    semimajorAxis: 2.76401,
    eccentricity: 0.1527151,
    inclination: 6.60223,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H223789',
      },
      {
        userName: 'apollo18',
        HNames: 'H223789',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307304',
      },
    ],
  },
  {
    provisionalCode: '2017 DS163',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.38304,
    eccentricity: 0.055045,
    inclination: 5.89264,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H223790',
      },
      {
        userName: 'apollo18',
        HNames: 'H223790',
      },
      {
        userName: 'ほたる',
        HNames: 'H223790',
      },
      {
        userName: 'れお',
        HNames: 'H291086',
      },
    ],
  },
  {
    provisionalCode: '2017 DT163',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 3.03041,
    eccentricity: 0.0534568,
    inclination: 19.43878,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242018',
      },
      {
        userName: 'aika',
        HNames: 'H242018',
      },
      {
        userName: 'nasiar99',
        HNames: 'H242018',
      },
      {
        userName: 'れお',
        HNames: 'H242018',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283027',
      },
    ],
  },
  {
    provisionalCode: '2017 DU163',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.6419,
    eccentricity: 0.2367904,
    inclination: 12.22353,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H291169',
      },
      {
        userName: 'れお',
        HNames: 'H294759',
      },
    ],
  },
  {
    provisionalCode: '2017 DV163',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 2.53865,
    eccentricity: 0.2234663,
    inclination: 13.19108,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H223791',
      },
      {
        userName: 'apollo18',
        HNames: 'H223791',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307330',
      },
    ],
  },
  {
    provisionalCode: '2017 DW163',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.10939,
    eccentricity: 0.1641769,
    inclination: 10.6553,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H253572',
      },
      {
        userName: 'aika',
        HNames: 'H253572, H286394',
      },
    ],
  },
  {
    provisionalCode: '2017 DX163',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 2.53981,
    eccentricity: 0.1532697,
    inclination: 16.81695,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288659',
      },
      {
        userName: 'aika',
        HNames: 'H288659',
      },
    ],
  },
  {
    provisionalCode: '2017 DY163',
    note: '',
    absoluteMagnitude: 19.7,
    semimajorAxis: 2.99735,
    eccentricity: 0.2138506,
    inclination: 6.28261,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H288646',
      },
      {
        userName: 'aika',
        HNames: 'H288646',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ163',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 2.96383,
    eccentricity: 0.1009683,
    inclination: 10.38255,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H283547',
      },
      {
        userName: 'れお',
        HNames: 'H283547',
      },
      {
        userName: '井上 康',
        HNames: 'H305305',
      },
    ],
  },
  {
    provisionalCode: '2017 DA164',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.03271,
    eccentricity: 0.0893617,
    inclination: 11.35833,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H260260, H273049',
      },
      {
        userName: 'kn1cht',
        HNames: 'H260260',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273049',
      },
    ],
  },
  {
    provisionalCode: '2017 DB164',
    note: '',
    absoluteMagnitude: 21.03,
    semimajorAxis: 2.57045,
    eccentricity: 0.099681,
    inclination: 8.99319,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246131',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246131',
      },
      {
        userName: 'ほたる',
        HNames: 'H246131',
      },
      {
        userName: 'れお',
        HNames: 'H246131',
      },
      {
        userName: 'aika',
        HNames: 'H286429',
      },
    ],
  },
  {
    provisionalCode: '2017 DC164',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.16355,
    eccentricity: 0.074016,
    inclination: 7.78693,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249797, H273367',
      },
    ],
  },
  {
    provisionalCode: '2017 EE52',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.16,
    semimajorAxis: 45.71667,
    eccentricity: 0.1041076,
    inclination: 6.1818,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H250231',
      },
      {
        userName: 'arda',
        HNames: 'H299778, H299792, H299795',
      },
    ],
  },
  {
    provisionalCode: '2017 EF52',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 2.55355,
    eccentricity: 0.1069192,
    inclination: 16.49475,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H281144',
      },
      {
        userName: 'apollo18',
        HNames: 'H302246',
      },
    ],
  },
  {
    provisionalCode: '2017 EH52',
    note: '',
    absoluteMagnitude: 18.31,
    semimajorAxis: 3.13601,
    eccentricity: 0.0265147,
    inclination: 14.18838,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245959, H245986',
      },
      {
        userName: 'aika',
        HNames: 'H251676',
      },
      {
        userName: 'sasaki',
        HNames: 'H301939',
      },
    ],
  },
  {
    provisionalCode: '2017 EJ52',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.25,
    semimajorAxis: 50.16386,
    eccentricity: 0.1972605,
    inclination: 53.33125,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H279832, H279834',
      },
    ],
  },
  {
    provisionalCode: '2017 EK52',
    note: '',
    absoluteMagnitude: 21.42,
    semimajorAxis: 2.28312,
    eccentricity: 0.194314,
    inclination: 5.02683,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246983',
      },
      {
        userName: 'arda',
        HNames: 'H246983',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246983',
      },
      {
        userName: 'sasaki',
        HNames: 'H304292',
      },
    ],
  },
  {
    provisionalCode: '2017 EL52',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.60095,
    eccentricity: 0.1388635,
    inclination: 9.58363,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252930',
      },
      {
        userName: 'nasiar99',
        HNames: 'H252930',
      },
      {
        userName: 'yakuba',
        HNames: 'H291016',
      },
    ],
  },
  {
    provisionalCode: '2017 EM52',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.18814,
    eccentricity: 0.126617,
    inclination: 9.53944,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252837',
      },
      {
        userName: 'nasiar99',
        HNames: 'H252837',
      },
      {
        userName: 'arda',
        HNames: 'H304094',
      },
    ],
  },
  {
    provisionalCode: '2017 EN52',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.32,
    semimajorAxis: 39.0153,
    eccentricity: 0.28472,
    inclination: 16.75758,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H250174',
      },
      {
        userName: 's.ozawa',
        HNames: 'H297095',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297095',
      },
    ],
  },
  {
    provisionalCode: '2017 EO52',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 2.54073,
    eccentricity: 0.0861207,
    inclination: 6.0671,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242014',
      },
      {
        userName: 'arda',
        HNames: 'H286290',
      },
      {
        userName: '井上 康',
        HNames: 'H305326',
      },
      {
        userName: 'sadap',
        HNames: 'H305480',
      },
    ],
  },
  {
    provisionalCode: '2017 EP52',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 2.86761,
    eccentricity: 0.148331,
    inclination: 7.97721,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267340',
      },
      {
        userName: 'aika',
        HNames: 'H277860',
      },
      {
        userName: 'joshua',
        HNames: 'H277860, H277861',
      },
    ],
  },
  {
    provisionalCode: '2017 EQ52',
    note: '',
    absoluteMagnitude: 17.57,
    semimajorAxis: 3.20002,
    eccentricity: 0.0641277,
    inclination: 9.39572,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245361',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245361',
      },
      {
        userName: '42',
        HNames: 'H261430',
      },
      {
        userName: 'yuki0124',
        HNames: 'H306284',
      },
    ],
  },
  {
    provisionalCode: '2017 ER52',
    note: '',
    absoluteMagnitude: 19.69,
    semimajorAxis: 2.69137,
    eccentricity: 0.1498635,
    inclination: 14.71373,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H237332',
      },
      {
        userName: 'arda',
        HNames: 'H280805',
      },
      {
        userName: 'nasiar99',
        HNames: 'H280805',
      },
    ],
  },
  {
    provisionalCode: '2017 ES52',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 3.07682,
    eccentricity: 0.2514683,
    inclination: 10.61113,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H245354',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245354',
      },
    ],
  },
  {
    provisionalCode: '2017 ET52',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.42367,
    eccentricity: 0.1413627,
    inclination: 7.5135,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288393',
      },
      {
        userName: 'aika',
        HNames: 'H288393, H296385',
      },
      {
        userName: 'kazusa',
        HNames: 'H298741',
      },
    ],
  },
  {
    provisionalCode: '2017 EU52',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.97248,
    eccentricity: 0.1542259,
    inclination: 14.98291,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286530',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286530',
      },
      {
        userName: 'nasiar99',
        HNames: 'H286530',
      },
    ],
  },
  {
    provisionalCode: '2017 EV52',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 2.57507,
    eccentricity: 0.1174343,
    inclination: 8.47753,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241996',
      },
      {
        userName: 'aika',
        HNames: 'H249773',
      },
    ],
  },
  {
    provisionalCode: '2017 EW52',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.606,
    eccentricity: 0.248724,
    inclination: 12.80854,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246963',
      },
      {
        userName: 'もしもス',
        HNames: 'H246963',
      },
    ],
  },
  {
    provisionalCode: '2017 EX52',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 2.83735,
    eccentricity: 0.0523593,
    inclination: 6.98686,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246965',
      },
      {
        userName: 'もしもス',
        HNames: 'H246965',
      },
      {
        userName: 'nasiar99',
        HNames: 'H300822',
      },
    ],
  },
  {
    provisionalCode: '2017 EY52',
    note: '',
    absoluteMagnitude: 17.62,
    semimajorAxis: 3.15535,
    eccentricity: 0.1700792,
    inclination: 10.72011,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246967',
      },
      {
        userName: 'もしもス',
        HNames: 'H246967',
      },
      {
        userName: 'れお',
        HNames: 'H246967',
      },
    ],
  },
  {
    provisionalCode: '2017 EZ52',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 2.864,
    eccentricity: 0.1114563,
    inclination: 19.85163,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246971',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246971',
      },
      {
        userName: 'えでぃ',
        HNames: 'H246971',
      },
    ],
  },
  {
    provisionalCode: '2017 EA53',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 2.73124,
    eccentricity: 0.1261661,
    inclination: 6.42095,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246973',
      },
      {
        userName: 'arda',
        HNames: 'H246973',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246973',
      },
      {
        userName: 'nasiar99',
        HNames: 'H300751',
      },
    ],
  },
  {
    provisionalCode: '2017 EB53',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 3.16962,
    eccentricity: 0.1352704,
    inclination: 6.75456,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H246974',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246974',
      },
      {
        userName: 'nasiar99',
        HNames: 'H300823',
      },
    ],
  },
  {
    provisionalCode: '2017 EC53',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.15792,
    eccentricity: 0.1130333,
    inclination: 7.75184,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249772',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ235',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 2.66408,
    eccentricity: 0.0904595,
    inclination: 7.30025,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222082',
      },
      {
        userName: 'aika',
        HNames: 'H243213',
      },
      {
        userName: 'kn1cht',
        HNames: 'H243213',
      },
    ],
  },
  {
    provisionalCode: '2017 FA236',
    note: '',
    absoluteMagnitude: 17.85,
    semimajorAxis: 2.97313,
    eccentricity: 0.0841954,
    inclination: 9.15907,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H237756',
      },
      {
        userName: 'aika',
        HNames: 'H243321',
      },
      {
        userName: 'kn1cht',
        HNames: 'H243321',
      },
    ],
  },
  {
    provisionalCode: '2017 FB236',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 3.19699,
    eccentricity: 0.0982734,
    inclination: 8.73431,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249474',
      },
      {
        userName: 'ko',
        HNames: 'H249474',
      },
      {
        userName: 'arda',
        HNames: 'H262191',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H302359',
      },
    ],
  },
  {
    provisionalCode: '2017 FC236',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 2.91337,
    eccentricity: 0.0920508,
    inclination: 6.87422,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ko',
        HNames: 'H249472',
      },
      {
        userName: 'れお',
        HNames: 'H249472',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290785',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H302351',
      },
    ],
  },
  {
    provisionalCode: '2017 FD236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.25,
    semimajorAxis: 5.22541,
    eccentricity: 0.0617706,
    inclination: 17.00497,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H247623, H301083',
      },
      {
        userName: 'yakuba',
        HNames: 'H247623',
      },
      {
        userName: 'aika',
        HNames: 'H247623',
      },
      {
        userName: 'zorome',
        HNames: 'H251815',
      },
    ],
  },
  {
    provisionalCode: '2017 FF236',
    note: '',
    absoluteMagnitude: 18.88,
    semimajorAxis: 2.65394,
    eccentricity: 0.0548788,
    inclination: 21.3421,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249903, H262943',
      },
      {
        userName: 'れお',
        HNames: 'H262943',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282794',
      },
    ],
  },
  {
    provisionalCode: '2017 FG236',
    note: '',
    absoluteMagnitude: 18.46,
    semimajorAxis: 2.99092,
    eccentricity: 0.0429778,
    inclination: 9.72447,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242484',
      },
      {
        userName: 'れお',
        HNames: 'H279460',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279460',
      },
    ],
  },
  {
    provisionalCode: '2017 FH236',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.57898,
    eccentricity: 0.1196552,
    inclination: 11.76075,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250464, H257521, H293519',
      },
      {
        userName: 'れお',
        HNames: 'H293519',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ236',
    note: '',
    absoluteMagnitude: 18.27,
    semimajorAxis: 3.01708,
    eccentricity: 0.0902663,
    inclination: 9.19805,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241992',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289387',
      },
      {
        userName: 'aika',
        HNames: 'H293520',
      },
      {
        userName: 'yakuba',
        HNames: 'H301671',
      },
    ],
  },
  {
    provisionalCode: '2017 FK236',
    note: '',
    absoluteMagnitude: 18.27,
    semimajorAxis: 3.22104,
    eccentricity: 0.0822351,
    inclination: 8.48681,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H242234',
      },
      {
        userName: 'zorome',
        HNames: 'H242234',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289384',
      },
      {
        userName: 'aika',
        HNames: 'H293526',
      },
      {
        userName: 'yakuba',
        HNames: 'H301672',
      },
    ],
  },
  {
    provisionalCode: '2017 FL236',
    note: '',
    absoluteMagnitude: 17.24,
    semimajorAxis: 3.17479,
    eccentricity: 0.0505845,
    inclination: 9.93087,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H241839',
      },
      {
        userName: '赵经远',
        HNames: 'H262531',
      },
      {
        userName: 'aika',
        HNames: 'H293576',
      },
    ],
  },
  {
    provisionalCode: '2017 FM236',
    note: '',
    absoluteMagnitude: 18.23,
    semimajorAxis: 3.18685,
    eccentricity: 0.1795874,
    inclination: 7.29921,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244461, H298947',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244461',
      },
    ],
  },
  {
    provisionalCode: '2017 FN236',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.05998,
    eccentricity: 0.1197162,
    inclination: 7.21441,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222086',
      },
      {
        userName: 'aika',
        HNames: 'H247520, H299709',
      },
    ],
  },
  {
    provisionalCode: '2017 FO236',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.20824,
    eccentricity: 0.1326381,
    inclination: 8.05813,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244902',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244902',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244902',
      },
      {
        userName: 'ほたる',
        HNames: 'H287777',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.46,
    semimajorAxis: 5.15134,
    eccentricity: 0.0865454,
    inclination: 12.6414,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H274872',
      },
      {
        userName: 'aika',
        HNames: 'H293715',
      },
    ],
  },
  {
    provisionalCode: '2017 FR236',
    note: '',
    absoluteMagnitude: 18.92,
    semimajorAxis: 2.96827,
    eccentricity: 0.1367238,
    inclination: 8.68879,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241359',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241359',
      },
      {
        userName: 'kn1cht',
        HNames: 'H263686',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263686',
      },
    ],
  },
  {
    provisionalCode: '2017 FT236',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.15995,
    eccentricity: 0.0189996,
    inclination: 8.76229,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233317',
      },
      {
        userName: 'ko',
        HNames: 'H262963',
      },
      {
        userName: 'aika',
        HNames: 'H293574',
      },
      {
        userName: 'nasiar99',
        HNames: 'H293574, H304649',
      },
    ],
  },
  {
    provisionalCode: '2017 FU236',
    note: '',
    absoluteMagnitude: 20.35,
    semimajorAxis: 2.23831,
    eccentricity: 0.1048586,
    inclination: 6.57591,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H245285',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245285, H290465',
      },
      {
        userName: 'もしもス',
        HNames: 'H270721',
      },
      {
        userName: 'aika',
        HNames: 'H302834',
      },
    ],
  },
  {
    provisionalCode: '2017 FV236',
    note: '',
    absoluteMagnitude: 20.27,
    semimajorAxis: 3.18457,
    eccentricity: 0.1222827,
    inclination: 11.50899,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H274540, H274747',
      },
      {
        userName: 'ms',
        HNames: 'H274540, H274747, H306464',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H274747',
      },
    ],
  },
  {
    provisionalCode: '2017 FX236',
    note: '',
    absoluteMagnitude: 20.66,
    semimajorAxis: 3.09763,
    eccentricity: 0.3294465,
    inclination: 10.43838,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249153',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249153',
      },
      {
        userName: 'zorome',
        HNames: 'H249413',
      },
    ],
  },
  {
    provisionalCode: '2017 FY236',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.6812,
    eccentricity: 0.2211039,
    inclination: 13.71427,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H269979, H274947',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H269979',
      },
      {
        userName: 'belleequipe',
        HNames: 'H274947',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ236',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.15868,
    eccentricity: 0.2310672,
    inclination: 8.62565,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H242372, H305940, H306251, H306255, H306265',
      },
    ],
  },
  {
    provisionalCode: '2017 FA237',
    note: '',
    absoluteMagnitude: 18.0,
    semimajorAxis: 3.13732,
    eccentricity: 0.0617303,
    inclination: 8.18405,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H239219, H306878, H306919, H308190',
      },
      {
        userName: 'ほたる',
        HNames: 'H285015',
      },
    ],
  },
  {
    provisionalCode: '2017 FC237',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.32,
    semimajorAxis: 5.18223,
    eccentricity: 0.089649,
    inclination: 24.89943,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H200442',
      },
      {
        userName: '42',
        HNames: 'H200442',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247280',
      },
      {
        userName: 'aika',
        HNames: 'H247280',
      },
      {
        userName: 'れお',
        HNames: 'H247280',
      },
    ],
  },
  {
    provisionalCode: '2017 FD237',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 2.71521,
    eccentricity: 0.1431769,
    inclination: 12.0609,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244477',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H269981',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290685',
      },
      {
        userName: 'aika',
        HNames: 'H302835',
      },
    ],
  },
  {
    provisionalCode: '2017 FE237',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.33824,
    eccentricity: 0.1355633,
    inclination: 8.24926,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H251472',
      },
      {
        userName: 'もしもス',
        HNames: 'H251472',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251472',
      },
      {
        userName: 'ms',
        HNames: 'H306463',
      },
    ],
  },
  {
    provisionalCode: '2017 FF237',
    note: '',
    absoluteMagnitude: 18.54,
    semimajorAxis: 3.23285,
    eccentricity: 0.0186212,
    inclination: 6.99036,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H224518, H243489',
      },
      {
        userName: 'kn1cht',
        HNames: 'H224518, H243489',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290806',
      },
    ],
  },
  {
    provisionalCode: '2017 FG237',
    note: '',
    absoluteMagnitude: 20.76,
    semimajorAxis: 2.98845,
    eccentricity: 0.1371684,
    inclination: 18.32855,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289667',
      },
    ],
  },
  {
    provisionalCode: '2017 FH237',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.13931,
    eccentricity: 0.0214718,
    inclination: 10.40258,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263051',
      },
      {
        userName: 'ko',
        HNames: 'H270854',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289698',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305495',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ237',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.44882,
    eccentricity: 0.2089685,
    inclination: 6.13279,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H249437',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249437',
      },
      {
        userName: 'aika',
        HNames: 'H249437',
      },
      {
        userName: 'zorome',
        HNames: 'H249437',
      },
    ],
  },
  {
    provisionalCode: '2017 GZ35',
    note: '',
    absoluteMagnitude: 20.35,
    semimajorAxis: 3.0231,
    eccentricity: 0.0547033,
    inclination: 8.46142,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247531, H256295',
      },
      {
        userName: 'ms',
        HNames: 'H276246, H306588, H306732',
      },
    ],
  },
  {
    provisionalCode: '2017 GB36',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 3.01557,
    eccentricity: 0.0443891,
    inclination: 9.02528,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289415',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289789',
      },
    ],
  },
  {
    provisionalCode: '2017 GC36',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 3.26954,
    eccentricity: 0.0179449,
    inclination: 8.05961,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H289442',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289442',
      },
    ],
  },
  {
    provisionalCode: '2017 HV109',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.03,
    semimajorAxis: 45.07641,
    eccentricity: 0.1263237,
    inclination: 10.61676,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H268035',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295001',
      },
      {
        userName: 'aika',
        HNames: 'H299971, H299973',
      },
    ],
  },
  {
    provisionalCode: '2017 HY109',
    note: '',
    absoluteMagnitude: 18.16,
    semimajorAxis: 2.84792,
    eccentricity: 0.1032627,
    inclination: 11.93423,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H276714, H279186',
      },
      {
        userName: 'sasaki',
        HNames: 'H303405',
      },
    ],
  },
  {
    provisionalCode: '2017 HA110',
    note: '',
    absoluteMagnitude: 19.63,
    semimajorAxis: 2.94274,
    eccentricity: 0.0293968,
    inclination: 10.00327,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H269560',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282089, H288536',
      },
    ],
  },
  {
    provisionalCode: '2017 HE110',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.15207,
    eccentricity: 0.0633731,
    inclination: 8.9199,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H299616, H305932, H305939, H305942, H317449',
      },
    ],
  },
  {
    provisionalCode: '2017 HF110',
    note: '2013 LP48, 2023 QL24と同定',
    absoluteMagnitude: 18.75,
    semimajorAxis: 2.77971,
    eccentricity: 0.1845209,
    inclination: 20.3733,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H210877',
      },
      {
        userName: 'えでぃ',
        HNames: 'H298159, H298188, H306405',
      },
    ],
  },
  {
    provisionalCode: '2017 HH110',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.65601,
    eccentricity: 0.1677889,
    inclination: 10.79176,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243157',
      },
      {
        userName: 'zorome',
        HNames: 'H267360',
      },
      {
        userName: 'えでぃ',
        HNames: 'H299608, H305906',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ110',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 3.02357,
    eccentricity: 0.064033,
    inclination: 9.30918,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H276890, H307533, H307580, H307628',
      },
    ],
  },
  {
    provisionalCode: '2019 EC7',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 3.20619,
    eccentricity: 0.0252847,
    inclination: 7.75903,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H261507, H284743',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H277769',
      },
      {
        userName: 'arda',
        HNames: 'H297777',
      },
    ],
  },
  {
    provisionalCode: '2019 GK183',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.69,
    semimajorAxis: 72.57524,
    eccentricity: 0.5352882,
    inclination: 9.49596,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H253443, H253450, H292286',
      },
    ],
  },
  {
    provisionalCode: '2019 KM76',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 3.08536,
    eccentricity: 0.0721324,
    inclination: 6.47172,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H227290',
      },
      {
        userName: 'ねこねこのこねこのこねこね',
        HNames: 'H232923',
      },
      {
        userName: '赵经远',
        HNames: 'H239416',
      },
      {
        userName: 'geneve',
        HNames: 'H299847',
      },
    ],
  },
  {
    provisionalCode: '2019 SM245',
    note: '',
    absoluteMagnitude: 17.84,
    semimajorAxis: 2.9965,
    eccentricity: 0.0681291,
    inclination: 17.17856,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H226816',
      },
      {
        userName: 'れお',
        HNames: 'H226816',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H226816',
      },
      {
        userName: 's.ozawa',
        HNames: 'H280338',
      },
      {
        userName: 'ほたる',
        HNames: 'H287905',
      },
    ],
  },
  {
    provisionalCode: '2019 TE96',
    note: 'Phocaea',
    absoluteMagnitude: 19.98,
    semimajorAxis: 2.29276,
    eccentricity: 0.2118919,
    inclination: 22.62339,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255579',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H258524',
      },
    ],
  },
  {
    provisionalCode: '2019 TO96',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.10237,
    eccentricity: 0.2058783,
    inclination: 12.78199,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216592',
      },
      {
        userName: 'このしろ',
        HNames: 'H216592',
      },
      {
        userName: 's.ozawa',
        HNames: 'H304368',
      },
      {
        userName: 'alberto76',
        HNames: 'H304587',
      },
    ],
  },
  {
    provisionalCode: '2019 UN175',
    note: '',
    absoluteMagnitude: 21.72,
    semimajorAxis: 2.69208,
    eccentricity: 0.1954297,
    inclination: 6.08427,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217066',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222471',
      },
    ],
  },
  {
    provisionalCode: '2019 UO175',
    note: '',
    absoluteMagnitude: 19.57,
    semimajorAxis: 3.03713,
    eccentricity: 0.0583031,
    inclination: 9.62256,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217094, H217510',
      },
    ],
  },
  {
    provisionalCode: '2019 UP175',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 3.1209,
    eccentricity: 0.1506823,
    inclination: 2.03301,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217093, H218476',
      },
    ],
  },
  {
    provisionalCode: '2019 UR175',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.72524,
    eccentricity: 0.0525441,
    inclination: 3.93636,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223139, H223863',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H305430',
      },
    ],
  },
  {
    provisionalCode: '2019 US175',
    note: '',
    absoluteMagnitude: 22.38,
    semimajorAxis: 2.61033,
    eccentricity: 0.2646426,
    inclination: 4.16937,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223223, H305752',
      },
      {
        userName: 'kn1cht',
        HNames: 'H223664',
      },
    ],
  },
  {
    provisionalCode: '2019 UT175',
    note: '',
    absoluteMagnitude: 20.63,
    semimajorAxis: 2.89354,
    eccentricity: 0.1756589,
    inclination: 5.20954,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224329, H228134, H305797',
      },
    ],
  },
  {
    provisionalCode: '2019 UU175',
    note: '',
    absoluteMagnitude: 20.87,
    semimajorAxis: 3.03658,
    eccentricity: 0.165836,
    inclination: 10.22036,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228437',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229215',
      },
    ],
  },
  {
    provisionalCode: '2019 VO51',
    note: 'Mars Crosser',
    absoluteMagnitude: 21.83,
    semimajorAxis: 2.58345,
    eccentricity: 0.425653,
    inclination: 3.52713,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222377',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223470',
      },
    ],
  },
  {
    provisionalCode: '2019 VP51',
    note: '',
    absoluteMagnitude: 20.94,
    semimajorAxis: 2.7326,
    eccentricity: 0.156907,
    inclination: 11.31097,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H222956, H271363',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222956',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302702, H302707',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ51',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.90859,
    eccentricity: 0.2431346,
    inclination: 12.06424,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240301',
      },
      {
        userName: 'もしもス',
        HNames: 'H240301',
      },
      {
        userName: 's.ozawa',
        HNames: 'H247349',
      },
    ],
  },
  {
    provisionalCode: '2019 VR51',
    note: '',
    absoluteMagnitude: 20.33,
    semimajorAxis: 3.00483,
    eccentricity: 0.2417574,
    inclination: 12.31819,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240302',
      },
      {
        userName: 'もしもス',
        HNames: 'H240302',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H301706',
      },
    ],
  },
  {
    provisionalCode: '2019 VS51',
    note: '',
    absoluteMagnitude: 21.77,
    semimajorAxis: 2.54565,
    eccentricity: 0.1734209,
    inclination: 9.81047,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247120, H286211',
      },
      {
        userName: 'もしもス',
        HNames: 'H247120',
      },
    ],
  },
  {
    provisionalCode: '2019 VT51',
    note: '',
    absoluteMagnitude: 21.04,
    semimajorAxis: 2.98421,
    eccentricity: 0.1121958,
    inclination: 11.81434,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H222955, H266570',
      },
      {
        userName: 'mitarushi',
        HNames: 'H222955',
      },
      {
        userName: 'もしもス',
        HNames: 'H222955',
      },
      {
        userName: 'れお',
        HNames: 'H266570',
      },
    ],
  },
  {
    provisionalCode: '2019 VU51',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.9181,
    eccentricity: 0.1294304,
    inclination: 11.46858,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'としちゃん',
        HNames: 'H218468',
      },
      {
        userName: 'れお',
        HNames: 'H231688',
      },
      {
        userName: 'mitarushi',
        HNames: 'H231688',
      },
      {
        userName: 's.ozawa',
        HNames: 'H254974',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302743',
      },
    ],
  },
  {
    provisionalCode: '2019 VV51',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 3.00932,
    eccentricity: 0.1175886,
    inclination: 3.43117,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H221246, H228439',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221246',
      },
      {
        userName: '赵经远',
        HNames: 'H227910',
      },
    ],
  },
  {
    provisionalCode: '2019 VW51',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.17118,
    eccentricity: 0.1229326,
    inclination: 18.4144,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H230702',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230702',
      },
      {
        userName: 's.ozawa',
        HNames: 'H254973',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254973',
      },
    ],
  },
  {
    provisionalCode: '2019 VX51',
    note: '',
    absoluteMagnitude: 19.47,
    semimajorAxis: 3.13011,
    eccentricity: 0.1055146,
    inclination: 12.00288,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H242346',
      },
      {
        userName: 'れお',
        HNames: 'H267378, H267418',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302760',
      },
    ],
  },
  {
    provisionalCode: '2019 VB52',
    note: '',
    absoluteMagnitude: 20.61,
    semimajorAxis: 2.66093,
    eccentricity: 0.1322936,
    inclination: 4.30414,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217068',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304414',
      },
    ],
  },
  {
    provisionalCode: '2019 VC52',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.04749,
    eccentricity: 0.1523002,
    inclination: 18.74542,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217367',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223862',
      },
    ],
  },
  {
    provisionalCode: '2019 VD52',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 2.57404,
    eccentricity: 0.0943645,
    inclination: 2.3695,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217056, H217368',
      },
    ],
  },
  {
    provisionalCode: '2019 VE52',
    note: '',
    absoluteMagnitude: 21.4,
    semimajorAxis: 2.36795,
    eccentricity: 0.13756,
    inclination: 4.07199,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217508',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222868',
      },
    ],
  },
  {
    provisionalCode: '2019 VF52',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 3.10778,
    eccentricity: 0.1513358,
    inclination: 4.51397,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H217509, H304453',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217509',
      },
      {
        userName: 'guoyou sun',
        HNames: 'H231356',
      },
    ],
  },
  {
    provisionalCode: '2019 VG52',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.9531,
    eccentricity: 0.171351,
    inclination: 2.18323,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217570',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217570',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222152',
      },
    ],
  },
  {
    provisionalCode: '2019 VH52',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.74721,
    eccentricity: 0.1046204,
    inclination: 2.40135,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219251',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304503',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ52',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 3.08532,
    eccentricity: 0.150275,
    inclination: 5.70871,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218217, H218218',
      },
      {
        userName: 'さんしろう',
        HNames: 'H248707',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304489',
      },
    ],
  },
  {
    provisionalCode: '2019 VK52',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.58496,
    eccentricity: 0.1356849,
    inclination: 6.86031,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217428, H219270',
      },
    ],
  },
  {
    provisionalCode: '2019 VL52',
    note: '',
    absoluteMagnitude: 22.66,
    semimajorAxis: 2.82504,
    eccentricity: 0.3065121,
    inclination: 2.70629,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H220029, H304833',
      },
    ],
  },
  {
    provisionalCode: '2019 VM52',
    note: 'Hilda',
    absoluteMagnitude: 19.69,
    semimajorAxis: 3.88172,
    eccentricity: 0.1232604,
    inclination: 3.92702,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213962, H220511, H304851',
      },
    ],
  },
  {
    provisionalCode: '2019 VN52',
    note: '',
    absoluteMagnitude: 21.37,
    semimajorAxis: 2.36744,
    eccentricity: 0.157728,
    inclination: 1.94504,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211714, H220537',
      },
    ],
  },
  {
    provisionalCode: '2019 VO52',
    note: '',
    absoluteMagnitude: 20.82,
    semimajorAxis: 2.84327,
    eccentricity: 0.073854,
    inclination: 7.68539,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221978',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304869',
      },
    ],
  },
  {
    provisionalCode: '2019 VP52',
    note: '',
    absoluteMagnitude: 23.07,
    semimajorAxis: 2.61139,
    eccentricity: 0.3526843,
    inclination: 8.10469,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222037, H228195',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ52',
    note: '',
    absoluteMagnitude: 21.31,
    semimajorAxis: 2.52143,
    eccentricity: 0.0618686,
    inclination: 2.14112,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222118, H304455',
      },
    ],
  },
  {
    provisionalCode: '2019 VR52',
    note: '',
    absoluteMagnitude: 22.4,
    semimajorAxis: 2.3087,
    eccentricity: 0.2255412,
    inclination: 1.91397,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222286',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304907',
      },
    ],
  },
  {
    provisionalCode: '2019 VS52',
    note: '',
    absoluteMagnitude: 21.88,
    semimajorAxis: 2.35902,
    eccentricity: 0.2303544,
    inclination: 4.58003,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H220719',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222351',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304916',
      },
    ],
  },
  {
    provisionalCode: '2019 VT52',
    note: '',
    absoluteMagnitude: 21.57,
    semimajorAxis: 2.43416,
    eccentricity: 0.099905,
    inclination: 4.27426,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222354, H224330',
      },
      {
        userName: 'aika',
        HNames: 'H222354',
      },
    ],
  },
  {
    provisionalCode: '2019 VU52',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.6108,
    eccentricity: 0.204897,
    inclination: 2.65422,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H219082',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H227929',
      },
      {
        userName: 'bny',
        HNames: 'H231099',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304886',
      },
    ],
  },
  {
    provisionalCode: '2019 VV52',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 16.2,
    semimajorAxis: 7.19114,
    eccentricity: 0.6109709,
    inclination: 5.66982,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219810, H304816',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219810',
      },
    ],
  },
  {
    provisionalCode: '2019 VW52',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 3.64281,
    eccentricity: 0.0992609,
    inclination: 9.80613,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222778',
      },
      {
        userName: 'さんしろう',
        HNames: 'H229572',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H305422',
      },
    ],
  },
  {
    provisionalCode: '2019 VX52',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 3.1213,
    eccentricity: 0.1321909,
    inclination: 5.08539,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223512, H305461',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223512',
      },
      {
        userName: 'さんしろう',
        HNames: 'H247612',
      },
    ],
  },
  {
    provisionalCode: '2019 VY52',
    note: '',
    absoluteMagnitude: 19.89,
    semimajorAxis: 2.95414,
    eccentricity: 0.0331162,
    inclination: 11.9718,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224828, H305767',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ52',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 3.17076,
    eccentricity: 0.1476406,
    inclination: 15.82413,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226931, H306246, H306247',
      },
      {
        userName: 'mitarushi',
        HNames: 'H226931',
      },
      {
        userName: 'れお',
        HNames: 'H226931',
      },
    ],
  },
  {
    provisionalCode: '2019 VA53',
    note: '',
    absoluteMagnitude: 21.08,
    semimajorAxis: 3.16128,
    eccentricity: 0.2657644,
    inclination: 14.94209,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226932',
      },
      {
        userName: 'れお',
        HNames: 'H226932',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286974',
      },
    ],
  },
  {
    provisionalCode: '2019 VB53',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 2.96966,
    eccentricity: 0.0342311,
    inclination: 9.55312,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221979, H305532',
      },
      {
        userName: 'aika',
        HNames: 'H305532',
      },
    ],
  },
  {
    provisionalCode: '2019 VC53',
    note: '',
    absoluteMagnitude: 17.81,
    semimajorAxis: 3.17817,
    eccentricity: 0.0616517,
    inclination: 12.11417,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H231682, H232792',
      },
      {
        userName: 's.ozawa',
        HNames: 'H282496',
      },
    ],
  },
  {
    provisionalCode: '2019 VD53',
    note: 'Mars Crosser',
    absoluteMagnitude: 22.95,
    semimajorAxis: 2.23842,
    eccentricity: 0.2996537,
    inclination: 2.89789,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228145, H306231',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228145',
      },
    ],
  },
  {
    provisionalCode: '2019 VE53',
    note: 'Mars Crosser',
    absoluteMagnitude: 22.52,
    semimajorAxis: 2.02041,
    eccentricity: 0.2285832,
    inclination: 1.84607,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213248, H280700',
      },
    ],
  },
  {
    provisionalCode: '2019 WY34',
    note: '',
    absoluteMagnitude: 20.51,
    semimajorAxis: 3.15182,
    eccentricity: 0.2667749,
    inclination: 13.42821,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H249101',
      },
      {
        userName: 'れお',
        HNames: 'H257948, H257949, H299241',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ34',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 2.85083,
    eccentricity: 0.0255447,
    inclination: 13.226,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'bny',
        HNames: 'H225110',
      },
      {
        userName: 'もしもス',
        HNames: 'H225110',
      },
      {
        userName: 'れお',
        HNames: 'H256645, H299104',
      },
    ],
  },
  {
    provisionalCode: '2019 WA35',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 2.85054,
    eccentricity: 0.0914268,
    inclination: 12.44636,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216025, H278409, H296806',
      },
      {
        userName: 'このしろ',
        HNames: 'H216025',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278409',
      },
    ],
  },
  {
    provisionalCode: '2019 WB35',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 3.06421,
    eccentricity: 0.0853627,
    inclination: 16.32994,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H252331, H282511',
      },
      {
        userName: 'れお',
        HNames: 'H257952',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282511',
      },
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H301088, H301121',
      },
    ],
  },
  {
    provisionalCode: '2019 WC35',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 3.18204,
    eccentricity: 0.2642795,
    inclination: 15.48503,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228764',
      },
      {
        userName: 'ctaka',
        HNames: 'H237131',
      },
      {
        userName: 'kn1cht',
        HNames: 'H298206',
      },
      {
        userName: 'れお',
        HNames: 'H299204',
      },
    ],
  },
  {
    provisionalCode: '2019 WD35',
    note: '',
    absoluteMagnitude: 21.05,
    semimajorAxis: 2.94487,
    eccentricity: 0.1667428,
    inclination: 12.65074,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228316',
      },
      {
        userName: 's.ozawa',
        HNames: 'H280399',
      },
      {
        userName: 'れお',
        HNames: 'H299186',
      },
    ],
  },
  {
    provisionalCode: '2019 WE35',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.07885,
    eccentricity: 0.1155231,
    inclination: 12.51037,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H248770, H269550, H270694, H270761',
      },
      {
        userName: 'れお',
        HNames: 'H257967',
      },
    ],
  },
  {
    provisionalCode: '2019 WF35',
    note: '',
    absoluteMagnitude: 20.81,
    semimajorAxis: 3.02,
    eccentricity: 0.2227465,
    inclination: 13.08693,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H252444, H282872, H282874',
      },
      {
        userName: 'れお',
        HNames: 'H257953',
      },
    ],
  },
  {
    provisionalCode: '2019 WG35',
    note: '',
    absoluteMagnitude: 20.27,
    semimajorAxis: 3.03576,
    eccentricity: 0.1295099,
    inclination: 12.51819,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228311',
      },
      {
        userName: 'kn1cht',
        HNames: 'H228399, H294366',
      },
      {
        userName: 'れお',
        HNames: 'H299200',
      },
    ],
  },
  {
    provisionalCode: '2019 WH35',
    note: '',
    absoluteMagnitude: 20.52,
    semimajorAxis: 3.12805,
    eccentricity: 0.2660503,
    inclination: 16.02954,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H257943',
      },
      {
        userName: 's.ozawa',
        HNames: 'H279911, H304624',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ35',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 3.07421,
    eccentricity: 0.1300003,
    inclination: 16.08243,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226132',
      },
      {
        userName: 'れお',
        HNames: 'H261614, H268307',
      },
      {
        userName: 's.ozawa',
        HNames: 'H304659, H304663',
      },
    ],
  },
  {
    provisionalCode: '2019 WK35',
    note: 'Mars Crosser',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.75081,
    eccentricity: 0.4334286,
    inclination: 18.87041,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H266653',
      },
      {
        userName: 'れお',
        HNames: 'H266653',
      },
      {
        userName: 's.ozawa',
        HNames: 'H304449, H304798',
      },
    ],
  },
  {
    provisionalCode: '2019 XR20',
    note: '',
    absoluteMagnitude: 18.2,
    semimajorAxis: 3.24282,
    eccentricity: 0.0761314,
    inclination: 15.88385,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H244247, H269024, H269026, H269028, H273758',
      },
    ],
  },
  {
    provisionalCode: '2016 WN85',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 2.49982,
    eccentricity: 0.0472797,
    inclination: 9.20178,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220785',
      },
      {
        userName: 'ms',
        HNames: 'H270995',
      },
      {
        userName: 'もしもス',
        HNames: 'H270995',
      },
    ],
  },
  {
    provisionalCode: '2016 YM40',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 2.70067,
    eccentricity: 0.0715537,
    inclination: 6.54326,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267836, H267868, H279643',
      },
    ],
  },
  {
    provisionalCode: '2016 YN40',
    note: '',
    absoluteMagnitude: 18.0,
    semimajorAxis: 3.17552,
    eccentricity: 0.0123358,
    inclination: 9.39832,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266730, H266771, H279644',
      },
    ],
  },
  {
    provisionalCode: '2017 AC64',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.15,
    semimajorAxis: 75.61132,
    eccentricity: 0.4646029,
    inclination: 28.06481,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'arda',
        HNames: 'H281663',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H308151, H308156, H308165, H308175',
      },
    ],
  },
  {
    provisionalCode: '2017 BM236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.03,
    semimajorAxis: 5.31852,
    eccentricity: 0.1333761,
    inclination: 12.98223,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H233775',
      },
      {
        userName: 'aika',
        HNames: 'H233775',
      },
      {
        userName: 'arda',
        HNames: 'H262652',
      },
      {
        userName: '42',
        HNames: 'H262652',
      },
      {
        userName: 'ossan',
        HNames: 'H308230',
      },
    ],
  },
  {
    provisionalCode: '2017 BN236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.9,
    semimajorAxis: 5.22909,
    eccentricity: 0.0557024,
    inclination: 21.38109,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245661',
      },
      {
        userName: 'nasiar99',
        HNames: 'H245661',
      },
      {
        userName: '42',
        HNames: 'H265885, H265889',
      },
      {
        userName: 'れお',
        HNames: 'H285221',
      },
    ],
  },
  {
    provisionalCode: '2017 BO236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.22,
    semimajorAxis: 41.19817,
    eccentricity: 0.0826881,
    inclination: 29.81241,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249758, H269999, H280984',
      },
    ],
  },
  {
    provisionalCode: '2017 BP236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.5,
    semimajorAxis: 35.18779,
    eccentricity: 0.0497253,
    inclination: 18.36495,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H238436',
      },
      {
        userName: 'zorome',
        HNames: 'H267877, H267881, H267951',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H308938',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.56,
    semimajorAxis: 43.84874,
    eccentricity: 0.1975905,
    inclination: 20.5548,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H284193, H284196',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H309389',
      },
    ],
  },
  {
    provisionalCode: '2017 BR236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.81,
    semimajorAxis: 41.4993,
    eccentricity: 0.0490434,
    inclination: 15.10408,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H279267, H279271',
      },
    ],
  },
  {
    provisionalCode: '2017 BS236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.76,
    semimajorAxis: 44.09311,
    eccentricity: 0.1760765,
    inclination: 23.93112,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H300428, H300475',
      },
    ],
  },
  {
    provisionalCode: '2017 BT236',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.12,
    semimajorAxis: 5.20196,
    eccentricity: 0.1197891,
    inclination: 25.59336,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H274195, H280029',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280029',
      },
      {
        userName: 'ms',
        HNames: 'H280029',
      },
    ],
  },
  {
    provisionalCode: '2017 BU236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.75,
    semimajorAxis: 48.01296,
    eccentricity: 0.1579811,
    inclination: 19.09077,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262410, H262441',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299587',
      },
    ],
  },
  {
    provisionalCode: '2017 BV236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.9,
    semimajorAxis: 46.35482,
    eccentricity: 0.1821659,
    inclination: 6.55917,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H264412, H300210',
      },
    ],
  },
  {
    provisionalCode: '2017 BW236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.97,
    semimajorAxis: 43.69485,
    eccentricity: 0.0504302,
    inclination: 2.05193,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301221',
      },
    ],
  },
  {
    provisionalCode: '2017 BX236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.33,
    semimajorAxis: 45.74982,
    eccentricity: 0.0751839,
    inclination: 3.06207,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297644',
      },
    ],
  },
  {
    provisionalCode: '2017 BY236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.07,
    semimajorAxis: 40.0122,
    eccentricity: 0.1093377,
    inclination: 4.57525,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H303733',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ236',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.31,
    semimajorAxis: 110.31929,
    eccentricity: 0.7270897,
    inclination: 18.99544,
    oppositions: 4,
    uncertainty: 3,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H205179',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H301457',
      },
    ],
  },
  {
    provisionalCode: '2017 BA237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.9,
    semimajorAxis: 44.37736,
    eccentricity: 0.0392041,
    inclination: 4.36365,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211681, H303742',
      },
      {
        userName: 'watan_rockhand',
        HNames: 'H211681',
      },
    ],
  },
  {
    provisionalCode: '2017 CY55',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.56947,
    eccentricity: 0.2495241,
    inclination: 5.76424,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H262713, H263152',
      },
      {
        userName: 'kn1cht',
        HNames: 'H304564',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ55',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.05,
    semimajorAxis: 5.20251,
    eccentricity: 0.0433599,
    inclination: 13.07213,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262706, H275293',
      },
      {
        userName: '42',
        HNames: 'H262706',
      },
      {
        userName: 'れお',
        HNames: 'H262706',
      },
      {
        userName: 'もしもス',
        HNames: 'H275293',
      },
    ],
  },
  {
    provisionalCode: '2017 CA56',
    note: '',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.08975,
    eccentricity: 0.1054681,
    inclination: 6.73698,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283033',
      },
      {
        userName: 'arda',
        HNames: 'H290871',
      },
      {
        userName: 'kn1cht',
        HNames: 'H308827',
      },
    ],
  },
  {
    provisionalCode: '2017 CB56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.56,
    semimajorAxis: 75.8206,
    eccentricity: 0.4918709,
    inclination: 33.20438,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H239231, H304088',
      },
      {
        userName: 'yakuba',
        HNames: 'H277714',
      },
    ],
  },
  {
    provisionalCode: '2017 CC56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.2,
    semimajorAxis: 47.39822,
    eccentricity: 0.3032316,
    inclination: 15.66113,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271908, H271909, H271910, H275533',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H307523',
      },
    ],
  },
  {
    provisionalCode: '2017 CD56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 39.42041,
    eccentricity: 0.0715814,
    inclination: 13.47847,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271130, H271144, H271146',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271144',
      },
    ],
  },
  {
    provisionalCode: '2017 CE56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.24,
    semimajorAxis: 46.10416,
    eccentricity: 0.0647451,
    inclination: 4.48211,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276177, H276186',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H303355',
      },
    ],
  },
  {
    provisionalCode: '2017 CF56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.75,
    semimajorAxis: 53.44819,
    eccentricity: 0.5850625,
    inclination: 14.52612,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266247, H266295, H267033',
      },
    ],
  },
  {
    provisionalCode: '2017 CG56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 11.61,
    semimajorAxis: 47.72063,
    eccentricity: 0.5984155,
    inclination: 15.54445,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265794, H265825, H265841',
      },
    ],
  },
  {
    provisionalCode: '2017 CH56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.27,
    semimajorAxis: 42.05447,
    eccentricity: 0.2721692,
    inclination: 15.96316,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H284610, H284621, H297154',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.17,
    semimajorAxis: 48.38276,
    eccentricity: 0.089654,
    inclination: 22.20789,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304332',
      },
    ],
  },
  {
    provisionalCode: '2017 CK56',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.12,
    semimajorAxis: 5.21758,
    eccentricity: 0.0379896,
    inclination: 14.12542,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H226332, H310108',
      },
      {
        userName: 'zorome',
        HNames: 'H284994',
      },
    ],
  },
  {
    provisionalCode: '2017 DD164',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 3.14227,
    eccentricity: 0.145067,
    inclination: 12.40626,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251725',
      },
      {
        userName: 'aika',
        HNames: 'H251725, H286431',
      },
      {
        userName: 'れお',
        HNames: 'H251725',
      },
    ],
  },
  {
    provisionalCode: '2017 DE164',
    note: 'Hungaria',
    absoluteMagnitude: 21.1,
    semimajorAxis: 1.87366,
    eccentricity: 0.0816904,
    inclination: 22.69255,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H256257',
      },
      {
        userName: 'aika',
        HNames: 'H256257',
      },
    ],
  },
  {
    provisionalCode: '2017 DF164',
    note: '',
    absoluteMagnitude: 19.56,
    semimajorAxis: 2.55547,
    eccentricity: 0.2107839,
    inclination: 30.03649,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257274',
      },
      {
        userName: 'れお',
        HNames: 'H257274',
      },
    ],
  },
  {
    provisionalCode: '2017 DG164',
    note: '',
    absoluteMagnitude: 18.13,
    semimajorAxis: 3.19761,
    eccentricity: 0.0491071,
    inclination: 13.0976,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259713',
      },
      {
        userName: 'kn1cht',
        HNames: 'H259713',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273654',
      },
    ],
  },
  {
    provisionalCode: '2017 DH164',
    note: '確定番号(697545)',
    absoluteMagnitude: 17.87,
    semimajorAxis: 2.9177,
    eccentricity: 0.0597983,
    inclination: 11.71767,
    oppositions: 10,
    uncertainty: 0,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H283173',
      },
      {
        userName: 'nasiar99',
        HNames: 'H283173',
      },
      {
        userName: 'れお',
        HNames: 'H283173',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283173',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H302768',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ164',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 2.94972,
    eccentricity: 0.0471742,
    inclination: 12.04326,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H283174',
      },
      {
        userName: 'nasiar99',
        HNames: 'H283174',
      },
      {
        userName: 'れお',
        HNames: 'H283174',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283174',
      },
    ],
  },
  {
    provisionalCode: '2017 DK164',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 18.01,
    semimajorAxis: 5.30243,
    eccentricity: 0.0414773,
    inclination: 8.35997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266666',
      },
      {
        userName: 'arda',
        HNames: 'H266666',
      },
      {
        userName: 'zorome',
        HNames: 'H273070',
      },
    ],
  },
  {
    provisionalCode: '2017 DL164',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.89,
    semimajorAxis: 5.21283,
    eccentricity: 0.0842299,
    inclination: 10.29303,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H267642',
      },
      {
        userName: 'arda',
        HNames: 'H267642',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267642, H309023',
      },
    ],
  },
  {
    provisionalCode: '2017 DM164',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.76,
    semimajorAxis: 5.16631,
    eccentricity: 0.10781,
    inclination: 7.55295,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H268136',
      },
      {
        userName: 'aika',
        HNames: 'H268136',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H282917, H309050',
      },
    ],
  },
  {
    provisionalCode: '2017 DN164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.5,
    semimajorAxis: 37.65838,
    eccentricity: 0.0598651,
    inclination: 20.58226,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242160, H251811',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H242160, H251811, H308243',
      },
    ],
  },
  {
    provisionalCode: '2017 DO164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.93,
    semimajorAxis: 41.66151,
    eccentricity: 0.1552167,
    inclination: 29.91302,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260974, H307147, H307156',
      },
    ],
  },
  {
    provisionalCode: '2017 DP164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.67,
    semimajorAxis: 44.19623,
    eccentricity: 0.0829389,
    inclination: 10.71923,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243254, H276158',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H306757',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.04,
    semimajorAxis: 43.51669,
    eccentricity: 0.0829231,
    inclination: 1.99382,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301014',
      },
    ],
  },
  {
    provisionalCode: '2017 DR164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.15,
    semimajorAxis: 43.85343,
    eccentricity: 0.0700787,
    inclination: 4.21448,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243273',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243273',
      },
    ],
  },
  {
    provisionalCode: '2017 DS164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.83,
    semimajorAxis: 45.92398,
    eccentricity: 0.1455267,
    inclination: 18.55099,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243677',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243677',
      },
    ],
  },
  {
    provisionalCode: '2017 DT164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.24,
    semimajorAxis: 43.97394,
    eccentricity: 0.0446812,
    inclination: 3.98594,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243453',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243453',
      },
    ],
  },
  {
    provisionalCode: '2017 DU164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.32,
    semimajorAxis: 42.70969,
    eccentricity: 0.0223347,
    inclination: 3.50389,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243485, H276205',
      },
    ],
  },
  {
    provisionalCode: '2017 DV164',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.72723,
    eccentricity: 0.160007,
    inclination: 6.72535,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H235331',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270267',
      },
      {
        userName: 'れお',
        HNames: 'H270267',
      },
      {
        userName: 'arda',
        HNames: 'H270267',
      },
    ],
  },
  {
    provisionalCode: '2017 DW164',
    note: '',
    absoluteMagnitude: 20.13,
    semimajorAxis: 3.16179,
    eccentricity: 0.1467895,
    inclination: 16.19551,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H270915',
      },
      {
        userName: 'もしもス',
        HNames: 'H270915',
      },
      {
        userName: 'arda',
        HNames: 'H270915',
      },
    ],
  },
  {
    provisionalCode: '2017 DX164',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.85535,
    eccentricity: 0.1537152,
    inclination: 15.84774,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272336',
      },
      {
        userName: 'れお',
        HNames: 'H272336',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272336',
      },
    ],
  },
  {
    provisionalCode: '2017 DY164',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 2.97295,
    eccentricity: 0.1049642,
    inclination: 10.66804,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H272337, H285866',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272337',
      },
      {
        userName: 'aika',
        HNames: 'H272337',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ164',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.42,
    semimajorAxis: 46.17352,
    eccentricity: 0.1421796,
    inclination: 4.73052,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243403, H276193',
      },
    ],
  },
  {
    provisionalCode: '2017 DA165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.89,
    semimajorAxis: 73.12103,
    eccentricity: 0.4325505,
    inclination: 4.586,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243500, H276213',
      },
    ],
  },
  {
    provisionalCode: '2017 DB165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.95,
    semimajorAxis: 57.37705,
    eccentricity: 0.2921657,
    inclination: 4.60908,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246805, H276503',
      },
    ],
  },
  {
    provisionalCode: '2017 DC165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.03,
    semimajorAxis: 59.24173,
    eccentricity: 0.2122032,
    inclination: 3.46976,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H244113',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244113',
      },
    ],
  },
  {
    provisionalCode: '2017 DD165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.39,
    semimajorAxis: 36.20973,
    eccentricity: 0.0438691,
    inclination: 7.39041,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H244015',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244015',
      },
    ],
  },
  {
    provisionalCode: '2017 DE165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.13,
    semimajorAxis: 55.32401,
    eccentricity: 0.5720777,
    inclination: 5.2795,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H272558',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307220, H307223',
      },
    ],
  },
  {
    provisionalCode: '2017 DF165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.81,
    semimajorAxis: 37.33903,
    eccentricity: 0.1118369,
    inclination: 3.6792,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304003',
      },
    ],
  },
  {
    provisionalCode: '2017 DG165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.51,
    semimajorAxis: 43.09101,
    eccentricity: 0.0090192,
    inclination: 3.5455,
    oppositions: 5,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H305323',
      },
    ],
  },
  {
    provisionalCode: '2017 DH165',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.55637,
    eccentricity: 0.1980829,
    inclination: 6.38648,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H272352',
      },
      {
        userName: 'もしもス',
        HNames: 'H272352',
      },
      {
        userName: 'aika',
        HNames: 'H272352',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272352',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ165',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 3.42733,
    eccentricity: 0.1167983,
    inclination: 8.27726,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272658, H309858',
      },
      {
        userName: 'aika',
        HNames: 'H272658',
      },
      {
        userName: 'れお',
        HNames: 'H272658',
      },
    ],
  },
  {
    provisionalCode: '2017 DK165',
    note: '',
    absoluteMagnitude: 22.31,
    semimajorAxis: 2.32504,
    eccentricity: 0.1650713,
    inclination: 8.60444,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275596',
      },
      {
        userName: 'aika',
        HNames: 'H275596',
      },
      {
        userName: 'れお',
        HNames: 'H275596',
      },
    ],
  },
  {
    provisionalCode: '2017 DL165',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 3.14351,
    eccentricity: 0.0844652,
    inclination: 7.66152,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H275666',
      },
      {
        userName: 'もしもス',
        HNames: 'H275666',
      },
      {
        userName: 'aika',
        HNames: 'H275666',
      },
    ],
  },
  {
    provisionalCode: '2017 DM165',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 2.83485,
    eccentricity: 0.0509982,
    inclination: 20.22601,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272167',
      },
      {
        userName: 'もしもス',
        HNames: 'H272167',
      },
    ],
  },
  {
    provisionalCode: '2017 DN165',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 3.17479,
    eccentricity: 0.2122736,
    inclination: 10.74586,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H275863',
      },
      {
        userName: 'aika',
        HNames: 'H275863',
      },
      {
        userName: 'arda',
        HNames: 'H304102',
      },
    ],
  },
  {
    provisionalCode: '2017 DO165',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.17804,
    eccentricity: 0.0573824,
    inclination: 7.86368,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H223799',
      },
      {
        userName: 'aika',
        HNames: 'H256258',
      },
      {
        userName: 'arda',
        HNames: 'H256258',
      },
      {
        userName: 'れお',
        HNames: 'H256258',
      },
    ],
  },
  {
    provisionalCode: '2017 ED53',
    note: 'Phocaea',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.33877,
    eccentricity: 0.2375895,
    inclination: 21.46353,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H245380',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245380',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H307684',
      },
    ],
  },
  {
    provisionalCode: '2017 EF53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.47,
    semimajorAxis: 79.84205,
    eccentricity: 0.5363974,
    inclination: 31.03802,
    oppositions: 5,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266296, H266379, H266388, H266400',
      },
    ],
  },
  {
    provisionalCode: '2017 EG53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.53,
    semimajorAxis: 43.60157,
    eccentricity: 0.1521061,
    inclination: 17.07579,
    oppositions: 8,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271449, H271458, H271465',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H309277',
      },
    ],
  },
  {
    provisionalCode: '2017 EJ53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.58,
    semimajorAxis: 41.00351,
    eccentricity: 0.0855224,
    inclination: 12.38146,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H285442, H285449, H285457',
      },
    ],
  },
  {
    provisionalCode: '2017 EK53',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 3.0583,
    eccentricity: 0.1349482,
    inclination: 7.20364,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270275',
      },
      {
        userName: 'arda',
        HNames: 'H270275',
      },
      {
        userName: 'aika',
        HNames: 'H270275',
      },
    ],
  },
  {
    provisionalCode: '2017 FL237',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 3.0832,
    eccentricity: 0.1407391,
    inclination: 16.20284,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H267353',
      },
      {
        userName: 'arda',
        HNames: 'H270821',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288526',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288991',
      },
    ],
  },
  {
    provisionalCode: '2017 FN237',
    note: '',
    absoluteMagnitude: 18.08,
    semimajorAxis: 3.17324,
    eccentricity: 0.0693834,
    inclination: 8.05917,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222081',
      },
      {
        userName: 'aika',
        HNames: 'H243219',
      },
      {
        userName: 'kn1cht',
        HNames: 'H308828',
      },
    ],
  },
  {
    provisionalCode: '2017 FO237',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 3.0736,
    eccentricity: 0.0954661,
    inclination: 9.02406,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H295720',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305400',
      },
      {
        userName: 'kn1cht',
        HNames: 'H307151',
      },
      {
        userName: 'えでぃ',
        HNames: 'H307151',
      },
    ],
  },
  {
    provisionalCode: '2017 FP237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.13,
    semimajorAxis: 44.27305,
    eccentricity: 0.0650981,
    inclination: 26.95679,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250295, H250366, H267401, H267586, H267589',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H250295',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ237',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.76426,
    eccentricity: 0.1529724,
    inclination: 8.21566,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H288624, H288673',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290777',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302898',
      },
      {
        userName: 'nasiar99',
        HNames: 'H302898',
      },
    ],
  },
  {
    provisionalCode: '2017 FR237',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.68,
    semimajorAxis: 5.32778,
    eccentricity: 0.0399244,
    inclination: 14.37861,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244456',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244456, H305621',
      },
      {
        userName: 'nasiar99',
        HNames: 'H304621',
      },
    ],
  },
  {
    provisionalCode: '2017 FS237',
    note: '',
    absoluteMagnitude: 21.74,
    semimajorAxis: 2.70841,
    eccentricity: 0.3568199,
    inclination: 13.24361,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H261314',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290807',
      },
    ],
  },
  {
    provisionalCode: '2017 FT237',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.84422,
    eccentricity: 0.1177825,
    inclination: 17.53636,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H259544',
      },
      {
        userName: 'aika',
        HNames: 'H259544',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290295',
      },
    ],
  },
  {
    provisionalCode: '2017 FU237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 15.56,
    semimajorAxis: 5.44806,
    eccentricity: 0.088043,
    inclination: 23.09725,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H251468, H267128',
      },
      {
        userName: 'ほたる',
        HNames: 'H284912',
      },
      {
        userName: 'nasiar99',
        HNames: 'H284912',
      },
    ],
  },
  {
    provisionalCode: '2017 HL110',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 3.18273,
    eccentricity: 0.0573536,
    inclination: 8.022,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H278519, H308455, H308463',
      },
    ],
  },
  {
    provisionalCode: '2017 HM110',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 3.40611,
    eccentricity: 0.1056001,
    inclination: 8.02189,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H278520, H309108, H309112',
      },
    ],
  },
  {
    provisionalCode: '2017 HS110',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.55,
    semimajorAxis: 5.17924,
    eccentricity: 0.0702813,
    inclination: 25.14343,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H243868, H298254',
      },
      {
        userName: 'もしもス',
        HNames: 'H243868',
      },
      {
        userName: 'zorome',
        HNames: 'H275152',
      },
    ],
  },
  {
    provisionalCode: '2017 HV110',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 2.64063,
    eccentricity: 0.0621526,
    inclination: 8.72099,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H229444, H259646',
      },
      {
        userName: '赵经远',
        HNames: 'H229444',
      },
      {
        userName: 'sasaki',
        HNames: 'H301953',
      },
      {
        userName: 'えでぃ',
        HNames: 'H310144, H310157',
      },
    ],
  },
  {
    provisionalCode: '2019 EF7',
    note: '',
    absoluteMagnitude: 18.2,
    semimajorAxis: 2.79219,
    eccentricity: 0.1029489,
    inclination: 5.05803,
    oppositions: 2,
    uncertainty: 2,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H270678',
      },
      {
        userName: 'zorome',
        HNames: 'H287017',
      },
    ],
  },
  {
    provisionalCode: '2019 VF53',
    note: '',
    absoluteMagnitude: 21.07,
    semimajorAxis: 2.75721,
    eccentricity: 0.136585,
    inclination: 2.97343,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213244, H214094',
      },
      {
        userName: 'さんしろう',
        HNames: 'H213244',
      },
    ],
  },
  {
    provisionalCode: '2019 VG53',
    note: '',
    absoluteMagnitude: 19.8,
    semimajorAxis: 2.58417,
    eccentricity: 0.0743847,
    inclination: 1.85967,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217883, H217887',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280083',
      },
    ],
  },
  {
    provisionalCode: '2019 VH53',
    note: '',
    absoluteMagnitude: 21.84,
    semimajorAxis: 3.19179,
    eccentricity: 0.3632224,
    inclination: 3.08964,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223433, H305443',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ53',
    note: '',
    absoluteMagnitude: 21.38,
    semimajorAxis: 2.24063,
    eccentricity: 0.1323592,
    inclination: 2.84934,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211439, H309676',
      },
    ],
  },
  {
    provisionalCode: '2019 VK53',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.21406,
    eccentricity: 0.0149046,
    inclination: 16.87461,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219248, H280681',
      },
    ],
  },
  {
    provisionalCode: '2019 WM35',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 16.61,
    semimajorAxis: 5.87028,
    eccentricity: 0.1942189,
    inclination: 21.29466,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214402',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H274407',
      },
    ],
  },
  {
    provisionalCode: '2019 WO35',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.79,
    semimajorAxis: 5.2736,
    eccentricity: 0.0830855,
    inclination: 9.75653,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H219422',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219422',
      },
      {
        userName: 'guoyou sun',
        HNames: 'H231347',
      },
    ],
  },
  {
    provisionalCode: '2017 BD237',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.14317,
    eccentricity: 0.2418509,
    inclination: 16.80978,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H284200, H284651, H286105',
      },
    ],
  },
  {
    provisionalCode: '2017 BE237',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 2.58651,
    eccentricity: 0.2234566,
    inclination: 10.29423,
    oppositions: 4,
    uncertainty: 2,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H249884',
      },
      {
        userName: 'zorome',
        HNames: 'H251812, H310691',
      },
    ],
  },
  {
    provisionalCode: '2017 BF237',
    note: '',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.17055,
    eccentricity: 0.0633697,
    inclination: 15.02322,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265407',
      },
      {
        userName: 'えでぃ',
        HNames: 'H265407',
      },
    ],
  },
  {
    provisionalCode: '2017 BG237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.15,
    semimajorAxis: 60.45798,
    eccentricity: 0.4182469,
    inclination: 12.3857,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286528, H310886',
      },
      {
        userName: 'もしもス',
        HNames: 'H286528',
      },
    ],
  },
  {
    provisionalCode: '2017 BH237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.84,
    semimajorAxis: 54.43316,
    eccentricity: 0.3401881,
    inclination: 9.85692,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H285872',
      },
    ],
  },
  {
    provisionalCode: '2017 CL56',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 3.16586,
    eccentricity: 0.2044466,
    inclination: 16.99744,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220427',
      },
      {
        userName: 'belleequipe',
        HNames: 'H220427',
      },
      {
        userName: '42',
        HNames: 'H267910',
      },
    ],
  },
  {
    provisionalCode: '2017 CM56',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 2.61489,
    eccentricity: 0.0387821,
    inclination: 11.44376,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H226724',
      },
      {
        userName: '赵经远',
        HNames: 'H226724',
      },
      {
        userName: 'nasiar99',
        HNames: 'H226724',
      },
    ],
  },
  {
    provisionalCode: '2017 CN56',
    note: '',
    absoluteMagnitude: 21.62,
    semimajorAxis: 2.23922,
    eccentricity: 0.0897485,
    inclination: 6.05533,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H233527',
      },
      {
        userName: 'aika',
        HNames: 'H233527',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233527',
      },
    ],
  },
  {
    provisionalCode: '2017 CP56',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.23,
    semimajorAxis: 5.22991,
    eccentricity: 0.0949536,
    inclination: 17.09302,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H237587, H311295',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H250506',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ165',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 3.17619,
    eccentricity: 0.2536278,
    inclination: 12.96382,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H256261',
      },
      {
        userName: 'aika',
        HNames: 'H256261',
      },
    ],
  },
  {
    provisionalCode: '2017 FW237',
    note: '2021 RN246と同定: Mars Crosser',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.20152,
    eccentricity: 0.2683397,
    inclination: 7.28769,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H246236, H310022',
      },
      {
        userName: 'kn1cht',
        HNames: 'H270823, H310364, H310368',
      },
      {
        userName: 'arda',
        HNames: 'H270823',
      },
    ],
  },
  {
    provisionalCode: '2017 FX237',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 2.73982,
    eccentricity: 0.2764543,
    inclination: 7.36515,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251898',
      },
      {
        userName: 'aika',
        HNames: 'H258025, H282391',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269422',
      },
    ],
  },
  {
    provisionalCode: '2017 HB111',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 3.11678,
    eccentricity: 0.0950963,
    inclination: 8.36013,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H282946, H283862, H289391, H310621',
      },
    ],
  },
  {
    provisionalCode: '2019 WP35',
    note: '',
    absoluteMagnitude: 18.0,
    semimajorAxis: 2.93326,
    eccentricity: 0.029036,
    inclination: 12.56934,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H252406',
      },
      {
        userName: 'kn1cht',
        HNames: 'H252406',
      },
      {
        userName: 'もしもス',
        HNames: 'H252406',
      },
      {
        userName: 'れお',
        HNames: 'H256096',
      },
      {
        userName: 's.ozawa',
        HNames: 'H304401, H304661',
      },
    ],
  },
  {
    provisionalCode: '2016 QN158',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.26,
    semimajorAxis: 41.03594,
    eccentricity: 0.1209962,
    inclination: 21.48261,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H258697, H258708, H312025',
      },
    ],
  },
  {
    provisionalCode: '2016 RN100',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.74,
    semimajorAxis: 39.61929,
    eccentricity: 0.1769061,
    inclination: 6.0816,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259327',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H312225',
      },
    ],
  },
  {
    provisionalCode: '2016 RO100',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.32,
    semimajorAxis: 51.12788,
    eccentricity: 0.278736,
    inclination: 6.60341,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H229553',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H260045, H312235',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260045',
      },
    ],
  },
  {
    provisionalCode: '2016 WQ85',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.99347,
    eccentricity: 0.1464575,
    inclination: 27.9134,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267476, H279330',
      },
      {
        userName: 'ms',
        HNames: 'H267476, H279294, H279330',
      },
    ],
  },
  {
    provisionalCode: '2016 WR85',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 4.45034,
    eccentricity: 0.443118,
    inclination: 8.82544,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273243, H273342',
      },
      {
        userName: 'もしもス',
        HNames: 'H273342',
      },
      {
        userName: 'まるガス',
        HNames: 'H307390',
      },
    ],
  },
  {
    provisionalCode: '2016 YQ40',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 3.08817,
    eccentricity: 0.0056816,
    inclination: 10.95407,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276875',
      },
      {
        userName: 'れお',
        HNames: 'H282990',
      },
      {
        userName: 'arda',
        HNames: 'H295914',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312581, H312589',
      },
    ],
  },
  {
    provisionalCode: '2016 YR40',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.19625,
    eccentricity: 0.102129,
    inclination: 8.76816,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H287609',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305202',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313498',
      },
    ],
  },
  {
    provisionalCode: '2017 AF64',
    note: '',
    absoluteMagnitude: 19.69,
    semimajorAxis: 2.78621,
    eccentricity: 0.1650751,
    inclination: 7.65125,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H281662',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287969',
      },
      {
        userName: 'れお',
        HNames: 'H292193',
      },
      {
        userName: 'sadap',
        HNames: 'H309711',
      },
    ],
  },
  {
    provisionalCode: '2017 AG64',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 3.18237,
    eccentricity: 0.0526536,
    inclination: 7.92755,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248145, H294576',
      },
      {
        userName: 'ko',
        HNames: 'H275694',
      },
    ],
  },
  {
    provisionalCode: '2017 AH64',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 3.1458,
    eccentricity: 0.0442018,
    inclination: 7.44659,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H271601',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288021',
      },
      {
        userName: 'zorome',
        HNames: 'H294972',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312290, H312331',
      },
    ],
  },
  {
    provisionalCode: '2017 AJ64',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 3.01021,
    eccentricity: 0.1346913,
    inclination: 8.23136,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H282063',
      },
      {
        userName: 'れお',
        HNames: 'H282966',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ237',
    note: '',
    absoluteMagnitude: 19.35,
    semimajorAxis: 2.8595,
    eccentricity: 0.0714234,
    inclination: 8.76243,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249830',
      },
    ],
  },
  {
    provisionalCode: '2017 BK237',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 3.21726,
    eccentricity: 0.0516805,
    inclination: 13.58162,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249890',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311665',
      },
    ],
  },
  {
    provisionalCode: '2017 BL237',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.17492,
    eccentricity: 0.0536484,
    inclination: 7.80235,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250340',
      },
      {
        userName: 'kn1cht',
        HNames: 'H250340',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H312080',
      },
    ],
  },
  {
    provisionalCode: '2017 BM237',
    note: '',
    absoluteMagnitude: 20.83,
    semimajorAxis: 2.88696,
    eccentricity: 0.0844175,
    inclination: 7.09241,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251155',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251155',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271059',
      },
      {
        userName: 'kn1cht',
        HNames: 'H271059',
      },
    ],
  },
  {
    provisionalCode: '2017 BN237',
    note: '',
    absoluteMagnitude: 17.65,
    semimajorAxis: 3.17451,
    eccentricity: 0.0585837,
    inclination: 8.03061,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259068',
      },
      {
        userName: 'もしもス',
        HNames: 'H259068',
      },
      {
        userName: 'れお',
        HNames: 'H259068',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H312325',
      },
    ],
  },
  {
    provisionalCode: '2017 BO237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.52,
    semimajorAxis: 69.42143,
    eccentricity: 0.6628512,
    inclination: 13.69673,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H280384, H280385, H280388',
      },
    ],
  },
  {
    provisionalCode: '2017 BP237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.99,
    semimajorAxis: 46.38597,
    eccentricity: 0.1861131,
    inclination: 10.854,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'れお',
        HNames: 'H283071',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H309840',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.3,
    semimajorAxis: 40.08386,
    eccentricity: 0.0515093,
    inclination: 17.76767,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H283486, H283507',
      },
    ],
  },
  {
    provisionalCode: '2017 BR237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.27,
    semimajorAxis: 42.77295,
    eccentricity: 0.159409,
    inclination: 15.40104,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H240126',
      },
      {
        userName: 'もしもス',
        HNames: 'H285817',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H309836, H309837',
      },
    ],
  },
  {
    provisionalCode: '2017 BS237',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.33,
    semimajorAxis: 74.78429,
    eccentricity: 0.5329859,
    inclination: 19.63265,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H280362',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H309795, H309796',
      },
    ],
  },
  {
    provisionalCode: '2017 BT237',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.33,
    semimajorAxis: 5.2157,
    eccentricity: 0.0836951,
    inclination: 19.53111,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266435',
      },
      {
        userName: 'もしもス',
        HNames: 'H266435',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313395',
      },
    ],
  },
  {
    provisionalCode: '2017 BU237',
    note: '',
    absoluteMagnitude: 17.83,
    semimajorAxis: 3.35676,
    eccentricity: 0.0786689,
    inclination: 9.35599,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275888',
      },
      {
        userName: 'ほたる',
        HNames: 'H284888, H284895',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H312192',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312195',
      },
    ],
  },
  {
    provisionalCode: '2017 BV237',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 2.63267,
    eccentricity: 0.1371131,
    inclination: 12.57492,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248130',
      },
      {
        userName: 'zorome',
        HNames: 'H248130',
      },
      {
        userName: 'ほたる',
        HNames: 'H289213',
      },
    ],
  },
  {
    provisionalCode: '2017 BW237',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 2.26344,
    eccentricity: 0.1406068,
    inclination: 5.49391,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251732',
      },
      {
        userName: 'nasiar99',
        HNames: 'H274797',
      },
      {
        userName: 'apollo18',
        HNames: 'H310956',
      },
    ],
  },
  {
    provisionalCode: '2017 BX237',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.19,
    semimajorAxis: 5.24094,
    eccentricity: 0.0489896,
    inclination: 18.63006,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251847',
      },
      {
        userName: 'もしもス',
        HNames: 'H251847',
      },
      {
        userName: 'aika',
        HNames: 'H262599',
      },
      {
        userName: '42',
        HNames: 'H262599',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312313',
      },
    ],
  },
  {
    provisionalCode: '2017 BY237',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.80658,
    eccentricity: 0.1143794,
    inclination: 16.31421,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233452',
      },
      {
        userName: 'aika',
        HNames: 'H271713',
      },
      {
        userName: 's.ozawa',
        HNames: 'H309881, H310659',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ237',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.23823,
    eccentricity: 0.2249174,
    inclination: 10.89005,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H262553',
      },
      {
        userName: 'ko',
        HNames: 'H276207',
      },
      {
        userName: 'ほたる',
        HNames: 'H287193, H289234',
      },
    ],
  },
  {
    provisionalCode: '2017 BA238',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 2.30199,
    eccentricity: 0.067077,
    inclination: 7.21089,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265513',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288016',
      },
      {
        userName: 'arda',
        HNames: 'H295915',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312582, H312590',
      },
    ],
  },
  {
    provisionalCode: '2017 BB238',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 3.09726,
    eccentricity: 0.0746695,
    inclination: 12.48992,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265605',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271163',
      },
      {
        userName: 'arda',
        HNames: 'H295913',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312583, H312591',
      },
    ],
  },
  {
    provisionalCode: '2017 BC238',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.76,
    semimajorAxis: 5.17946,
    eccentricity: 0.0578792,
    inclination: 19.04717,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267659, H283618',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267659',
      },
      {
        userName: 'もしもス',
        HNames: 'H283618',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312038',
      },
    ],
  },
  {
    provisionalCode: '2017 BD238',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.07305,
    eccentricity: 0.1886564,
    inclination: 7.33589,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H273593',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273593',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H273593',
      },
      {
        userName: 'nasiar99',
        HNames: 'H273593',
      },
      {
        userName: 'sasaki',
        HNames: 'H311979',
      },
    ],
  },
  {
    provisionalCode: '2017 BE238',
    note: '',
    absoluteMagnitude: 20.11,
    semimajorAxis: 3.16776,
    eccentricity: 0.0699768,
    inclination: 9.22964,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H279219',
      },
      {
        userName: 'arda',
        HNames: 'H279219',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313666',
      },
    ],
  },
  {
    provisionalCode: '2017 BF238',
    note: '',
    absoluteMagnitude: 21.77,
    semimajorAxis: 3.07544,
    eccentricity: 0.2636617,
    inclination: 9.49748,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279406',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313668',
      },
    ],
  },
  {
    provisionalCode: '2017 BG238',
    note: '',
    absoluteMagnitude: 21.08,
    semimajorAxis: 2.24707,
    eccentricity: 0.1003007,
    inclination: 6.02868,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H278486',
      },
      {
        userName: 'ほたる',
        HNames: 'H280590',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305383',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311563',
      },
    ],
  },
  {
    provisionalCode: '2017 BK238',
    note: '',
    absoluteMagnitude: 20.45,
    semimajorAxis: 2.35643,
    eccentricity: 0.0660832,
    inclination: 6.14871,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241957, H242010',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241957',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273142, H314840',
      },
    ],
  },
  {
    provisionalCode: '2017 BL238',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.44766,
    eccentricity: 0.0171654,
    inclination: 9.49107,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251775',
      },
      {
        userName: '42',
        HNames: 'H262624, H262667',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312966, H312973',
      },
    ],
  },
  {
    provisionalCode: '2017 BM238',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.38602,
    eccentricity: 0.1899173,
    inclination: 8.66575,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262320',
      },
      {
        userName: '42',
        HNames: 'H262320',
      },
      {
        userName: 'れお',
        HNames: 'H262320',
      },
      {
        userName: 'arda',
        HNames: 'H282654',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311750',
      },
    ],
  },
  {
    provisionalCode: '2017 BN238',
    note: '',
    absoluteMagnitude: 20.25,
    semimajorAxis: 3.04623,
    eccentricity: 0.0409816,
    inclination: 9.34318,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H265413',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267726',
      },
      {
        userName: '42',
        HNames: 'H267908',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310879',
      },
    ],
  },
  {
    provisionalCode: '2017 BO238',
    note: '',
    absoluteMagnitude: 18.18,
    semimajorAxis: 3.10832,
    eccentricity: 0.0487137,
    inclination: 21.07779,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H272781',
      },
      {
        userName: 'もしもス',
        HNames: 'H272781',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282001',
      },
      {
        userName: 'れお',
        HNames: 'H282001',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313213',
      },
    ],
  },
  {
    provisionalCode: '2017 BP238',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 3.0399,
    eccentricity: 0.1262713,
    inclination: 15.5452,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H274123',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H278555',
      },
      {
        userName: 'aika',
        HNames: 'H278555',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312471',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ238',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.81,
    semimajorAxis: 5.37924,
    eccentricity: 0.0148699,
    inclination: 8.13727,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272421',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313612',
      },
    ],
  },
  {
    provisionalCode: '2017 BR238',
    note: '',
    absoluteMagnitude: 21.01,
    semimajorAxis: 3.11481,
    eccentricity: 0.3236841,
    inclination: 9.26257,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H281273',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288068',
      },
      {
        userName: 'arda',
        HNames: 'H295910',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312588',
      },
    ],
  },
  {
    provisionalCode: '2017 BS238',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.59789,
    eccentricity: 0.2094749,
    inclination: 7.70128,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272416',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313611',
      },
    ],
  },
  {
    provisionalCode: '2017 BT238',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 3.47342,
    eccentricity: 0.0924563,
    inclination: 12.24904,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241956',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241956, H283881',
      },
      {
        userName: 'れお',
        HNames: 'H283881',
      },
    ],
  },
  {
    provisionalCode: '2017 BU238',
    note: '',
    absoluteMagnitude: 20.96,
    semimajorAxis: 2.27358,
    eccentricity: 0.1332628,
    inclination: 6.33453,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276752',
      },
      {
        userName: 'もしもス',
        HNames: 'H276752',
      },
      {
        userName: 'れお',
        HNames: 'H279504',
      },
    ],
  },
  {
    provisionalCode: '2017 BV238',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.22,
    semimajorAxis: 5.22088,
    eccentricity: 0.1345838,
    inclination: 8.97203,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H281219',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313802',
      },
    ],
  },
  {
    provisionalCode: '2017 BW238',
    note: 'Hilda',
    absoluteMagnitude: 18.71,
    semimajorAxis: 3.9861,
    eccentricity: 0.2498419,
    inclination: 7.98325,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H281297',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311383, H313822',
      },
    ],
  },
  {
    provisionalCode: '2017 BX238',
    note: '',
    absoluteMagnitude: 21.77,
    semimajorAxis: 2.95366,
    eccentricity: 0.2539702,
    inclination: 7.95123,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H281937',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313837',
      },
    ],
  },
  {
    provisionalCode: '2017 BA239',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.04385,
    eccentricity: 0.0761667,
    inclination: 8.94645,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275292',
      },
      {
        userName: 'もしもス',
        HNames: 'H275292',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313215',
      },
      {
        userName: 'ms',
        HNames: 'H315455, H315456',
      },
    ],
  },
  {
    provisionalCode: '2017 BB239',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 3.11733,
    eccentricity: 0.0658145,
    inclination: 9.86405,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H275290',
      },
      {
        userName: 'れお',
        HNames: 'H280095',
      },
    ],
  },
  {
    provisionalCode: '2017 BC239',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 2.65081,
    eccentricity: 0.0812443,
    inclination: 7.61502,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H283562, H286001',
      },
      {
        userName: 'ms',
        HNames: 'H316691',
      },
    ],
  },
  {
    provisionalCode: '2017 BD239',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.88795,
    eccentricity: 0.0992481,
    inclination: 12.98811,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248188',
      },
      {
        userName: 'れお',
        HNames: 'H248188',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268947',
      },
      {
        userName: 'もしもス',
        HNames: 'H268947',
      },
      {
        userName: 'arda',
        HNames: 'H268947',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311841',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ56',
    note: '',
    absoluteMagnitude: 20.53,
    semimajorAxis: 3.17163,
    eccentricity: 0.0501925,
    inclination: 8.88596,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247876, H311643',
      },
      {
        userName: 'れお',
        HNames: 'H247876',
      },
      {
        userName: 'zorome',
        HNames: 'H247876',
      },
    ],
  },
  {
    provisionalCode: '2017 CR56',
    note: '',
    absoluteMagnitude: 20.13,
    semimajorAxis: 2.68622,
    eccentricity: 0.1446056,
    inclination: 7.53468,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261169',
      },
      {
        userName: 'nasiar99',
        HNames: 'H261169',
      },
      {
        userName: 'aika',
        HNames: 'H296890',
      },
    ],
  },
  {
    provisionalCode: '2017 CS56',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.2,
    semimajorAxis: 5.19736,
    eccentricity: 0.0967477,
    inclination: 8.68185,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261854, H283068',
      },
      {
        userName: 'もしもス',
        HNames: 'H261854',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H312350',
      },
    ],
  },
  {
    provisionalCode: '2017 CT56',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.00261,
    eccentricity: 0.0317342,
    inclination: 6.83747,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H262575',
      },
      {
        userName: '42',
        HNames: 'H262575',
      },
      {
        userName: 'ko',
        HNames: 'H264175',
      },
      {
        userName: 'もしもス',
        HNames: 'H276929',
      },
    ],
  },
  {
    provisionalCode: '2017 CU56',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.44,
    semimajorAxis: 5.14239,
    eccentricity: 0.0730566,
    inclination: 27.32456,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266596, H273376',
      },
      {
        userName: 'もしもス',
        HNames: 'H266596',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273376',
      },
    ],
  },
  {
    provisionalCode: '2017 CV56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.96,
    semimajorAxis: 43.48311,
    eccentricity: 0.0948646,
    inclination: 18.09534,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286424, H286437, H286438',
      },
    ],
  },
  {
    provisionalCode: '2017 CW56',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 3.17282,
    eccentricity: 0.0877524,
    inclination: 9.41099,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263128',
      },
      {
        userName: 'もしもス',
        HNames: 'H263128',
      },
      {
        userName: '42',
        HNames: 'H263128',
      },
    ],
  },
  {
    provisionalCode: '2017 CX56',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 3.074,
    eccentricity: 0.1657066,
    inclination: 9.8235,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263129',
      },
      {
        userName: 'もしもス',
        HNames: 'H263129',
      },
      {
        userName: '42',
        HNames: 'H263129',
      },
    ],
  },
  {
    provisionalCode: '2017 CY56',
    note: '',
    absoluteMagnitude: 21.2,
    semimajorAxis: 2.30237,
    eccentricity: 0.1643806,
    inclination: 6.7916,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263168',
      },
      {
        userName: '42',
        HNames: 'H263168',
      },
      {
        userName: 'もしもス',
        HNames: 'H263168',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ56',
    note: '',
    absoluteMagnitude: 19.63,
    semimajorAxis: 2.73602,
    eccentricity: 0.1630766,
    inclination: 8.42551,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266594',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H266594',
      },
    ],
  },
  {
    provisionalCode: '2017 CA57',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.97859,
    eccentricity: 0.0309417,
    inclination: 10.77557,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259121, H268399',
      },
      {
        userName: '42',
        HNames: 'H265883',
      },
      {
        userName: 'arda',
        HNames: 'H293582',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311823',
      },
    ],
  },
  {
    provisionalCode: '2017 CB57',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 3.01325,
    eccentricity: 0.2312858,
    inclination: 7.33279,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276836',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283195',
      },
      {
        userName: 'れお',
        HNames: 'H283195',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313769',
      },
    ],
  },
  {
    provisionalCode: '2017 CC57',
    note: '',
    absoluteMagnitude: 18.42,
    semimajorAxis: 2.90853,
    eccentricity: 0.0871064,
    inclination: 10.76925,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260129',
      },
      {
        userName: '42',
        HNames: 'H262633',
      },
      {
        userName: 'ko',
        HNames: 'H264177',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312853, H312869',
      },
    ],
  },
  {
    provisionalCode: '2017 CD57',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.64,
    semimajorAxis: 5.17283,
    eccentricity: 0.0695447,
    inclination: 10.17535,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H276322, H304615',
      },
      {
        userName: 'aika',
        HNames: 'H276322',
      },
    ],
  },
  {
    provisionalCode: '2017 CF57',
    note: '',
    absoluteMagnitude: 18.92,
    semimajorAxis: 3.23027,
    eccentricity: 0.0977117,
    inclination: 15.3071,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248117',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262347, H282725',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312395, H312405',
      },
    ],
  },
  {
    provisionalCode: '2017 CG57',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 3.12928,
    eccentricity: 0.0326062,
    inclination: 7.38134,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262348',
      },
      {
        userName: 'aika',
        HNames: 'H262561',
      },
      {
        userName: 'ほたる',
        HNames: 'H280621',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312309',
      },
    ],
  },
  {
    provisionalCode: '2017 CH57',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 2.57132,
    eccentricity: 0.2070633,
    inclination: 6.80517,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262354',
      },
      {
        userName: 'aika',
        HNames: 'H262581',
      },
      {
        userName: 'ko',
        HNames: 'H264174',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312315',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ57',
    note: '',
    absoluteMagnitude: 20.87,
    semimajorAxis: 2.45999,
    eccentricity: 0.147016,
    inclination: 11.22364,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H262708, H283013',
      },
      {
        userName: '42',
        HNames: 'H262708',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310880',
      },
    ],
  },
  {
    provisionalCode: '2017 CK57',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 3.13158,
    eccentricity: 0.0363701,
    inclination: 10.16133,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H270544, H278727, H281633',
      },
    ],
  },
  {
    provisionalCode: '2017 CM57',
    note: '',
    absoluteMagnitude: 17.97,
    semimajorAxis: 3.22471,
    eccentricity: 0.2303619,
    inclination: 8.72505,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H276968',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305289',
      },
      {
        userName: 'apollo18',
        HNames: 'H310974',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311420',
      },
    ],
  },
  {
    provisionalCode: '2017 CN57',
    note: '',
    absoluteMagnitude: 22.76,
    semimajorAxis: 2.21181,
    eccentricity: 0.1019776,
    inclination: 5.91393,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267333',
      },
      {
        userName: 'aika',
        HNames: 'H267333',
      },
      {
        userName: 'kn1cht',
        HNames: 'H307394',
      },
    ],
  },
  {
    provisionalCode: '2017 CO57',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.09642,
    eccentricity: 0.096231,
    inclination: 13.23522,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263123',
      },
      {
        userName: 'もしもス',
        HNames: 'H263123',
      },
      {
        userName: '42',
        HNames: 'H263123',
      },
    ],
  },
  {
    provisionalCode: '2017 CP57',
    note: '',
    absoluteMagnitude: 20.9,
    semimajorAxis: 2.64805,
    eccentricity: 0.253655,
    inclination: 6.4556,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262587',
      },
      {
        userName: 'aika',
        HNames: 'H262587',
      },
      {
        userName: '42',
        HNames: 'H262587',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ57',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 3.14139,
    eccentricity: 0.157098,
    inclination: 15.80036,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H259108',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259108',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259108',
      },
    ],
  },
  {
    provisionalCode: '2017 CR57',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.54,
    semimajorAxis: 5.25047,
    eccentricity: 0.1055772,
    inclination: 12.94112,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263167',
      },
      {
        userName: '42',
        HNames: 'H263167',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313365, H313371',
      },
    ],
  },
  {
    provisionalCode: '2017 CS57',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.94445,
    eccentricity: 0.1326925,
    inclination: 8.56358,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H263144, H263172',
      },
      {
        userName: 'aika',
        HNames: 'H263172',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312491, H312506',
      },
    ],
  },
  {
    provisionalCode: '2017 CT57',
    note: '',
    absoluteMagnitude: 19.97,
    semimajorAxis: 3.0959,
    eccentricity: 0.1027433,
    inclination: 9.34568,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H282931',
      },
      {
        userName: 'aika',
        HNames: 'H282931',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H282931',
      },
    ],
  },
  {
    provisionalCode: '2017 CU57',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 3.29807,
    eccentricity: 0.1928907,
    inclination: 7.46273,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290929',
      },
      {
        userName: 'aika',
        HNames: 'H290929',
      },
      {
        userName: 'arda',
        HNames: 'H290929',
      },
    ],
  },
  {
    provisionalCode: '2017 DS165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.45,
    semimajorAxis: 40.7382,
    eccentricity: 0.0560589,
    inclination: 14.37393,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H284487',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310412, H310417, H310435',
      },
    ],
  },
  {
    provisionalCode: '2017 DT165',
    note: '',
    absoluteMagnitude: 21.07,
    semimajorAxis: 2.56456,
    eccentricity: 0.1265834,
    inclination: 8.66186,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251723',
      },
      {
        userName: 'aika',
        HNames: 'H251723',
      },
      {
        userName: 'arda',
        HNames: 'H251723',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251723, H308791',
      },
    ],
  },
  {
    provisionalCode: '2017 DU165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 54.06571,
    eccentricity: 0.5182248,
    inclination: 5.36435,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246757',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246757',
      },
    ],
  },
  {
    provisionalCode: '2017 DV165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.58,
    semimajorAxis: 42.33468,
    eccentricity: 0.0375931,
    inclination: 5.23778,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243755',
      },
    ],
  },
  {
    provisionalCode: '2017 DW165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.01,
    semimajorAxis: 45.23451,
    eccentricity: 0.1847955,
    inclination: 9.54381,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H244511',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244511',
      },
    ],
  },
  {
    provisionalCode: '2017 DX165',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.62,
    semimajorAxis: 44.3194,
    eccentricity: 0.1089531,
    inclination: 3.31108,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261518, H261826',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H261518',
      },
    ],
  },
  {
    provisionalCode: '2017 DA166',
    note: '',
    absoluteMagnitude: 18.57,
    semimajorAxis: 3.51502,
    eccentricity: 0.2604643,
    inclination: 15.51057,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220780',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H220780',
      },
      {
        userName: 'apollo18',
        HNames: 'H220780',
      },
      {
        userName: 'arda',
        HNames: 'H267273',
      },
      {
        userName: 'ko',
        HNames: 'H267746',
      },
    ],
  },
  {
    provisionalCode: '2017 DB166',
    note: '',
    absoluteMagnitude: 18.46,
    semimajorAxis: 3.41251,
    eccentricity: 0.0427932,
    inclination: 7.60515,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H253568, H303814',
      },
      {
        userName: 'aika',
        HNames: 'H253568, H292717',
      },
      {
        userName: 'nasiar99',
        HNames: 'H303814',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311255',
      },
    ],
  },
  {
    provisionalCode: '2017 DC166',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.03128,
    eccentricity: 0.0772689,
    inclination: 8.01193,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H252855',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266676',
      },
      {
        userName: 'えでぃ',
        HNames: 'H266676',
      },
      {
        userName: 'れお',
        HNames: 'H266676',
      },
      {
        userName: 'arda',
        HNames: 'H266676',
      },
    ],
  },
  {
    provisionalCode: '2017 DD166',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 2.96959,
    eccentricity: 0.0783137,
    inclination: 6.40367,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H268133',
      },
      {
        userName: 'arda',
        HNames: 'H268133',
      },
      {
        userName: 'aika',
        HNames: 'H268133',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268137',
      },
    ],
  },
  {
    provisionalCode: '2017 DE166',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.18661,
    eccentricity: 0.0427133,
    inclination: 8.52999,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286650',
      },
      {
        userName: 'nasiar99',
        HNames: 'H290861',
      },
      {
        userName: 'arda',
        HNames: 'H291559',
      },
      {
        userName: 'apollo18',
        HNames: 'H310957',
      },
    ],
  },
  {
    provisionalCode: '2017 DF166',
    note: '',
    absoluteMagnitude: 20.28,
    semimajorAxis: 2.71581,
    eccentricity: 0.1455961,
    inclination: 13.59284,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275778',
      },
      {
        userName: 'aika',
        HNames: 'H275778',
      },
    ],
  },
  {
    provisionalCode: '2017 DG166',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 3.02322,
    eccentricity: 0.0577986,
    inclination: 9.69325,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H265403',
      },
      {
        userName: 'aika',
        HNames: 'H265403',
      },
      {
        userName: 'れお',
        HNames: 'H265403',
      },
    ],
  },
  {
    provisionalCode: '2017 DH166',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.99439,
    eccentricity: 0.1442048,
    inclination: 8.24782,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260791, H282109',
      },
      {
        userName: 'aika',
        HNames: 'H260791',
      },
      {
        userName: 'れお',
        HNames: 'H260791',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282109',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ166',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.38544,
    eccentricity: 0.0229718,
    inclination: 6.79893,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260258',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267358',
      },
      {
        userName: 'えでぃ',
        HNames: 'H280923',
      },
    ],
  },
  {
    provisionalCode: '2017 DK166',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 3.00116,
    eccentricity: 0.1557974,
    inclination: 6.40087,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H253569, H316901',
      },
      {
        userName: 'aika',
        HNames: 'H253569',
      },
      {
        userName: 'nasiar99',
        HNames: 'H253569',
      },
    ],
  },
  {
    provisionalCode: '2017 DL166',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.07165,
    eccentricity: 0.1028714,
    inclination: 9.61637,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H279395',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279395',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H279395',
      },
    ],
  },
  {
    provisionalCode: '2017 EL53',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.57309,
    eccentricity: 0.086378,
    inclination: 12.65126,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H246376',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246376',
      },
    ],
  },
  {
    provisionalCode: '2017 EM53',
    note: '',
    absoluteMagnitude: 20.99,
    semimajorAxis: 2.33902,
    eccentricity: 0.2101064,
    inclination: 5.32493,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H220765',
      },
      {
        userName: 'apollo18',
        HNames: 'H220765',
      },
      {
        userName: 'kn1cht',
        HNames: 'H220765',
      },
      {
        userName: 'nasiar99',
        HNames: 'H301082',
      },
    ],
  },
  {
    provisionalCode: '2017 EN53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.27,
    semimajorAxis: 38.3594,
    eccentricity: 0.3111696,
    inclination: 7.81003,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H286365, H286504',
      },
    ],
  },
  {
    provisionalCode: '2017 EO53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.52,
    semimajorAxis: 46.84532,
    eccentricity: 0.2297228,
    inclination: 24.84674,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H285673, H285677, H285679',
      },
    ],
  },
  {
    provisionalCode: '2017 EP53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.13,
    semimajorAxis: 48.00888,
    eccentricity: 0.2712399,
    inclination: 25.92306,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H287032',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287032',
      },
    ],
  },
  {
    provisionalCode: '2017 EQ53',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.9797,
    eccentricity: 0.1893615,
    inclination: 5.33919,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267315, H305131',
      },
      {
        userName: 'aika',
        HNames: 'H288667',
      },
    ],
  },
  {
    provisionalCode: '2017 ER53',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 3.38624,
    eccentricity: 0.0346374,
    inclination: 8.79008,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270256, H300821',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270256',
      },
    ],
  },
  {
    provisionalCode: '2017 ES53',
    note: '',
    absoluteMagnitude: 18.64,
    semimajorAxis: 3.17376,
    eccentricity: 0.261999,
    inclination: 20.04989,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248300',
      },
      {
        userName: 'ほたる',
        HNames: 'H284900',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312622, H312631',
      },
    ],
  },
  {
    provisionalCode: '2017 ET53',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 3.04869,
    eccentricity: 0.0611191,
    inclination: 6.6136,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246985',
      },
      {
        userName: 'nasiar99',
        HNames: 'H246985',
      },
      {
        userName: 'aika',
        HNames: 'H287462',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311821',
      },
    ],
  },
  {
    provisionalCode: '2017 EU53',
    note: 'Phocaea',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.42844,
    eccentricity: 0.027245,
    inclination: 14.31365,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246008, H295169',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246008',
      },
      {
        userName: 'もしもス',
        HNames: 'H295169',
      },
    ],
  },
  {
    provisionalCode: '2017 EV53',
    note: '',
    absoluteMagnitude: 20.72,
    semimajorAxis: 2.89228,
    eccentricity: 0.1971028,
    inclination: 14.45168,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259590, H288927',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312576',
      },
    ],
  },
  {
    provisionalCode: '2017 EW53',
    note: '',
    absoluteMagnitude: 18.18,
    semimajorAxis: 3.17311,
    eccentricity: 0.1916415,
    inclination: 10.73151,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H261410',
      },
      {
        userName: '42',
        HNames: 'H261410',
      },
      {
        userName: 'aika',
        HNames: 'H262841',
      },
      {
        userName: 'apollo18',
        HNames: 'H310992',
      },
      {
        userName: 'theoria',
        HNames: 'H312170',
      },
    ],
  },
  {
    provisionalCode: '2017 EX53',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.71361,
    eccentricity: 0.1088855,
    inclination: 12.22223,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H285850',
      },
      {
        userName: 'aika',
        HNames: 'H285850',
      },
      {
        userName: 'arda',
        HNames: 'H285850',
      },
      {
        userName: 'nasiar99',
        HNames: 'H285850',
      },
    ],
  },
  {
    provisionalCode: '2017 EY53',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 3.22435,
    eccentricity: 0.057901,
    inclination: 7.63575,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286131, H295167',
      },
      {
        userName: 'arda',
        HNames: 'H286131',
      },
      {
        userName: 'もしもス',
        HNames: 'H295167',
      },
    ],
  },
  {
    provisionalCode: '2017 EZ53',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 3.04181,
    eccentricity: 0.1409909,
    inclination: 10.49574,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286531',
      },
      {
        userName: 'nasiar99',
        HNames: 'H286531',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H286531',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312276',
      },
    ],
  },
  {
    provisionalCode: '2017 EA54',
    note: '確定番号(622712): 2014 QO472と同定',
    absoluteMagnitude: 17.47,
    semimajorAxis: 2.92062,
    eccentricity: 0.1476629,
    inclination: 6.8701,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'aika',
        HNames: 'H286631, H291125',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H291125',
      },
    ],
  },
  {
    provisionalCode: '2017 FY237',
    note: '',
    absoluteMagnitude: 22.42,
    semimajorAxis: 2.62848,
    eccentricity: 0.2164299,
    inclination: 12.08995,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267414',
      },
      {
        userName: 'aika',
        HNames: 'H267414',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ237',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 2.99925,
    eccentricity: 0.0348656,
    inclination: 9.62023,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H228563',
      },
      {
        userName: 'arda',
        HNames: 'H288307',
      },
      {
        userName: 'aika',
        HNames: 'H294806',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312848',
      },
    ],
  },
  {
    provisionalCode: '2017 FB238',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 3.13975,
    eccentricity: 0.1888336,
    inclination: 17.06298,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245466, H245473',
      },
      {
        userName: 'aika',
        HNames: 'H245473',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245473',
      },
    ],
  },
  {
    provisionalCode: '2017 FC238',
    note: '',
    absoluteMagnitude: 20.86,
    semimajorAxis: 2.37572,
    eccentricity: 0.0656861,
    inclination: 5.98752,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245463',
      },
      {
        userName: 'aika',
        HNames: 'H279371',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310780',
      },
    ],
  },
  {
    provisionalCode: '2017 FD238',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.36039,
    eccentricity: 0.1208187,
    inclination: 5.54503,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245410',
      },
      {
        userName: 'nasiar99',
        HNames: 'H245410',
      },
      {
        userName: 'sasaki',
        HNames: 'H310272',
      },
    ],
  },
  {
    provisionalCode: '2017 FE238',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 3.12771,
    eccentricity: 0.0714668,
    inclination: 9.4859,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H244455',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244455',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244455, H298946',
      },
    ],
  },
  {
    provisionalCode: '2017 FF238',
    note: '',
    absoluteMagnitude: 21.31,
    semimajorAxis: 2.36925,
    eccentricity: 0.1552898,
    inclination: 5.4549,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249059',
      },
      {
        userName: 'aika',
        HNames: 'H249059',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312083',
      },
    ],
  },
  {
    provisionalCode: '2017 FG238',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 2.61185,
    eccentricity: 0.1650878,
    inclination: 6.29518,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250707, H276039',
      },
      {
        userName: 'れお',
        HNames: 'H276039',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276039',
      },
    ],
  },
  {
    provisionalCode: '2017 FH238',
    note: '',
    absoluteMagnitude: 20.43,
    semimajorAxis: 2.69044,
    eccentricity: 0.1336612,
    inclination: 12.45697,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H264880, H268889',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312761',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ238',
    note: '',
    absoluteMagnitude: 20.65,
    semimajorAxis: 2.90105,
    eccentricity: 0.1267717,
    inclination: 8.44823,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249260',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249260',
      },
      {
        userName: 'nasiar99',
        HNames: 'H249260',
      },
      {
        userName: 'aika',
        HNames: 'H249260',
      },
    ],
  },
  {
    provisionalCode: '2017 FK238',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 3.09838,
    eccentricity: 0.0286236,
    inclination: 6.5048,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H262520',
      },
      {
        userName: 'aika',
        HNames: 'H274950, H293984',
      },
      {
        userName: 'ms',
        HNames: 'H306466',
      },
    ],
  },
  {
    provisionalCode: '2017 GK36',
    note: 'Phocaea',
    absoluteMagnitude: 21.74,
    semimajorAxis: 2.40164,
    eccentricity: 0.1585305,
    inclination: 14.79561,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247283',
      },
      {
        userName: 'aika',
        HNames: 'H247283',
      },
      {
        userName: 'nasiar99',
        HNames: 'H280884, H281590',
      },
    ],
  },
  {
    provisionalCode: '2017 GL36',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 2.99318,
    eccentricity: 0.1044581,
    inclination: 10.60609,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'arda',
        HNames: 'H270294',
      },
      {
        userName: 'aika',
        HNames: 'H294809',
      },
      {
        userName: 'apollo18',
        HNames: 'H310977',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312610, H313025',
      },
    ],
  },
  {
    provisionalCode: '2017 GM36',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 3.14358,
    eccentricity: 0.063132,
    inclination: 15.01916,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243205, H291087',
      },
      {
        userName: 'zorome',
        HNames: 'H248449',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H312191',
      },
    ],
  },
  {
    provisionalCode: '2017 GR36',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.00521,
    eccentricity: 0.1136875,
    inclination: 8.69246,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H235595',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H290503',
      },
    ],
  },
  {
    provisionalCode: '2017 HE111',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.95321,
    eccentricity: 0.0940243,
    inclination: 7.95832,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H282949, H286515, H310620, H311407',
      },
    ],
  },
  {
    provisionalCode: '2017 HF111',
    note: '',
    absoluteMagnitude: 19.89,
    semimajorAxis: 3.05279,
    eccentricity: 0.0740548,
    inclination: 10.27981,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H286511',
      },
      {
        userName: 'えでぃ',
        HNames: 'H307142, H307152, H312647',
      },
    ],
  },
  {
    provisionalCode: '2017 HG111',
    note: '',
    absoluteMagnitude: 19.57,
    semimajorAxis: 3.11015,
    eccentricity: 0.2813069,
    inclination: 15.83401,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H286513, H312645, H312670, H312675, H312683',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ111',
    note: '',
    absoluteMagnitude: 20.15,
    semimajorAxis: 2.88402,
    eccentricity: 0.1643473,
    inclination: 9.19775,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248974',
      },
      {
        userName: 'ms',
        HNames: 'H287953',
      },
    ],
  },
  {
    provisionalCode: '2017 HK111',
    note: 'Phocaea',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.40939,
    eccentricity: 0.1043157,
    inclination: 14.21216,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275046',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288323',
      },
      {
        userName: 'ms',
        HNames: 'H288323',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288958',
      },
    ],
  },
  {
    provisionalCode: '2017 HL111',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 3.04094,
    eccentricity: 0.1560899,
    inclination: 8.20608,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H288814, H313290, H313310',
      },
    ],
  },
  {
    provisionalCode: '2017 HM111',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 3.00315,
    eccentricity: 0.0350537,
    inclination: 9.0713,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242805',
      },
      {
        userName: 'kn1cht',
        HNames: 'H272465',
      },
      {
        userName: 'arda',
        HNames: 'H297818',
      },
      {
        userName: 'nasiar99',
        HNames: 'H304165',
      },
    ],
  },
  {
    provisionalCode: '2017 HN111',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.94324,
    eccentricity: 0.1861393,
    inclination: 8.21309,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H290665, H313624, H313628',
      },
    ],
  },
  {
    provisionalCode: '2017 HO111',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 3.79053,
    eccentricity: 0.4418634,
    inclination: 6.23368,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H243200',
      },
      {
        userName: 'aika',
        HNames: 'H262947, H263395',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312619',
      },
    ],
  },
  {
    provisionalCode: '2017 HP111',
    note: '',
    absoluteMagnitude: 19.77,
    semimajorAxis: 2.73961,
    eccentricity: 0.0755425,
    inclination: 7.54244,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245249',
      },
      {
        userName: 'えでぃ',
        HNames: 'H302751',
      },
      {
        userName: 'れお',
        HNames: 'H302751',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ111',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.61222,
    eccentricity: 0.134826,
    inclination: 7.6319,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H292553',
      },
      {
        userName: 'もしもス',
        HNames: 'H292553',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305026',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311051',
      },
    ],
  },
  {
    provisionalCode: '2017 HR111',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 3.32095,
    eccentricity: 0.1153608,
    inclination: 7.61059,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H288324, H313160, H313166',
      },
    ],
  },
  {
    provisionalCode: '2017 HS111',
    note: '',
    absoluteMagnitude: 20.86,
    semimajorAxis: 2.91717,
    eccentricity: 0.0443295,
    inclination: 8.64121,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H287300, H289392, H312882',
      },
    ],
  },
  {
    provisionalCode: '2017 HT111',
    note: '',
    absoluteMagnitude: 20.08,
    semimajorAxis: 3.10103,
    eccentricity: 0.0895007,
    inclination: 9.99974,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243659',
      },
      {
        userName: 'もしもス',
        HNames: 'H243659',
      },
    ],
  },
  {
    provisionalCode: '2017 HU111',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 3.24123,
    eccentricity: 0.2257728,
    inclination: 8.17154,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H243816',
      },
      {
        userName: 'もしもス',
        HNames: 'H243816',
      },
      {
        userName: 'ほたる',
        HNames: 'H269625',
      },
      {
        userName: 'nasiar99',
        HNames: 'H269625',
      },
    ],
  },
  {
    provisionalCode: '2017 HV111',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 3.40794,
    eccentricity: 0.1141852,
    inclination: 7.72581,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H308454, H314492, H314493',
      },
    ],
  },
  {
    provisionalCode: '2017 HY111',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 3.18604,
    eccentricity: 0.0786406,
    inclination: 8.19908,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H246990',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271027',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315682',
      },
    ],
  },
  {
    provisionalCode: '2017 HZ111',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.02617,
    eccentricity: 0.0829828,
    inclination: 8.86128,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H246995',
      },
      {
        userName: 'もしもス',
        HNames: 'H246995',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H246995',
      },
      {
        userName: 'jim',
        HNames: 'H307111',
      },
    ],
  },
  {
    provisionalCode: '2017 HA112',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 3.01772,
    eccentricity: 0.0258091,
    inclination: 7.96864,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243658, H314135',
      },
      {
        userName: 'もしもス',
        HNames: 'H243658',
      },
    ],
  },
  {
    provisionalCode: '2017 HB112',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.20583,
    eccentricity: 0.0706995,
    inclination: 8.4203,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H249022, H288389',
      },
      {
        userName: 'もしもス',
        HNames: 'H249022',
      },
    ],
  },
  {
    provisionalCode: '2017 HC112',
    note: '',
    absoluteMagnitude: 19.35,
    semimajorAxis: 3.12875,
    eccentricity: 0.075125,
    inclination: 9.54776,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H272176',
      },
      {
        userName: 'arda',
        HNames: 'H272176',
      },
    ],
  },
  {
    provisionalCode: '2017 HD112',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.33099,
    eccentricity: 0.1426986,
    inclination: 8.15629,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243815, H314145',
      },
      {
        userName: 'もしもス',
        HNames: 'H243815',
      },
      {
        userName: 'ほたる',
        HNames: 'H284897',
      },
    ],
  },
  {
    provisionalCode: '2017 HE112',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 2.97833,
    eccentricity: 0.0475419,
    inclination: 9.9674,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H285029',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285029',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315937',
      },
    ],
  },
  {
    provisionalCode: '2017 HF112',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.33316,
    eccentricity: 0.15022,
    inclination: 15.39656,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H285030',
      },
      {
        userName: 'ほたる',
        HNames: 'H285030',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285030',
      },
      {
        userName: 'sasaki',
        HNames: 'H301693',
      },
    ],
  },
  {
    provisionalCode: '2017 HG112',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.18,
    semimajorAxis: 5.12128,
    eccentricity: 0.1173718,
    inclination: 28.12978,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288990, H316028',
      },
      {
        userName: 'kn1cht',
        HNames: 'H288990',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305402',
      },
    ],
  },
  {
    provisionalCode: '2017 HH112',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.63847,
    eccentricity: 0.1375636,
    inclination: 13.88357,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288329, H315960',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288329',
      },
      {
        userName: 'arda',
        HNames: 'H297817',
      },
    ],
  },
  {
    provisionalCode: '2017 HJ112',
    note: '',
    absoluteMagnitude: 19.37,
    semimajorAxis: 3.06213,
    eccentricity: 0.1605722,
    inclination: 8.36519,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H290631',
      },
      {
        userName: 'kn1cht',
        HNames: 'H290631',
      },
      {
        userName: 'ms',
        HNames: 'H290631',
      },
    ],
  },
  {
    provisionalCode: '2018 HS12',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.3,
    semimajorAxis: 41.95553,
    eccentricity: 0.1844406,
    inclination: 14.83164,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H307648, H307658',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311492',
      },
    ],
  },
  {
    provisionalCode: '2019 CC30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.64,
    semimajorAxis: 43.5848,
    eccentricity: 0.0419505,
    inclination: 3.89789,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H245517, H289663, H289674, H308126',
      },
    ],
  },
  {
    provisionalCode: '2019 CD30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.38,
    semimajorAxis: 41.2686,
    eccentricity: 0.0291347,
    inclination: 16.53818,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H305610, H305615, H307833, H307902, H310299',
      },
    ],
  },
  {
    provisionalCode: '2019 CE30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.96,
    semimajorAxis: 42.70879,
    eccentricity: 0.0388851,
    inclination: 3.72333,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H245565, H289261, H289268, H289271, H309758',
      },
    ],
  },
  {
    provisionalCode: '2019 CF30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.71,
    semimajorAxis: 42.37858,
    eccentricity: 0.1376033,
    inclination: 5.98626,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286836, H286849, H286859',
      },
    ],
  },
  {
    provisionalCode: '2019 CG30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.72,
    semimajorAxis: 42.87832,
    eccentricity: 0.0605064,
    inclination: 4.83949,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H287024, H291175, H291422',
      },
    ],
  },
  {
    provisionalCode: '2019 CH30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.39,
    semimajorAxis: 45.05491,
    eccentricity: 0.1166916,
    inclination: 3.62245,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H245821, H289137',
      },
      {
        userName: 'ほたる',
        HNames: 'H249110, H276266',
      },
    ],
  },
  {
    provisionalCode: '2019 CJ30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.13,
    semimajorAxis: 43.26749,
    eccentricity: 0.0513678,
    inclination: 1.88731,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: '逆風の谷のナウシカ1',
        HNames: 'H236623',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H248485',
      },
      {
        userName: 'zorome',
        HNames: 'H308446',
      },
    ],
  },
  {
    provisionalCode: '2019 CK30',
    note: '2004 EQ95と同定: TNO, Centaur, etc.',
    absoluteMagnitude: 7.82,
    semimajorAxis: 45.70918,
    eccentricity: 0.1071747,
    inclination: 4.52201,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286807, H286816, H286824',
      },
    ],
  },
  {
    provisionalCode: '2019 CL30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.61,
    semimajorAxis: 41.98576,
    eccentricity: 0.0675314,
    inclination: 7.23872,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286961',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310073, H310075',
      },
    ],
  },
  {
    provisionalCode: '2019 CM30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 42.11929,
    eccentricity: 0.1445952,
    inclination: 2.41788,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H308589, H308597, H308601',
      },
    ],
  },
  {
    provisionalCode: '2019 CN30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.89,
    semimajorAxis: 56.50179,
    eccentricity: 0.6087061,
    inclination: 15.63724,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H306100, H306104',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H306104',
      },
    ],
  },
  {
    provisionalCode: '2019 CO30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.87,
    semimajorAxis: 41.96411,
    eccentricity: 0.2128519,
    inclination: 9.85229,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H221852',
      },
      {
        userName: 'zorome',
        HNames: 'H307209',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311580',
      },
    ],
  },
  {
    provisionalCode: '2019 CP30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.17,
    semimajorAxis: 39.13744,
    eccentricity: 0.0961481,
    inclination: 3.70217,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H245873, H276289',
      },
    ],
  },
  {
    provisionalCode: '2019 CQ30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.4,
    semimajorAxis: 43.95963,
    eccentricity: 0.0581488,
    inclination: 3.39319,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H249387',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H310244, H310245',
      },
      {
        userName: 'zorome',
        HNames: 'H311956',
      },
    ],
  },
  {
    provisionalCode: '2019 CR30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.93,
    semimajorAxis: 47.71798,
    eccentricity: 0.1031847,
    inclination: 2.61006,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H306263, H306269, H306272',
      },
    ],
  },
  {
    provisionalCode: '2019 CS30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.58,
    semimajorAxis: 44.87772,
    eccentricity: 0.067244,
    inclination: 4.43423,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H287478, H287489, H287491',
      },
    ],
  },
  {
    provisionalCode: '2019 EG7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.14,
    semimajorAxis: 44.06927,
    eccentricity: 0.077569,
    inclination: 1.74422,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'karaboku',
        HNames: 'H304760',
      },
      {
        userName: 'zorome',
        HNames: 'H307082, H307083',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311537',
      },
    ],
  },
  {
    provisionalCode: '2019 EH7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.66,
    semimajorAxis: 51.58003,
    eccentricity: 0.2375644,
    inclination: 29.33798,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229055',
      },
      {
        userName: 'yakuba',
        HNames: 'H229055',
      },
      {
        userName: 'zorome',
        HNames: 'H309048, H309265, H309266',
      },
    ],
  },
  {
    provisionalCode: '2019 EJ7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.65,
    semimajorAxis: 44.9747,
    eccentricity: 0.1200803,
    inclination: 6.97678,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H305854, H305868, H307821, H310310',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H311136',
      },
    ],
  },
  {
    provisionalCode: '2019 EK7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.22,
    semimajorAxis: 44.06495,
    eccentricity: 0.0681539,
    inclination: 1.69321,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: '逆風の谷のナウシカ1',
        HNames: 'H238996',
      },
      {
        userName: 'zorome',
        HNames: 'H306638, H306647, H307778, H307783',
      },
    ],
  },
  {
    provisionalCode: '2019 EL7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.33,
    semimajorAxis: 42.28733,
    eccentricity: 0.0152267,
    inclination: 2.79675,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H305647, H305652, H305663, H307897, H307910',
      },
    ],
  },
  {
    provisionalCode: '2019 EM7',
    note: '2002 CQ154と同定: TNO, Centaur, etc.',
    absoluteMagnitude: 6.92,
    semimajorAxis: 44.25646,
    eccentricity: 0.0512393,
    inclination: 3.2058,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H225594, H306143',
      },
      {
        userName: 'imai',
        HNames: 'H225594',
      },
      {
        userName: 's.ozawa',
        HNames: 'H290219',
      },
    ],
  },
  {
    provisionalCode: '2019 EN7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.54,
    semimajorAxis: 42.67025,
    eccentricity: 0.036851,
    inclination: 3.35049,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'やんがー',
        HNames: 'H268844',
      },
      {
        userName: 'zorome',
        HNames: 'H308163, H308164',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311493',
      },
    ],
  },
  {
    provisionalCode: '2019 EO7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.45,
    semimajorAxis: 26.48088,
    eccentricity: 0.8539316,
    inclination: 1.15031,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H310740, H310756, H310761',
      },
    ],
  },
  {
    provisionalCode: '2019 EP7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.48,
    semimajorAxis: 48.85869,
    eccentricity: 0.0966755,
    inclination: 0.94529,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H307519, H307524',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307519',
      },
    ],
  },
  {
    provisionalCode: '2019 EQ7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.53,
    semimajorAxis: 53.11003,
    eccentricity: 0.3299366,
    inclination: 6.11255,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H290636',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310126, H310128',
      },
    ],
  },
  {
    provisionalCode: '2019 ER7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.03,
    semimajorAxis: 38.39932,
    eccentricity: 0.0567049,
    inclination: 33.9768,
    oppositions: 4,
    uncertainty: 3,
    detail: [
      {
        userName: 'スミハル',
        HNames: 'H212497',
      },
      {
        userName: 'まとりょーしか',
        HNames: 'H243949',
      },
      {
        userName: 'zorome',
        HNames: 'H307081, H308477',
      },
    ],
  },
  {
    provisionalCode: '2019 ES7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.88,
    semimajorAxis: 42.70037,
    eccentricity: 0.0467506,
    inclination: 2.61626,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H242433',
      },
      {
        userName: 'zorome',
        HNames: 'H308859, H308861',
      },
    ],
  },
  {
    provisionalCode: '2019 ET7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.73,
    semimajorAxis: 42.59385,
    eccentricity: 0.0270429,
    inclination: 1.74098,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H307472, H307473, H307474',
      },
    ],
  },
  {
    provisionalCode: '2019 EU7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.55,
    semimajorAxis: 43.87467,
    eccentricity: 0.0890507,
    inclination: 3.1898,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H243964',
      },
      {
        userName: 'apollo18',
        HNames: 'H243964',
      },
      {
        userName: 'zorome',
        HNames: 'H309641, H309646',
      },
    ],
  },
  {
    provisionalCode: '2019 EV7',
    note: '',
    absoluteMagnitude: 18.08,
    semimajorAxis: 2.70979,
    eccentricity: 0.0730617,
    inclination: 4.65954,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H238752, H260172',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H277059',
      },
    ],
  },
  {
    provisionalCode: '2019 GT184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.94,
    semimajorAxis: 43.74507,
    eccentricity: 0.0680432,
    inclination: 3.98553,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H234812, H288465',
      },
    ],
  },
  {
    provisionalCode: '2019 GU184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.65,
    semimajorAxis: 43.63581,
    eccentricity: 0.0702574,
    inclination: 3.16402,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H236575, H288502',
      },
    ],
  },
  {
    provisionalCode: '2019 GV184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.24,
    semimajorAxis: 76.88641,
    eccentricity: 0.5587486,
    inclination: 11.63868,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H287129, H287130, H287132',
      },
    ],
  },
  {
    provisionalCode: '2019 GW184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.84,
    semimajorAxis: 91.66481,
    eccentricity: 0.620007,
    inclination: 5.35151,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H291256, H291259, H291261',
      },
    ],
  },
  {
    provisionalCode: '2019 GX184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.27,
    semimajorAxis: 43.75111,
    eccentricity: 0.0719936,
    inclination: 4.64002,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H288153, H290608',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310097',
      },
    ],
  },
  {
    provisionalCode: '2019 GY184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.51,
    semimajorAxis: 44.04043,
    eccentricity: 0.066997,
    inclination: 4.05617,
    oppositions: 5,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H287128, H287134, H287136',
      },
    ],
  },
  {
    provisionalCode: '2019 GZ184',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.47,
    semimajorAxis: 50.04913,
    eccentricity: 0.2919843,
    inclination: 19.29466,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H227257',
      },
      {
        userName: 'zorome',
        HNames: 'H291035',
      },
    ],
  },
  {
    provisionalCode: '2019 GA185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.78,
    semimajorAxis: 44.75817,
    eccentricity: 0.0583685,
    inclination: 3.45551,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H234738, H288227',
      },
    ],
  },
  {
    provisionalCode: '2019 GE185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.68,
    semimajorAxis: 91.58258,
    eccentricity: 0.6025124,
    inclination: 27.9062,
    oppositions: 2,
    uncertainty: 9,
    detail: [
      {
        userName: 'kuni',
        HNames: 'H256374',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H256374, H313104',
      },
    ],
  },
  {
    provisionalCode: '2019 JY144',
    note: '',
    absoluteMagnitude: 18.09,
    semimajorAxis: 2.79025,
    eccentricity: 0.016898,
    inclination: 4.99683,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: '南川',
        HNames: 'H247607',
      },
      {
        userName: 'yakuba',
        HNames: 'H250000',
      },
    ],
  },
  {
    provisionalCode: '2019 JA145',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.17,
    semimajorAxis: 39.09165,
    eccentricity: 0.1739578,
    inclination: 13.04575,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249395, H303131',
      },
      {
        userName: 'れお',
        HNames: 'H303131',
      },
    ],
  },
  {
    provisionalCode: '2019 SA247',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.12848,
    eccentricity: 0.2100935,
    inclination: 11.88241,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240972, H241010',
      },
      {
        userName: 'れお',
        HNames: 'H240972',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H312596',
      },
    ],
  },
  {
    provisionalCode: '2019 UN176',
    note: 'Mars Crosser',
    absoluteMagnitude: 21.15,
    semimajorAxis: 2.44524,
    eccentricity: 0.3202115,
    inclination: 12.77899,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H230597',
      },
      {
        userName: 'もしもス',
        HNames: 'H230597',
      },
    ],
  },
  {
    provisionalCode: '2019 UO176',
    note: '',
    absoluteMagnitude: 20.69,
    semimajorAxis: 2.7782,
    eccentricity: 0.3040053,
    inclination: 15.52976,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'guoyou sun',
        HNames: 'H228491',
      },
      {
        userName: 's.ozawa',
        HNames: 'H249506',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249506',
      },
    ],
  },
  {
    provisionalCode: '2019 UP176',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 2.98786,
    eccentricity: 0.2091512,
    inclination: 12.51951,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H207195',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213256, H305416',
      },
    ],
  },
  {
    provisionalCode: '2019 VL53',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.5366,
    eccentricity: 0.1018458,
    inclination: 13.92862,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H238667',
      },
      {
        userName: 'れお',
        HNames: 'H256437',
      },
    ],
  },
  {
    provisionalCode: '2019 VM53',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.08353,
    eccentricity: 0.1006737,
    inclination: 12.59633,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251876, H301253',
      },
      {
        userName: 's.ozawa',
        HNames: 'H251876',
      },
    ],
  },
  {
    provisionalCode: '2019 VN53',
    note: '',
    absoluteMagnitude: 19.76,
    semimajorAxis: 2.98171,
    eccentricity: 0.0498797,
    inclination: 13.81418,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H215878',
      },
      {
        userName: 'このしろ',
        HNames: 'H215878',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278750, H278784',
      },
    ],
  },
  {
    provisionalCode: '2019 VO53',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 2.61941,
    eccentricity: 0.116333,
    inclination: 17.78014,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H240881, H241622, H247529',
      },
    ],
  },
  {
    provisionalCode: '2019 WQ35',
    note: 'Mars Crosser',
    absoluteMagnitude: 23.49,
    semimajorAxis: 2.48001,
    eccentricity: 0.4137151,
    inclination: 12.52877,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H280154',
      },
      {
        userName: 'れお',
        HNames: 'H280154',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280154',
      },
    ],
  },
  {
    provisionalCode: '2019 WR35',
    note: '',
    absoluteMagnitude: 20.73,
    semimajorAxis: 2.73311,
    eccentricity: 0.0173019,
    inclination: 2.96071,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221764, H305769',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223418',
      },
      {
        userName: 'さんしろう',
        HNames: 'H236531',
      },
    ],
  },
  {
    provisionalCode: '2019 WS35',
    note: 'Phocaea',
    absoluteMagnitude: 22.48,
    semimajorAxis: 2.4772,
    eccentricity: 0.169635,
    inclination: 15.45432,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228323',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H264006',
      },
      {
        userName: 'れお',
        HNames: 'H264006, H296829',
      },
    ],
  },
  {
    provisionalCode: '2019 WT35',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.66386,
    eccentricity: 0.0900068,
    inclination: 13.66408,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H227712',
      },
      {
        userName: 'ngc',
        HNames: 'H227712',
      },
      {
        userName: 'kn1cht',
        HNames: 'H270897',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H312579',
      },
    ],
  },
  {
    provisionalCode: '2019 WU35',
    note: 'NEO (Amor)',
    absoluteMagnitude: 26.02,
    semimajorAxis: 2.29739,
    eccentricity: 0.5034786,
    inclination: 12.18725,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H255168',
      },
      {
        userName: 'れお',
        HNames: 'H255168',
      },
    ],
  },
  {
    provisionalCode: '2016 PY295',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.48,
    semimajorAxis: 48.4214,
    eccentricity: 0.2894034,
    inclination: 13.02986,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H300528',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H312237',
      },
    ],
  },
  {
    provisionalCode: '2016 WS85',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 2.7618,
    eccentricity: 0.1383424,
    inclination: 11.08182,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271346, H318026',
      },
      {
        userName: 'aika',
        HNames: 'H271346',
      },
    ],
  },
  {
    provisionalCode: '2016 WT85',
    note: '',
    absoluteMagnitude: 17.84,
    semimajorAxis: 3.37062,
    eccentricity: 0.0673899,
    inclination: 8.96274,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271669',
      },
      {
        userName: 'れお',
        HNames: 'H271669',
      },
      {
        userName: 'aika',
        HNames: 'H271669',
      },
      {
        userName: 'えでぃ',
        HNames: 'H271669',
      },
      {
        userName: 'zorome',
        HNames: 'H276989',
      },
    ],
  },
  {
    provisionalCode: '2016 WU85',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 3.01969,
    eccentricity: 0.0967085,
    inclination: 10.2587,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250699',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279514, H318032',
      },
      {
        userName: 'ほたる',
        HNames: 'H279514, H287147',
      },
      {
        userName: 'れお',
        HNames: 'H283119',
      },
    ],
  },
  {
    provisionalCode: '2016 WV85',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 3.00473,
    eccentricity: 0.0240913,
    inclination: 9.59245,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H281564',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281564',
      },
      {
        userName: 'nasiar99',
        HNames: 'H297419',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H318043',
      },
    ],
  },
  {
    provisionalCode: '2016 WW85',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.13879,
    eccentricity: 0.1444811,
    inclination: 9.54038,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287154',
      },
      {
        userName: 'ほたる',
        HNames: 'H287154',
      },
      {
        userName: 'れお',
        HNames: 'H287154',
      },
    ],
  },
  {
    provisionalCode: '2016 WX85',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 2.61309,
    eccentricity: 0.2359816,
    inclination: 9.84271,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H267501',
      },
      {
        userName: 'ほたる',
        HNames: 'H292515',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H318151',
      },
    ],
  },
  {
    provisionalCode: '2016 WY85',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.94,
    semimajorAxis: 104.67095,
    eccentricity: 0.7794303,
    inclination: 14.26798,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268954, H296229',
      },
    ],
  },
  {
    provisionalCode: '2016 YS40',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.12083,
    eccentricity: 0.1808692,
    inclination: 16.51046,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H245798',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245798, H317835',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245798',
      },
    ],
  },
  {
    provisionalCode: '2016 YT40',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.58,
    semimajorAxis: 5.08206,
    eccentricity: 0.0463264,
    inclination: 10.26978,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245762',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245762',
      },
      {
        userName: 'ほたる',
        HNames: 'H287586',
      },
    ],
  },
  {
    provisionalCode: '2016 YU40',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 3.02741,
    eccentricity: 0.0592538,
    inclination: 9.84159,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279496, H279500',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312410, H312411',
      },
    ],
  },
  {
    provisionalCode: '2016 YV40',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 3.05676,
    eccentricity: 0.0821065,
    inclination: 10.12014,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H290996',
      },
    ],
  },
  {
    provisionalCode: '2017 AL64',
    note: '',
    absoluteMagnitude: 19.69,
    semimajorAxis: 2.67821,
    eccentricity: 0.162069,
    inclination: 12.40618,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281499',
      },
      {
        userName: 'れお',
        HNames: 'H282584',
      },
      {
        userName: 'zorome',
        HNames: 'H283438, H284198',
      },
    ],
  },
  {
    provisionalCode: '2017 AM64',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.8314,
    eccentricity: 0.0977702,
    inclination: 7.99751,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ko',
        HNames: 'H281805',
      },
      {
        userName: 'れお',
        HNames: 'H290248',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290248',
      },
    ],
  },
  {
    provisionalCode: '2017 BE239',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.55076,
    eccentricity: 0.1071589,
    inclination: 15.91141,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276837, H286906',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276837',
      },
      {
        userName: 'ko',
        HNames: 'H281369',
      },
    ],
  },
  {
    provisionalCode: '2017 BF239',
    note: 'Phocaea',
    absoluteMagnitude: 21.64,
    semimajorAxis: 2.41822,
    eccentricity: 0.1351314,
    inclination: 13.83488,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263075',
      },
      {
        userName: 'もしもス',
        HNames: 'H286907',
      },
      {
        userName: 'nasiar99',
        HNames: 'H294871',
      },
    ],
  },
  {
    provisionalCode: '2017 BG239',
    note: '',
    absoluteMagnitude: 17.94,
    semimajorAxis: 3.17216,
    eccentricity: 0.0776858,
    inclination: 9.10823,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249352',
      },
      {
        userName: 'kn1cht',
        HNames: 'H249352',
      },
      {
        userName: 'ほたる',
        HNames: 'H287172',
      },
    ],
  },
  {
    provisionalCode: '2017 BH239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.71,
    semimajorAxis: 33.4776,
    eccentricity: 0.1053362,
    inclination: 16.91222,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269611, H269617, H269792',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.94,
    semimajorAxis: 41.84077,
    eccentricity: 0.0393319,
    inclination: 22.29703,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H316728',
      },
    ],
  },
  {
    provisionalCode: '2017 BK239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.3,
    semimajorAxis: 46.02087,
    eccentricity: 0.0893648,
    inclination: 4.21459,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H314605',
      },
    ],
  },
  {
    provisionalCode: '2017 BL239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.01,
    semimajorAxis: 39.66031,
    eccentricity: 0.1100036,
    inclination: 5.97586,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H314919',
      },
    ],
  },
  {
    provisionalCode: '2017 BM239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.57,
    semimajorAxis: 40.81263,
    eccentricity: 0.1061755,
    inclination: 6.26753,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H314557',
      },
    ],
  },
  {
    provisionalCode: '2017 BN239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.0,
    semimajorAxis: 56.91025,
    eccentricity: 0.2244723,
    inclination: 21.51353,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278863',
      },
    ],
  },
  {
    provisionalCode: '2017 BO239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.23,
    semimajorAxis: 42.52918,
    eccentricity: 0.1166841,
    inclination: 14.59242,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278793',
      },
    ],
  },
  {
    provisionalCode: '2017 BP239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.84,
    semimajorAxis: 38.91592,
    eccentricity: 0.1110539,
    inclination: 4.57537,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H314196',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ239',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.8,
    semimajorAxis: 44.2532,
    eccentricity: 0.1068536,
    inclination: 7.31022,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H314577',
      },
    ],
  },
  {
    provisionalCode: '2017 BS239',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 2.16223,
    eccentricity: 0.0693894,
    inclination: 6.85103,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261070',
      },
      {
        userName: 'もしもス',
        HNames: 'H261070',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283898',
      },
      {
        userName: 'れお',
        HNames: 'H283898',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312472',
      },
    ],
  },
  {
    provisionalCode: '2017 BT239',
    note: 'Hungaria',
    absoluteMagnitude: 20.64,
    semimajorAxis: 1.89314,
    eccentricity: 0.0650672,
    inclination: 19.21301,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250957',
      },
      {
        userName: 'nasiar99',
        HNames: 'H264845, H299109',
      },
      {
        userName: 'yakuba',
        HNames: 'H298028',
      },
    ],
  },
  {
    provisionalCode: '2017 BU239',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 3.12643,
    eccentricity: 0.2347916,
    inclination: 16.63432,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H265689',
      },
      {
        userName: 'nasiar99',
        HNames: 'H287976',
      },
    ],
  },
  {
    provisionalCode: '2017 BV239',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 2.43753,
    eccentricity: 0.1417727,
    inclination: 6.44631,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241927, H247965',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H241927',
      },
      {
        userName: 'れお',
        HNames: 'H247965',
      },
    ],
  },
  {
    provisionalCode: '2017 BX239',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 3.1515,
    eccentricity: 0.3296115,
    inclination: 6.76702,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261244, H269394',
      },
      {
        userName: 'もしもス',
        HNames: 'H261244',
      },
      {
        userName: 'れお',
        HNames: 'H280092',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ239',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.51,
    semimajorAxis: 5.24211,
    eccentricity: 0.0708878,
    inclination: 9.4849,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241975',
      },
      {
        userName: '42',
        HNames: 'H262649, H263113',
      },
      {
        userName: 'aika',
        HNames: 'H316711',
      },
    ],
  },
  {
    provisionalCode: '2017 BA240',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.3699,
    eccentricity: 0.1824895,
    inclination: 2.95516,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H271023, H271841, H271851',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315803',
      },
    ],
  },
  {
    provisionalCode: '2017 BC240',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.99928,
    eccentricity: 0.0671858,
    inclination: 9.82373,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251778',
      },
      {
        userName: 'aika',
        HNames: 'H284492',
      },
      {
        userName: 'れお',
        HNames: 'H284492',
      },
    ],
  },
  {
    provisionalCode: '2017 BD240',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.40743,
    eccentricity: 0.1075339,
    inclination: 6.2707,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202164',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297630, H298334',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317498',
      },
    ],
  },
  {
    provisionalCode: '2017 BE240',
    note: '',
    absoluteMagnitude: 20.46,
    semimajorAxis: 2.27391,
    eccentricity: 0.1708825,
    inclination: 4.27522,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270007',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315628',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315865, H316693',
      },
    ],
  },
  {
    provisionalCode: '2017 BF240',
    note: '',
    absoluteMagnitude: 20.68,
    semimajorAxis: 2.99117,
    eccentricity: 0.0726791,
    inclination: 9.92297,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242462',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H311629',
      },
    ],
  },
  {
    provisionalCode: '2017 BN240',
    note: '2017 DM158と同定',
    absoluteMagnitude: 19.68,
    semimajorAxis: 2.65857,
    eccentricity: 0.2177663,
    inclination: 11.128,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: '42',
        HNames: 'H262576, H263134',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304557',
      },
      {
        userName: 'yakuba',
        HNames: 'H304557',
      },
    ],
  },
  {
    provisionalCode: '2017 CV57',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 3.16721,
    eccentricity: 0.0590151,
    inclination: 7.45819,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H240755, H263076',
      },
      {
        userName: 'もしもス',
        HNames: 'H263076',
      },
      {
        userName: 'れお',
        HNames: 'H284481',
      },
    ],
  },
  {
    provisionalCode: '2017 CW57',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.62,
    semimajorAxis: 44.45895,
    eccentricity: 0.1042006,
    inclination: 4.45092,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H265452',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315221',
      },
    ],
  },
  {
    provisionalCode: '2017 CX57',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 12.37,
    semimajorAxis: 24.62059,
    eccentricity: 0.2864018,
    inclination: 4.29809,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H314576, H314588',
      },
    ],
  },
  {
    provisionalCode: '2017 CY57',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.99676,
    eccentricity: 0.1139742,
    inclination: 9.87536,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H311357',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ57',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.96,
    semimajorAxis: 32.66855,
    eccentricity: 0.1051991,
    inclination: 25.81089,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271173, H271184, H271192',
      },
    ],
  },
  {
    provisionalCode: '2017 CA58',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.58,
    semimajorAxis: 50.17258,
    eccentricity: 0.0990179,
    inclination: 27.65744,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278941',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H278941',
      },
    ],
  },
  {
    provisionalCode: '2017 CB58',
    note: '',
    absoluteMagnitude: 20.09,
    semimajorAxis: 3.08043,
    eccentricity: 0.042559,
    inclination: 7.6818,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H260008',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260008',
      },
      {
        userName: '42',
        HNames: 'H265756',
      },
      {
        userName: 'れお',
        HNames: 'H288851',
      },
    ],
  },
  {
    provisionalCode: '2017 CC58',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.91051,
    eccentricity: 0.0232652,
    inclination: 2.92839,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202239',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261884, H315901',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298077',
      },
    ],
  },
  {
    provisionalCode: '2017 CD58',
    note: '',
    absoluteMagnitude: 21.28,
    semimajorAxis: 2.374,
    eccentricity: 0.1312641,
    inclination: 2.56561,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296132, H296235, H296268',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H316705',
      },
    ],
  },
  {
    provisionalCode: '2017 CE58',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 2.75019,
    eccentricity: 0.027442,
    inclination: 5.18145,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'arda',
        HNames: 'H259081',
      },
      {
        userName: 'joshua',
        HNames: 'H271008',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315847, H315863',
      },
    ],
  },
  {
    provisionalCode: '2017 CF58',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.27459,
    eccentricity: 0.1770263,
    inclination: 6.42612,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H220425',
      },
      {
        userName: 'belleequipe',
        HNames: 'H220425',
      },
      {
        userName: 'nasiar99',
        HNames: 'H220425',
      },
    ],
  },
  {
    provisionalCode: '2017 CH58',
    note: '',
    absoluteMagnitude: 17.9,
    semimajorAxis: 3.44156,
    eccentricity: 0.1012192,
    inclination: 10.58729,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H263184, H265894',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265894',
      },
    ],
  },
  {
    provisionalCode: '2017 CO58',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.02752,
    eccentricity: 0.0334845,
    inclination: 7.60077,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: '42',
        HNames: 'H262352, H262563',
      },
      {
        userName: 'aika',
        HNames: 'H262563',
      },
      {
        userName: 'れお',
        HNames: 'H285218',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312312',
      },
    ],
  },
  {
    provisionalCode: '2017 CP58',
    note: '2015 RQ73と同定',
    absoluteMagnitude: 17.67,
    semimajorAxis: 3.10752,
    eccentricity: 0.2745416,
    inclination: 13.79012,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H272957',
      },
      {
        userName: 'yakuba',
        HNames: 'H292551',
      },
    ],
  },
  {
    provisionalCode: '2017 DN166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.36,
    semimajorAxis: 41.9693,
    eccentricity: 0.108602,
    inclination: 3.80335,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271499, H307213',
      },
    ],
  },
  {
    provisionalCode: '2017 DO166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.74,
    semimajorAxis: 40.10568,
    eccentricity: 0.1599737,
    inclination: 9.54998,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H259750',
      },
      {
        userName: 'arda',
        HNames: 'H259750',
      },
      {
        userName: 'joshua',
        HNames: 'H273083',
      },
    ],
  },
  {
    provisionalCode: '2017 DP166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.43,
    semimajorAxis: 40.77965,
    eccentricity: 0.3087673,
    inclination: 5.81567,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H244204',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H265661',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.81,
    semimajorAxis: 42.46603,
    eccentricity: 0.1074463,
    inclination: 10.84483,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264237',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315218',
      },
    ],
  },
  {
    provisionalCode: '2017 DR166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.69,
    semimajorAxis: 56.89938,
    eccentricity: 0.4640207,
    inclination: 4.43125,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H263199',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H314568',
      },
    ],
  },
  {
    provisionalCode: '2017 DS166',
    note: '',
    absoluteMagnitude: 21.27,
    semimajorAxis: 2.5348,
    eccentricity: 0.1672,
    inclination: 9.49478,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294581, H297106',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296749, H315895',
      },
    ],
  },
  {
    provisionalCode: '2017 DT166',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 3.03017,
    eccentricity: 0.0490029,
    inclination: 2.71979,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'すぎうら',
        HNames: 'H239559',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315864, H315868, H315870',
      },
    ],
  },
  {
    provisionalCode: '2017 DU166',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 2.68337,
    eccentricity: 0.1060943,
    inclination: 15.09737,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262774',
      },
      {
        userName: 'arda',
        HNames: 'H266674',
      },
      {
        userName: 'ko',
        HNames: 'H273112',
      },
      {
        userName: 'apollo18',
        HNames: 'H316798',
      },
    ],
  },
  {
    provisionalCode: '2017 DV166',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.21672,
    eccentricity: 0.0389313,
    inclination: 8.34579,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241767',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279412',
      },
    ],
  },
  {
    provisionalCode: '2017 DW166',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.10582,
    eccentricity: 0.1314846,
    inclination: 16.01797,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272332',
      },
      {
        userName: 'れお',
        HNames: 'H272332',
      },
      {
        userName: 'ko',
        HNames: 'H273111',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282107',
      },
    ],
  },
  {
    provisionalCode: '2017 DX166',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.97002,
    eccentricity: 0.0576317,
    inclination: 8.37953,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H221887, H267322',
      },
      {
        userName: 'apollo18',
        HNames: 'H221887',
      },
      {
        userName: 'aika',
        HNames: 'H221887',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H221887',
      },
    ],
  },
  {
    provisionalCode: '2017 DY166',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.54,
    semimajorAxis: 61.49118,
    eccentricity: 0.4198964,
    inclination: 18.4623,
    oppositions: 6,
    uncertainty: 3,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H248277',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261914',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ166',
    note: '',
    absoluteMagnitude: 21.4,
    semimajorAxis: 2.36258,
    eccentricity: 0.0788916,
    inclination: 5.58077,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268150',
      },
      {
        userName: 'arda',
        HNames: 'H268150',
      },
      {
        userName: 'れお',
        HNames: 'H268150',
      },
    ],
  },
  {
    provisionalCode: '2017 DA167',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.58266,
    eccentricity: 0.2702547,
    inclination: 13.08427,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257272',
      },
      {
        userName: 'れお',
        HNames: 'H257272',
      },
    ],
  },
  {
    provisionalCode: '2017 DB167',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 2.53437,
    eccentricity: 0.0864864,
    inclination: 15.68715,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H284473',
      },
    ],
  },
  {
    provisionalCode: '2017 DC167',
    note: '',
    absoluteMagnitude: 18.44,
    semimajorAxis: 3.04182,
    eccentricity: 0.069314,
    inclination: 9.69996,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H220176',
      },
      {
        userName: 'apollo18',
        HNames: 'H220176',
      },
      {
        userName: '井上 康',
        HNames: 'H305361',
      },
    ],
  },
  {
    provisionalCode: '2017 DD167',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 3.04887,
    eccentricity: 0.0646237,
    inclination: 9.01977,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H276389',
      },
      {
        userName: 'maria w',
        HNames: 'H278567',
      },
    ],
  },
  {
    provisionalCode: '2017 DE167',
    note: '',
    absoluteMagnitude: 19.8,
    semimajorAxis: 2.5812,
    eccentricity: 0.1225501,
    inclination: 5.02563,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H275229, H275320',
      },
    ],
  },
  {
    provisionalCode: '2017 DF167',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.65041,
    eccentricity: 0.2259595,
    inclination: 4.55282,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275226',
      },
      {
        userName: 'arda',
        HNames: 'H275226',
      },
    ],
  },
  {
    provisionalCode: '2017 DM167',
    note: '',
    absoluteMagnitude: 17.34,
    semimajorAxis: 3.02489,
    eccentricity: 0.1082232,
    inclination: 8.69443,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'れお',
        HNames: 'H277792',
      },
      {
        userName: 'arda',
        HNames: 'H277792',
      },
    ],
  },
  {
    provisionalCode: '2017 DO167',
    note: '2014 QX91と同定',
    absoluteMagnitude: 18.37,
    semimajorAxis: 2.97306,
    eccentricity: 0.0890048,
    inclination: 14.35914,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'ko',
        HNames: 'H273561',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H273561',
      },
      {
        userName: 'arda',
        HNames: 'H277794',
      },
    ],
  },
  {
    provisionalCode: '2017 DP167',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.62915,
    eccentricity: 0.0962516,
    inclination: 11.308,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H278093',
      },
    ],
  },
  {
    provisionalCode: '2017 DS167',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 2.36774,
    eccentricity: 0.0651617,
    inclination: 6.72831,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H284449',
      },
    ],
  },
  {
    provisionalCode: '2017 DU167',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.23456,
    eccentricity: 0.0665905,
    inclination: 11.60964,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H275321',
      },
      {
        userName: 'aika',
        HNames: 'H275321',
      },
    ],
  },
  {
    provisionalCode: '2017 EB54',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.06637,
    eccentricity: 0.073045,
    inclination: 8.97489,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H246366',
      },
      {
        userName: 'kn1cht',
        HNames: 'H246366',
      },
    ],
  },
  {
    provisionalCode: '2017 FN238',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.07423,
    eccentricity: 0.1246627,
    inclination: 9.71599,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H274837, H288592',
      },
      {
        userName: 'yakuba',
        HNames: 'H288345',
      },
    ],
  },
  {
    provisionalCode: '2017 FP238',
    note: '',
    absoluteMagnitude: 20.11,
    semimajorAxis: 2.75275,
    eccentricity: 0.0726508,
    inclination: 7.66796,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263050, H282335',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289418',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H313582',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ238',
    note: '',
    absoluteMagnitude: 20.66,
    semimajorAxis: 2.63372,
    eccentricity: 0.0908396,
    inclination: 12.18747,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H290853',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H306449',
      },
      {
        userName: 'えでぃ',
        HNames: 'H306449',
      },
      {
        userName: 'apollo18',
        HNames: 'H314420',
      },
    ],
  },
  {
    provisionalCode: '2017 GS36',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 2.81579,
    eccentricity: 0.1399206,
    inclination: 7.17053,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H255689',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287556',
      },
      {
        userName: 'aika',
        HNames: 'H289413',
      },
      {
        userName: '赵经远',
        HNames: 'H289413',
      },
    ],
  },
  {
    provisionalCode: '2017 HK112',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.54283,
    eccentricity: 0.1455765,
    inclination: 8.15585,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282088',
      },
      {
        userName: 'kn1cht',
        HNames: 'H298255',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298255',
      },
    ],
  },
  {
    provisionalCode: '2017 HL112',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 2.69686,
    eccentricity: 0.1736876,
    inclination: 12.53979,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H298253',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302250, H317382',
      },
      {
        userName: 'aika',
        HNames: 'H302250',
      },
      {
        userName: 'apollo18',
        HNames: 'H302250',
      },
    ],
  },
  {
    provisionalCode: '2017 HM112',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.20018,
    eccentricity: 0.105813,
    inclination: 6.93978,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H302963',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H317384, H317385',
      },
    ],
  },
  {
    provisionalCode: '2017 HN112',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 3.11089,
    eccentricity: 0.0838823,
    inclination: 7.80393,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H305391, H317389',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305391, H305409',
      },
      {
        userName: 'えでぃ',
        HNames: 'H305391',
      },
    ],
  },
  {
    provisionalCode: '2017 HO112',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 3.21603,
    eccentricity: 0.1754072,
    inclination: 27.0786,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H306250',
      },
      {
        userName: 'えでぃ',
        HNames: 'H306250',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H306250',
      },
    ],
  },
  {
    provisionalCode: '2017 HP112',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 3.1596,
    eccentricity: 0.0739829,
    inclination: 8.82067,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H306457',
      },
      {
        userName: '赵经远',
        HNames: 'H309383',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317525',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ112',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 2.91674,
    eccentricity: 0.0662661,
    inclination: 7.98518,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H295342, H306559',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317531, H317534',
      },
    ],
  },
  {
    provisionalCode: '2017 HR112',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 3.18903,
    eccentricity: 0.2690367,
    inclination: 15.57408,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H309897, H317544',
      },
      {
        userName: 'えでぃ',
        HNames: 'H309897',
      },
    ],
  },
  {
    provisionalCode: '2017 HT112',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 3.07061,
    eccentricity: 0.2515048,
    inclination: 12.62797,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H235312',
      },
      {
        userName: 'もしもス',
        HNames: 'H235312',
      },
      {
        userName: 'えでぃ',
        HNames: 'H305933, H306879',
      },
    ],
  },
  {
    provisionalCode: '2017 HU112',
    note: '',
    absoluteMagnitude: 20.79,
    semimajorAxis: 2.80965,
    eccentricity: 0.2969368,
    inclination: 10.27685,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H262770',
      },
      {
        userName: 'えでぃ',
        HNames: 'H262770',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H262770',
      },
    ],
  },
  {
    provisionalCode: '2017 SK381',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.22,
    semimajorAxis: 38.78579,
    eccentricity: 0.0905256,
    inclination: 13.31389,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'akmt',
        HNames: 'H213859',
      },
      {
        userName: '井上 康',
        HNames: 'H307015',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307015',
      },
    ],
  },
  {
    provisionalCode: '2019 CV30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.66,
    semimajorAxis: 45.81209,
    eccentricity: 0.0687892,
    inclination: 19.54766,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H221841',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307217',
      },
      {
        userName: 'zorome',
        HNames: 'H307217',
      },
    ],
  },
  {
    provisionalCode: '2019 CW30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.26,
    semimajorAxis: 44.09741,
    eccentricity: 0.0580276,
    inclination: 1.67968,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H306683, H306693, H310312',
      },
    ],
  },
  {
    provisionalCode: '2019 CY30',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 2.72567,
    eccentricity: 0.1161039,
    inclination: 2.34437,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H289291, H309623',
      },
    ],
  },
  {
    provisionalCode: '2019 CZ30',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.75,
    semimajorAxis: 52.19159,
    eccentricity: 0.4652599,
    inclination: 14.81653,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286738, H286739',
      },
    ],
  },
  {
    provisionalCode: '2019 EW7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.6,
    semimajorAxis: 37.31848,
    eccentricity: 0.5916344,
    inclination: 3.5206,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H288507, H288511',
      },
    ],
  },
  {
    provisionalCode: '2019 EX7',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.59,
    semimajorAxis: 69.05466,
    eccentricity: 0.817516,
    inclination: 10.69688,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H290476, H290488',
      },
    ],
  },
  {
    provisionalCode: '2019 GF185',
    note: '',
    absoluteMagnitude: 18.3,
    semimajorAxis: 2.75115,
    eccentricity: 0.1569277,
    inclination: 7.06873,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'arda',
        HNames: 'H249624',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H249624',
      },
      {
        userName: 'zorome',
        HNames: 'H260939',
      },
    ],
  },
  {
    provisionalCode: '2019 GG185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.78,
    semimajorAxis: 49.53587,
    eccentricity: 0.2339213,
    inclination: 8.38332,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H251227',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313127',
      },
    ],
  },
  {
    provisionalCode: '2019 GH185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.83,
    semimajorAxis: 66.24609,
    eccentricity: 0.4530851,
    inclination: 9.33134,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H251456',
      },
      {
        userName: 'akira',
        HNames: 'H294589',
      },
    ],
  },
  {
    provisionalCode: '2019 GJ185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.95,
    semimajorAxis: 45.89227,
    eccentricity: 0.1639906,
    inclination: 22.8325,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H252327',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313114',
      },
    ],
  },
  {
    provisionalCode: '2019 GK185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.27,
    semimajorAxis: 42.98984,
    eccentricity: 0.044726,
    inclination: 8.05558,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H295370, H295376',
      },
    ],
  },
  {
    provisionalCode: '2019 GS185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.08,
    semimajorAxis: 45.78462,
    eccentricity: 0.373129,
    inclination: 20.41443,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286337, H286340',
      },
    ],
  },
  {
    provisionalCode: '2019 SB247',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.59873,
    eccentricity: 0.2329341,
    inclination: 14.87043,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H227279',
      },
      {
        userName: 'れお',
        HNames: 'H255622',
      },
    ],
  },
  {
    provisionalCode: '2019 SC247',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.4276,
    eccentricity: 0.1301244,
    inclination: 11.77583,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H277669, H291490',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H318376',
      },
    ],
  },
  {
    provisionalCode: '2019 SG247',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 2.58894,
    eccentricity: 0.1081286,
    inclination: 11.22518,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H278347',
      },
      {
        userName: 'ほたる',
        HNames: 'H287883',
      },
    ],
  },
  {
    provisionalCode: '2019 SH247',
    note: '',
    absoluteMagnitude: 18.05,
    semimajorAxis: 3.15143,
    eccentricity: 0.1335031,
    inclination: 22.18196,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H272380',
      },
      {
        userName: 'ほたる',
        HNames: 'H287895',
      },
    ],
  },
  {
    provisionalCode: '2019 SJ247',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 2.99154,
    eccentricity: 0.1117055,
    inclination: 12.11439,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232839',
      },
      {
        userName: 'mitarushi',
        HNames: 'H232839',
      },
      {
        userName: 'ほたる',
        HNames: 'H287896',
      },
    ],
  },
  {
    provisionalCode: '2019 SK247',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.16632,
    eccentricity: 0.2444242,
    inclination: 16.51205,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H277667, H288615',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277667',
      },
      {
        userName: '赵经远',
        HNames: 'H288615',
      },
    ],
  },
  {
    provisionalCode: '2019 SL247',
    note: '',
    absoluteMagnitude: 21.34,
    semimajorAxis: 2.20518,
    eccentricity: 0.2334628,
    inclination: 7.59428,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H274101, H291479',
      },
    ],
  },
  {
    provisionalCode: '2019 SM247',
    note: '',
    absoluteMagnitude: 18.15,
    semimajorAxis: 3.15518,
    eccentricity: 0.2597872,
    inclination: 14.75734,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'れお',
        HNames: 'H278350, H287891',
      },
      {
        userName: 'ほたる',
        HNames: 'H287891',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H319020',
      },
    ],
  },
  {
    provisionalCode: '2019 SN247',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 2.71164,
    eccentricity: 0.1313434,
    inclination: 13.33613,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H274093',
      },
      {
        userName: 'ほたる',
        HNames: 'H287796',
      },
    ],
  },
  {
    provisionalCode: '2019 SO247',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.04065,
    eccentricity: 0.0586725,
    inclination: 12.18333,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H260657',
      },
      {
        userName: 'ほたる',
        HNames: 'H287798',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H319013',
      },
    ],
  },
  {
    provisionalCode: '2019 SP247',
    note: '',
    absoluteMagnitude: 18.52,
    semimajorAxis: 2.89653,
    eccentricity: 0.0710098,
    inclination: 12.08653,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H274092, H291475',
      },
    ],
  },
  {
    provisionalCode: '2019 SQ247',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.85,
    semimajorAxis: 41.48154,
    eccentricity: 0.1805037,
    inclination: 31.49955,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H274645',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H313309',
      },
    ],
  },
  {
    provisionalCode: '2019 TW96',
    note: '',
    absoluteMagnitude: 18.24,
    semimajorAxis: 3.21133,
    eccentricity: 0.0945951,
    inclination: 18.09384,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H232847, H265829',
      },
      {
        userName: 'mitarushi',
        HNames: 'H232847',
      },
    ],
  },
  {
    provisionalCode: '2019 TX96',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 3.1163,
    eccentricity: 0.2327424,
    inclination: 10.74521,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H274079, H274100',
      },
      {
        userName: 'jim',
        HNames: 'H312770',
      },
    ],
  },
  {
    provisionalCode: '2019 TY96',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.74716,
    eccentricity: 0.1845997,
    inclination: 12.30475,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H287902',
      },
      {
        userName: 'alberto76',
        HNames: 'H304586',
      },
    ],
  },
  {
    provisionalCode: '2019 TZ96',
    note: '',
    absoluteMagnitude: 18.1,
    semimajorAxis: 3.0442,
    eccentricity: 0.1151734,
    inclination: 11.5297,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H287919',
      },
      {
        userName: 's.ozawa',
        HNames: 'H307105, H307159',
      },
    ],
  },
  {
    provisionalCode: '2019 TA97',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.02042,
    eccentricity: 0.2327409,
    inclination: 12.35701,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H242422',
      },
      {
        userName: 's.ozawa',
        HNames: 'H260681',
      },
    ],
  },
  {
    provisionalCode: '2019 TB97',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 2.99816,
    eccentricity: 0.0094239,
    inclination: 12.21668,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H287920',
      },
      {
        userName: 's.ozawa',
        HNames: 'H307104, H307158',
      },
    ],
  },
  {
    provisionalCode: '2019 UR176',
    note: '',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.97655,
    eccentricity: 0.026382,
    inclination: 12.19415,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222929',
      },
      {
        userName: 'れお',
        HNames: 'H273159, H273197',
      },
    ],
  },
  {
    provisionalCode: '2019 US176',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.69487,
    eccentricity: 0.2036059,
    inclination: 12.53366,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226930',
      },
      {
        userName: 's.ozawa',
        HNames: 'H227142, H238883',
      },
      {
        userName: 'もしもス',
        HNames: 'H240362',
      },
    ],
  },
  {
    provisionalCode: '2019 UT176',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.94758,
    eccentricity: 0.0976733,
    inclination: 12.13798,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H215092',
      },
      {
        userName: 'ngc',
        HNames: 'H229156',
      },
      {
        userName: 'ms',
        HNames: 'H319535',
      },
    ],
  },
  {
    provisionalCode: '2019 UU176',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.01512,
    eccentricity: 0.125832,
    inclination: 10.86914,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280082',
      },
      {
        userName: 'ms',
        HNames: 'H319536, H319617, H319625',
      },
    ],
  },
  {
    provisionalCode: '2019 UV176',
    note: 'Mars Crosser',
    absoluteMagnitude: 21.81,
    semimajorAxis: 1.85831,
    eccentricity: 0.1067222,
    inclination: 18.88077,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223285',
      },
      {
        userName: 'ms',
        HNames: 'H319627, H319650',
      },
    ],
  },
  {
    provisionalCode: '2019 UW176',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.64561,
    eccentricity: 0.1365456,
    inclination: 5.59893,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H215086',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H230766',
      },
      {
        userName: 'ms',
        HNames: 'H319618',
      },
    ],
  },
  {
    provisionalCode: '2019 UX176',
    note: '',
    absoluteMagnitude: 21.77,
    semimajorAxis: 2.24535,
    eccentricity: 0.1035853,
    inclination: 5.98954,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H248496',
      },
      {
        userName: 'ms',
        HNames: 'H319984, H320091, H320095',
      },
    ],
  },
  {
    provisionalCode: '2019 VP53',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.13,
    semimajorAxis: 53.51928,
    eccentricity: 0.4551246,
    inclination: 2.74257,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214385',
      },
      {
        userName: 'ms',
        HNames: 'H319391, H319394, H319407',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ53',
    note: 'Mars Crosser',
    absoluteMagnitude: 22.55,
    semimajorAxis: 1.87715,
    eccentricity: 0.2583302,
    inclination: 3.4048,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H217086',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221765, H223908',
      },
    ],
  },
  {
    provisionalCode: '2019 VR53',
    note: '',
    absoluteMagnitude: 20.35,
    semimajorAxis: 2.63507,
    eccentricity: 0.2683391,
    inclination: 3.09709,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H222043',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304887',
      },
    ],
  },
  {
    provisionalCode: '2019 VS53',
    note: '2022 AE29と同定',
    absoluteMagnitude: 18.3,
    semimajorAxis: 3.04419,
    eccentricity: 0.2347153,
    inclination: 9.24781,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222431, H222839',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H305421',
      },
    ],
  },
  {
    provisionalCode: '2019 VT53',
    note: '',
    absoluteMagnitude: 21.73,
    semimajorAxis: 2.76939,
    eccentricity: 0.2720544,
    inclination: 2.75731,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222857',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285233',
      },
    ],
  },
  {
    provisionalCode: '2019 VU53',
    note: '',
    absoluteMagnitude: 20.89,
    semimajorAxis: 2.69744,
    eccentricity: 0.0924797,
    inclination: 2.31189,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223463, H305445',
      },
    ],
  },
  {
    provisionalCode: '2019 VW53',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.75519,
    eccentricity: 0.1165107,
    inclination: 3.59965,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H319626, H319894, H319898',
      },
    ],
  },
  {
    provisionalCode: '2019 VX53',
    note: '',
    absoluteMagnitude: 20.78,
    semimajorAxis: 2.54381,
    eccentricity: 0.1750169,
    inclination: 12.86596,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ms',
        HNames: 'H320328, H320354, H320422',
      },
    ],
  },
  {
    provisionalCode: '2019 WV35',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.99,
    semimajorAxis: 44.75887,
    eccentricity: 0.1286466,
    inclination: 25.30135,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H255676',
      },
      {
        userName: 'もしもス',
        HNames: 'H255676',
      },
      {
        userName: 'kn1cht',
        HNames: 'H284810',
      },
    ],
  },
  {
    provisionalCode: '2019 WW35',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.79,
    semimajorAxis: 39.69906,
    eccentricity: 0.1613386,
    inclination: 24.99332,
    oppositions: 6,
    uncertainty: 4,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H234545, H238039',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H234545, H238039, H313289',
      },
    ],
  },
  {
    provisionalCode: '2019 WX35',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.18275,
    eccentricity: 0.2307543,
    inclination: 17.441,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H252405',
      },
      {
        userName: 'もしもス',
        HNames: 'H252405',
      },
      {
        userName: 'kn1cht',
        HNames: 'H252405, H303352',
      },
      {
        userName: 'alberto76',
        HNames: 'H303352',
      },
    ],
  },
  {
    provisionalCode: '2019 WY35',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.04,
    semimajorAxis: 5.10738,
    eccentricity: 0.0523283,
    inclination: 3.96418,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219309',
      },
      {
        userName: 'さんしろう',
        HNames: 'H220398',
      },
      {
        userName: 'ms',
        HNames: 'H318811, H318955',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ35',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.86,
    semimajorAxis: 90.36961,
    eccentricity: 0.5609364,
    inclination: 33.51531,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H268836, H313300',
      },
      {
        userName: 'れお',
        HNames: 'H268836',
      },
    ],
  },
  {
    provisionalCode: '2019 WA36',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.62,
    semimajorAxis: 41.45964,
    eccentricity: 0.1165866,
    inclination: 23.04796,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H296765',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319244, H319247',
      },
    ],
  },
  {
    provisionalCode: '2019 WB36',
    note: 'Mars Crosser',
    absoluteMagnitude: 19.17,
    semimajorAxis: 2.23944,
    eccentricity: 0.40767,
    inclination: 4.18875,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219419',
      },
      {
        userName: 'aika',
        HNames: 'H219419',
      },
    ],
  },
  {
    provisionalCode: '2019 WC36',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.15955,
    eccentricity: 0.1523047,
    inclination: 26.36247,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H228310, H298948',
      },
      {
        userName: 'zorome',
        HNames: 'H228310',
      },
    ],
  },
  {
    provisionalCode: '2019 WD36',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 3.06062,
    eccentricity: 0.1334898,
    inclination: 12.47577,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H228807',
      },
      {
        userName: 'zorome',
        HNames: 'H228807',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287627',
      },
    ],
  },
  {
    provisionalCode: '2019 WE36',
    note: '',
    absoluteMagnitude: 20.25,
    semimajorAxis: 2.96152,
    eccentricity: 0.0573523,
    inclination: 12.5166,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H228809',
      },
      {
        userName: 'zorome',
        HNames: 'H228809',
      },
    ],
  },
  {
    provisionalCode: '2019 WF36',
    note: '',
    absoluteMagnitude: 21.32,
    semimajorAxis: 2.67474,
    eccentricity: 0.2996688,
    inclination: 13.63428,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H228814',
      },
      {
        userName: 'zorome',
        HNames: 'H228814',
      },
    ],
  },
  {
    provisionalCode: '2019 WG36',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 3.05525,
    eccentricity: 0.2294995,
    inclination: 11.96498,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228841',
      },
      {
        userName: 'れお',
        HNames: 'H228841',
      },
    ],
  },
  {
    provisionalCode: '2019 WH36',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 3.03379,
    eccentricity: 0.1029458,
    inclination: 17.78682,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H231494',
      },
      {
        userName: 'aika',
        HNames: 'H231494',
      },
      {
        userName: 'maria w',
        HNames: 'H277715',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ36',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 3.14956,
    eccentricity: 0.2028332,
    inclination: 18.19746,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H236878',
      },
      {
        userName: 'れお',
        HNames: 'H236878',
      },
      {
        userName: 'ctaka',
        HNames: 'H236878',
      },
    ],
  },
  {
    provisionalCode: '2019 WK36',
    note: '',
    absoluteMagnitude: 22.35,
    semimajorAxis: 2.53149,
    eccentricity: 0.1683924,
    inclination: 13.1898,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254761',
      },
      {
        userName: 'もしもス',
        HNames: 'H254761',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319344',
      },
    ],
  },
  {
    provisionalCode: '2019 WL36',
    note: '',
    absoluteMagnitude: 20.65,
    semimajorAxis: 2.71904,
    eccentricity: 0.1812422,
    inclination: 12.65973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255549',
      },
    ],
  },
  {
    provisionalCode: '2019 WM36',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 3.2057,
    eccentricity: 0.0166157,
    inclination: 18.40047,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H266651',
      },
      {
        userName: 'れお',
        HNames: 'H266651',
      },
    ],
  },
  {
    provisionalCode: '2019 WN36',
    note: '',
    absoluteMagnitude: 17.6,
    semimajorAxis: 3.12409,
    eccentricity: 0.0225766,
    inclination: 12.4171,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261101',
      },
      {
        userName: 'alberto76',
        HNames: 'H274188',
      },
      {
        userName: 'れお',
        HNames: 'H274188',
      },
      {
        userName: 'legohasiri',
        HNames: 'H274188',
      },
      {
        userName: 'apollo18',
        HNames: 'H312103',
      },
    ],
  },
  {
    provisionalCode: '2019 WO36',
    note: '',
    absoluteMagnitude: 20.69,
    semimajorAxis: 2.66296,
    eccentricity: 0.2231404,
    inclination: 13.33218,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H279915, H279916',
      },
      {
        userName: 'kn1cht',
        HNames: 'H279916',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H279916',
      },
    ],
  },
  {
    provisionalCode: '2019 WP36',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.17564,
    eccentricity: 0.0397945,
    inclination: 16.26993,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H280151, H299181',
      },
      {
        userName: 'kn1cht',
        HNames: 'H280151',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280151',
      },
    ],
  },
  {
    provisionalCode: '2019 WQ36',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.67613,
    eccentricity: 0.2372453,
    inclination: 12.93026,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217400',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221957',
      },
    ],
  },
  {
    provisionalCode: '2019 WR36',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 3.06033,
    eccentricity: 0.1266581,
    inclination: 18.23194,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H274951',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H274951',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H274951',
      },
    ],
  },
  {
    provisionalCode: '2019 WS36',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 3.03703,
    eccentricity: 0.164284,
    inclination: 12.01905,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H280398',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280398',
      },
      {
        userName: 'kn1cht',
        HNames: 'H286189',
      },
    ],
  },
  {
    provisionalCode: '2019 WT36',
    note: '',
    absoluteMagnitude: 20.18,
    semimajorAxis: 2.69711,
    eccentricity: 0.1913637,
    inclination: 2.41375,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H219415',
      },
      {
        userName: 'aika',
        HNames: 'H219415',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219415',
      },
    ],
  },
  {
    provisionalCode: '2019 WU36',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 2.68581,
    eccentricity: 0.1327268,
    inclination: 13.01488,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H216018',
      },
      {
        userName: 'れお',
        HNames: 'H216018',
      },
    ],
  },
  {
    provisionalCode: '2019 WV36',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.67792,
    eccentricity: 0.1366901,
    inclination: 12.14194,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216020, H255487',
      },
      {
        userName: 'このしろ',
        HNames: 'H216020',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319533',
      },
    ],
  },
  {
    provisionalCode: '2019 WW36',
    note: '',
    absoluteMagnitude: 21.0,
    semimajorAxis: 2.62527,
    eccentricity: 0.0609298,
    inclination: 11.83894,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216024, H255488',
      },
      {
        userName: 'このしろ',
        HNames: 'H216024',
      },
    ],
  },
  {
    provisionalCode: '2019 WX36',
    note: '',
    absoluteMagnitude: 21.61,
    semimajorAxis: 2.59653,
    eccentricity: 0.1934159,
    inclination: 12.2356,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216339',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H216339',
      },
      {
        userName: 'apollo18',
        HNames: 'H216339',
      },
    ],
  },
  {
    provisionalCode: '2019 WY36',
    note: '',
    absoluteMagnitude: 21.42,
    semimajorAxis: 2.44871,
    eccentricity: 0.2688132,
    inclination: 23.81819,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255939',
      },
      {
        userName: 'れお',
        HNames: 'H255939',
      },
      {
        userName: 'kn1cht',
        HNames: 'H272315',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ36',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.7419,
    eccentricity: 0.1225817,
    inclination: 18.94486,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H216570',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H216570',
      },
      {
        userName: 'ゆうたろう',
        HNames: 'H216570',
      },
    ],
  },
  {
    provisionalCode: '2019 WA37',
    note: '',
    absoluteMagnitude: 21.7,
    semimajorAxis: 2.58844,
    eccentricity: 0.2314575,
    inclination: 13.35928,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H225166',
      },
      {
        userName: 'zorome',
        HNames: 'H225166',
      },
      {
        userName: 'kn1cht',
        HNames: 'H303270',
      },
    ],
  },
  {
    provisionalCode: '2019 WB37',
    note: '',
    absoluteMagnitude: 21.05,
    semimajorAxis: 3.19424,
    eccentricity: 0.2702164,
    inclination: 14.98281,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228324',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319554',
      },
    ],
  },
  {
    provisionalCode: '2019 WC37',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.57174,
    eccentricity: 0.0723673,
    inclination: 17.47188,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228765',
      },
      {
        userName: 'arda',
        HNames: 'H290836',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319556',
      },
    ],
  },
  {
    provisionalCode: '2019 WD37',
    note: '',
    absoluteMagnitude: 20.27,
    semimajorAxis: 2.89499,
    eccentricity: 0.1481668,
    inclination: 13.30956,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H229248',
      },
    ],
  },
  {
    provisionalCode: '2019 WE37',
    note: '',
    absoluteMagnitude: 20.73,
    semimajorAxis: 2.59467,
    eccentricity: 0.118233,
    inclination: 12.21555,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H229337',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H320046',
      },
    ],
  },
  {
    provisionalCode: '2019 WF37',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.68962,
    eccentricity: 0.267908,
    inclination: 16.38998,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H252410',
      },
      {
        userName: 'もしもス',
        HNames: 'H252410',
      },
      {
        userName: 'kn1cht',
        HNames: 'H303353',
      },
    ],
  },
  {
    provisionalCode: '2019 WG37',
    note: 'Phocaea',
    absoluteMagnitude: 21.61,
    semimajorAxis: 2.46405,
    eccentricity: 0.1892658,
    inclination: 12.82689,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255167',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H320062',
      },
    ],
  },
  {
    provisionalCode: '2019 WH37',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.59332,
    eccentricity: 0.1824464,
    inclination: 14.05192,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'starolite',
        HNames: 'H224173',
      },
      {
        userName: 'れお',
        HNames: 'H255546',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ37',
    note: '',
    absoluteMagnitude: 22.41,
    semimajorAxis: 2.66016,
    eccentricity: 0.3233651,
    inclination: 12.41369,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255664',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255664',
      },
    ],
  },
  {
    provisionalCode: '2019 WK37',
    note: '',
    absoluteMagnitude: 21.88,
    semimajorAxis: 3.16226,
    eccentricity: 0.2774257,
    inclination: 14.17682,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255677',
      },
      {
        userName: 'もしもス',
        HNames: 'H255677',
      },
      {
        userName: 'えでぃ',
        HNames: 'H306742',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H320072',
      },
    ],
  },
  {
    provisionalCode: '2019 WL37',
    note: '',
    absoluteMagnitude: 20.56,
    semimajorAxis: 3.17228,
    eccentricity: 0.1978812,
    inclination: 16.47886,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255164, H256047',
      },
      {
        userName: 'maria w',
        HNames: 'H277716',
      },
    ],
  },
  {
    provisionalCode: '2019 WM37',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.13624,
    eccentricity: 0.1350291,
    inclination: 13.25402,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255163, H256048',
      },
      {
        userName: 'kn1cht',
        HNames: 'H284918',
      },
    ],
  },
  {
    provisionalCode: '2019 WN37',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 2.99632,
    eccentricity: 0.0532014,
    inclination: 13.20469,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H257942',
      },
      {
        userName: 'kn1cht',
        HNames: 'H303269',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H312585',
      },
    ],
  },
  {
    provisionalCode: '2019 XT20',
    note: '',
    absoluteMagnitude: 20.6,
    semimajorAxis: 2.52892,
    eccentricity: 0.035254,
    inclination: 14.311,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'caldariにゃんこ',
        HNames: 'H217253',
      },
      {
        userName: 'れお',
        HNames: 'H267149, H267565',
      },
      {
        userName: 'jim',
        HNames: 'H313682',
      },
    ],
  },
  {
    provisionalCode: '2020 BZ158',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.3116,
    eccentricity: 0.2941535,
    inclination: 17.51271,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215245, H318157',
      },
      {
        userName: 'kn1cht',
        HNames: 'H215245',
      },
      {
        userName: '武川知知夫',
        HNames: 'H215245',
      },
    ],
  },
  {
    provisionalCode: '2020 BA159',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.21705,
    eccentricity: 0.0843557,
    inclination: 19.03296,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H217648',
      },
      {
        userName: 'mitarushi',
        HNames: 'H217648',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H318163',
      },
    ],
  },
  {
    provisionalCode: '2020 BB159',
    note: '',
    absoluteMagnitude: 18.31,
    semimajorAxis: 2.73549,
    eccentricity: 0.1735802,
    inclination: 16.64676,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'てるてる',
        HNames: 'H217653',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217653',
      },
      {
        userName: 'mitarushi',
        HNames: 'H217653',
      },
    ],
  },
  {
    provisionalCode: '2020 BC159',
    note: '',
    absoluteMagnitude: 18.54,
    semimajorAxis: 3.6292,
    eccentricity: 0.1966733,
    inclination: 19.97917,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H217916',
      },
      {
        userName: 'mitarushi',
        HNames: 'H217916',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H318167, H318172',
      },
    ],
  },
  {
    provisionalCode: '2016 PK296',
    note: '',
    absoluteMagnitude: 17.75,
    semimajorAxis: 3.13547,
    eccentricity: 0.0765611,
    inclination: 8.24652,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H221731',
      },
      {
        userName: 'コージ',
        HNames: 'H251076',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H318327',
      },
    ],
  },
  {
    provisionalCode: '2016 PO296',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 5.89,
    semimajorAxis: 58.27648,
    eccentricity: 0.3011905,
    inclination: 14.35431,
    oppositions: 6,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222306',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H222306',
      },
    ],
  },
  {
    provisionalCode: '2016 PP296',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.86,
    semimajorAxis: 46.31703,
    eccentricity: 0.1222626,
    inclination: 4.09533,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H318015',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ296',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.0,
    semimajorAxis: 126.78704,
    eccentricity: 0.8086669,
    inclination: 4.40458,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H318209',
      },
    ],
  },
  {
    provisionalCode: '2016 QZ158',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.67,
    semimajorAxis: 60.17967,
    eccentricity: 0.2947294,
    inclination: 24.05431,
    oppositions: 2,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H235672, H235817',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319933',
      },
    ],
  },
  {
    provisionalCode: '2016 YW40',
    note: '',
    absoluteMagnitude: 17.6,
    semimajorAxis: 3.18187,
    eccentricity: 0.0642059,
    inclination: 9.50438,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H309458',
      },
    ],
  },
  {
    provisionalCode: '2016 YZ40',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.35,
    semimajorAxis: 42.35013,
    eccentricity: 0.1748263,
    inclination: 19.88996,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278678, H282964',
      },
    ],
  },
  {
    provisionalCode: '2017 BR240',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.15571,
    eccentricity: 0.0360501,
    inclination: 8.88915,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H268066, H277597',
      },
      {
        userName: 'kn1cht',
        HNames: 'H277771',
      },
      {
        userName: 'ほたる',
        HNames: 'H287435',
      },
    ],
  },
  {
    provisionalCode: '2017 BS240',
    note: '',
    absoluteMagnitude: 18.67,
    semimajorAxis: 3.17373,
    eccentricity: 0.0485968,
    inclination: 8.04616,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233455, H310960',
      },
      {
        userName: 'aika',
        HNames: 'H265776',
      },
      {
        userName: '42',
        HNames: 'H265776',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310960',
      },
    ],
  },
  {
    provisionalCode: '2017 BW240',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.3,
    semimajorAxis: 5.12028,
    eccentricity: 0.01165,
    inclination: 30.99761,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242514, H247964',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H242514',
      },
      {
        userName: 'れお',
        HNames: 'H247964',
      },
    ],
  },
  {
    provisionalCode: '2017 BX240',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.84,
    semimajorAxis: 5.20455,
    eccentricity: 0.0311957,
    inclination: 30.82263,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268943',
      },
      {
        userName: 'arda',
        HNames: 'H268943',
      },
      {
        userName: 'れお',
        HNames: 'H292643',
      },
      {
        userName: 'ほたる',
        HNames: 'H300327',
      },
    ],
  },
  {
    provisionalCode: '2017 BA241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.77,
    semimajorAxis: 47.91764,
    eccentricity: 0.2135428,
    inclination: 15.10998,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H241932',
      },
      {
        userName: 'zorome',
        HNames: 'H241932',
      },
    ],
  },
  {
    provisionalCode: '2017 BB241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.36,
    semimajorAxis: 42.90523,
    eccentricity: 0.0312524,
    inclination: 14.28259,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H247978',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247978',
      },
    ],
  },
  {
    provisionalCode: '2017 BC241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.9,
    semimajorAxis: 68.14549,
    eccentricity: 0.4870357,
    inclination: 12.88389,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271193, H271195',
      },
    ],
  },
  {
    provisionalCode: '2017 BD241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.59,
    semimajorAxis: 45.48435,
    eccentricity: 0.1556274,
    inclination: 23.03007,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H262858',
      },
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H262858, H281985',
      },
    ],
  },
  {
    provisionalCode: '2017 BE241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.26,
    semimajorAxis: 46.75979,
    eccentricity: 0.0885291,
    inclination: 4.28445,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H317350',
      },
    ],
  },
  {
    provisionalCode: '2017 BF241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.76,
    semimajorAxis: 37.65658,
    eccentricity: 0.0996085,
    inclination: 30.89031,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317495',
      },
    ],
  },
  {
    provisionalCode: '2017 BG241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.66,
    semimajorAxis: 43.66216,
    eccentricity: 0.0843267,
    inclination: 2.68319,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317500',
      },
    ],
  },
  {
    provisionalCode: '2017 BH241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.31,
    semimajorAxis: 105.50688,
    eccentricity: 0.6888126,
    inclination: 2.71699,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H317680',
      },
    ],
  },
  {
    provisionalCode: '2017 BK241',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.43832,
    eccentricity: 0.1194279,
    inclination: 7.2978,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220816',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H310804',
      },
    ],
  },
  {
    provisionalCode: '2017 BL241',
    note: '2022 AY44と同定',
    absoluteMagnitude: 18.88,
    semimajorAxis: 2.70904,
    eccentricity: 0.268171,
    inclination: 11.33968,
    oppositions: 2,
    uncertainty: 2,
    detail: [
      {
        userName: 'shuntaroh',
        HNames: 'H295077',
      },
    ],
  },
  {
    provisionalCode: '2017 BM241',
    note: '',
    absoluteMagnitude: 17.45,
    semimajorAxis: 3.09611,
    eccentricity: 0.212011,
    inclination: 11.86686,
    oppositions: 5,
    uncertainty: 2,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H281047',
      },
      {
        userName: 'zorome',
        HNames: 'H281047',
      },
    ],
  },
  {
    provisionalCode: '2017 BO241',
    note: '',
    absoluteMagnitude: 20.29,
    semimajorAxis: 2.73206,
    eccentricity: 0.0997588,
    inclination: 11.70641,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H201906',
      },
      {
        userName: 'もしもス',
        HNames: 'H201906',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267810',
      },
    ],
  },
  {
    provisionalCode: '2017 BP241',
    note: 'Hilda',
    absoluteMagnitude: 18.25,
    semimajorAxis: 3.97681,
    eccentricity: 0.1248133,
    inclination: 10.57291,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H259671',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259671, H280649',
      },
      {
        userName: 'もしもス',
        HNames: 'H259671',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ241',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.25,
    semimajorAxis: 5.18875,
    eccentricity: 0.033114,
    inclination: 16.12243,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H273585',
      },
      {
        userName: 'もしもス',
        HNames: 'H273585',
      },
      {
        userName: 'nasiar99',
        HNames: 'H274135',
      },
    ],
  },
  {
    provisionalCode: '2017 BR241',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.38,
    semimajorAxis: 5.24678,
    eccentricity: 0.0575919,
    inclination: 7.42601,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220204',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H220204',
      },
      {
        userName: 'もしもス',
        HNames: 'H281415',
      },
      {
        userName: 'nasiar99',
        HNames: 'H281415',
      },
    ],
  },
  {
    provisionalCode: '2017 BS241',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 3.16991,
    eccentricity: 0.0320096,
    inclination: 7.08951,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H281462',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283055',
      },
      {
        userName: 'yakuba',
        HNames: 'H298026',
      },
    ],
  },
  {
    provisionalCode: '2017 BT241',
    note: '',
    absoluteMagnitude: 17.73,
    semimajorAxis: 3.38089,
    eccentricity: 0.0634786,
    inclination: 8.52997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267780, H281941',
      },
    ],
  },
  {
    provisionalCode: '2017 BU241',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 2.88208,
    eccentricity: 0.0401637,
    inclination: 6.35766,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H273594',
      },
      {
        userName: 'kn1cht',
        HNames: 'H273594',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H273594',
      },
      {
        userName: 'れお',
        HNames: 'H292237',
      },
    ],
  },
  {
    provisionalCode: '2017 BW241',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 2.66501,
    eccentricity: 0.2337467,
    inclination: 13.28093,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242011',
      },
      {
        userName: 'もしもス',
        HNames: 'H242011, H286002',
      },
      {
        userName: 'nasiar99',
        HNames: 'H304287',
      },
    ],
  },
  {
    provisionalCode: '2017 BX241',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.05885,
    eccentricity: 0.0877011,
    inclination: 9.92087,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261243',
      },
      {
        userName: 'もしもス',
        HNames: 'H261243, H286003',
      },
    ],
  },
  {
    provisionalCode: '2017 BY241',
    note: '',
    absoluteMagnitude: 20.97,
    semimajorAxis: 2.44046,
    eccentricity: 0.2018619,
    inclination: 6.60775,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H278946',
      },
      {
        userName: 'nasiar99',
        HNames: 'H278946',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289842',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ241',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.2274,
    eccentricity: 0.0828971,
    inclination: 10.95832,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279564',
      },
      {
        userName: 'aika',
        HNames: 'H279564',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288724',
      },
    ],
  },
  {
    provisionalCode: '2017 BA242',
    note: '',
    absoluteMagnitude: 18.35,
    semimajorAxis: 3.06983,
    eccentricity: 0.0499038,
    inclination: 9.8913,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279565',
      },
      {
        userName: 'aika',
        HNames: 'H279565',
      },
      {
        userName: 'れお',
        HNames: 'H288781',
      },
    ],
  },
  {
    provisionalCode: '2017 BB242',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.56372,
    eccentricity: 0.142876,
    inclination: 5.83627,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267332',
      },
      {
        userName: 'ほたる',
        HNames: 'H281113',
      },
      {
        userName: 'れお',
        HNames: 'H281113',
      },
    ],
  },
  {
    provisionalCode: '2017 BC242',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.29897,
    eccentricity: 0.1498786,
    inclination: 4.80536,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'れお',
        HNames: 'H279596',
      },
      {
        userName: 'もしもス',
        HNames: 'H280086',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305187',
      },
    ],
  },
  {
    provisionalCode: '2017 BD242',
    note: 'Hilda',
    absoluteMagnitude: 17.74,
    semimajorAxis: 3.97087,
    eccentricity: 0.1490828,
    inclination: 7.07888,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H231411',
      },
      {
        userName: '42',
        HNames: 'H267904, H267924',
      },
    ],
  },
  {
    provisionalCode: '2017 BE242',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.19178,
    eccentricity: 0.0968673,
    inclination: 13.85567,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265405',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265405',
      },
      {
        userName: '42',
        HNames: 'H267892, H267893, H267901',
      },
    ],
  },
  {
    provisionalCode: '2017 BF242',
    note: '',
    absoluteMagnitude: 18.85,
    semimajorAxis: 3.38501,
    eccentricity: 0.0555979,
    inclination: 12.49446,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265406',
      },
      {
        userName: '42',
        HNames: 'H265892, H267891',
      },
    ],
  },
  {
    provisionalCode: '2017 BG242',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.15776,
    eccentricity: 0.045788,
    inclination: 8.91637,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268412',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268412',
      },
      {
        userName: 'aika',
        HNames: 'H268412',
      },
      {
        userName: 'れお',
        HNames: 'H291109',
      },
    ],
  },
  {
    provisionalCode: '2017 BH242',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.59505,
    eccentricity: 0.1165813,
    inclination: 7.5536,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265414',
      },
      {
        userName: 'れお',
        HNames: 'H279518',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ242',
    note: 'Phocaea',
    absoluteMagnitude: 20.26,
    semimajorAxis: 2.40627,
    eccentricity: 0.1396836,
    inclination: 12.32004,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242008, H248509',
      },
      {
        userName: 'れお',
        HNames: 'H279519',
      },
    ],
  },
  {
    provisionalCode: '2017 BK242',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 2.55172,
    eccentricity: 0.2513722,
    inclination: 5.61396,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H280089',
      },
      {
        userName: 'れお',
        HNames: 'H284446',
      },
      {
        userName: 'nasiar99',
        HNames: 'H305186',
      },
    ],
  },
  {
    provisionalCode: '2017 BL242',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.19492,
    eccentricity: 0.0223492,
    inclination: 8.76397,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H279595',
      },
      {
        userName: 'もしもス',
        HNames: 'H280090',
      },
    ],
  },
  {
    provisionalCode: '2017 BM242',
    note: '',
    absoluteMagnitude: 21.38,
    semimajorAxis: 2.7413,
    eccentricity: 0.1829571,
    inclination: 7.32621,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H276510',
      },
      {
        userName: 'れお',
        HNames: 'H279523',
      },
      {
        userName: 'arda',
        HNames: 'H282655',
      },
    ],
  },
  {
    provisionalCode: '2017 BN242',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.11639,
    eccentricity: 0.2007906,
    inclination: 22.79402,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H261796',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262321',
      },
      {
        userName: 'もしもス',
        HNames: 'H262321',
      },
      {
        userName: '42',
        HNames: 'H262321',
      },
    ],
  },
  {
    provisionalCode: '2017 BO242',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 3.23024,
    eccentricity: 0.0329537,
    inclination: 7.28761,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279636',
      },
      {
        userName: 'れお',
        HNames: 'H288788',
      },
    ],
  },
  {
    provisionalCode: '2017 BP242',
    note: '',
    absoluteMagnitude: 19.77,
    semimajorAxis: 3.02287,
    eccentricity: 0.0659906,
    inclination: 10.69605,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267683',
      },
      {
        userName: 'もしもス',
        HNames: 'H267683',
      },
      {
        userName: 'arda',
        HNames: 'H278621',
      },
      {
        userName: 'ほたる',
        HNames: 'H280253',
      },
      {
        userName: 'れお',
        HNames: 'H280253',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ242',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 3.06188,
    eccentricity: 0.055333,
    inclination: 8.86948,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H278087',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H283882',
      },
      {
        userName: 'れお',
        HNames: 'H283882',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ58',
    note: '',
    absoluteMagnitude: 20.86,
    semimajorAxis: 2.29506,
    eccentricity: 0.1323234,
    inclination: 8.63874,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '42',
        HNames: 'H263149',
      },
      {
        userName: 'aika',
        HNames: 'H296701',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312232',
      },
    ],
  },
  {
    provisionalCode: '2017 CT58',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.51,
    semimajorAxis: 90.90758,
    eccentricity: 0.5190333,
    inclination: 11.52509,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H267516, H319971, H319979, H320001',
      },
    ],
  },
  {
    provisionalCode: '2017 CU58',
    note: '',
    absoluteMagnitude: 17.91,
    semimajorAxis: 2.90351,
    eccentricity: 0.1437655,
    inclination: 16.20004,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'arda',
        HNames: 'H268296',
      },
      {
        userName: 'nasiar99',
        HNames: 'H268296',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310658, H310661',
      },
    ],
  },
  {
    provisionalCode: '2017 CW58',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.38,
    semimajorAxis: 5.29457,
    eccentricity: 0.128472,
    inclination: 9.35779,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H265766',
      },
      {
        userName: '42',
        HNames: 'H265766',
      },
      {
        userName: 'aika',
        HNames: 'H265766',
      },
    ],
  },
  {
    provisionalCode: '2017 CX58',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.17974,
    eccentricity: 0.0820735,
    inclination: 8.14761,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220776',
      },
      {
        userName: 'もしもス',
        HNames: 'H265767',
      },
      {
        userName: '42',
        HNames: 'H265767',
      },
    ],
  },
  {
    provisionalCode: '2017 CY58',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.14728,
    eccentricity: 0.0583561,
    inclination: 16.09856,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H267218',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267218',
      },
      {
        userName: 'arda',
        HNames: 'H282868',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ58',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.93029,
    eccentricity: 0.1018702,
    inclination: 10.82171,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267226',
      },
      {
        userName: 'aika',
        HNames: 'H288630, H319592',
      },
      {
        userName: 'もしもス',
        HNames: 'H288630',
      },
    ],
  },
  {
    provisionalCode: '2017 CA59',
    note: '',
    absoluteMagnitude: 20.92,
    semimajorAxis: 2.42715,
    eccentricity: 0.0740671,
    inclination: 7.38309,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H266595',
      },
      {
        userName: 'aika',
        HNames: 'H267915',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267915',
      },
      {
        userName: '42',
        HNames: 'H267915',
      },
    ],
  },
  {
    provisionalCode: '2017 CB59',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 3.05591,
    eccentricity: 0.0439382,
    inclination: 7.78947,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ko',
        HNames: 'H262920',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267227',
      },
      {
        userName: 'もしもス',
        HNames: 'H267917',
      },
      {
        userName: '42',
        HNames: 'H267917',
      },
    ],
  },
  {
    provisionalCode: '2017 CC59',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 3.12774,
    eccentricity: 0.2162973,
    inclination: 8.44589,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220426',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H220426',
      },
      {
        userName: 'belleequipe',
        HNames: 'H220426',
      },
      {
        userName: '42',
        HNames: 'H265772',
      },
    ],
  },
  {
    provisionalCode: '2017 CD59',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 3.02967,
    eccentricity: 0.1553762,
    inclination: 6.22524,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H265765',
      },
      {
        userName: 'apollo18',
        HNames: 'H310958',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H310958',
      },
      {
        userName: 'aika',
        HNames: 'H319593',
      },
    ],
  },
  {
    provisionalCode: '2017 CE59',
    note: 'Hilda',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.99166,
    eccentricity: 0.1926869,
    inclination: 7.75962,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H245615, H311367',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245615',
      },
    ],
  },
  {
    provisionalCode: '2017 CF59',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 2.61116,
    eccentricity: 0.2546865,
    inclination: 9.40453,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263148',
      },
      {
        userName: 'aika',
        HNames: 'H263148',
      },
      {
        userName: '42',
        HNames: 'H263148',
      },
    ],
  },
  {
    provisionalCode: '2017 CG59',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.18773,
    eccentricity: 0.0064505,
    inclination: 7.5774,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H263150, H291081',
      },
      {
        userName: '42',
        HNames: 'H263150',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H291081',
      },
    ],
  },
  {
    provisionalCode: '2017 CH59',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.1927,
    eccentricity: 0.0536448,
    inclination: 8.00832,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245616',
      },
      {
        userName: 'nasiar99',
        HNames: 'H265888',
      },
      {
        userName: '42',
        HNames: 'H265888',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ59',
    note: '',
    absoluteMagnitude: 19.41,
    semimajorAxis: 3.16541,
    eccentricity: 0.0741623,
    inclination: 8.11538,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H262353, H262564',
      },
      {
        userName: 'ほたる',
        HNames: 'H280622',
      },
      {
        userName: 'arda',
        HNames: 'H291080',
      },
    ],
  },
  {
    provisionalCode: '2017 CK59',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.71136,
    eccentricity: 0.0517629,
    inclination: 8.96324,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H262585, H262603',
      },
      {
        userName: 'aika',
        HNames: 'H262603',
      },
    ],
  },
  {
    provisionalCode: '2017 DV167',
    note: '',
    absoluteMagnitude: 18.27,
    semimajorAxis: 3.18818,
    eccentricity: 0.082823,
    inclination: 7.66933,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H247950, H273189',
      },
      {
        userName: 'arda',
        HNames: 'H247950',
      },
    ],
  },
  {
    provisionalCode: '2017 DW167',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.41,
    semimajorAxis: 5.2263,
    eccentricity: 0.0684688,
    inclination: 18.82809,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260775',
      },
      {
        userName: 'aika',
        HNames: 'H260775',
      },
      {
        userName: 'zorome',
        HNames: 'H273322',
      },
    ],
  },
  {
    provisionalCode: '2017 DY167',
    note: '',
    absoluteMagnitude: 20.53,
    semimajorAxis: 2.64475,
    eccentricity: 0.100185,
    inclination: 9.1906,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H290409',
      },
      {
        userName: 'yakuba',
        HNames: 'H292145',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313058, H315151',
      },
    ],
  },
  {
    provisionalCode: '2017 DE168',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.51,
    semimajorAxis: 87.32148,
    eccentricity: 0.3902938,
    inclination: 24.36507,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H316844',
      },
    ],
  },
  {
    provisionalCode: '2017 DF168',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.25,
    semimajorAxis: 55.9349,
    eccentricity: 0.2713845,
    inclination: 4.47131,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317001',
      },
    ],
  },
  {
    provisionalCode: '2017 DG168',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 3.0302,
    eccentricity: 0.2246473,
    inclination: 9.6039,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221331',
      },
      {
        userName: 'apollo18',
        HNames: 'H221331',
      },
      {
        userName: 'kn1cht',
        HNames: 'H260784',
      },
    ],
  },
  {
    provisionalCode: '2017 DH168',
    note: 'Hungaria',
    absoluteMagnitude: 20.94,
    semimajorAxis: 1.85822,
    eccentricity: 0.0847175,
    inclination: 24.11218,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242163',
      },
      {
        userName: 'aika',
        HNames: 'H242163',
      },
      {
        userName: 'ほたる',
        HNames: 'H242163',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ168',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 3.09768,
    eccentricity: 0.0940831,
    inclination: 15.90236,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260798',
      },
      {
        userName: 'aika',
        HNames: 'H260798',
      },
      {
        userName: 'nasiar99',
        HNames: 'H271895',
      },
    ],
  },
  {
    provisionalCode: '2017 DK168',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.15184,
    eccentricity: 0.0575554,
    inclination: 8.03416,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267327',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H267327',
      },
      {
        userName: 'arda',
        HNames: 'H267327',
      },
      {
        userName: 'ほたる',
        HNames: 'H267327',
      },
    ],
  },
  {
    provisionalCode: '2017 DL168',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 2.98673,
    eccentricity: 0.0717979,
    inclination: 6.54969,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267356, H271893',
      },
      {
        userName: 'aika',
        HNames: 'H271893',
      },
      {
        userName: 'arda',
        HNames: 'H291791',
      },
    ],
  },
  {
    provisionalCode: '2017 DM168',
    note: '',
    absoluteMagnitude: 21.52,
    semimajorAxis: 2.2665,
    eccentricity: 0.0426779,
    inclination: 6.01355,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H275862',
      },
      {
        userName: 'aika',
        HNames: 'H275862',
      },
      {
        userName: 'ほたる',
        HNames: 'H275862',
      },
    ],
  },
  {
    provisionalCode: '2017 DN168',
    note: '',
    absoluteMagnitude: 21.58,
    semimajorAxis: 2.66926,
    eccentricity: 0.2032054,
    inclination: 5.64405,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H285299',
      },
      {
        userName: 'aika',
        HNames: 'H285299',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285299',
      },
      {
        userName: 'ほたる',
        HNames: 'H285299',
      },
    ],
  },
  {
    provisionalCode: '2017 DO168',
    note: '',
    absoluteMagnitude: 17.73,
    semimajorAxis: 3.17063,
    eccentricity: 0.0817261,
    inclination: 7.79502,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H239146, H239171',
      },
      {
        userName: 'nasiar99',
        HNames: 'H239171, H267324',
      },
      {
        userName: 'ko',
        HNames: 'H267747',
      },
    ],
  },
  {
    provisionalCode: '2017 DP168',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 3.22629,
    eccentricity: 0.2051777,
    inclination: 19.08997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H239172',
      },
      {
        userName: 'aika',
        HNames: 'H239172',
      },
      {
        userName: 'kn1cht',
        HNames: 'H239172',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ168',
    note: '',
    absoluteMagnitude: 17.61,
    semimajorAxis: 3.01522,
    eccentricity: 0.1064473,
    inclination: 9.63366,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260256',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260256, H267336',
      },
      {
        userName: 'ほたる',
        HNames: 'H282446',
      },
    ],
  },
  {
    provisionalCode: '2017 DR168',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.65243,
    eccentricity: 0.2841352,
    inclination: 11.10178,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H270134',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270134',
      },
    ],
  },
  {
    provisionalCode: '2017 DS168',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.61,
    semimajorAxis: 5.23888,
    eccentricity: 0.0041553,
    inclination: 27.92035,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H260777',
      },
      {
        userName: 'kn1cht',
        HNames: 'H260777',
      },
      {
        userName: 'れお',
        HNames: 'H260777',
      },
    ],
  },
  {
    provisionalCode: '2017 DT168',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.58666,
    eccentricity: 0.1030163,
    inclination: 7.84828,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H260778',
      },
      {
        userName: 'aika',
        HNames: 'H295537',
      },
    ],
  },
  {
    provisionalCode: '2017 DU168',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 2.66674,
    eccentricity: 0.1242554,
    inclination: 8.12866,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H265402',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310721',
      },
    ],
  },
  {
    provisionalCode: '2017 DV168',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.2,
    semimajorAxis: 5.2289,
    eccentricity: 0.0388837,
    inclination: 9.40484,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H268132',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H282106',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282106',
      },
      {
        userName: 'aika',
        HNames: 'H292281',
      },
    ],
  },
  {
    provisionalCode: '2017 DW168',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 3.0806,
    eccentricity: 0.0991925,
    inclination: 17.38941,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H268135, H292282',
      },
      {
        userName: 'もしもス',
        HNames: 'H268135',
      },
      {
        userName: 'arda',
        HNames: 'H268135',
      },
    ],
  },
  {
    provisionalCode: '2017 DX168',
    note: '',
    absoluteMagnitude: 20.51,
    semimajorAxis: 3.04531,
    eccentricity: 0.0217381,
    inclination: 7.58837,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H275592',
      },
      {
        userName: 'れお',
        HNames: 'H275592',
      },
    ],
  },
  {
    provisionalCode: '2017 DY168',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.92578,
    eccentricity: 0.0863245,
    inclination: 10.99083,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H275775',
      },
      {
        userName: 'aika',
        HNames: 'H275775, H295538',
      },
      {
        userName: 'れお',
        HNames: 'H295538',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ168',
    note: '',
    absoluteMagnitude: 20.29,
    semimajorAxis: 3.02958,
    eccentricity: 0.0923362,
    inclination: 7.99758,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275866',
      },
      {
        userName: 'aika',
        HNames: 'H275866',
      },
      {
        userName: 'れお',
        HNames: 'H275866',
      },
    ],
  },
  {
    provisionalCode: '2017 DA169',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.06715,
    eccentricity: 0.1381962,
    inclination: 18.10477,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H220867',
      },
      {
        userName: 'aika',
        HNames: 'H220867',
      },
      {
        userName: 'apollo18',
        HNames: 'H220867',
      },
      {
        userName: 'ko',
        HNames: 'H267742',
      },
    ],
  },
  {
    provisionalCode: '2017 DB169',
    note: '',
    absoluteMagnitude: 19.97,
    semimajorAxis: 2.56575,
    eccentricity: 0.0986659,
    inclination: 14.19568,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262783, H276222',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H262783',
      },
      {
        userName: 'arda',
        HNames: 'H266449',
      },
    ],
  },
  {
    provisionalCode: '2017 DC169',
    note: '',
    absoluteMagnitude: 19.83,
    semimajorAxis: 2.88927,
    eccentricity: 0.0764493,
    inclination: 12.60717,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267240',
      },
      {
        userName: 'arda',
        HNames: 'H267240',
      },
      {
        userName: 'aika',
        HNames: 'H267240',
      },
    ],
  },
  {
    provisionalCode: '2017 DD169',
    note: '',
    absoluteMagnitude: 21.22,
    semimajorAxis: 2.65411,
    eccentricity: 0.0578217,
    inclination: 7.2824,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272335',
      },
      {
        userName: 'aika',
        HNames: 'H272335',
      },
      {
        userName: 'れお',
        HNames: 'H272335',
      },
    ],
  },
  {
    provisionalCode: '2017 DE169',
    note: '2004 TS176, 2015 XL358と同定',
    absoluteMagnitude: 18.46,
    semimajorAxis: 3.079,
    eccentricity: 0.2159286,
    inclination: 9.49295,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H284905',
      },
      {
        userName: 'arda',
        HNames: 'H284905',
      },
      {
        userName: 'れお',
        HNames: 'H284905, H292656',
      },
    ],
  },
  {
    provisionalCode: '2017 DF169',
    note: '',
    absoluteMagnitude: 20.7,
    semimajorAxis: 2.73425,
    eccentricity: 0.1419724,
    inclination: 10.91297,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267334',
      },
      {
        userName: 'arda',
        HNames: 'H284906',
      },
      {
        userName: 'れお',
        HNames: 'H284906',
      },
      {
        userName: 'wanko',
        HNames: 'H306227',
      },
    ],
  },
  {
    provisionalCode: '2017 DG169',
    note: '2015 SH2と同定',
    absoluteMagnitude: 20.02,
    semimajorAxis: 2.20823,
    eccentricity: 0.2319974,
    inclination: 6.13222,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H284907',
      },
      {
        userName: 'arda',
        HNames: 'H284907',
      },
      {
        userName: 'れお',
        HNames: 'H284907',
      },
    ],
  },
  {
    provisionalCode: '2017 DH169',
    note: '',
    absoluteMagnitude: 17.74,
    semimajorAxis: 3.16583,
    eccentricity: 0.0178375,
    inclination: 14.55313,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H228862',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282110',
      },
      {
        userName: 'れお',
        HNames: 'H285865',
      },
      {
        userName: 'nasiar99',
        HNames: 'H285865',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ169',
    note: '',
    absoluteMagnitude: 21.12,
    semimajorAxis: 2.67997,
    eccentricity: 0.2210032,
    inclination: 16.50916,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242339',
      },
      {
        userName: 'aika',
        HNames: 'H257255',
      },
      {
        userName: 'えでぃ',
        HNames: 'H257255',
      },
      {
        userName: 'nasiar99',
        HNames: 'H283188',
      },
    ],
  },
  {
    provisionalCode: '2017 DK169',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.02419,
    eccentricity: 0.0245836,
    inclination: 8.04124,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H252801',
      },
      {
        userName: 'aika',
        HNames: 'H252801',
      },
      {
        userName: 'arda',
        HNames: 'H274786',
      },
    ],
  },
  {
    provisionalCode: '2017 DL169',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 3.06654,
    eccentricity: 0.0980259,
    inclination: 11.05577,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H268146',
      },
      {
        userName: 'arda',
        HNames: 'H268146',
      },
      {
        userName: 'aika',
        HNames: 'H268146',
      },
    ],
  },
  {
    provisionalCode: '2017 DM169',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.90551,
    eccentricity: 0.1991337,
    inclination: 8.78291,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H268237',
      },
      {
        userName: 'aika',
        HNames: 'H268237',
      },
      {
        userName: 'ko',
        HNames: 'H273117',
      },
    ],
  },
  {
    provisionalCode: '2017 DN169',
    note: '',
    absoluteMagnitude: 19.84,
    semimajorAxis: 3.06118,
    eccentricity: 0.1948987,
    inclination: 17.6384,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272364',
      },
      {
        userName: 'aika',
        HNames: 'H272364',
      },
      {
        userName: 'れお',
        HNames: 'H272364',
      },
    ],
  },
  {
    provisionalCode: '2017 DO169',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 2.27226,
    eccentricity: 0.1327597,
    inclination: 5.48534,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H233747, H283655',
      },
      {
        userName: 'nasiar99',
        HNames: 'H283655',
      },
      {
        userName: 'ほたる',
        HNames: 'H286224',
      },
    ],
  },
  {
    provisionalCode: '2017 DP169',
    note: '',
    absoluteMagnitude: 18.38,
    semimajorAxis: 3.14273,
    eccentricity: 0.2032961,
    inclination: 9.55641,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H252868',
      },
      {
        userName: 'aika',
        HNames: 'H252868',
      },
      {
        userName: 'れお',
        HNames: 'H252868',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ169',
    note: '',
    absoluteMagnitude: 20.25,
    semimajorAxis: 2.62754,
    eccentricity: 0.0881727,
    inclination: 11.25539,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256274, H284963',
      },
      {
        userName: 'arda',
        HNames: 'H270888',
      },
    ],
  },
  {
    provisionalCode: '2017 DR169',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.76669,
    eccentricity: 0.1007514,
    inclination: 10.08632,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266661',
      },
      {
        userName: 'もしもス',
        HNames: 'H266661',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H313600',
      },
    ],
  },
  {
    provisionalCode: '2017 DS169',
    note: '',
    absoluteMagnitude: 20.16,
    semimajorAxis: 2.24407,
    eccentricity: 0.168837,
    inclination: 6.99316,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266663',
      },
      {
        userName: 'arda',
        HNames: 'H266663',
      },
      {
        userName: 'もしもス',
        HNames: 'H266663',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H313599',
      },
    ],
  },
  {
    provisionalCode: '2017 DT169',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.15034,
    eccentricity: 0.0475149,
    inclination: 8.9214,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257271',
      },
      {
        userName: 'arda',
        HNames: 'H266665, H280748',
      },
      {
        userName: 'もしもス',
        HNames: 'H266665',
      },
    ],
  },
  {
    provisionalCode: '2017 DU169',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 2.97296,
    eccentricity: 0.0918194,
    inclination: 9.91555,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'aika',
        HNames: 'H233748',
      },
      {
        userName: 'nasiar99',
        HNames: 'H279006',
      },
      {
        userName: 'えでぃ',
        HNames: 'H279006',
      },
      {
        userName: 'arda',
        HNames: 'H279006',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H302757',
      },
    ],
  },
  {
    provisionalCode: '2017 DV169',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.99,
    semimajorAxis: 5.22512,
    eccentricity: 0.1217615,
    inclination: 10.09633,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256268, H279497',
      },
      {
        userName: 'れお',
        HNames: 'H279497',
      },
    ],
  },
  {
    provisionalCode: '2017 DW169',
    note: '2023 TL158と同定',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.63983,
    eccentricity: 0.1687224,
    inclination: 12.67641,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241745',
      },
      {
        userName: 'ほたる',
        HNames: 'H241745',
      },
    ],
  },
  {
    provisionalCode: '2017 DX169',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.02737,
    eccentricity: 0.0766771,
    inclination: 10.4284,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H241997',
      },
      {
        userName: 'zorome',
        HNames: 'H241997',
      },
      {
        userName: 'aika',
        HNames: 'H241997',
      },
      {
        userName: 'もしもス',
        HNames: 'H277921',
      },
    ],
  },
  {
    provisionalCode: '2017 DY169',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 3.00804,
    eccentricity: 0.1954137,
    inclination: 7.46371,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241999',
      },
      {
        userName: 'aika',
        HNames: 'H241999',
      },
    ],
  },
  {
    provisionalCode: '2017 DZ169',
    note: '',
    absoluteMagnitude: 17.87,
    semimajorAxis: 2.79491,
    eccentricity: 0.0524763,
    inclination: 11.62047,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262794',
      },
      {
        userName: '☆野ー暉',
        HNames: 'H307719',
      },
    ],
  },
  {
    provisionalCode: '2017 DA170',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 3.99031,
    eccentricity: 0.3183655,
    inclination: 6.97306,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H272386',
      },
      {
        userName: 'aika',
        HNames: 'H272386',
      },
    ],
  },
  {
    provisionalCode: '2017 DB170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.77,
    semimajorAxis: 46.01853,
    eccentricity: 0.1297109,
    inclination: 4.19367,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H276115',
      },
    ],
  },
  {
    provisionalCode: '2017 EF55',
    note: '',
    absoluteMagnitude: 18.16,
    semimajorAxis: 3.19422,
    eccentricity: 0.0551491,
    inclination: 8.867,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288094',
      },
      {
        userName: 'aika',
        HNames: 'H288094',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288094',
      },
    ],
  },
  {
    provisionalCode: '2017 EG55',
    note: '',
    absoluteMagnitude: 18.01,
    semimajorAxis: 2.97804,
    eccentricity: 0.0793242,
    inclination: 8.50137,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288390',
      },
      {
        userName: 'aika',
        HNames: 'H288390, H295175',
      },
    ],
  },
  {
    provisionalCode: '2017 EH55',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.63911,
    eccentricity: 0.2190859,
    inclination: 11.27952,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H221325',
      },
      {
        userName: 'aika',
        HNames: 'H296386',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296386',
      },
    ],
  },
  {
    provisionalCode: '2017 EJ55',
    note: '2023 FY29と同定',
    absoluteMagnitude: 17.92,
    semimajorAxis: 3.13942,
    eccentricity: 0.0646658,
    inclination: 9.92432,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H278256, H304623',
      },
      {
        userName: 'belleequipe',
        HNames: 'H278256',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H304623',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304623',
      },
    ],
  },
  {
    provisionalCode: '2017 EM55',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 2.97415,
    eccentricity: 0.1350058,
    inclination: 8.76898,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246375, H282975',
      },
    ],
  },
  {
    provisionalCode: '2017 EN55',
    note: '',
    absoluteMagnitude: 20.01,
    semimajorAxis: 2.89135,
    eccentricity: 0.0639676,
    inclination: 6.00449,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H246987, H282994',
      },
      {
        userName: 'nasiar99',
        HNames: 'H246987',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311824',
      },
    ],
  },
  {
    provisionalCode: '2017 EO55',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 3.13851,
    eccentricity: 0.0364133,
    inclination: 8.31047,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270276',
      },
      {
        userName: 'arda',
        HNames: 'H270276',
      },
    ],
  },
  {
    provisionalCode: '2017 EP55',
    note: '',
    absoluteMagnitude: 18.7,
    semimajorAxis: 3.16162,
    eccentricity: 0.0424254,
    inclination: 7.90665,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270277',
      },
      {
        userName: 'arda',
        HNames: 'H270277',
      },
      {
        userName: 'aika',
        HNames: 'H270277',
      },
    ],
  },
  {
    provisionalCode: '2017 EQ55',
    note: '',
    absoluteMagnitude: 20.45,
    semimajorAxis: 2.36906,
    eccentricity: 0.0693286,
    inclination: 5.3517,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270278',
      },
      {
        userName: 'arda',
        HNames: 'H270278',
      },
      {
        userName: 'aika',
        HNames: 'H270278',
      },
    ],
  },
  {
    provisionalCode: '2017 ER55',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 2.46211,
    eccentricity: 0.0408249,
    inclination: 9.039,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'aika',
        HNames: 'H270899, H303543',
      },
      {
        userName: 'arda',
        HNames: 'H270899',
      },
      {
        userName: 'yakuba',
        HNames: 'H303464',
      },
    ],
  },
  {
    provisionalCode: '2017 ES55',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.91898,
    eccentricity: 0.0404125,
    inclination: 10.24103,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H270903',
      },
      {
        userName: 'arda',
        HNames: 'H270903',
      },
      {
        userName: 'aika',
        HNames: 'H270903',
      },
    ],
  },
  {
    provisionalCode: '2017 ET55',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.99536,
    eccentricity: 0.059503,
    inclination: 6.58324,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H303572, H306254',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H303572',
      },
    ],
  },
  {
    provisionalCode: '2017 EU55',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 3.14752,
    eccentricity: 0.1985139,
    inclination: 7.69016,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241909',
      },
      {
        userName: 'arda',
        HNames: 'H281859',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313014',
      },
    ],
  },
  {
    provisionalCode: '2017 EV55',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 3.16594,
    eccentricity: 0.0485776,
    inclination: 8.84011,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242015',
      },
      {
        userName: 'arda',
        HNames: 'H286289',
      },
      {
        userName: 'ossan',
        HNames: 'H308375',
      },
    ],
  },
  {
    provisionalCode: '2017 EW55',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 3.07451,
    eccentricity: 0.0418582,
    inclination: 18.78897,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '42',
        HNames: 'H261425',
      },
      {
        userName: 'arda',
        HNames: 'H290960',
      },
      {
        userName: 'theoria',
        HNames: 'H312171',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313042',
      },
    ],
  },
  {
    provisionalCode: '2017 EX55',
    note: '',
    absoluteMagnitude: 20.58,
    semimajorAxis: 2.34756,
    eccentricity: 0.0684573,
    inclination: 6.11425,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H268471',
      },
      {
        userName: 'arda',
        HNames: 'H268471',
      },
      {
        userName: 'yakuba',
        HNames: 'H303465',
      },
    ],
  },
  {
    provisionalCode: '2017 EY55',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.09217,
    eccentricity: 0.0564179,
    inclination: 9.63499,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H270255',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270255',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270255',
      },
    ],
  },
  {
    provisionalCode: '2017 EZ55',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.57887,
    eccentricity: 0.1774988,
    inclination: 7.73414,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H278997',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H278997',
      },
      {
        userName: 'maria w',
        HNames: 'H281394',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312256',
      },
    ],
  },
  {
    provisionalCode: '2017 EA56',
    note: '',
    absoluteMagnitude: 19.97,
    semimajorAxis: 2.7121,
    eccentricity: 0.1471934,
    inclination: 13.24367,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H280804',
      },
      {
        userName: 'arda',
        HNames: 'H280804',
      },
      {
        userName: 'nasiar99',
        HNames: 'H280804',
      },
    ],
  },
  {
    provisionalCode: '2017 EB56',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 2.77803,
    eccentricity: 0.2270341,
    inclination: 11.47723,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H281860',
      },
      {
        userName: 'arda',
        HNames: 'H281860',
      },
      {
        userName: 'bny',
        HNames: 'H303296',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312621, H312632',
      },
    ],
  },
  {
    provisionalCode: '2017 EC56',
    note: '',
    absoluteMagnitude: 18.17,
    semimajorAxis: 2.99245,
    eccentricity: 0.0968639,
    inclination: 9.43473,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H237333',
      },
      {
        userName: 'もしもス',
        HNames: 'H246968',
      },
      {
        userName: 'ほたる',
        HNames: 'H284879',
      },
      {
        userName: 'nasiar99',
        HNames: 'H284879',
      },
    ],
  },
  {
    provisionalCode: '2017 ED56',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.59426,
    eccentricity: 0.1851531,
    inclination: 8.98001,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H290957',
      },
      {
        userName: 'arda',
        HNames: 'H290957, H290961',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312275',
      },
    ],
  },
  {
    provisionalCode: '2017 EH56',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.88,
    semimajorAxis: 43.62372,
    eccentricity: 0.0786313,
    inclination: 5.25834,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H269761, H269771',
      },
    ],
  },
  {
    provisionalCode: '2017 FV241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.16,
    semimajorAxis: 47.50392,
    eccentricity: 0.3401882,
    inclination: 8.51034,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H282083',
      },
    ],
  },
  {
    provisionalCode: '2017 FW241',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.14,
    semimajorAxis: 36.26998,
    eccentricity: 0.0712724,
    inclination: 11.61615,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H282080',
      },
    ],
  },
  {
    provisionalCode: '2017 GT36',
    note: '',
    absoluteMagnitude: 20.53,
    semimajorAxis: 2.65152,
    eccentricity: 0.2078736,
    inclination: 11.74448,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244417, H288674',
      },
      {
        userName: 'nasiar99',
        HNames: 'H244417',
      },
    ],
  },
  {
    provisionalCode: '2017 GU36',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.14,
    semimajorAxis: 43.92514,
    eccentricity: 0.0763766,
    inclination: 6.08458,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H290345',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295048',
      },
    ],
  },
  {
    provisionalCode: '2017 HZ112',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 3.09649,
    eccentricity: 0.1314676,
    inclination: 19.61699,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266885',
      },
      {
        userName: 'aika',
        HNames: 'H282084',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282084',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H282084',
      },
    ],
  },
  {
    provisionalCode: '2017 HA113',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 2.58643,
    eccentricity: 0.1674364,
    inclination: 12.1021,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H219192, H297815',
      },
      {
        userName: 'apollo18',
        HNames: 'H219192, H221933',
      },
      {
        userName: 'arda',
        HNames: 'H297815',
      },
    ],
  },
  {
    provisionalCode: '2018 AR81',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 3.09279,
    eccentricity: 0.0745372,
    inclination: 11.34527,
    oppositions: 1,
    uncertainty: 3,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H302215',
      },
    ],
  },
  {
    provisionalCode: '2018 FL73',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.97,
    semimajorAxis: 44.79758,
    eccentricity: 0.1233937,
    inclination: 3.46017,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H307993, H307996',
      },
    ],
  },
  {
    provisionalCode: '2018 HN13',
    note: '',
    absoluteMagnitude: 17.97,
    semimajorAxis: 2.91873,
    eccentricity: 0.0063687,
    inclination: 2.50191,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H311505',
      },
    ],
  },
  {
    provisionalCode: '2018 HG14',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.93,
    semimajorAxis: 5.17453,
    eccentricity: 0.073133,
    inclination: 32.22261,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'としちゃん',
        HNames: 'H239350',
      },
    ],
  },
  {
    provisionalCode: '2019 AG142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.56,
    semimajorAxis: 46.66317,
    eccentricity: 0.232742,
    inclination: 8.34093,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H231324, H293286, H293349',
      },
    ],
  },
  {
    provisionalCode: '2019 AJ142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.62,
    semimajorAxis: 41.90452,
    eccentricity: 0.2415334,
    inclination: 6.06423,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H226744, H292347, H293013',
      },
    ],
  },
  {
    provisionalCode: '2019 AK142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.35,
    semimajorAxis: 60.39607,
    eccentricity: 0.4021293,
    inclination: 18.16968,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H230434, H257371, H293010',
      },
    ],
  },
  {
    provisionalCode: '2019 AL142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.04,
    semimajorAxis: 46.37013,
    eccentricity: 0.1961248,
    inclination: 11.09941,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H231981, H233656, H279070',
      },
    ],
  },
  {
    provisionalCode: '2019 AM142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.71,
    semimajorAxis: 72.15078,
    eccentricity: 0.5559993,
    inclination: 9.91373,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H230255, H256971',
      },
    ],
  },
  {
    provisionalCode: '2019 AN142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.22,
    semimajorAxis: 93.96023,
    eccentricity: 0.5938579,
    inclination: 14.76708,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H233951, H279025, H310277',
      },
    ],
  },
  {
    provisionalCode: '2019 AO142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.11,
    semimajorAxis: 138.78075,
    eccentricity: 0.7179681,
    inclination: 24.88957,
    oppositions: 4,
    uncertainty: 3,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H234038, H317461, H317467',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249722',
      },
    ],
  },
  {
    provisionalCode: '2019 AP142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.02,
    semimajorAxis: 18.28305,
    eccentricity: 0.2392308,
    inclination: 17.91573,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248690, H254324, H261448',
      },
    ],
  },
  {
    provisionalCode: '2019 AQ142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.55,
    semimajorAxis: 33.38396,
    eccentricity: 0.1007828,
    inclination: 15.20692,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248969, H261491, H261493',
      },
    ],
  },
  {
    provisionalCode: '2019 AS142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.5,
    semimajorAxis: 61.6969,
    eccentricity: 0.3576792,
    inclination: 33.95383,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249050, H254398, H263403',
      },
    ],
  },
  {
    provisionalCode: '2019 AT142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.34,
    semimajorAxis: 36.33095,
    eccentricity: 0.1849412,
    inclination: 18.2157,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H253823, H255722, H255873',
      },
    ],
  },
  {
    provisionalCode: '2019 AZ142',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.16,
    semimajorAxis: 53.40735,
    eccentricity: 0.4584608,
    inclination: 14.77462,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248607, H256136',
      },
    ],
  },
  {
    provisionalCode: '2019 AH143',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.51801,
    eccentricity: 0.0246405,
    inclination: 5.19131,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H231913',
      },
      {
        userName: 'yakuba',
        HNames: 'H254527',
      },
    ],
  },
  {
    provisionalCode: '2019 CB31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.02,
    semimajorAxis: 42.59975,
    eccentricity: 0.0592057,
    inclination: 6.31294,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286775, H286787',
      },
    ],
  },
  {
    provisionalCode: '2019 CC31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.08,
    semimajorAxis: 49.69311,
    eccentricity: 0.2773303,
    inclination: 17.2543,
    oppositions: 4,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235196, H287537, H287539',
      },
    ],
  },
  {
    provisionalCode: '2019 EF8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.65,
    semimajorAxis: 46.3265,
    eccentricity: 0.3081272,
    inclination: 17.14192,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252511, H253352, H260985',
      },
    ],
  },
  {
    provisionalCode: '2019 EG8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.37,
    semimajorAxis: 45.25636,
    eccentricity: 0.0889982,
    inclination: 11.49571,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H254411, H294637, H294655',
      },
    ],
  },
  {
    provisionalCode: '2019 EH8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.95,
    semimajorAxis: 42.90396,
    eccentricity: 0.1241965,
    inclination: 26.25285,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H256044, H261416, H294925',
      },
    ],
  },
  {
    provisionalCode: '2019 EM8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.27,
    semimajorAxis: 42.56618,
    eccentricity: 0.0795118,
    inclination: 10.11895,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H263190, H263952, H264005',
      },
    ],
  },
  {
    provisionalCode: '2019 GX185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 11.52,
    semimajorAxis: 19.6484,
    eccentricity: 0.4053358,
    inclination: 10.22847,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235238, H236091',
      },
      {
        userName: 'sasaki',
        HNames: 'H235238',
      },
    ],
  },
  {
    provisionalCode: '2019 GF186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.68,
    semimajorAxis: 72.88721,
    eccentricity: 0.4948215,
    inclination: 11.72444,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H246241',
      },
      {
        userName: 'nasiar99',
        HNames: 'H257339',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313105',
      },
    ],
  },
  {
    provisionalCode: '2019 GG186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.84,
    semimajorAxis: 44.11372,
    eccentricity: 0.1741228,
    inclination: 12.75329,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H227533, H230171, H310272',
      },
    ],
  },
  {
    provisionalCode: '2019 GH186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.62,
    semimajorAxis: 47.00022,
    eccentricity: 0.1512257,
    inclination: 29.46846,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228181, H230820',
      },
    ],
  },
  {
    provisionalCode: '2019 GJ186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.93,
    semimajorAxis: 34.37945,
    eccentricity: 0.2814093,
    inclination: 24.79495,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H232906',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249275',
      },
    ],
  },
  {
    provisionalCode: '2019 GL186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.46,
    semimajorAxis: 38.96793,
    eccentricity: 0.1640991,
    inclination: 6.71278,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H256474, H292191, H319434',
      },
    ],
  },
  {
    provisionalCode: '2019 GM186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.07,
    semimajorAxis: 41.54662,
    eccentricity: 0.0709712,
    inclination: 6.225,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H256823, H257559, H321080',
      },
    ],
  },
  {
    provisionalCode: '2019 GN186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.03,
    semimajorAxis: 46.74981,
    eccentricity: 0.1677566,
    inclination: 21.2176,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H257235, H257496, H310547',
      },
    ],
  },
  {
    provisionalCode: '2019 GO186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.52,
    semimajorAxis: 47.48399,
    eccentricity: 0.2662322,
    inclination: 14.63171,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H258593, H259252, H310581',
      },
    ],
  },
  {
    provisionalCode: '2019 GP186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.75,
    semimajorAxis: 96.07129,
    eccentricity: 0.6277633,
    inclination: 29.30797,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H258602, H259289, H259384',
      },
    ],
  },
  {
    provisionalCode: '2019 GQ186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.93,
    semimajorAxis: 46.4026,
    eccentricity: 0.0767212,
    inclination: 19.86055,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H317293, H317295',
      },
    ],
  },
  {
    provisionalCode: '2019 GU186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.99,
    semimajorAxis: 41.99888,
    eccentricity: 0.2177973,
    inclination: 25.69619,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228171, H319449',
      },
    ],
  },
  {
    provisionalCode: '2019 GX186',
    note: 'NEO (Apollo)',
    absoluteMagnitude: 24.76,
    semimajorAxis: 2.48561,
    eccentricity: 0.7484469,
    inclination: 8.24646,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H236379',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H284671',
      },
    ],
  },
  {
    provisionalCode: '2019 UY176',
    note: '',
    absoluteMagnitude: 22.17,
    semimajorAxis: 2.27991,
    eccentricity: 0.1727098,
    inclination: 8.14032,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214274',
      },
      {
        userName: 'ms',
        HNames: 'H320331, H320355',
      },
    ],
  },
  {
    provisionalCode: '2019 UA177',
    note: '',
    absoluteMagnitude: 21.01,
    semimajorAxis: 2.7505,
    eccentricity: 0.1524658,
    inclination: 8.91931,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217067, H217874',
      },
    ],
  },
  {
    provisionalCode: '2019 UB177',
    note: '',
    absoluteMagnitude: 21.43,
    semimajorAxis: 2.20956,
    eccentricity: 0.1445286,
    inclination: 3.24186,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219725, H229875, H321042',
      },
    ],
  },
  {
    provisionalCode: '2019 UC177',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.81491,
    eccentricity: 0.1757598,
    inclination: 3.37591,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223756, H224420',
      },
    ],
  },
  {
    provisionalCode: '2019 UD177',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 3.12791,
    eccentricity: 0.0679841,
    inclination: 8.86889,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213875',
      },
      {
        userName: 'さんしろう',
        HNames: 'H215925, H227173',
      },
      {
        userName: 'mi zhang',
        HNames: 'H314290',
      },
    ],
  },
  {
    provisionalCode: '2019 UE177',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 3.17325,
    eccentricity: 0.0450702,
    inclination: 6.10648,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213154, H214933, H220160',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H220160',
      },
    ],
  },
  {
    provisionalCode: '2019 UF177',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 3.0853,
    eccentricity: 0.1990303,
    inclination: 14.1648,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H230129',
      },
      {
        userName: 'れお',
        HNames: 'H257890, H260643',
      },
    ],
  },
  {
    provisionalCode: '2019 VY53',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.7082,
    eccentricity: 0.2360878,
    inclination: 9.06279,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217937',
      },
      {
        userName: 'kn1cht',
        HNames: 'H219591',
      },
      {
        userName: 'ms',
        HNames: 'H320330',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ53',
    note: '',
    absoluteMagnitude: 21.15,
    semimajorAxis: 2.83966,
    eccentricity: 0.0438383,
    inclination: 4.54331,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219690, H274422',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H219690',
      },
    ],
  },
  {
    provisionalCode: '2019 VA54',
    note: 'Hungaria',
    absoluteMagnitude: 22.7,
    semimajorAxis: 1.89043,
    eccentricity: 0.0718973,
    inclination: 19.69862,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H256006, H271266',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H256006',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271266',
      },
    ],
  },
  {
    provisionalCode: '2019 VB54',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.87,
    semimajorAxis: 5.15077,
    eccentricity: 0.0373508,
    inclination: 19.13346,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289259',
      },
      {
        userName: 'ms',
        HNames: 'H320351, H320806, H320826',
      },
    ],
  },
  {
    provisionalCode: '2019 VC54',
    note: '',
    absoluteMagnitude: 20.92,
    semimajorAxis: 2.53459,
    eccentricity: 0.0597455,
    inclination: 6.10929,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H215037, H321016',
      },
      {
        userName: 'さんしろう',
        HNames: 'H231070',
      },
    ],
  },
  {
    provisionalCode: '2019 VD54',
    note: '',
    absoluteMagnitude: 21.36,
    semimajorAxis: 2.27853,
    eccentricity: 0.1510126,
    inclination: 7.51979,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217305',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223798',
      },
    ],
  },
  {
    provisionalCode: '2019 VE54',
    note: '',
    absoluteMagnitude: 20.69,
    semimajorAxis: 2.66135,
    eccentricity: 0.0333251,
    inclination: 2.05554,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H216969, H218221, H218222',
      },
    ],
  },
  {
    provisionalCode: '2019 VF54',
    note: '',
    absoluteMagnitude: 20.96,
    semimajorAxis: 2.70866,
    eccentricity: 0.0847125,
    inclination: 2.11461,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218922',
      },
      {
        userName: 'aika',
        HNames: 'H317935',
      },
      {
        userName: 'えでぃ',
        HNames: 'H318556',
      },
    ],
  },
  {
    provisionalCode: '2019 VG54',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.59624,
    eccentricity: 0.2293831,
    inclination: 12.96881,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221585, H225307',
      },
    ],
  },
  {
    provisionalCode: '2019 VH54',
    note: '',
    absoluteMagnitude: 21.73,
    semimajorAxis: 2.27366,
    eccentricity: 0.2221179,
    inclination: 2.3457,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H221642',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H321071',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ54',
    note: '',
    absoluteMagnitude: 20.56,
    semimajorAxis: 2.53264,
    eccentricity: 0.2204869,
    inclination: 8.21452,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222373, H225310',
      },
    ],
  },
  {
    provisionalCode: '2019 VK54',
    note: '',
    absoluteMagnitude: 21.01,
    semimajorAxis: 2.1194,
    eccentricity: 0.1275955,
    inclination: 2.19675,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222376',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H225843',
      },
    ],
  },
  {
    provisionalCode: '2019 VL54',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 2.8713,
    eccentricity: 0.2120503,
    inclination: 5.96163,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223155',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H321089',
      },
    ],
  },
  {
    provisionalCode: '2019 VN54',
    note: '',
    absoluteMagnitude: 21.03,
    semimajorAxis: 2.71889,
    eccentricity: 0.0913315,
    inclination: 2.77337,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228831, H229279',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229279',
      },
      {
        userName: 'クメン',
        HNames: 'H233602',
      },
    ],
  },
  {
    provisionalCode: '2019 VO54',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 2.85213,
    eccentricity: 0.029588,
    inclination: 2.01197,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214419, H217055, H218971',
      },
      {
        userName: 'このしろ',
        HNames: 'H214419',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ54',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.57,
    semimajorAxis: 5.13585,
    eccentricity: 0.0292349,
    inclination: 5.73359,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219386, H220143',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H220143',
      },
    ],
  },
  {
    provisionalCode: '2019 WO37',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 3.12994,
    eccentricity: 0.0416149,
    inclination: 29.355,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H263982',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263982',
      },
    ],
  },
  {
    provisionalCode: '2019 WP37',
    note: '',
    absoluteMagnitude: 21.08,
    semimajorAxis: 2.53116,
    eccentricity: 0.1021476,
    inclination: 12.82638,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267576, H320762',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267576',
      },
      {
        userName: 'れお',
        HNames: 'H299193',
      },
    ],
  },
  {
    provisionalCode: '2019 WQ37',
    note: '',
    absoluteMagnitude: 21.0,
    semimajorAxis: 3.00162,
    eccentricity: 0.1046944,
    inclination: 16.54629,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H267578',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H320073, H320780',
      },
    ],
  },
  {
    provisionalCode: '2019 WR37',
    note: '',
    absoluteMagnitude: 21.0,
    semimajorAxis: 3.07692,
    eccentricity: 0.1655287,
    inclination: 14.75593,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287399, H320782',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287399',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313711',
      },
    ],
  },
  {
    provisionalCode: '2019 WS37',
    note: '',
    absoluteMagnitude: 21.61,
    semimajorAxis: 2.54973,
    eccentricity: 0.0969044,
    inclination: 11.90763,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H293580, H299168',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H293580, H320785',
      },
      {
        userName: 'arda',
        HNames: 'H293580',
      },
    ],
  },
  {
    provisionalCode: '2019 WT37',
    note: '',
    absoluteMagnitude: 21.94,
    semimajorAxis: 3.15249,
    eccentricity: 0.2545208,
    inclination: 15.69201,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H296761',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296761',
      },
    ],
  },
  {
    provisionalCode: '2019 WU37',
    note: '',
    absoluteMagnitude: 22.7,
    semimajorAxis: 2.59089,
    eccentricity: 0.2054011,
    inclination: 10.56382,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H296762',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H296762',
      },
    ],
  },
  {
    provisionalCode: '2019 WV37',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.58289,
    eccentricity: 0.0824552,
    inclination: 16.13419,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H297728',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297728',
      },
    ],
  },
  {
    provisionalCode: '2019 WW37',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.93,
    semimajorAxis: 5.28771,
    eccentricity: 0.0982056,
    inclination: 4.83819,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217766, H219221',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217766',
      },
    ],
  },
  {
    provisionalCode: '2019 WX37',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.58486,
    eccentricity: 0.2123673,
    inclination: 2.71057,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224432',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H224432',
      },
      {
        userName: '赵经远',
        HNames: 'H227852',
      },
      {
        userName: 'aika',
        HNames: 'H229067',
      },
    ],
  },
  {
    provisionalCode: '2019 WY37',
    note: 'Hilda',
    absoluteMagnitude: 18.56,
    semimajorAxis: 3.89611,
    eccentricity: 0.2172164,
    inclination: 4.62325,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H212449, H297660',
      },
      {
        userName: 'devastrotech',
        HNames: 'H212449',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219895',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ37',
    note: '',
    absoluteMagnitude: 20.72,
    semimajorAxis: 3.14427,
    eccentricity: 0.1752325,
    inclination: 15.40796,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H253468, H253470',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H253470',
      },
      {
        userName: 'れお',
        HNames: 'H299209',
      },
    ],
  },
  {
    provisionalCode: '2017 BR242',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.17673,
    eccentricity: 0.1083404,
    inclination: 5.47704,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H261080',
      },
      {
        userName: 'もしもス',
        HNames: 'H261080',
      },
      {
        userName: 'kn1cht',
        HNames: 'H274187',
      },
      {
        userName: 'れお',
        HNames: 'H280093',
      },
    ],
  },
  {
    provisionalCode: '2017 BS242',
    note: '',
    absoluteMagnitude: 20.56,
    semimajorAxis: 2.79802,
    eccentricity: 0.1494514,
    inclination: 7.65502,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251733',
      },
      {
        userName: 'もしもス',
        HNames: 'H262579',
      },
      {
        userName: '42',
        HNames: 'H262579, H262681',
      },
    ],
  },
  {
    provisionalCode: '2017 BT242',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.74223,
    eccentricity: 0.1561939,
    inclination: 8.30956,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251950',
      },
      {
        userName: 'もしもス',
        HNames: 'H262580',
      },
      {
        userName: '42',
        HNames: 'H262580, H262682',
      },
    ],
  },
  {
    provisionalCode: '2017 BU242',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.06159,
    eccentricity: 0.057486,
    inclination: 9.77962,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252197',
      },
      {
        userName: '42',
        HNames: 'H262555, H263132',
      },
      {
        userName: 'もしもス',
        HNames: 'H263132',
      },
    ],
  },
  {
    provisionalCode: '2017 BV242',
    note: '',
    absoluteMagnitude: 17.94,
    semimajorAxis: 3.18881,
    eccentricity: 0.0536368,
    inclination: 9.73527,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251773',
      },
      {
        userName: '42',
        HNames: 'H262619, H262654',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312964, H312971',
      },
    ],
  },
  {
    provisionalCode: '2017 BW242',
    note: '',
    absoluteMagnitude: 20.89,
    semimajorAxis: 2.70274,
    eccentricity: 0.1636624,
    inclination: 6.36564,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242107, H272214',
      },
      {
        userName: 'ko',
        HNames: 'H281350',
      },
    ],
  },
  {
    provisionalCode: '2017 BX242',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 2.73422,
    eccentricity: 0.1718497,
    inclination: 6.97791,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242108, H251496',
      },
    ],
  },
  {
    provisionalCode: '2017 BY242',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 2.55575,
    eccentricity: 0.0506987,
    inclination: 21.68811,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H233453',
      },
      {
        userName: 'れお',
        HNames: 'H251149',
      },
      {
        userName: 'zorome',
        HNames: 'H251149',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ242',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 2.84899,
    eccentricity: 0.0650284,
    inclination: 7.34866,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251840',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H310852',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312965, H312972',
      },
    ],
  },
  {
    provisionalCode: '2017 BA243',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.03,
    semimajorAxis: 5.1615,
    eccentricity: 0.0678318,
    inclination: 8.05612,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H285284',
      },
      {
        userName: 'ほたる',
        HNames: 'H285284',
      },
      {
        userName: 'aika',
        HNames: 'H296421',
      },
    ],
  },
  {
    provisionalCode: '2017 BB243',
    note: '',
    absoluteMagnitude: 17.71,
    semimajorAxis: 3.14668,
    eccentricity: 0.1294703,
    inclination: 6.48773,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H219915',
      },
      {
        userName: 'apollo18',
        HNames: 'H219915',
      },
      {
        userName: 'もしもス',
        HNames: 'H219915',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267802',
      },
    ],
  },
  {
    provisionalCode: '2017 BC243',
    note: '2017 CX64と同定',
    absoluteMagnitude: 18.78,
    semimajorAxis: 2.9897,
    eccentricity: 0.0325905,
    inclination: 13.15594,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242023',
      },
      {
        userName: 'えでぃ',
        HNames: 'H242023',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H280454',
      },
    ],
  },
  {
    provisionalCode: '2017 BD243',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.74338,
    eccentricity: 0.1635358,
    inclination: 8.50636,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H250739, H272406',
      },
    ],
  },
  {
    provisionalCode: '2017 BE243',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.72311,
    eccentricity: 0.2457523,
    inclination: 8.48284,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H266687',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266687, H288299',
      },
    ],
  },
  {
    provisionalCode: '2017 BF243',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.17378,
    eccentricity: 0.067665,
    inclination: 7.46476,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H266689, H280245',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266689',
      },
    ],
  },
  {
    provisionalCode: '2017 BG243',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.28026,
    eccentricity: 0.0750976,
    inclination: 5.16039,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267792, H277827',
      },
    ],
  },
  {
    provisionalCode: '2017 BH243',
    note: '',
    absoluteMagnitude: 18.52,
    semimajorAxis: 3.02742,
    eccentricity: 0.2728597,
    inclination: 6.96527,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H277836, H289869',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ243',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.09835,
    eccentricity: 0.22588,
    inclination: 9.15967,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H277846, H284219, H289943',
      },
    ],
  },
  {
    provisionalCode: '2017 BK243',
    note: '',
    absoluteMagnitude: 18.44,
    semimajorAxis: 3.17403,
    eccentricity: 0.0474897,
    inclination: 7.70705,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267793, H277907',
      },
    ],
  },
  {
    provisionalCode: '2017 BL243',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 3.12479,
    eccentricity: 0.0501271,
    inclination: 10.65723,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279061',
      },
      {
        userName: 'aika',
        HNames: 'H279061',
      },
      {
        userName: 'nasiar99',
        HNames: 'H289509',
      },
    ],
  },
  {
    provisionalCode: '2017 BM243',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.80608,
    eccentricity: 0.0630238,
    inclination: 8.76483,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267801, H281939',
      },
    ],
  },
  {
    provisionalCode: '2017 BN243',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 3.15086,
    eccentricity: 0.0760551,
    inclination: 7.86693,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H284176, H289868',
      },
    ],
  },
  {
    provisionalCode: '2017 BO243',
    note: '',
    absoluteMagnitude: 18.06,
    semimajorAxis: 2.74816,
    eccentricity: 0.121436,
    inclination: 7.3701,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H241077',
      },
      {
        userName: 'nasiar99',
        HNames: 'H241077, H288727',
      },
    ],
  },
  {
    provisionalCode: '2017 BP243',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.18046,
    eccentricity: 0.0392272,
    inclination: 7.71005,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H266698',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266698, H288728',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ243',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.67129,
    eccentricity: 0.1435154,
    inclination: 13.3709,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H273125',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288729',
      },
    ],
  },
  {
    provisionalCode: '2017 BR243',
    note: '',
    absoluteMagnitude: 20.45,
    semimajorAxis: 2.74456,
    eccentricity: 0.1691406,
    inclination: 8.98307,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H273131',
      },
      {
        userName: 'aika',
        HNames: 'H273131',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288726',
      },
    ],
  },
  {
    provisionalCode: '2017 BS243',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.6628,
    eccentricity: 0.0560659,
    inclination: 12.54307,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H277845',
      },
      {
        userName: 'もしもス',
        HNames: 'H280243',
      },
    ],
  },
  {
    provisionalCode: '2017 BT243',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 3.16455,
    eccentricity: 0.0684574,
    inclination: 8.42956,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279065',
      },
      {
        userName: 'aika',
        HNames: 'H279065',
      },
      {
        userName: 'nasiar99',
        HNames: 'H290398',
      },
    ],
  },
  {
    provisionalCode: '2017 BU243',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 3.1056,
    eccentricity: 0.0553391,
    inclination: 10.55188,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279380',
      },
      {
        userName: 'aika',
        HNames: 'H279380',
      },
      {
        userName: 'れお',
        HNames: 'H294000',
      },
    ],
  },
  {
    provisionalCode: '2017 BV243',
    note: '',
    absoluteMagnitude: 19.58,
    semimajorAxis: 2.53551,
    eccentricity: 0.0937921,
    inclination: 11.26853,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H268293',
      },
      {
        userName: 'aika',
        HNames: 'H268293',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H268293',
      },
      {
        userName: 'れお',
        HNames: 'H284460',
      },
    ],
  },
  {
    provisionalCode: '2017 BW243',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.79519,
    eccentricity: 0.0980896,
    inclination: 12.70002,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272232',
      },
      {
        userName: 'れお',
        HNames: 'H280629, H280693',
      },
      {
        userName: 'ほたる',
        HNames: 'H288985',
      },
    ],
  },
  {
    provisionalCode: '2017 BX243',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 3.07865,
    eccentricity: 0.1135436,
    inclination: 7.87556,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272411, H280263',
      },
    ],
  },
  {
    provisionalCode: '2017 BY243',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 2.7557,
    eccentricity: 0.1784789,
    inclination: 8.92997,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272412, H280262',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ243',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 2.68104,
    eccentricity: 0.1099368,
    inclination: 6.44991,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H272417',
      },
      {
        userName: 'れお',
        HNames: 'H283048',
      },
    ],
  },
  {
    provisionalCode: '2017 BA244',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 3.18744,
    eccentricity: 0.1030918,
    inclination: 9.11753,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H273127',
      },
      {
        userName: 'kn1cht',
        HNames: 'H286521',
      },
    ],
  },
  {
    provisionalCode: '2017 BB244',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 3.06484,
    eccentricity: 0.1878333,
    inclination: 8.81372,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273724',
      },
      {
        userName: 'もしもス',
        HNames: 'H280248',
      },
    ],
  },
  {
    provisionalCode: '2017 BC244',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.10928,
    eccentricity: 0.1877446,
    inclination: 8.08245,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276231',
      },
      {
        userName: 'れお',
        HNames: 'H281320',
      },
    ],
  },
  {
    provisionalCode: '2017 BD244',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.90086,
    eccentricity: 0.1937408,
    inclination: 8.85006,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H278401',
      },
      {
        userName: 'れお',
        HNames: 'H280695',
      },
    ],
  },
  {
    provisionalCode: '2017 BE244',
    note: '',
    absoluteMagnitude: 21.42,
    semimajorAxis: 2.34838,
    eccentricity: 0.1002613,
    inclination: 5.86022,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H279550',
      },
      {
        userName: 'もしもス',
        HNames: 'H279550',
      },
      {
        userName: 'れお',
        HNames: 'H279598',
      },
    ],
  },
  {
    provisionalCode: '2017 BF244',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 2.33364,
    eccentricity: 0.1451683,
    inclination: 7.03911,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'aika',
        HNames: 'H279731',
      },
      {
        userName: 'もしもス',
        HNames: 'H280246, H286411',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311050, H311053',
      },
    ],
  },
  {
    provisionalCode: '2017 BG244',
    note: '',
    absoluteMagnitude: 18.85,
    semimajorAxis: 3.16493,
    eccentricity: 0.2189547,
    inclination: 10.57735,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242385',
      },
      {
        userName: 'arda',
        HNames: 'H242385',
      },
      {
        userName: 'れお',
        HNames: 'H283900',
      },
    ],
  },
  {
    provisionalCode: '2017 BH244',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 3.2139,
    eccentricity: 0.0675979,
    inclination: 9.82107,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249500',
      },
      {
        userName: 'れお',
        HNames: 'H284865',
      },
      {
        userName: 'ほたる',
        HNames: 'H287194',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ244',
    note: '',
    absoluteMagnitude: 19.13,
    semimajorAxis: 3.06733,
    eccentricity: 0.0869668,
    inclination: 8.14783,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H249900',
      },
      {
        userName: 'zorome',
        HNames: 'H249900',
      },
      {
        userName: 'れお',
        HNames: 'H284866',
      },
    ],
  },
  {
    provisionalCode: '2017 BK244',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 3.11611,
    eccentricity: 0.0668107,
    inclination: 10.48694,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259042',
      },
      {
        userName: 'もしもス',
        HNames: 'H259042',
      },
      {
        userName: 'れお',
        HNames: 'H282995',
      },
    ],
  },
  {
    provisionalCode: '2017 BL244',
    note: '',
    absoluteMagnitude: 19.83,
    semimajorAxis: 2.75851,
    eccentricity: 0.0713571,
    inclination: 6.0546,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H273377',
      },
      {
        userName: 'もしもス',
        HNames: 'H273377',
      },
      {
        userName: 'れお',
        HNames: 'H280091',
      },
    ],
  },
  {
    provisionalCode: '2017 BM244',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.08319,
    eccentricity: 0.0364986,
    inclination: 7.51213,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H281410',
      },
      {
        userName: 'もしもス',
        HNames: 'H282197',
      },
      {
        userName: 'れお',
        HNames: 'H284445',
      },
      {
        userName: 's.ozawa',
        HNames: 'H311843',
      },
    ],
  },
  {
    provisionalCode: '2017 BN244',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 3.09977,
    eccentricity: 0.0906443,
    inclination: 9.84891,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276074, H283629',
      },
      {
        userName: 'れお',
        HNames: 'H279503',
      },
    ],
  },
  {
    provisionalCode: '2017 BO244',
    note: '',
    absoluteMagnitude: 19.81,
    semimajorAxis: 2.90714,
    eccentricity: 0.0491477,
    inclination: 12.00973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276075',
      },
      {
        userName: 'れお',
        HNames: 'H283906',
      },
    ],
  },
  {
    provisionalCode: '2017 BP244',
    note: '',
    absoluteMagnitude: 17.92,
    semimajorAxis: 3.34957,
    eccentricity: 0.1063394,
    inclination: 8.2037,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H276576',
      },
      {
        userName: 'れお',
        HNames: 'H283082',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ244',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.62222,
    eccentricity: 0.0356604,
    inclination: 10.6969,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H277446',
      },
      {
        userName: 'れお',
        HNames: 'H283083',
      },
    ],
  },
  {
    provisionalCode: '2017 BR244',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.75,
    semimajorAxis: 5.2659,
    eccentricity: 0.0733134,
    inclination: 8.44969,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H279318',
      },
      {
        userName: 'aika',
        HNames: 'H279318',
      },
      {
        userName: 'れお',
        HNames: 'H280094',
      },
    ],
  },
  {
    provisionalCode: '2017 CL59',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.21394,
    eccentricity: 0.0604868,
    inclination: 7.54193,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245614, H245660',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245614',
      },
      {
        userName: 'もしもス',
        HNames: 'H245660',
      },
      {
        userName: '42',
        HNames: 'H263183',
      },
    ],
  },
  {
    provisionalCode: '2017 CM59',
    note: '',
    absoluteMagnitude: 22.32,
    semimajorAxis: 2.24376,
    eccentricity: 0.1038055,
    inclination: 5.98908,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248118, H251731',
      },
      {
        userName: 'aika',
        HNames: 'H248118',
      },
    ],
  },
  {
    provisionalCode: '2017 CN59',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 2.68362,
    eccentricity: 0.1375823,
    inclination: 8.86188,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248119',
      },
      {
        userName: 'aika',
        HNames: 'H271577',
      },
      {
        userName: 'もしもス',
        HNames: 'H274124',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312430, H312431',
      },
    ],
  },
  {
    provisionalCode: '2017 CO59',
    note: '',
    absoluteMagnitude: 18.63,
    semimajorAxis: 3.10059,
    eccentricity: 0.1078176,
    inclination: 10.00521,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H259110, H259120',
      },
      {
        userName: 'ほたる',
        HNames: 'H259120',
      },
    ],
  },
  {
    provisionalCode: '2017 CP59',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 2.54302,
    eccentricity: 0.1102062,
    inclination: 16.44285,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H259123',
      },
      {
        userName: 'れお',
        HNames: 'H259123',
      },
      {
        userName: 'nasiar99',
        HNames: 'H259123',
      },
      {
        userName: '42',
        HNames: 'H265886',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ59',
    note: '',
    absoluteMagnitude: 20.51,
    semimajorAxis: 2.70842,
    eccentricity: 0.1083243,
    inclination: 6.24032,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262582',
      },
      {
        userName: '42',
        HNames: 'H262582, H262683',
      },
    ],
  },
  {
    provisionalCode: '2017 CR59',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 2.67617,
    eccentricity: 0.1645817,
    inclination: 7.65389,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H262577, H263131',
      },
      {
        userName: 'もしもス',
        HNames: 'H263131',
      },
    ],
  },
  {
    provisionalCode: '2017 CS59',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.58297,
    eccentricity: 0.1161871,
    inclination: 13.59004,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256356',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H256356',
      },
      {
        userName: 'arda',
        HNames: 'H266005',
      },
    ],
  },
  {
    provisionalCode: '2017 CT59',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.54144,
    eccentricity: 0.1169594,
    inclination: 15.04804,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H240757',
      },
      {
        userName: 'aika',
        HNames: 'H240757',
      },
      {
        userName: 'zorome',
        HNames: 'H260687',
      },
    ],
  },
  {
    provisionalCode: '2017 CU59',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.55624,
    eccentricity: 0.2463463,
    inclination: 5.60947,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H265863',
      },
      {
        userName: '42',
        HNames: 'H265863',
      },
      {
        userName: 'はじめ',
        HNames: 'H312524',
      },
    ],
  },
  {
    provisionalCode: '2017 CV59',
    note: '',
    absoluteMagnitude: 21.07,
    semimajorAxis: 2.24842,
    eccentricity: 0.10067,
    inclination: 6.03746,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H265871',
      },
      {
        userName: 'ほたる',
        HNames: 'H280191',
      },
    ],
  },
  {
    provisionalCode: '2017 CW59',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 16.89,
    semimajorAxis: 5.69077,
    eccentricity: 0.1956709,
    inclination: 25.25606,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H241649',
      },
      {
        userName: '42',
        HNames: 'H263110',
      },
    ],
  },
  {
    provisionalCode: '2017 CX59',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 2.98267,
    eccentricity: 0.0388781,
    inclination: 9.49741,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H263126, H263163',
      },
      {
        userName: 'ほたる',
        HNames: 'H285328',
      },
    ],
  },
  {
    provisionalCode: '2017 FB242',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.16016,
    eccentricity: 0.1094524,
    inclination: 11.6488,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262879, H262898',
      },
      {
        userName: 'arda',
        HNames: 'H262898',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312420',
      },
    ],
  },
  {
    provisionalCode: '2017 FC242',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 3.04427,
    eccentricity: 0.0699711,
    inclination: 8.08216,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H241148',
      },
      {
        userName: '赵经远',
        HNames: 'H241148',
      },
      {
        userName: 'ほたる',
        HNames: 'H300648',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310777',
      },
    ],
  },
  {
    provisionalCode: '2017 FD242',
    note: '',
    absoluteMagnitude: 18.42,
    semimajorAxis: 3.14671,
    eccentricity: 0.0445949,
    inclination: 8.04913,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H221319, H221888',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244476',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288633',
      },
    ],
  },
  {
    provisionalCode: '2017 FE242',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.66121,
    eccentricity: 0.1041983,
    inclination: 12.118,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H264261',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H264261',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H264261',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312850, H314882',
      },
    ],
  },
  {
    provisionalCode: '2017 FF242',
    note: '',
    absoluteMagnitude: 19.32,
    semimajorAxis: 3.19107,
    eccentricity: 0.191728,
    inclination: 23.99078,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H264262',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H264262, H282096',
      },
      {
        userName: 'れお',
        HNames: 'H282096',
      },
      {
        userName: 'sasaki',
        HNames: 'H310254',
      },
    ],
  },
  {
    provisionalCode: '2017 FG242',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 3.04891,
    eccentricity: 0.1368284,
    inclination: 8.97983,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263375',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H263375, H289671',
      },
    ],
  },
  {
    provisionalCode: '2019 UQ177',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 2.19137,
    eccentricity: 0.0630345,
    inclination: 2.4579,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H219423, H229065',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H229445',
      },
    ],
  },
  {
    provisionalCode: '2019 VS54',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 2.3332,
    eccentricity: 0.1272928,
    inclination: 2.59831,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217401',
      },
      {
        userName: 'aika',
        HNames: 'H222408, H228867',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228867',
      },
    ],
  },
  {
    provisionalCode: '2019 WC38',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.32499,
    eccentricity: 0.137206,
    inclination: 8.3118,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219952',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228175, H228868',
      },
      {
        userName: 'aika',
        HNames: 'H228868',
      },
    ],
  },
  {
    provisionalCode: '2016 ES363',
    note: '',
    absoluteMagnitude: 18.23,
    semimajorAxis: 3.17046,
    eccentricity: 0.1224402,
    inclination: 8.50548,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324262, H324369, H324458',
      },
    ],
  },
  {
    provisionalCode: '2016 ET363',
    note: '',
    absoluteMagnitude: 17.97,
    semimajorAxis: 3.10616,
    eccentricity: 0.1502221,
    inclination: 13.52385,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324330, H324526, H324585',
      },
    ],
  },
  {
    provisionalCode: '2016 EU363',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 3.15669,
    eccentricity: 0.1086936,
    inclination: 12.88669,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324403, H326073, H326093',
      },
    ],
  },
  {
    provisionalCode: '2016 EB364',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.15,
    semimajorAxis: 52.58884,
    eccentricity: 0.4927501,
    inclination: 11.14131,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H323123, H323219, H323260, H323286',
      },
    ],
  },
  {
    provisionalCode: '2016 EF364',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.21,
    semimajorAxis: 40.54658,
    eccentricity: 0.0595499,
    inclination: 20.30188,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H326362, H326390, H326489',
      },
    ],
  },
  {
    provisionalCode: '2016 EG364',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.77,
    semimajorAxis: 42.7062,
    eccentricity: 0.2164398,
    inclination: 15.54621,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H328012, H328115, H328149, H328175',
      },
    ],
  },
  {
    provisionalCode: '2016 EH364',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 3.16165,
    eccentricity: 0.0791198,
    inclination: 8.85139,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324528, H326160, H326220',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ364',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 3.14233,
    eccentricity: 0.2559376,
    inclination: 13.21905,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H326074, H328037, H328095',
      },
    ],
  },
  {
    provisionalCode: '2016 EK364',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 3.13555,
    eccentricity: 0.1064586,
    inclination: 11.99542,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328038, H328314, H328380',
      },
    ],
  },
  {
    provisionalCode: '2016 EL364',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 3.0014,
    eccentricity: 0.0655405,
    inclination: 8.83112,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328059, H328155, H328448',
      },
    ],
  },
  {
    provisionalCode: '2016 EM364',
    note: '',
    absoluteMagnitude: 20.84,
    semimajorAxis: 2.69793,
    eccentricity: 0.1541429,
    inclination: 5.87217,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328157, H328599, H328924',
      },
    ],
  },
  {
    provisionalCode: '2016 EN364',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.16124,
    eccentricity: 0.1969487,
    inclination: 8.0418,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324407, H328158, H329222',
      },
    ],
  },
  {
    provisionalCode: '2016 ET364',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.87129,
    eccentricity: 0.0776187,
    inclination: 10.05442,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323474',
      },
      {
        userName: 'えでぃ',
        HNames: 'H326192, H326219',
      },
    ],
  },
  {
    provisionalCode: '2016 EU364',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 2.21644,
    eccentricity: 0.091938,
    inclination: 5.61168,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324181, H325312',
      },
      {
        userName: 'yakuba',
        HNames: 'H325312',
      },
      {
        userName: 'えでぃ',
        HNames: 'H328316',
      },
    ],
  },
  {
    provisionalCode: '2016 ED365',
    note: '',
    absoluteMagnitude: 20.97,
    semimajorAxis: 2.29813,
    eccentricity: 0.0377114,
    inclination: 5.52894,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328329, H332151',
      },
      {
        userName: 'aika',
        HNames: 'H330190',
      },
    ],
  },
  {
    provisionalCode: '2016 EE365',
    note: '',
    absoluteMagnitude: 21.96,
    semimajorAxis: 2.66074,
    eccentricity: 0.132871,
    inclination: 8.40529,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328506, H328549, H332755, H332778',
      },
    ],
  },
  {
    provisionalCode: '2016 EF365',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 3.12288,
    eccentricity: 0.0801705,
    inclination: 8.58696,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332777, H332933, H336844',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ365',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.2,
    semimajorAxis: 42.43588,
    eccentricity: 0.0302742,
    inclination: 11.38376,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H335400, H335406, H337130, H341291',
      },
    ],
  },
  {
    provisionalCode: '2016 EB366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.57,
    semimajorAxis: 56.29552,
    eccentricity: 0.4049446,
    inclination: 11.12107,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H336790, H336830, H339117, H341075',
      },
    ],
  },
  {
    provisionalCode: '2016 EC366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.98,
    semimajorAxis: 157.93804,
    eccentricity: 0.754658,
    inclination: 37.36749,
    oppositions: 4,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286142',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H335558, H339259',
      },
    ],
  },
  {
    provisionalCode: '2016 ED366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.48,
    semimajorAxis: 46.87088,
    eccentricity: 0.3491709,
    inclination: 22.32827,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H335607, H339279',
      },
    ],
  },
  {
    provisionalCode: '2016 EE366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.28,
    semimajorAxis: 95.33779,
    eccentricity: 0.6943015,
    inclination: 10.67503,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H336726, H336751, H340923, H340955',
      },
    ],
  },
  {
    provisionalCode: '2016 EF366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.41,
    semimajorAxis: 39.14014,
    eccentricity: 0.1763086,
    inclination: 18.27456,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H339968, H340216, H340226, H340775',
      },
    ],
  },
  {
    provisionalCode: '2016 EG366',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.2,
    semimajorAxis: 70.14476,
    eccentricity: 0.4258633,
    inclination: 33.56325,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H339972, H340795, H340799',
      },
    ],
  },
  {
    provisionalCode: '2016 EN366',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.99814,
    eccentricity: 0.0804712,
    inclination: 9.59099,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H324609, H326703',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324609',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324615',
      },
    ],
  },
  {
    provisionalCode: '2016 EY366',
    note: '',
    absoluteMagnitude: 18.51,
    semimajorAxis: 3.07392,
    eccentricity: 0.0655524,
    inclination: 8.32499,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H323533',
      },
      {
        userName: 'arda',
        HNames: 'H323533',
      },
      {
        userName: 'えでぃ',
        HNames: 'H328446',
      },
      {
        userName: 'aika',
        HNames: 'H330138',
      },
    ],
  },
  {
    provisionalCode: '2016 EA367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.48,
    semimajorAxis: 41.44068,
    eccentricity: 0.2728785,
    inclination: 32.89783,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341362, H341363, H344284',
      },
    ],
  },
  {
    provisionalCode: '2016 EB367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.75,
    semimajorAxis: 43.67381,
    eccentricity: 0.1387807,
    inclination: 10.9661,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H346154, H346170, H346180',
      },
    ],
  },
  {
    provisionalCode: '2016 FF185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.41,
    semimajorAxis: 40.49836,
    eccentricity: 0.3197817,
    inclination: 12.1387,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H321662, H321754, H321799, H321846',
      },
    ],
  },
  {
    provisionalCode: '2016 FK185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.46,
    semimajorAxis: 46.37996,
    eccentricity: 0.1822572,
    inclination: 16.64988,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H327444, H327482, H327501, H327530',
      },
    ],
  },
  {
    provisionalCode: '2016 FL185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.67,
    semimajorAxis: 100.34671,
    eccentricity: 0.6807259,
    inclination: 19.10893,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H328393, H328490, H328503',
      },
    ],
  },
  {
    provisionalCode: '2016 FX185',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.35,
    semimajorAxis: 77.28418,
    eccentricity: 0.5382294,
    inclination: 9.9138,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H339892, H339922, H340897',
      },
    ],
  },
  {
    provisionalCode: '2016 FE186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.86,
    semimajorAxis: 44.53704,
    eccentricity: 0.1864482,
    inclination: 29.59023,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H343979, H344016, H344022, H344056',
      },
    ],
  },
  {
    provisionalCode: '2016 FF186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.16,
    semimajorAxis: 47.77931,
    eccentricity: 0.2505158,
    inclination: 11.40128,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H343964, H350026, H350031',
      },
    ],
  },
  {
    provisionalCode: '2016 FG186',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.84,
    semimajorAxis: 49.94327,
    eccentricity: 0.244427,
    inclination: 10.50292,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H348387, H348411, H348424',
      },
    ],
  },
  {
    provisionalCode: '2016 GJ367',
    note: '2004 DG64と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294135',
      },
      {
        userName: 'nasiar99',
        HNames: 'H326353',
      },
    ],
  },
  {
    provisionalCode: '2016 GR367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.95,
    semimajorAxis: 45.83751,
    eccentricity: 0.260787,
    inclination: 10.58064,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H324908, H324927, H324949, H324956',
      },
    ],
  },
  {
    provisionalCode: '2016 GW367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.36,
    semimajorAxis: 137.6134,
    eccentricity: 0.7482664,
    inclination: 16.43935,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H325477, H325478, H325556',
      },
    ],
  },
  {
    provisionalCode: '2016 GX367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.67,
    semimajorAxis: 54.68969,
    eccentricity: 0.4533151,
    inclination: 12.87429,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H329228, H329236, H329287, H329320',
      },
    ],
  },
  {
    provisionalCode: '2016 GD368',
    note: '',
    absoluteMagnitude: 21.84,
    semimajorAxis: 2.33569,
    eccentricity: 0.0282461,
    inclination: 6.63532,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H323102, H326260',
      },
      {
        userName: 'もしもス',
        HNames: 'H326260',
      },
      {
        userName: 'れお',
        HNames: 'H326260',
      },
    ],
  },
  {
    provisionalCode: '2016 GH368',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.80383,
    eccentricity: 0.0450809,
    inclination: 1.5845,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H325133',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H329386',
      },
      {
        userName: 'ほたる',
        HNames: 'H331316',
      },
    ],
  },
  {
    provisionalCode: '2016 GK368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.63,
    semimajorAxis: 62.36489,
    eccentricity: 0.4769786,
    inclination: 10.95226,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324969, H337469',
      },
    ],
  },
  {
    provisionalCode: '2016 GL368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.21,
    semimajorAxis: 43.9661,
    eccentricity: 0.071151,
    inclination: 1.17999,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325042, H337564',
      },
    ],
  },
  {
    provisionalCode: '2016 GN368',
    note: '2016 GV28と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H323907, H327191',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H328297',
      },
      {
        userName: 'れお',
        HNames: 'H330132',
      },
    ],
  },
  {
    provisionalCode: '2016 GR368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.45,
    semimajorAxis: 43.89943,
    eccentricity: 0.1124,
    inclination: 17.99667,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H330920, H330944, H330969, H330984, H331010, H341009',
      },
    ],
  },
  {
    provisionalCode: '2016 GU368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.59,
    semimajorAxis: 113.85083,
    eccentricity: 0.8105919,
    inclination: 0.96553,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338944, H339015',
      },
    ],
  },
  {
    provisionalCode: '2016 GV368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.84,
    semimajorAxis: 43.82079,
    eccentricity: 0.1006332,
    inclination: 0.82596,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325795',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338963',
      },
    ],
  },
  {
    provisionalCode: '2016 GY368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.41,
    semimajorAxis: 82.70312,
    eccentricity: 0.7142033,
    inclination: 2.14193,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325339',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338893',
      },
    ],
  },
  {
    provisionalCode: '2016 GZ368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.47,
    semimajorAxis: 44.25128,
    eccentricity: 0.3197904,
    inclination: 4.55062,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325406',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338903',
      },
    ],
  },
  {
    provisionalCode: '2016 GA369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.36,
    semimajorAxis: 133.58291,
    eccentricity: 0.8492027,
    inclination: 31.52229,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338946, H339073',
      },
    ],
  },
  {
    provisionalCode: '2016 GB369',
    note: '2022 HK5と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H329210, H339224',
      },
    ],
  },
  {
    provisionalCode: '2016 GC369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.27,
    semimajorAxis: 45.36406,
    eccentricity: 0.1572383,
    inclination: 14.60527,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H331042, H331049, H331069',
      },
    ],
  },
  {
    provisionalCode: '2016 GD369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.12,
    semimajorAxis: 64.03477,
    eccentricity: 0.3694801,
    inclination: 25.88693,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H331257, H331312, H331347',
      },
    ],
  },
  {
    provisionalCode: '2016 GH369',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.88416,
    eccentricity: 0.0265307,
    inclination: 2.57648,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H323561, H324270, H327420',
      },
    ],
  },
  {
    provisionalCode: '2016 GJ369',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 2.85446,
    eccentricity: 0.1048531,
    inclination: 2.05496,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H323870',
      },
      {
        userName: 'nasiar99',
        HNames: 'H326882',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H328017',
      },
    ],
  },
  {
    provisionalCode: '2016 GL369',
    note: '',
    absoluteMagnitude: 20.43,
    semimajorAxis: 2.86832,
    eccentricity: 0.0114432,
    inclination: 2.26711,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H323304, H325862',
      },
      {
        userName: 'れお',
        HNames: 'H325862, H331519',
      },
    ],
  },
  {
    provisionalCode: '2016 GM369',
    note: '',
    absoluteMagnitude: 17.38,
    semimajorAxis: 3.1764,
    eccentricity: 0.0722047,
    inclination: 16.98993,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H328961, H334314',
      },
      {
        userName: '赵经远',
        HNames: 'H335632',
      },
    ],
  },
  {
    provisionalCode: '2016 GN369',
    note: '',
    absoluteMagnitude: 18.05,
    semimajorAxis: 2.85017,
    eccentricity: 0.0953514,
    inclination: 9.70085,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H334999, H340225, H341003',
      },
    ],
  },
  {
    provisionalCode: '2016 GX369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 74.7329,
    eccentricity: 0.678359,
    inclination: 11.92983,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H342564, H342570, H342581',
      },
    ],
  },
  {
    provisionalCode: '2016 GY369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.93,
    semimajorAxis: 41.75701,
    eccentricity: 0.1710072,
    inclination: 28.33586,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H348196, H348247',
      },
    ],
  },
  {
    provisionalCode: '2016 GZ369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.57,
    semimajorAxis: 50.60544,
    eccentricity: 0.3214931,
    inclination: 13.98993,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H334808, H348794, H349063',
      },
    ],
  },
  {
    provisionalCode: '2016 GA370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.47,
    semimajorAxis: 36.86432,
    eccentricity: 0.4389081,
    inclination: 26.08803,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H349203, H349207, H349268',
      },
    ],
  },
  {
    provisionalCode: '2016 GB370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 11.33,
    semimajorAxis: 23.82357,
    eccentricity: 0.2700455,
    inclination: 16.24372,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H349670, H349671, H349677',
      },
    ],
  },
  {
    provisionalCode: '2016 LZ105',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.5782,
    eccentricity: 0.1521574,
    inclination: 8.86285,
    oppositions: 6,
    uncertainty: 1,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H224564',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H237740',
      },
      {
        userName: '倉敷科学センター',
        HNames: 'H342429',
      },
    ],
  },
  {
    provisionalCode: '2016 LA106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.3,
    semimajorAxis: 46.29268,
    eccentricity: 0.2342167,
    inclination: 25.57348,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H337089, H343758, H343847, H343856',
      },
    ],
  },
  {
    provisionalCode: '2016 LB106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.47,
    semimajorAxis: 42.50498,
    eccentricity: 0.0698151,
    inclination: 34.75844,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H337558, H344286, H344288, H344304',
      },
    ],
  },
  {
    provisionalCode: '2016 LC106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.18,
    semimajorAxis: 43.00613,
    eccentricity: 0.14274,
    inclination: 16.88956,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H338713, H344767, H344770',
      },
    ],
  },
  {
    provisionalCode: '2016 LD106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.82,
    semimajorAxis: 41.95439,
    eccentricity: 0.0345075,
    inclination: 11.14924,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H339348, H346084, H346092',
      },
    ],
  },
  {
    provisionalCode: '2016 LE106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.41,
    semimajorAxis: 39.42894,
    eccentricity: 0.206735,
    inclination: 11.04368,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H346733, H346767, H346772',
      },
    ],
  },
  {
    provisionalCode: '2016 LF106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.08,
    semimajorAxis: 47.47763,
    eccentricity: 0.3236882,
    inclination: 23.842,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H346814, H346835, H346838, H346857',
      },
    ],
  },
  {
    provisionalCode: '2016 LG106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.83,
    semimajorAxis: 39.19779,
    eccentricity: 0.1147664,
    inclination: 22.12111,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H347919, H347936, H347943',
      },
    ],
  },
  {
    provisionalCode: '2016 LH106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 11.18,
    semimajorAxis: 27.6274,
    eccentricity: 0.1508445,
    inclination: 14.71695,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H348793, H349102, H349111',
      },
    ],
  },
  {
    provisionalCode: '2016 LJ106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.33,
    semimajorAxis: 44.18748,
    eccentricity: 0.0966616,
    inclination: 9.75484,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H347762, H349302, H349311',
      },
    ],
  },
  {
    provisionalCode: '2016 LK106',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.08,
    semimajorAxis: 44.63576,
    eccentricity: 0.1940853,
    inclination: 33.04274,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H339340, H349937, H349942',
      },
    ],
  },
  {
    provisionalCode: '2016 OF16',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.05753,
    eccentricity: 0.1610163,
    inclination: 2.6012,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H321749, H325375',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329754',
      },
      {
        userName: 'wanko',
        HNames: 'H330542',
      },
    ],
  },
  {
    provisionalCode: '2016 PE297',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 3.01592,
    eccentricity: 0.1508892,
    inclination: 8.23713,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224782, H239763',
      },
      {
        userName: 'コージ',
        HNames: 'H251077',
      },
      {
        userName: 'maria w',
        HNames: 'H325258',
      },
    ],
  },
  {
    provisionalCode: '2016 PF297',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.97472,
    eccentricity: 0.2438078,
    inclination: 8.06874,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238917, H239833',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H253039',
      },
      {
        userName: 'みしま',
        HNames: 'H324363',
      },
      {
        userName: 'maria w',
        HNames: 'H325047',
      },
      {
        userName: 'ioring',
        HNames: 'H348593',
      },
    ],
  },
  {
    provisionalCode: '2016 PG297',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 2.87568,
    eccentricity: 0.0443311,
    inclination: 5.98578,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H207389',
      },
      {
        userName: 'コージ',
        HNames: 'H251004',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277415',
      },
      {
        userName: 'ほたる',
        HNames: 'H323462',
      },
    ],
  },
  {
    provisionalCode: '2016 PH297',
    note: '',
    absoluteMagnitude: 20.4,
    semimajorAxis: 2.72488,
    eccentricity: 0.1875898,
    inclination: 2.50778,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238663, H318433',
      },
      {
        userName: 'コージ',
        HNames: 'H251659',
      },
      {
        userName: 'wanko',
        HNames: 'H325013',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H339421',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ297',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 2.66106,
    eccentricity: 0.0806766,
    inclination: 2.02239,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H211801',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232462',
      },
      {
        userName: 'ほたる',
        HNames: 'H323338',
      },
      {
        userName: 'maria w',
        HNames: 'H323738, H325439',
      },
      {
        userName: 'みしま',
        HNames: 'H324396',
      },
    ],
  },
  {
    provisionalCode: '2016 PK297',
    note: '',
    absoluteMagnitude: 19.94,
    semimajorAxis: 2.87092,
    eccentricity: 0.101564,
    inclination: 1.89694,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232593',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H252164',
      },
      {
        userName: 'maria w',
        HNames: 'H323739, H325443',
      },
      {
        userName: 'みしま',
        HNames: 'H329416',
      },
      {
        userName: 'ioring',
        HNames: 'H346479',
      },
    ],
  },
  {
    provisionalCode: '2016 PM297',
    note: '',
    absoluteMagnitude: 20.94,
    semimajorAxis: 2.68182,
    eccentricity: 0.2049051,
    inclination: 2.52499,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238870',
      },
      {
        userName: 'コージ',
        HNames: 'H251629',
      },
      {
        userName: 'ほたる',
        HNames: 'H312863',
      },
      {
        userName: 'wanko',
        HNames: 'H327431',
      },
    ],
  },
  {
    provisionalCode: '2016 PN297',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 2.96774,
    eccentricity: 0.0885103,
    inclination: 2.01002,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229787',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H231954',
      },
      {
        userName: 'maria w',
        HNames: 'H323730, H327074',
      },
      {
        userName: 'ioring',
        HNames: 'H345235',
      },
    ],
  },
  {
    provisionalCode: '2016 PO297',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 2.78237,
    eccentricity: 0.0994948,
    inclination: 4.28548,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238627, H313567',
      },
      {
        userName: 'コージ',
        HNames: 'H251042',
      },
      {
        userName: 'みしま',
        HNames: 'H324229',
      },
      {
        userName: 'maria w',
        HNames: 'H337887',
      },
    ],
  },
  {
    provisionalCode: '2016 PP297',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 2.21905,
    eccentricity: 0.1782705,
    inclination: 2.81534,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283516',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286923',
      },
      {
        userName: 'miru',
        HNames: 'H325652',
      },
      {
        userName: 'ひお',
        HNames: 'H327298',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ297',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 2.71562,
    eccentricity: 0.0284026,
    inclination: 2.8585,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'みしま',
        HNames: 'H232740',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232740',
      },
      {
        userName: 'maria w',
        HNames: 'H323530, H328951',
      },
    ],
  },
  {
    provisionalCode: '2016 PR297',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 2.86078,
    eccentricity: 0.1182734,
    inclination: 2.78378,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232560',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H233207',
      },
      {
        userName: 'nasiar99',
        HNames: 'H321945',
      },
      {
        userName: 'ioring',
        HNames: 'H345224, H345232',
      },
    ],
  },
  {
    provisionalCode: '2016 PS297',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.29001,
    eccentricity: 0.1664785,
    inclination: 2.86143,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: '鈴音',
        HNames: 'H205779',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254914',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H318435, H344119',
      },
      {
        userName: 'みしま',
        HNames: 'H324883',
      },
      {
        userName: 'maria w',
        HNames: 'H343576',
      },
    ],
  },
  {
    provisionalCode: '2016 PT297',
    note: '',
    absoluteMagnitude: 17.95,
    semimajorAxis: 2.81906,
    eccentricity: 0.0085327,
    inclination: 4.69213,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254927, H255055',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332411',
      },
    ],
  },
  {
    provisionalCode: '2016 PU297',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 2.77603,
    eccentricity: 0.0500298,
    inclination: 3.4145,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220319',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H253570',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317996',
      },
      {
        userName: 'maria w',
        HNames: 'H333252, H333392',
      },
    ],
  },
  {
    provisionalCode: '2016 PV297',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 2.74932,
    eccentricity: 0.0949079,
    inclination: 2.80884,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H207441',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277315',
      },
      {
        userName: 'ioring',
        HNames: 'H319490',
      },
      {
        userName: 'maria w',
        HNames: 'H329925, H333403',
      },
    ],
  },
  {
    provisionalCode: '2016 PX297',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 2.35112,
    eccentricity: 0.1765285,
    inclination: 2.00814,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247259, H251861',
      },
      {
        userName: 'ほたる',
        HNames: 'H321628',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H336522',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348487',
      },
    ],
  },
  {
    provisionalCode: '2016 PY297',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 2.60843,
    eccentricity: 0.2088206,
    inclination: 8.08153,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'しょうたろう',
        HNames: 'H201848',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285170',
      },
      {
        userName: 'maria w',
        HNames: 'H321801',
      },
      {
        userName: 'kyoukawakawaiidesu',
        HNames: 'H336990, H337053',
      },
    ],
  },
  {
    provisionalCode: '2016 PZ297',
    note: '',
    absoluteMagnitude: 17.48,
    semimajorAxis: 3.66052,
    eccentricity: 0.0841601,
    inclination: 3.92635,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'たくみ丸',
        HNames: 'H214062',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251425',
      },
      {
        userName: 'miru',
        HNames: 'H324379',
      },
      {
        userName: 'maria w',
        HNames: 'H330295, H336475',
      },
      {
        userName: 'ioring',
        HNames: 'H348557',
      },
    ],
  },
  {
    provisionalCode: '2016 PA298',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 2.8975,
    eccentricity: 0.1160881,
    inclination: 8.05914,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H250129, H336479',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286872',
      },
      {
        userName: 'caldariにゃんこ',
        HNames: 'H327762',
      },
    ],
  },
  {
    provisionalCode: '2016 PB298',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.60722,
    eccentricity: 0.1226333,
    inclination: 1.89762,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251575, H341048, H348162',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251844',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330026, H336409',
      },
    ],
  },
  {
    provisionalCode: '2016 PC298',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.01635,
    eccentricity: 0.0628496,
    inclination: 9.43781,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'ぴーすぽーと',
        HNames: 'H284742',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286955',
      },
      {
        userName: 'wanko',
        HNames: 'H327740',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330496',
      },
      {
        userName: 'ioring',
        HNames: 'H350182',
      },
    ],
  },
  {
    provisionalCode: '2016 PD298',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 2.89013,
    eccentricity: 0.105618,
    inclination: 11.99549,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H284248',
      },
      {
        userName: 'wanko',
        HNames: 'H325011',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332518, H336401',
      },
    ],
  },
  {
    provisionalCode: '2016 PE298',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.59657,
    eccentricity: 0.1066387,
    inclination: 3.24387,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232762, H251867',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332619, H337452',
      },
      {
        userName: 'nasiar99',
        HNames: 'H346626',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348403',
      },
    ],
  },
  {
    provisionalCode: '2016 PG298',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.5272,
    eccentricity: 0.1673728,
    inclination: 4.04759,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H202499, H212668',
      },
      {
        userName: 'yuqing ren',
        HNames: 'H243918',
      },
      {
        userName: 'maria w',
        HNames: 'H336120',
      },
      {
        userName: 'ms',
        HNames: 'H342589',
      },
    ],
  },
  {
    provisionalCode: '2016 PH298',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.78444,
    eccentricity: 0.096634,
    inclination: 3.48542,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232559',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H233173',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330513',
      },
      {
        userName: 'ms',
        HNames: 'H335992, H341699',
      },
      {
        userName: 'ioring',
        HNames: 'H343651, H346493',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ298',
    note: '2019 JN99と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H313135, H321785',
      },
      {
        userName: 'miru',
        HNames: 'H325686',
      },
      {
        userName: 'ms',
        HNames: 'H335990',
      },
    ],
  },
  {
    provisionalCode: '2016 PK298',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.31343,
    eccentricity: 0.1387346,
    inclination: 3.65965,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H236994, H348401',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251916',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332466, H335986',
      },
    ],
  },
  {
    provisionalCode: '2016 PL298',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 2.20072,
    eccentricity: 0.1155516,
    inclination: 5.53909,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277644, H282098',
      },
      {
        userName: 'ほたる',
        HNames: 'H321925',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H336381',
      },
    ],
  },
  {
    provisionalCode: '2016 PM298',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.19807,
    eccentricity: 0.182702,
    inclination: 6.10188,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238672',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254953',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332519, H336404',
      },
      {
        userName: 'joshua',
        HNames: 'H348566',
      },
      {
        userName: 'junnjunn',
        HNames: 'H360912',
      },
    ],
  },
  {
    provisionalCode: '2016 PN298',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 2.36781,
    eccentricity: 0.1441318,
    inclination: 6.18015,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H217852',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H284733',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329904, H336380',
      },
    ],
  },
  {
    provisionalCode: '2016 PO298',
    note: '2023 VU19と同定',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.20114,
    eccentricity: 0.0938078,
    inclination: 4.09759,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278228',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283522',
      },
      {
        userName: 'みしま',
        HNames: 'H324522',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330403',
      },
      {
        userName: 'maria w',
        HNames: 'H337973',
      },
    ],
  },
  {
    provisionalCode: '2016 PP298',
    note: '',
    absoluteMagnitude: 19.56,
    semimajorAxis: 2.65192,
    eccentricity: 0.0713593,
    inclination: 5.2415,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282102, H285693',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330406',
      },
      {
        userName: 'maria w',
        HNames: 'H337975',
      },
      {
        userName: 'ぴ',
        HNames: 'H347322',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ298',
    note: '',
    absoluteMagnitude: 21.1,
    semimajorAxis: 2.55186,
    eccentricity: 0.1437299,
    inclination: 2.39676,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H224759',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H239389',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329062, H338101',
      },
    ],
  },
  {
    provisionalCode: '2016 PR298',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.21885,
    eccentricity: 0.0128309,
    inclination: 2.48452,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H217198',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H237719',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329340, H338102',
      },
      {
        userName: 'nasiar99',
        HNames: 'H346821',
      },
      {
        userName: 'ioring',
        HNames: 'H348009',
      },
    ],
  },
  {
    provisionalCode: '2016 PS298',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.54381,
    eccentricity: 0.0953161,
    inclination: 5.31352,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260181, H345498',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H320482',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332548',
      },
      {
        userName: 'なずな',
        HNames: 'H338008',
      },
    ],
  },
  {
    provisionalCode: '2016 PT298',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 2.79333,
    eccentricity: 0.0503805,
    inclination: 8.97522,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H212590',
      },
      {
        userName: 'みしま',
        HNames: 'H324787',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330386',
      },
      {
        userName: 'maria w',
        HNames: 'H337977',
      },
      {
        userName: 'ioring',
        HNames: 'H345234',
      },
    ],
  },
  {
    provisionalCode: '2016 PU298',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.02,
    semimajorAxis: 60.08309,
    eccentricity: 0.6021848,
    inclination: 3.96141,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H234121',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H234121',
      },
    ],
  },
  {
    provisionalCode: '2016 PV298',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.29,
    semimajorAxis: 47.92341,
    eccentricity: 0.2938703,
    inclination: 12.62495,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H209699',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H234172, H341030',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H234172',
      },
    ],
  },
  {
    provisionalCode: '2016 PW298',
    note: '',
    absoluteMagnitude: 20.36,
    semimajorAxis: 2.53929,
    eccentricity: 0.0567634,
    inclination: 4.25996,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: '鈴音',
        HNames: 'H205789',
      },
      {
        userName: 'ほたる',
        HNames: 'H321843',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H336508, H336509',
      },
    ],
  },
  {
    provisionalCode: '2016 PX298',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 3.10064,
    eccentricity: 0.1540202,
    inclination: 2.834,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255778, H256880',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H334090, H339493',
      },
    ],
  },
  {
    provisionalCode: '2016 PY298',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 2.68082,
    eccentricity: 0.1278316,
    inclination: 4.65623,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232061, H233210',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H334091, H339578',
      },
      {
        userName: 'nasiar99',
        HNames: 'H346818',
      },
    ],
  },
  {
    provisionalCode: '2016 PZ298',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 3.04796,
    eccentricity: 0.109193,
    inclination: 11.72794,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260191',
      },
      {
        userName: 'ほたる',
        HNames: 'H322134',
      },
      {
        userName: 'guoyou sun',
        HNames: 'H334996',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H339580',
      },
    ],
  },
  {
    provisionalCode: '2016 PA299',
    note: '',
    absoluteMagnitude: 20.51,
    semimajorAxis: 2.33988,
    eccentricity: 0.1263616,
    inclination: 1.78914,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232060',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232763',
      },
      {
        userName: 'nasiar99',
        HNames: 'H334519',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H339643',
      },
    ],
  },
  {
    provisionalCode: '2016 PB299',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 2.31568,
    eccentricity: 0.2035534,
    inclination: 4.2268,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H236357, H237926',
      },
      {
        userName: 'ひお',
        HNames: 'H330240',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H339733',
      },
    ],
  },
  {
    provisionalCode: '2016 PD299',
    note: '',
    absoluteMagnitude: 18.47,
    semimajorAxis: 3.03854,
    eccentricity: 0.1131045,
    inclination: 4.46494,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228789',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H321544',
      },
      {
        userName: 'ms',
        HNames: 'H335995, H341670',
      },
    ],
  },
  {
    provisionalCode: '2016 PE299',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 2.73431,
    eccentricity: 0.1276246,
    inclination: 12.25396,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ぴーすぽーと',
        HNames: 'H284807',
      },
      {
        userName: 'wanko',
        HNames: 'H327283',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330387',
      },
      {
        userName: 'ゆうまり',
        HNames: 'H340911',
      },
    ],
  },
  {
    provisionalCode: '2016 PF299',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 3.03812,
    eccentricity: 0.098012,
    inclination: 5.30318,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228682',
      },
      {
        userName: 'もしもス',
        HNames: 'H322372',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330465',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341049, H348404',
      },
    ],
  },
  {
    provisionalCode: '2016 PG299',
    note: '',
    absoluteMagnitude: 20.68,
    semimajorAxis: 3.06676,
    eccentricity: 0.237304,
    inclination: 1.95385,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251573, H341050, H348164',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330010, H336407',
      },
    ],
  },
  {
    provisionalCode: '2016 PH299',
    note: '2015 FP395と同定',
    absoluteMagnitude: 19.41,
    semimajorAxis: 2.37402,
    eccentricity: 0.126702,
    inclination: 2.48435,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233756',
      },
      {
        userName: 'maria w',
        HNames: 'H323732, H327077',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341117',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ299',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 2.76993,
    eccentricity: 0.0548777,
    inclination: 3.21054,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255969',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H257556, H342438',
      },
      {
        userName: 'wanko',
        HNames: 'H341109',
      },
    ],
  },
  {
    provisionalCode: '2016 PK299',
    note: '2014 EN88と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238671',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254951, H342445',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330535',
      },
    ],
  },
  {
    provisionalCode: '2016 PL299',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 2.62561,
    eccentricity: 0.2335232,
    inclination: 3.92563,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276979, H342437',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277556, H341220',
      },
    ],
  },
  {
    provisionalCode: '2016 PM299',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 2.7423,
    eccentricity: 0.0539,
    inclination: 2.98158,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254837, H342543',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255329',
      },
      {
        userName: 'なずな',
        HNames: 'H338010',
      },
    ],
  },
  {
    provisionalCode: '2016 PN299',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 3.11738,
    eccentricity: 0.0603542,
    inclination: 11.90559,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285760',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332467',
      },
      {
        userName: 'なずな',
        HNames: 'H338012',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342544',
      },
    ],
  },
  {
    provisionalCode: '2016 PO299',
    note: '',
    absoluteMagnitude: 20.01,
    semimajorAxis: 2.34384,
    eccentricity: 0.0680355,
    inclination: 3.22614,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238855, H238930',
      },
      {
        userName: 'ほたる',
        HNames: 'H321966',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H342583',
      },
      {
        userName: 'ioring',
        HNames: 'H347629, H348541',
      },
    ],
  },
  {
    provisionalCode: '2016 PP299',
    note: '2017 YR56と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283619',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330430, H342624',
      },
      {
        userName: 'wanko',
        HNames: 'H342204',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ299',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 2.75238,
    eccentricity: 0.0474723,
    inclination: 4.13396,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H235142',
      },
      {
        userName: 'ms',
        HNames: 'H322667',
      },
      {
        userName: 'みしま',
        HNames: 'H324892',
      },
      {
        userName: 'maria w',
        HNames: 'H327038',
      },
    ],
  },
  {
    provisionalCode: '2016 QR159',
    note: 'Hilda',
    absoluteMagnitude: 17.39,
    semimajorAxis: 3.94933,
    eccentricity: 0.1857109,
    inclination: 6.27199,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253024',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H283460, H285737',
      },
      {
        userName: 'maria w',
        HNames: 'H333437',
      },
    ],
  },
  {
    provisionalCode: '2016 QX159',
    note: '',
    absoluteMagnitude: 21.32,
    semimajorAxis: 2.39734,
    eccentricity: 0.1389333,
    inclination: 2.63733,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'コージ',
        HNames: 'H251649',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317998',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H318137',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H339734',
      },
    ],
  },
  {
    provisionalCode: '2016 QY159',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.20249,
    eccentricity: 0.1684153,
    inclination: 2.85383,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H258892, H342004',
      },
      {
        userName: 'wanko',
        HNames: 'H327318',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330514, H342004',
      },
    ],
  },
  {
    provisionalCode: '2016 QA160',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 2.64223,
    eccentricity: 0.1284482,
    inclination: 5.78311,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H254080',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278091',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285704',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H342631',
      },
    ],
  },
  {
    provisionalCode: '2016 RP105',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.06,
    semimajorAxis: 46.60938,
    eccentricity: 0.1126352,
    inclination: 7.93735,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H258789',
      },
      {
        userName: 'zorome',
        HNames: 'H318197',
      },
    ],
  },
  {
    provisionalCode: '2016 RQ105',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.15,
    semimajorAxis: 47.78032,
    eccentricity: 0.2154184,
    inclination: 20.43222,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232125, H348320, H348356, H348357',
      },
    ],
  },
  {
    provisionalCode: '2016 TO209',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.62203,
    eccentricity: 0.1301994,
    inclination: 11.76954,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'jim',
        HNames: 'H308837',
      },
      {
        userName: 'miru',
        HNames: 'H324957',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H327035, H341184',
      },
      {
        userName: 'maria w',
        HNames: 'H327035',
      },
    ],
  },
  {
    provisionalCode: '2017 AX64',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 3.05809,
    eccentricity: 0.0814745,
    inclination: 9.88117,
    oppositions: 3,
    uncertainty: 1,
    detail: [
      {
        userName: 'alberto76',
        HNames: 'H268336',
      },
    ],
  },
  {
    provisionalCode: '2017 BS244',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.11,
    semimajorAxis: 43.70111,
    eccentricity: 0.0595976,
    inclination: 4.12508,
    oppositions: 3,
    uncertainty: 4,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272826',
      },
    ],
  },
  {
    provisionalCode: '2017 BT244',
    note: '',
    absoluteMagnitude: 21.21,
    semimajorAxis: 2.24031,
    eccentricity: 0.1251619,
    inclination: 6.18818,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H237325',
      },
      {
        userName: 'もしもス',
        HNames: 'H237325',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H281999',
      },
    ],
  },
  {
    provisionalCode: '2017 BU244',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 2.58073,
    eccentricity: 0.1921662,
    inclination: 6.90305,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248323, H277593',
      },
      {
        userName: 'ko',
        HNames: 'H280255',
      },
    ],
  },
  {
    provisionalCode: '2017 BV244',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.61,
    semimajorAxis: 5.33377,
    eccentricity: 0.0346886,
    inclination: 12.55266,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249764, H277592',
      },
    ],
  },
  {
    provisionalCode: '2017 BW244',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 17.22,
    semimajorAxis: 5.3197,
    eccentricity: 0.0568153,
    inclination: 19.88004,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249851',
      },
      {
        userName: 'kn1cht',
        HNames: 'H282780',
      },
    ],
  },
  {
    provisionalCode: '2017 BX244',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 3.04073,
    eccentricity: 0.1258255,
    inclination: 8.3893,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H249920',
      },
      {
        userName: 'zorome',
        HNames: 'H249920',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282000',
      },
    ],
  },
  {
    provisionalCode: '2017 BY244',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.27,
    semimajorAxis: 42.84572,
    eccentricity: 0.0609671,
    inclination: 2.04895,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H307235',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ244',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.39,
    semimajorAxis: 43.05522,
    eccentricity: 0.1297079,
    inclination: 14.60768,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H296983',
      },
    ],
  },
  {
    provisionalCode: '2017 BA245',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.95,
    semimajorAxis: 52.2145,
    eccentricity: 0.3102726,
    inclination: 12.03759,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H265205',
      },
      {
        userName: 'zorome',
        HNames: 'H274468, H274473',
      },
    ],
  },
  {
    provisionalCode: '2017 BB245',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.03,
    semimajorAxis: 90.61774,
    eccentricity: 0.626752,
    inclination: 18.95442,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H299185',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H299185',
      },
    ],
  },
  {
    provisionalCode: '2017 BC245',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 3.16532,
    eccentricity: 0.1300719,
    inclination: 16.98961,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H308909',
      },
    ],
  },
  {
    provisionalCode: '2017 CZ59',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.05828,
    eccentricity: 0.0722966,
    inclination: 9.95673,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H272852',
      },
      {
        userName: 'ほたる',
        HNames: 'H280240',
      },
      {
        userName: 'nasiar99',
        HNames: 'H300768',
      },
    ],
  },
  {
    provisionalCode: '2017 CB60',
    note: '',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.57285,
    eccentricity: 0.1039664,
    inclination: 5.29955,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H291160, H305638',
      },
    ],
  },
  {
    provisionalCode: '2017 CC60',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.05355,
    eccentricity: 0.1319118,
    inclination: 9.55505,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H267912, H267933',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267933',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267933',
      },
    ],
  },
  {
    provisionalCode: '2017 CD60',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.37,
    semimajorAxis: 41.97376,
    eccentricity: 0.0541101,
    inclination: 12.71234,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266281, H282302',
      },
    ],
  },
  {
    provisionalCode: '2017 CE60',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.41,
    semimajorAxis: 42.48197,
    eccentricity: 0.5031922,
    inclination: 24.66604,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H286178, H286185',
      },
    ],
  },
  {
    provisionalCode: '2017 CF60',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.09,
    semimajorAxis: 44.02474,
    eccentricity: 0.0985328,
    inclination: 12.73293,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H271285, H271290',
      },
    ],
  },
  {
    provisionalCode: '2017 CG60',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 3.20469,
    eccentricity: 0.1083431,
    inclination: 9.93885,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H272606',
      },
      {
        userName: 'nasiar99',
        HNames: 'H302374',
      },
    ],
  },
  {
    provisionalCode: '2017 DC170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.13,
    semimajorAxis: 44.8673,
    eccentricity: 0.086064,
    inclination: 3.23241,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243408',
      },
    ],
  },
  {
    provisionalCode: '2017 DD170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.98,
    semimajorAxis: 38.07492,
    eccentricity: 0.0365247,
    inclination: 27.45522,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243477',
      },
    ],
  },
  {
    provisionalCode: '2017 DE170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.32,
    semimajorAxis: 84.5325,
    eccentricity: 0.5466908,
    inclination: 13.83203,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243702',
      },
    ],
  },
  {
    provisionalCode: '2017 DF170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.07,
    semimajorAxis: 43.56463,
    eccentricity: 0.2146513,
    inclination: 11.81235,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243752',
      },
    ],
  },
  {
    provisionalCode: '2017 DG170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.54,
    semimajorAxis: 43.06288,
    eccentricity: 0.0456643,
    inclination: 4.21418,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H244163',
      },
    ],
  },
  {
    provisionalCode: '2017 DH170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.01,
    semimajorAxis: 44.69461,
    eccentricity: 0.1170649,
    inclination: 5.43053,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246656',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.83,
    semimajorAxis: 43.54444,
    eccentricity: 0.0673253,
    inclination: 5.74046,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246695',
      },
    ],
  },
  {
    provisionalCode: '2017 DK170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.42,
    semimajorAxis: 43.7205,
    eccentricity: 0.0734023,
    inclination: 3.38536,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261889',
      },
    ],
  },
  {
    provisionalCode: '2017 DL170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.94,
    semimajorAxis: 39.17546,
    eccentricity: 0.2468424,
    inclination: 7.96058,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267105',
      },
    ],
  },
  {
    provisionalCode: '2017 DM170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.96,
    semimajorAxis: 43.55886,
    eccentricity: 0.044223,
    inclination: 3.67414,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261905, H261908',
      },
    ],
  },
  {
    provisionalCode: '2017 DN170',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.03,
    semimajorAxis: 55.15674,
    eccentricity: 0.4435421,
    inclination: 5.2208,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H306903',
      },
    ],
  },
  {
    provisionalCode: '2017 DU170',
    note: '',
    absoluteMagnitude: 17.54,
    semimajorAxis: 3.10868,
    eccentricity: 0.1201227,
    inclination: 6.05795,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H288715',
      },
    ],
  },
  {
    provisionalCode: '2017 DC171',
    note: '',
    absoluteMagnitude: 20.59,
    semimajorAxis: 2.31271,
    eccentricity: 0.1039666,
    inclination: 5.92364,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H282621',
      },
    ],
  },
  {
    provisionalCode: '2017 DH171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.13,
    semimajorAxis: 45.371,
    eccentricity: 0.1067413,
    inclination: 6.46294,
    oppositions: 6,
    uncertainty: 4,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247169',
      },
      {
        userName: 'amitora20025@gol.com',
        HNames: 'H247169',
      },
    ],
  },
  {
    provisionalCode: '2017 DJ171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.33,
    semimajorAxis: 43.00544,
    eccentricity: 0.1266784,
    inclination: 13.16001,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243387',
      },
    ],
  },
  {
    provisionalCode: '2017 DK171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.82,
    semimajorAxis: 43.06923,
    eccentricity: 0.0173794,
    inclination: 4.31404,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'ないとう',
        HNames: 'H230102, H230112',
      },
    ],
  },
  {
    provisionalCode: '2017 DL171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.09,
    semimajorAxis: 39.09116,
    eccentricity: 0.2709194,
    inclination: 5.18009,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H246438',
      },
    ],
  },
  {
    provisionalCode: '2017 DM171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.36,
    semimajorAxis: 43.02598,
    eccentricity: 0.010765,
    inclination: 4.52175,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'arda',
        HNames: 'H260849',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275620',
      },
    ],
  },
  {
    provisionalCode: '2017 DN171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.74,
    semimajorAxis: 42.3785,
    eccentricity: 0.0949054,
    inclination: 19.50816,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243478',
      },
    ],
  },
  {
    provisionalCode: '2017 DO171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.31,
    semimajorAxis: 119.07084,
    eccentricity: 0.6454924,
    inclination: 38.36821,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H273384',
      },
    ],
  },
  {
    provisionalCode: '2017 DP171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.71,
    semimajorAxis: 44.85202,
    eccentricity: 0.0135298,
    inclination: 6.36016,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H265279',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H265279',
      },
    ],
  },
  {
    provisionalCode: '2017 DQ171',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.64,
    semimajorAxis: 45.41855,
    eccentricity: 0.1671609,
    inclination: 7.56994,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'arda',
        HNames: 'H264220',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H282242',
      },
    ],
  },
  {
    provisionalCode: '2017 EM56',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.00818,
    eccentricity: 0.1042308,
    inclination: 9.92839,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H280406',
      },
    ],
  },
  {
    provisionalCode: '2017 EN56',
    note: '',
    absoluteMagnitude: 17.84,
    semimajorAxis: 3.09461,
    eccentricity: 0.0664301,
    inclination: 9.27213,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H278473',
      },
    ],
  },
  {
    provisionalCode: '2017 EU57',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.99,
    semimajorAxis: 55.28472,
    eccentricity: 0.3482674,
    inclination: 13.56597,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H250496',
      },
      {
        userName: 'zorome',
        HNames: 'H277614',
      },
    ],
  },
  {
    provisionalCode: '2017 EV57',
    note: '',
    absoluteMagnitude: 17.78,
    semimajorAxis: 3.20984,
    eccentricity: 0.0386718,
    inclination: 9.92219,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H281071',
      },
    ],
  },
  {
    provisionalCode: '2017 EW57',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.66558,
    eccentricity: 0.032381,
    inclination: 10.89519,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H284983',
      },
    ],
  },
  {
    provisionalCode: '2017 EX57',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 3.05225,
    eccentricity: 0.0747553,
    inclination: 9.00503,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H279856',
      },
    ],
  },
  {
    provisionalCode: '2017 EY57',
    note: '',
    absoluteMagnitude: 17.69,
    semimajorAxis: 3.17434,
    eccentricity: 0.056537,
    inclination: 10.36647,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H280888',
      },
    ],
  },
  {
    provisionalCode: '2017 EZ57',
    note: '',
    absoluteMagnitude: 17.75,
    semimajorAxis: 3.09527,
    eccentricity: 0.0838941,
    inclination: 14.97743,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H299479',
      },
    ],
  },
  {
    provisionalCode: '2017 FH242',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 3.06755,
    eccentricity: 0.1136466,
    inclination: 10.98765,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H221317',
      },
      {
        userName: '赵经远',
        HNames: 'H262530',
      },
      {
        userName: 'aika',
        HNames: 'H302833',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ242',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 3.15454,
    eccentricity: 0.1825047,
    inclination: 9.09929,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H241112',
      },
      {
        userName: 'kn1cht',
        HNames: 'H241112',
      },
    ],
  },
  {
    provisionalCode: '2017 FK242',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.00485,
    eccentricity: 0.2161375,
    inclination: 17.8125,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H241149',
      },
      {
        userName: '赵经远',
        HNames: 'H241149',
      },
      {
        userName: 'kn1cht',
        HNames: 'H241149',
      },
    ],
  },
  {
    provisionalCode: '2017 FL242',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.54579,
    eccentricity: 0.1249545,
    inclination: 15.54384,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H244469',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244469',
      },
      {
        userName: 'aika',
        HNames: 'H275800',
      },
    ],
  },
  {
    provisionalCode: '2017 FM242',
    note: '',
    absoluteMagnitude: 21.03,
    semimajorAxis: 2.36845,
    eccentricity: 0.1179763,
    inclination: 6.25228,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244471',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244471',
      },
    ],
  },
  {
    provisionalCode: '2017 FN242',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 3.0122,
    eccentricity: 0.09214,
    inclination: 11.37538,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H245458',
      },
      {
        userName: 'aika',
        HNames: 'H245458',
      },
      {
        userName: 'nasiar99',
        HNames: 'H245458',
      },
    ],
  },
  {
    provisionalCode: '2017 FO242',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 3.16832,
    eccentricity: 0.0580471,
    inclination: 8.23483,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H219694',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219694',
      },
      {
        userName: 'apollo18',
        HNames: 'H219694',
      },
    ],
  },
  {
    provisionalCode: '2017 FP242',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.97606,
    eccentricity: 0.1063906,
    inclination: 9.21855,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H200443',
      },
      {
        userName: '42',
        HNames: 'H200443',
      },
      {
        userName: 'れお',
        HNames: 'H200443',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ242',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.75146,
    eccentricity: 0.2066658,
    inclination: 6.81308,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H241180',
      },
      {
        userName: 'もしもス',
        HNames: 'H241180',
      },
      {
        userName: '赵经远',
        HNames: 'H241180',
      },
      {
        userName: 's.ozawa',
        HNames: 'H310779',
      },
    ],
  },
  {
    provisionalCode: '2017 FR242',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.13863,
    eccentricity: 0.0837544,
    inclination: 23.13444,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242670',
      },
      {
        userName: 'aika',
        HNames: 'H242670',
      },
    ],
  },
  {
    provisionalCode: '2017 FS242',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.99256,
    eccentricity: 0.1077757,
    inclination: 9.51147,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H242671',
      },
      {
        userName: 'もしもス',
        HNames: 'H242671',
      },
      {
        userName: 'kn1cht',
        HNames: 'H245461',
      },
      {
        userName: 'ほたる',
        HNames: 'H285565',
      },
    ],
  },
  {
    provisionalCode: '2017 FT242',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 2.78384,
    eccentricity: 0.1356452,
    inclination: 6.50802,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244891, H244894',
      },
      {
        userName: 's.sasaki',
        HNames: 'H305192',
      },
    ],
  },
  {
    provisionalCode: '2017 FU242',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 2.9532,
    eccentricity: 0.0624119,
    inclination: 16.91813,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244895',
      },
      {
        userName: 'aika',
        HNames: 'H275767',
      },
    ],
  },
  {
    provisionalCode: '2017 FV242',
    note: '',
    absoluteMagnitude: 18.21,
    semimajorAxis: 3.08065,
    eccentricity: 0.1903207,
    inclination: 13.95499,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244907',
      },
      {
        userName: 'kn1cht',
        HNames: 'H244907',
      },
      {
        userName: 'れお',
        HNames: 'H244907',
      },
    ],
  },
  {
    provisionalCode: '2017 FW242',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 2.34194,
    eccentricity: 0.1171308,
    inclination: 6.2473,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242668',
      },
      {
        userName: 'ほたる',
        HNames: 'H245460',
      },
      {
        userName: 'kn1cht',
        HNames: 'H245460',
      },
    ],
  },
  {
    provisionalCode: '2017 FX242',
    note: '',
    absoluteMagnitude: 22.11,
    semimajorAxis: 2.38369,
    eccentricity: 0.0821103,
    inclination: 6.30476,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245286, H278703',
      },
    ],
  },
  {
    provisionalCode: '2017 FY242',
    note: '',
    absoluteMagnitude: 20.8,
    semimajorAxis: 3.68777,
    eccentricity: 0.3926285,
    inclination: 8.15058,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H288761',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H288761',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289479',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ242',
    note: '',
    absoluteMagnitude: 20.58,
    semimajorAxis: 2.72818,
    eccentricity: 0.062986,
    inclination: 6.42933,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H287772',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290680',
      },
      {
        userName: 'れお',
        HNames: 'H290680',
      },
    ],
  },
  {
    provisionalCode: '2017 FY243',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 2.55492,
    eccentricity: 0.1091436,
    inclination: 13.8989,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243646, H267687',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270155',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ243',
    note: '',
    absoluteMagnitude: 19.76,
    semimajorAxis: 2.74872,
    eccentricity: 0.1895656,
    inclination: 6.82893,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H269983, H270065',
      },
      {
        userName: 'aika',
        HNames: 'H270065',
      },
    ],
  },
  {
    provisionalCode: '2017 FA244',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.87649,
    eccentricity: 0.0166711,
    inclination: 6.30638,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265987',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H270095',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270095',
      },
    ],
  },
  {
    provisionalCode: '2017 FB244',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.52788,
    eccentricity: 0.2201193,
    inclination: 8.22273,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H275500',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H275500',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275500',
      },
    ],
  },
  {
    provisionalCode: '2017 FC244',
    note: '',
    absoluteMagnitude: 21.12,
    semimajorAxis: 2.3879,
    eccentricity: 0.129865,
    inclination: 5.68009,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H275501',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H275501, H290798',
      },
    ],
  },
  {
    provisionalCode: '2017 FW245',
    note: '',
    absoluteMagnitude: 18.74,
    semimajorAxis: 3.04653,
    eccentricity: 0.183212,
    inclination: 11.39373,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H220777',
      },
      {
        userName: 'apollo18',
        HNames: 'H220777',
      },
      {
        userName: 'もしもス',
        HNames: 'H242642',
      },
    ],
  },
  {
    provisionalCode: '2017 FX245',
    note: 'Mars Crosser',
    absoluteMagnitude: 20.5,
    semimajorAxis: 3.34786,
    eccentricity: 0.5180506,
    inclination: 15.70211,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H244447',
      },
      {
        userName: 'ko',
        HNames: 'H265989',
      },
      {
        userName: 'もしもス',
        HNames: 'H270725',
      },
    ],
  },
  {
    provisionalCode: '2017 FY245',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 3.04918,
    eccentricity: 0.1379086,
    inclination: 6.52441,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H242669',
      },
      {
        userName: 'kn1cht',
        HNames: 'H249417',
      },
      {
        userName: 'zorome',
        HNames: 'H249417',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ245',
    note: '',
    absoluteMagnitude: 20.54,
    semimajorAxis: 2.6047,
    eccentricity: 0.11411,
    inclination: 11.16864,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H249414',
      },
      {
        userName: 'aika',
        HNames: 'H249699',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249699',
      },
      {
        userName: 'kn1cht',
        HNames: 'H258129',
      },
    ],
  },
  {
    provisionalCode: '2017 FA246',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 3.35902,
    eccentricity: 0.0970635,
    inclination: 8.56754,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H292205, H314545',
      },
      {
        userName: 'aika',
        HNames: 'H314545, H315741',
      },
    ],
  },
  {
    provisionalCode: '2017 FB246',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.16281,
    eccentricity: 0.0467173,
    inclination: 8.46686,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H244468, H245459',
      },
      {
        userName: 'aika',
        HNames: 'H245459',
      },
    ],
  },
  {
    provisionalCode: '2017 GD37',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.92778,
    eccentricity: 0.0439038,
    inclination: 9.72369,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H270844',
      },
      {
        userName: 'ほたる',
        HNames: 'H279659, H279662',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289301',
      },
    ],
  },
  {
    provisionalCode: '2017 GE37',
    note: '',
    absoluteMagnitude: 20.43,
    semimajorAxis: 2.61128,
    eccentricity: 0.1723002,
    inclination: 13.68065,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H293131',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H293131',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H309776',
      },
    ],
  },
  {
    provisionalCode: '2018 FX73',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.07,
    semimajorAxis: 43.84061,
    eccentricity: 0.0601079,
    inclination: 3.16825,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H309012, H309021',
      },
    ],
  },
  {
    provisionalCode: '2018 FY73',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.66,
    semimajorAxis: 42.23705,
    eccentricity: 0.1715708,
    inclination: 4.09488,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H310894, H310901',
      },
    ],
  },
  {
    provisionalCode: '2019 AR143',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.33,
    semimajorAxis: 52.19995,
    eccentricity: 0.258903,
    inclination: 30.52719,
    oppositions: 4,
    uncertainty: 4,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H255961, H256121',
      },
    ],
  },
  {
    provisionalCode: '2019 CK31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.13,
    semimajorAxis: 135.51302,
    eccentricity: 0.799369,
    inclination: 11.01117,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H310843, H310875',
      },
    ],
  },
  {
    provisionalCode: '2019 CL31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.79,
    semimajorAxis: 43.90614,
    eccentricity: 0.1022166,
    inclination: 29.29587,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H274822',
      },
      {
        userName: 'zorome',
        HNames: 'H290176',
      },
    ],
  },
  {
    provisionalCode: '2019 ET8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.65,
    semimajorAxis: 43.52007,
    eccentricity: 0.0300612,
    inclination: 3.37754,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H309770, H309772',
      },
    ],
  },
  {
    provisionalCode: '2019 GP187',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.62,
    semimajorAxis: 43.68868,
    eccentricity: 0.0075079,
    inclination: 4.15923,
    oppositions: 3,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H236236',
      },
    ],
  },
  {
    provisionalCode: '2019 GQ187',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.81,
    semimajorAxis: 39.41944,
    eccentricity: 0.1075842,
    inclination: 6.15997,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235622',
      },
    ],
  },
  {
    provisionalCode: '2019 VT54',
    note: '',
    absoluteMagnitude: 20.62,
    semimajorAxis: 2.51493,
    eccentricity: 0.3223011,
    inclination: 2.27654,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H214349',
      },
      {
        userName: 'さんしろう',
        HNames: 'H214349',
      },
    ],
  },
  {
    provisionalCode: '2016 ED367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.91,
    semimajorAxis: 43.47507,
    eccentricity: 0.122375,
    inclination: 3.46996,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H355989, H355992, H356306',
      },
    ],
  },
  {
    provisionalCode: '2016 EE367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.2,
    semimajorAxis: 74.10723,
    eccentricity: 0.5189368,
    inclination: 10.17765,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H356329, H356341, H357025',
      },
    ],
  },
  {
    provisionalCode: '2016 EF367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.15,
    semimajorAxis: 35.24614,
    eccentricity: 0.1089787,
    inclination: 18.90786,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331423',
      },
    ],
  },
  {
    provisionalCode: '2016 GC370',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.77,
    semimajorAxis: 5.17176,
    eccentricity: 0.0503693,
    inclination: 8.89887,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H322159, H322408, H353898',
      },
    ],
  },
  {
    provisionalCode: '2016 GD370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.32,
    semimajorAxis: 28.39797,
    eccentricity: 0.3457385,
    inclination: 12.71296,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H342796, H342812, H351434',
      },
    ],
  },
  {
    provisionalCode: '2016 GE370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.73,
    semimajorAxis: 40.20324,
    eccentricity: 0.0119848,
    inclination: 13.29984,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H352735, H352738, H352762',
      },
    ],
  },
  {
    provisionalCode: '2016 GF370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.35,
    semimajorAxis: 58.53619,
    eccentricity: 0.2534277,
    inclination: 2.40667,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H324657',
      },
    ],
  },
  {
    provisionalCode: '2016 GG370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.74,
    semimajorAxis: 42.48532,
    eccentricity: 0.1353189,
    inclination: 3.3737,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H357444, H357449, H357581',
      },
    ],
  },
  {
    provisionalCode: '2016 GH370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.6,
    semimajorAxis: 285.48743,
    eccentricity: 0.9074947,
    inclination: 1.59916,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H323757',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357281',
      },
    ],
  },
  {
    provisionalCode: '2016 GJ370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.19,
    semimajorAxis: 193.24901,
    eccentricity: 0.8851505,
    inclination: 14.46138,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H328425',
      },
      {
        userName: 'れお',
        HNames: 'H328425',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357312',
      },
    ],
  },
  {
    provisionalCode: '2016 GK370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.18,
    semimajorAxis: 112.6631,
    eccentricity: 0.8427503,
    inclination: 2.18422,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H334563',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357374, H357375',
      },
    ],
  },
  {
    provisionalCode: '2016 RR105',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.44,
    semimajorAxis: 41.17302,
    eccentricity: 0.0955479,
    inclination: 16.92772,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233039, H319422',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H233039',
      },
    ],
  },
  {
    provisionalCode: '2016 RS105',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.02,
    semimajorAxis: 95.26925,
    eccentricity: 0.6281692,
    inclination: 5.95424,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H239102',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H239102',
      },
    ],
  },
  {
    provisionalCode: '2017 DR171',
    note: '',
    absoluteMagnitude: 19.45,
    semimajorAxis: 3.02008,
    eccentricity: 0.1243141,
    inclination: 9.12733,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ko',
        HNames: 'H267741',
      },
      {
        userName: 'aika',
        HNames: 'H267741',
      },
    ],
  },
  {
    provisionalCode: '2017 EA58',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.37,
    semimajorAxis: 43.28709,
    eccentricity: 0.1226128,
    inclination: 11.45301,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: '井上 康',
        HNames: 'H300639',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H306066',
      },
    ],
  },
  {
    provisionalCode: '2017 FG246',
    note: '',
    absoluteMagnitude: 19.28,
    semimajorAxis: 3.1053,
    eccentricity: 0.1526894,
    inclination: 10.03084,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220938, H310976',
      },
      {
        userName: 'kn1cht',
        HNames: 'H224505',
      },
      {
        userName: 'aika',
        HNames: 'H244412',
      },
    ],
  },
  {
    provisionalCode: '2017 FH246',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 2.98489,
    eccentricity: 0.109108,
    inclination: 9.16839,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H242683',
      },
      {
        userName: 'もしもス',
        HNames: 'H242683',
      },
      {
        userName: 'aika',
        HNames: 'H242683',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ246',
    note: '',
    absoluteMagnitude: 20.4,
    semimajorAxis: 2.40329,
    eccentricity: 0.2108893,
    inclination: 6.56528,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249055',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249055',
      },
    ],
  },
  {
    provisionalCode: '2017 FK246',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.23278,
    eccentricity: 0.0756676,
    inclination: 8.18985,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249056',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249056',
      },
    ],
  },
  {
    provisionalCode: '2017 FL246',
    note: '',
    absoluteMagnitude: 20.34,
    semimajorAxis: 3.16156,
    eccentricity: 0.0614942,
    inclination: 8.01116,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249058',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249058',
      },
    ],
  },
  {
    provisionalCode: '2017 FM246',
    note: '',
    absoluteMagnitude: 21.28,
    semimajorAxis: 2.38378,
    eccentricity: 0.1882697,
    inclination: 5.30088,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249483',
      },
      {
        userName: 'aika',
        HNames: 'H249483',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312084',
      },
    ],
  },
  {
    provisionalCode: '2017 FN246',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 3.14541,
    eccentricity: 0.1148431,
    inclination: 10.93558,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H249484',
      },
      {
        userName: 'aika',
        HNames: 'H249484',
      },
      {
        userName: 'れお',
        HNames: 'H249484',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312085',
      },
    ],
  },
  {
    provisionalCode: '2017 FO246',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.98707,
    eccentricity: 0.0614166,
    inclination: 8.76976,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H277811',
      },
      {
        userName: 'aika',
        HNames: 'H277811',
      },
      {
        userName: 'もしもス',
        HNames: 'H277968',
      },
    ],
  },
  {
    provisionalCode: '2017 FP246',
    note: '',
    absoluteMagnitude: 22.19,
    semimajorAxis: 2.01181,
    eccentricity: 0.0530422,
    inclination: 18.40716,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H224527',
      },
      {
        userName: 'aika',
        HNames: 'H224527',
      },
      {
        userName: 'arda',
        HNames: 'H273148',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ246',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 3.22156,
    eccentricity: 0.2063681,
    inclination: 25.25965,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H224528',
      },
      {
        userName: 'aika',
        HNames: 'H224528',
      },
    ],
  },
  {
    provisionalCode: '2017 FR246',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.17836,
    eccentricity: 0.0527529,
    inclination: 9.09925,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H240696, H243155',
      },
    ],
  },
  {
    provisionalCode: '2017 FS246',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 3.2666,
    eccentricity: 0.1134124,
    inclination: 8.34294,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243154, H244111',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H244111',
      },
    ],
  },
  {
    provisionalCode: '2017 FT246',
    note: 'Mars Crosser',
    absoluteMagnitude: 22.16,
    semimajorAxis: 2.27547,
    eccentricity: 0.3478062,
    inclination: 4.92468,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H249257',
      },
      {
        userName: 'aika',
        HNames: 'H249257',
      },
      {
        userName: 'ossan',
        HNames: 'H305515',
      },
    ],
  },
  {
    provisionalCode: '2017 FU246',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.02433,
    eccentricity: 0.0691147,
    inclination: 6.9501,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H249259',
      },
      {
        userName: 'aika',
        HNames: 'H249259',
      },
      {
        userName: 'kn1cht',
        HNames: 'H258258',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H258258',
      },
    ],
  },
  {
    provisionalCode: '2017 FV246',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 2.96774,
    eccentricity: 0.0988089,
    inclination: 8.91903,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250028, H268303',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H250028',
      },
      {
        userName: 'nasiar99',
        HNames: 'H250028',
      },
    ],
  },
  {
    provisionalCode: '2017 FW246',
    note: '2011 UQ238と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'arda',
        HNames: 'H266080',
      },
      {
        userName: 'aika',
        HNames: 'H268890',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312759',
      },
    ],
  },
  {
    provisionalCode: '2017 FX246',
    note: '',
    absoluteMagnitude: 18.52,
    semimajorAxis: 3.18034,
    eccentricity: 0.0742743,
    inclination: 8.92389,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H267085, H268302',
      },
      {
        userName: 'arda',
        HNames: 'H267085',
      },
      {
        userName: 'kn1cht',
        HNames: 'H283955',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312360',
      },
    ],
  },
  {
    provisionalCode: '2017 FY246',
    note: '2019 PY81と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249902, H249904, H268301',
      },
      {
        userName: 'れお',
        HNames: 'H268301',
      },
      {
        userName: '☆野ー暉',
        HNames: 'H310657',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312023',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ246',
    note: '',
    absoluteMagnitude: 20.92,
    semimajorAxis: 3.2265,
    eccentricity: 0.2022681,
    inclination: 12.48349,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H267127, H273151',
      },
      {
        userName: 'arda',
        HNames: 'H273151',
      },
    ],
  },
  {
    provisionalCode: '2017 FA247',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 2.75496,
    eccentricity: 0.1695899,
    inclination: 7.97405,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282076',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289670',
      },
    ],
  },
  {
    provisionalCode: '2017 FB247',
    note: '',
    absoluteMagnitude: 19.83,
    semimajorAxis: 3.19064,
    eccentricity: 0.133843,
    inclination: 9.29185,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H289679',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289679',
      },
      {
        userName: 'れお',
        HNames: 'H297743',
      },
    ],
  },
  {
    provisionalCode: '2017 FC247',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 3.22749,
    eccentricity: 0.0601738,
    inclination: 15.34452,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H252278',
      },
      {
        userName: 'aika',
        HNames: 'H255681, H290852',
      },
      {
        userName: 'もしもス',
        HNames: 'H255681',
      },
    ],
  },
  {
    provisionalCode: '2017 FD247',
    note: '',
    absoluteMagnitude: 21.3,
    semimajorAxis: 2.54143,
    eccentricity: 0.1724929,
    inclination: 11.66874,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H262926, H267234',
      },
    ],
  },
  {
    provisionalCode: '2017 FE247',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.64189,
    eccentricity: 0.1943577,
    inclination: 12.71419,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H263377',
      },
      {
        userName: 'aika',
        HNames: 'H263377',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289665, H289666, H289697',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H313584',
      },
    ],
  },
  {
    provisionalCode: '2017 FF247',
    note: '',
    absoluteMagnitude: 22.1,
    semimajorAxis: 2.27921,
    eccentricity: 0.1667393,
    inclination: 5.10563,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H222084',
      },
      {
        userName: 'aika',
        HNames: 'H281222',
      },
      {
        userName: 'ほたる',
        HNames: 'H281222',
      },
    ],
  },
  {
    provisionalCode: '2017 FG247',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 2.63462,
    eccentricity: 0.2576909,
    inclination: 13.0846,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290804',
      },
      {
        userName: 'aika',
        HNames: 'H293575',
      },
    ],
  },
  {
    provisionalCode: '2017 FH247',
    note: '',
    absoluteMagnitude: 20.08,
    semimajorAxis: 2.5599,
    eccentricity: 0.1737692,
    inclination: 7.03334,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247528, H267141',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H313583',
      },
    ],
  },
  {
    provisionalCode: '2017 FJ247',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.51699,
    eccentricity: 0.4214438,
    inclination: 8.47338,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H262944',
      },
      {
        userName: 'aika',
        HNames: 'H262944',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312620',
      },
    ],
  },
  {
    provisionalCode: '2017 FK247',
    note: '',
    absoluteMagnitude: 21.67,
    semimajorAxis: 2.5794,
    eccentricity: 0.1056156,
    inclination: 8.38712,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263052',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H263052, H289422',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H292196',
      },
    ],
  },
  {
    provisionalCode: '2017 FL247',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 3.18399,
    eccentricity: 0.0999804,
    inclination: 8.89385,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263409, H264150',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H264150',
      },
      {
        userName: 's.ozawa',
        HNames: 'H314339',
      },
    ],
  },
  {
    provisionalCode: '2017 FM247',
    note: '',
    absoluteMagnitude: 20.11,
    semimajorAxis: 3.15758,
    eccentricity: 0.0630612,
    inclination: 7.79207,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263414, H320912',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290291',
      },
    ],
  },
  {
    provisionalCode: '2017 FN247',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 3.10891,
    eccentricity: 0.2433303,
    inclination: 16.37068,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290294',
      },
      {
        userName: 'aika',
        HNames: 'H293549',
      },
    ],
  },
  {
    provisionalCode: '2017 FO247',
    note: '',
    absoluteMagnitude: 18.11,
    semimajorAxis: 3.23376,
    eccentricity: 0.1146238,
    inclination: 12.40166,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'けんたろうのすけ',
        HNames: 'H218689',
      },
      {
        userName: 'zorome',
        HNames: 'H248099, H249475',
      },
    ],
  },
  {
    provisionalCode: '2017 FP247',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 3.1697,
    eccentricity: 0.0523245,
    inclination: 8.78936,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H250472',
      },
      {
        userName: 'zorome',
        HNames: 'H252279',
      },
    ],
  },
  {
    provisionalCode: '2017 FQ247',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.67014,
    eccentricity: 0.24272,
    inclination: 7.05985,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H256348, H294805',
      },
    ],
  },
  {
    provisionalCode: '2017 FR247',
    note: '',
    absoluteMagnitude: 20.65,
    semimajorAxis: 2.27909,
    eccentricity: 0.2683447,
    inclination: 26.38817,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H257516',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279452, H290803',
      },
    ],
  },
  {
    provisionalCode: '2017 FS247',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.99,
    semimajorAxis: 50.98029,
    eccentricity: 0.3468619,
    inclination: 22.77282,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251530, H354380, H354402',
      },
      {
        userName: 'aika',
        HNames: 'H251530',
      },
    ],
  },
  {
    provisionalCode: '2017 FT247',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.49298,
    eccentricity: 0.0382477,
    inclination: 14.05085,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220934',
      },
      {
        userName: 'zorome',
        HNames: 'H241358',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H241358',
      },
    ],
  },
  {
    provisionalCode: '2017 FU247',
    note: '2014 SG302と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242420, H248098',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289774',
      },
    ],
  },
  {
    provisionalCode: '2017 FV247',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.2284,
    eccentricity: 0.0343003,
    inclination: 8.27889,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249152',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249152',
      },
      {
        userName: 'れお',
        HNames: 'H249152',
      },
    ],
  },
  {
    provisionalCode: '2017 FW247',
    note: 'Mars Crosser',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.94372,
    eccentricity: 0.4687681,
    inclination: 7.30357,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249482',
      },
      {
        userName: 'ko',
        HNames: 'H270881',
      },
      {
        userName: 'theoria',
        HNames: 'H311793',
      },
      {
        userName: 's.ozawa',
        HNames: 'H314543',
      },
    ],
  },
  {
    provisionalCode: '2017 FX247',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 2.98178,
    eccentricity: 0.0706528,
    inclination: 9.77995,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H249704',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H249704',
      },
      {
        userName: 'かふぇいん',
        HNames: 'H281098',
      },
      {
        userName: 'れお',
        HNames: 'H281098',
      },
    ],
  },
  {
    provisionalCode: '2017 FY247',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.2009,
    eccentricity: 0.1351237,
    inclination: 8.38107,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278386',
      },
      {
        userName: 'arda',
        HNames: 'H278386, H279231',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312082',
      },
    ],
  },
  {
    provisionalCode: '2017 FZ247',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.65107,
    eccentricity: 0.1400593,
    inclination: 12.19085,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263673',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282074',
      },
      {
        userName: 'sasaki',
        HNames: 'H310289',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312833',
      },
    ],
  },
  {
    provisionalCode: '2017 FA248',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 3.12395,
    eccentricity: 0.1371372,
    inclination: 8.5022,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279450, H282078',
      },
      {
        userName: 'ko',
        HNames: 'H282078',
      },
    ],
  },
  {
    provisionalCode: '2017 GH37',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 3.0723,
    eccentricity: 0.0099195,
    inclination: 7.90732,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H245931',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245931',
      },
      {
        userName: 'aika',
        HNames: 'H245931',
      },
    ],
  },
  {
    provisionalCode: '2017 GJ37',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 3.13188,
    eccentricity: 0.1983552,
    inclination: 12.55647,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H243490',
      },
      {
        userName: 'aika',
        HNames: 'H243490',
      },
    ],
  },
  {
    provisionalCode: '2017 GK37',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.65344,
    eccentricity: 0.2253286,
    inclination: 8.57537,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H272155',
      },
      {
        userName: 'arda',
        HNames: 'H272155',
      },
      {
        userName: 'ほたる',
        HNames: 'H281173',
      },
    ],
  },
  {
    provisionalCode: '2017 GL37',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 3.23703,
    eccentricity: 0.1582784,
    inclination: 7.27336,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H220866',
      },
      {
        userName: 'aika',
        HNames: 'H243322',
      },
    ],
  },
  {
    provisionalCode: '2017 GM37',
    note: '',
    absoluteMagnitude: 21.04,
    semimajorAxis: 2.55975,
    eccentricity: 0.1551329,
    inclination: 6.7822,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242324',
      },
      {
        userName: 'aika',
        HNames: 'H243208',
      },
      {
        userName: 'れお',
        HNames: 'H318085',
      },
    ],
  },
  {
    provisionalCode: '2017 GN37',
    note: '',
    absoluteMagnitude: 20.12,
    semimajorAxis: 2.33756,
    eccentricity: 0.2329576,
    inclination: 10.96157,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H259576',
      },
      {
        userName: '赵经远',
        HNames: 'H289395',
      },
      {
        userName: 'kn1cht',
        HNames: 'H297326',
      },
    ],
  },
  {
    provisionalCode: '2017 GO37',
    note: '',
    absoluteMagnitude: 20.89,
    semimajorAxis: 2.38131,
    eccentricity: 0.1267059,
    inclination: 6.69793,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247521',
      },
      {
        userName: 'ほたる',
        HNames: 'H281174',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H290293',
      },
    ],
  },
  {
    provisionalCode: '2017 GP37',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.94042,
    eccentricity: 0.0713325,
    inclination: 7.27685,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247522',
      },
      {
        userName: 'ほたる',
        HNames: 'H281224',
      },
    ],
  },
  {
    provisionalCode: '2017 GQ37',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.65946,
    eccentricity: 0.2289553,
    inclination: 12.88043,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H243191, H267709',
      },
    ],
  },
  {
    provisionalCode: '2017 GR37',
    note: '',
    absoluteMagnitude: 20.73,
    semimajorAxis: 2.13289,
    eccentricity: 0.1774442,
    inclination: 6.14744,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H247519, H281223',
      },
      {
        userName: 'ほたる',
        HNames: 'H281223',
      },
    ],
  },
  {
    provisionalCode: '2017 GS37',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 3.63911,
    eccentricity: 0.291749,
    inclination: 7.86265,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H287338',
      },
      {
        userName: 'arda',
        HNames: 'H288308',
      },
      {
        userName: 'maria w',
        HNames: 'H295595',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312847',
      },
    ],
  },
  {
    provisionalCode: '2017 SQ385',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.98,
    semimajorAxis: 57.75139,
    eccentricity: 0.3715972,
    inclination: 24.12947,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H206128',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319847',
      },
    ],
  },
  {
    provisionalCode: '2019 CM31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.08,
    semimajorAxis: 53.5219,
    eccentricity: 0.3181646,
    inclination: 4.84539,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H291710, H291716',
      },
    ],
  },
  {
    provisionalCode: '2019 CN31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 6.68,
    semimajorAxis: 70.18199,
    eccentricity: 0.4502267,
    inclination: 21.02979,
    oppositions: 3,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H291294, H291326, H311964',
      },
    ],
  },
  {
    provisionalCode: '2019 CO31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.86,
    semimajorAxis: 46.48366,
    eccentricity: 0.2201546,
    inclination: 34.10491,
    oppositions: 5,
    uncertainty: 5,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H273974',
      },
      {
        userName: 'zorome',
        HNames: 'H290692',
      },
    ],
  },
  {
    provisionalCode: '2019 CP31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.97,
    semimajorAxis: 44.76271,
    eccentricity: 0.1193797,
    inclination: 4.03585,
    oppositions: 2,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H286553',
      },
      {
        userName: 'zorome',
        HNames: 'H290979',
      },
    ],
  },
  {
    provisionalCode: '2019 CQ31',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.23,
    semimajorAxis: 43.78661,
    eccentricity: 0.0643855,
    inclination: 4.17928,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H274125',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H274125',
      },
    ],
  },
  {
    provisionalCode: '2019 EV8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.68,
    semimajorAxis: 46.27842,
    eccentricity: 0.0415402,
    inclination: 3.82632,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H289706',
      },
      {
        userName: 'zorome',
        HNames: 'H291752',
      },
    ],
  },
  {
    provisionalCode: '2019 EW8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.43,
    semimajorAxis: 42.22119,
    eccentricity: 0.2003822,
    inclination: 13.64906,
    oppositions: 5,
    uncertainty: 4,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H271803, H274151',
      },
    ],
  },
  {
    provisionalCode: '2019 GS187',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.18,
    semimajorAxis: 39.32407,
    eccentricity: 0.2108293,
    inclination: 10.71163,
    oppositions: 2,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235010',
      },
    ],
  },
  {
    provisionalCode: '2019 GT187',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.76,
    semimajorAxis: 45.35215,
    eccentricity: 0.1656265,
    inclination: 10.42729,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235047',
      },
    ],
  },
  {
    provisionalCode: '2019 GU187',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.18,
    semimajorAxis: 43.08661,
    eccentricity: 0.1386492,
    inclination: 10.08752,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H235114',
      },
    ],
  },
  {
    provisionalCode: '2019 SO248',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 2.89217,
    eccentricity: 0.1007077,
    inclination: 15.88159,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H227771, H234365',
      },
      {
        userName: 'れお',
        HNames: 'H291476',
      },
    ],
  },
  {
    provisionalCode: '2019 SP248',
    note: '',
    absoluteMagnitude: 20.4,
    semimajorAxis: 2.99565,
    eccentricity: 0.2333195,
    inclination: 10.75088,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H230324',
      },
      {
        userName: 'hirata',
        HNames: 'H230324',
      },
      {
        userName: 'ほたる',
        HNames: 'H287897',
      },
    ],
  },
  {
    provisionalCode: '2019 SQ248',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.31482,
    eccentricity: 0.1152626,
    inclination: 10.91666,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232780, H266918',
      },
      {
        userName: 'mitarushi',
        HNames: 'H232835',
      },
    ],
  },
  {
    provisionalCode: '2019 SR248',
    note: '',
    absoluteMagnitude: 18.95,
    semimajorAxis: 2.59467,
    eccentricity: 0.038817,
    inclination: 12.47543,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'hirata',
        HNames: 'H236472',
      },
      {
        userName: 'れお',
        HNames: 'H236472, H298195',
      },
    ],
  },
  {
    provisionalCode: '2019 SS248',
    note: '',
    absoluteMagnitude: 19.27,
    semimajorAxis: 3.25229,
    eccentricity: 0.1125795,
    inclination: 14.28505,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H281611',
      },
      {
        userName: 'れお',
        HNames: 'H281611',
      },
      {
        userName: 'ほたる',
        HNames: 'H287906',
      },
    ],
  },
  {
    provisionalCode: '2019 ST248',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 3.12796,
    eccentricity: 0.2117523,
    inclination: 12.10652,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H229668',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229668',
      },
      {
        userName: 's.ozawa',
        HNames: 'H276624',
      },
    ],
  },
  {
    provisionalCode: '2019 SU248',
    note: '',
    absoluteMagnitude: 19.83,
    semimajorAxis: 2.73289,
    eccentricity: 0.0264735,
    inclination: 13.86993,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H253655',
      },
      {
        userName: 's.ozawa',
        HNames: 'H253655',
      },
      {
        userName: 'jim',
        HNames: 'H313185',
      },
    ],
  },
  {
    provisionalCode: '2019 SV248',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 2.7355,
    eccentricity: 0.3329395,
    inclination: 18.37515,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'やまささ',
        HNames: 'H215877',
      },
      {
        userName: 'れお',
        HNames: 'H276625',
      },
      {
        userName: 's.ozawa',
        HNames: 'H276625',
      },
    ],
  },
  {
    provisionalCode: '2019 SW248',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 2.88486,
    eccentricity: 0.0855474,
    inclination: 12.12422,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H255746',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H255746',
      },
      {
        userName: 'れお',
        HNames: 'H262065, H313186',
      },
      {
        userName: 'jim',
        HNames: 'H313186',
      },
    ],
  },
  {
    provisionalCode: '2019 TJ97',
    note: '',
    absoluteMagnitude: 19.57,
    semimajorAxis: 2.65435,
    eccentricity: 0.1960255,
    inclination: 14.44446,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H232840',
      },
      {
        userName: '赵经远',
        HNames: 'H242425',
      },
    ],
  },
  {
    provisionalCode: '2019 TK97',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 3.01035,
    eccentricity: 0.1040676,
    inclination: 13.60479,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H232845',
      },
      {
        userName: 's.ozawa',
        HNames: 'H272935',
      },
      {
        userName: 'れお',
        HNames: 'H272935',
      },
    ],
  },
  {
    provisionalCode: '2019 UW177',
    note: '',
    absoluteMagnitude: 17.56,
    semimajorAxis: 3.1006,
    eccentricity: 0.0721921,
    inclination: 17.11059,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mikage_stone0720',
        HNames: 'H205140',
      },
      {
        userName: 'れお',
        HNames: 'H265248, H266501',
      },
    ],
  },
  {
    provisionalCode: '2019 UX177',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 2.65559,
    eccentricity: 0.2457713,
    inclination: 12.26963,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214444',
      },
      {
        userName: 'もしもス',
        HNames: 'H214444',
      },
      {
        userName: 's.ozawa',
        HNames: 'H239637',
      },
    ],
  },
  {
    provisionalCode: '2019 UY177',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.09774,
    eccentricity: 0.0696522,
    inclination: 12.07265,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'やまささ',
        HNames: 'H215884',
      },
      {
        userName: 's.ozawa',
        HNames: 'H235665, H239491',
      },
    ],
  },
  {
    provisionalCode: '2019 UZ177',
    note: '',
    absoluteMagnitude: 19.98,
    semimajorAxis: 2.71577,
    eccentricity: 0.2139316,
    inclination: 10.47628,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H231702, H238000',
      },
      {
        userName: 'れお',
        HNames: 'H263974',
      },
    ],
  },
  {
    provisionalCode: '2019 UA178',
    note: '',
    absoluteMagnitude: 18.83,
    semimajorAxis: 3.16476,
    eccentricity: 0.0894706,
    inclination: 15.73664,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H242349',
      },
      {
        userName: 's.ozawa',
        HNames: 'H247014',
      },
    ],
  },
  {
    provisionalCode: '2019 UB178',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.02282,
    eccentricity: 0.1150623,
    inclination: 12.62521,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H247371',
      },
      {
        userName: 'れお',
        HNames: 'H267379, H267417',
      },
    ],
  },
  {
    provisionalCode: '2019 UC178',
    note: '',
    absoluteMagnitude: 20.42,
    semimajorAxis: 3.01229,
    eccentricity: 0.2222397,
    inclination: 10.89838,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226220',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238617, H249504',
      },
    ],
  },
  {
    provisionalCode: '2019 UD178',
    note: '',
    absoluteMagnitude: 21.8,
    semimajorAxis: 2.54308,
    eccentricity: 0.1651097,
    inclination: 17.94918,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232753, H238649',
      },
      {
        userName: 'れお',
        HNames: 'H267180',
      },
    ],
  },
  {
    provisionalCode: '2019 UE178',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.05049,
    eccentricity: 0.143584,
    inclination: 11.62207,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232916, H238538',
      },
      {
        userName: 'れお',
        HNames: 'H257887',
      },
    ],
  },
  {
    provisionalCode: '2019 UF178',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 10.45,
    semimajorAxis: 40.0772,
    eccentricity: 0.2855807,
    inclination: 22.99024,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mi zhang',
        HNames: 'H314409',
      },
      {
        userName: 'chara',
        HNames: 'H320913',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H320913, H358236, H358237',
      },
    ],
  },
  {
    provisionalCode: '2019 VV54',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 3.04443,
    eccentricity: 0.2420768,
    inclination: 14.13007,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H222190, H279454',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H288018',
      },
    ],
  },
  {
    provisionalCode: '2019 VW54',
    note: '',
    absoluteMagnitude: 18.63,
    semimajorAxis: 3.12707,
    eccentricity: 0.2803112,
    inclination: 23.70852,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'guoyou sun',
        HNames: 'H228565',
      },
      {
        userName: 's.ozawa',
        HNames: 'H259644',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279615',
      },
    ],
  },
  {
    provisionalCode: '2019 VX54',
    note: '',
    absoluteMagnitude: 20.36,
    semimajorAxis: 2.96605,
    eccentricity: 0.0810783,
    inclination: 13.24761,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H237795',
      },
      {
        userName: 'このしろ',
        HNames: 'H237795',
      },
      {
        userName: 's.ozawa',
        HNames: 'H247370',
      },
    ],
  },
  {
    provisionalCode: '2019 VY54',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.00004,
    eccentricity: 0.0954259,
    inclination: 11.91232,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H222173',
      },
      {
        userName: 's.ozawa',
        HNames: 'H235669',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ54',
    note: '',
    absoluteMagnitude: 17.86,
    semimajorAxis: 3.20147,
    eccentricity: 0.0407293,
    inclination: 12.63017,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226221',
      },
      {
        userName: 's.ozawa',
        HNames: 'H229414, H237911',
      },
    ],
  },
  {
    provisionalCode: '2019 VA55',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 2.98378,
    eccentricity: 0.1251694,
    inclination: 11.51609,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H226223',
      },
      {
        userName: 'もしもス',
        HNames: 'H226223',
      },
      {
        userName: 's.ozawa',
        HNames: 'H234923',
      },
    ],
  },
  {
    provisionalCode: '2019 VB55',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 3.10116,
    eccentricity: 0.1863834,
    inclination: 11.97788,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H230023',
      },
      {
        userName: 'mitarushi',
        HNames: 'H230023',
      },
      {
        userName: 's.ozawa',
        HNames: 'H236839, H251883, H282031',
      },
    ],
  },
  {
    provisionalCode: '2019 VC55',
    note: '',
    absoluteMagnitude: 18.15,
    semimajorAxis: 3.02628,
    eccentricity: 0.0915539,
    inclination: 11.99544,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H230596',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279610',
      },
    ],
  },
  {
    provisionalCode: '2019 VD55',
    note: '',
    absoluteMagnitude: 18.74,
    semimajorAxis: 3.124,
    eccentricity: 0.1518428,
    inclination: 11.77656,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H230721',
      },
      {
        userName: '赵经远',
        HNames: 'H230721',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279612',
      },
    ],
  },
  {
    provisionalCode: '2019 VE55',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.55097,
    eccentricity: 0.0798061,
    inclination: 17.87174,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232852, H285112',
      },
      {
        userName: 's.ozawa',
        HNames: 'H232852',
      },
    ],
  },
  {
    provisionalCode: '2019 VF55',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 3.03347,
    eccentricity: 0.0852722,
    inclination: 12.30243,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238274',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238274',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H301730',
      },
    ],
  },
  {
    provisionalCode: '2019 WE38',
    note: '',
    absoluteMagnitude: 19.89,
    semimajorAxis: 2.83059,
    eccentricity: 0.1159546,
    inclination: 13.27217,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'やまささ',
        HNames: 'H215783',
      },
      {
        userName: 's.ozawa',
        HNames: 'H226424, H233735',
      },
    ],
  },
  {
    provisionalCode: '2019 WF38',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 3.09457,
    eccentricity: 0.2265801,
    inclination: 20.09135,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'starolite',
        HNames: 'H224186',
      },
      {
        userName: 'zorome',
        HNames: 'H229913',
      },
      {
        userName: 's.ozawa',
        HNames: 'H232426',
      },
    ],
  },
  {
    provisionalCode: '2019 WG38',
    note: 'Hungaria',
    absoluteMagnitude: 21.16,
    semimajorAxis: 1.90215,
    eccentricity: 0.0913115,
    inclination: 22.502,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H226474',
      },
      {
        userName: 'れお',
        HNames: 'H268176',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287662',
      },
    ],
  },
  {
    provisionalCode: '2019 WH38',
    note: '',
    absoluteMagnitude: 20.44,
    semimajorAxis: 2.54574,
    eccentricity: 0.0422849,
    inclination: 14.51849,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H230002',
      },
      {
        userName: 's.ozawa',
        HNames: 'H264578',
      },
      {
        userName: 'れお',
        HNames: 'H269919',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ38',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.72527,
    eccentricity: 0.228316,
    inclination: 14.46955,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'bny',
        HNames: 'H225108',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238173',
      },
      {
        userName: 'belleequipe',
        HNames: 'H241327',
      },
    ],
  },
  {
    provisionalCode: '2019 WK38',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.05146,
    eccentricity: 0.1100268,
    inclination: 14.26042,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'bny',
        HNames: 'H225109',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238174, H251163',
      },
    ],
  },
  {
    provisionalCode: '2019 WL38',
    note: '',
    absoluteMagnitude: 21.9,
    semimajorAxis: 2.65,
    eccentricity: 0.2342406,
    inclination: 12.93313,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H226209',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H226209',
      },
      {
        userName: 'mitarushi',
        HNames: 'H226209',
      },
    ],
  },
  {
    provisionalCode: '2019 WM38',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.56533,
    eccentricity: 0.0915762,
    inclination: 27.0396,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H226477',
      },
      {
        userName: 's.ozawa',
        HNames: 'H237829, H248332',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240935',
      },
    ],
  },
  {
    provisionalCode: '2019 WN38',
    note: '',
    absoluteMagnitude: 19.5,
    semimajorAxis: 2.56642,
    eccentricity: 0.0598564,
    inclination: 15.91514,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H229334',
      },
      {
        userName: 'れお',
        HNames: 'H256018',
      },
    ],
  },
  {
    provisionalCode: '2019 WO38',
    note: '',
    absoluteMagnitude: 20.49,
    semimajorAxis: 3.05652,
    eccentricity: 0.1282189,
    inclination: 14.92471,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H267577',
      },
      {
        userName: 'kn1cht',
        HNames: 'H267577',
      },
    ],
  },
  {
    provisionalCode: '2019 WP38',
    note: '',
    absoluteMagnitude: 18.87,
    semimajorAxis: 3.10085,
    eccentricity: 0.1566584,
    inclination: 18.3611,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H267579',
      },
      {
        userName: 's.ozawa',
        HNames: 'H273174',
      },
      {
        userName: 'jim',
        HNames: 'H313681',
      },
    ],
  },
  {
    provisionalCode: '2019 WQ38',
    note: '',
    absoluteMagnitude: 18.92,
    semimajorAxis: 2.87342,
    eccentricity: 0.1441574,
    inclination: 17.26928,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H248771',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277598',
      },
      {
        userName: 'れお',
        HNames: 'H277598',
      },
    ],
  },
  {
    provisionalCode: '2019 WR38',
    note: '',
    absoluteMagnitude: 17.83,
    semimajorAxis: 3.12769,
    eccentricity: 0.1273931,
    inclination: 17.26937,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H232441, H282870, H282873, H282875',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282870',
      },
    ],
  },
  {
    provisionalCode: '2019 WS38',
    note: '',
    absoluteMagnitude: 20.08,
    semimajorAxis: 3.15572,
    eccentricity: 0.1640565,
    inclination: 16.44777,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H241795',
      },
      {
        userName: 's.ozawa',
        HNames: 'H278807',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H287663',
      },
    ],
  },
  {
    provisionalCode: '2019 WT38',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 3.03816,
    eccentricity: 0.0678138,
    inclination: 13.28449,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H267215, H267620',
      },
    ],
  },
  {
    provisionalCode: '2019 WU38',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 2.96893,
    eccentricity: 0.2397476,
    inclination: 12.19961,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H252359',
      },
      {
        userName: 'れお',
        HNames: 'H260694',
      },
      {
        userName: 'kn1cht',
        HNames: 'H276214',
      },
    ],
  },
  {
    provisionalCode: '2019 WV38',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 2.8445,
    eccentricity: 0.2727364,
    inclination: 14.72838,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'bny',
        HNames: 'H225173',
      },
      {
        userName: '宇宙人202404',
        HNames: 'H302139',
      },
      {
        userName: 's.ozawa',
        HNames: 'H313710',
      },
    ],
  },
  {
    provisionalCode: '2019 WW38',
    note: '',
    absoluteMagnitude: 20.31,
    semimajorAxis: 2.61514,
    eccentricity: 0.2381092,
    inclination: 12.79884,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228398',
      },
      {
        userName: 'れお',
        HNames: 'H255551',
      },
    ],
  },
  {
    provisionalCode: '2019 WX38',
    note: '',
    absoluteMagnitude: 21.07,
    semimajorAxis: 2.29394,
    eccentricity: 0.2043315,
    inclination: 9.15972,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255654, H255666, H255952',
      },
    ],
  },
  {
    provisionalCode: '2019 WY38',
    note: '',
    absoluteMagnitude: 23.04,
    semimajorAxis: 2.24401,
    eccentricity: 0.1560996,
    inclination: 9.43622,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255655, H255667',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255667',
      },
    ],
  },
  {
    provisionalCode: '2019 WZ38',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.16271,
    eccentricity: 0.202577,
    inclination: 12.26526,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255953',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H289797, H289799, H289816',
      },
      {
        userName: 'maria w',
        HNames: 'H295164',
      },
    ],
  },
  {
    provisionalCode: '2019 WA39',
    note: '',
    absoluteMagnitude: 17.42,
    semimajorAxis: 3.13235,
    eccentricity: 0.0674045,
    inclination: 15.65739,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H200057',
      },
      {
        userName: 'kn1cht',
        HNames: 'H200057',
      },
    ],
  },
  {
    provisionalCode: '2019 WB39',
    note: '',
    absoluteMagnitude: 18.79,
    semimajorAxis: 3.13223,
    eccentricity: 0.0432973,
    inclination: 15.51668,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H216021',
      },
      {
        userName: 'zorome',
        HNames: 'H216021',
      },
      {
        userName: 's.ozawa',
        HNames: 'H241110',
      },
    ],
  },
  {
    provisionalCode: '2019 WC39',
    note: '',
    absoluteMagnitude: 21.37,
    semimajorAxis: 2.6798,
    eccentricity: 0.1943845,
    inclination: 14.94719,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228766',
      },
      {
        userName: 'zorome',
        HNames: 'H228766',
      },
    ],
  },
  {
    provisionalCode: '2019 WD39',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.76815,
    eccentricity: 0.1794168,
    inclination: 12.76105,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228793',
      },
    ],
  },
  {
    provisionalCode: '2019 WE39',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.09607,
    eccentricity: 0.1132087,
    inclination: 12.33829,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H228808',
      },
      {
        userName: 'れお',
        HNames: 'H296813',
      },
    ],
  },
  {
    provisionalCode: '2019 WF39',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 2.99659,
    eccentricity: 0.1089381,
    inclination: 13.85229,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H228827',
      },
      {
        userName: 'zorome',
        HNames: 'H228828',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319558',
      },
    ],
  },
  {
    provisionalCode: '2019 WG39',
    note: '',
    absoluteMagnitude: 19.66,
    semimajorAxis: 2.86777,
    eccentricity: 0.2150096,
    inclination: 12.99555,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H229332',
      },
      {
        userName: 'zorome',
        HNames: 'H229332, H229369',
      },
    ],
  },
  {
    provisionalCode: '2019 WH39',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 2.53995,
    eccentricity: 0.1300187,
    inclination: 13.20614,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H229379',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H287472',
      },
      {
        userName: 'れお',
        HNames: 'H296344',
      },
    ],
  },
  {
    provisionalCode: '2019 WJ39',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 3.08735,
    eccentricity: 0.079306,
    inclination: 13.22153,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H236877',
      },
      {
        userName: 'ctaka',
        HNames: 'H236877, H238536',
      },
    ],
  },
  {
    provisionalCode: '2019 WK39',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.37579,
    eccentricity: 0.193095,
    inclination: 15.49154,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H216022',
      },
      {
        userName: 'れお',
        HNames: 'H255486',
      },
    ],
  },
  {
    provisionalCode: '2019 WL39',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 3.26356,
    eccentricity: 0.0495548,
    inclination: 14.73696,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H266652',
      },
      {
        userName: 'kn1cht',
        HNames: 'H266652',
      },
      {
        userName: 'arda',
        HNames: 'H290835',
      },
    ],
  },
  {
    provisionalCode: '2019 XA21',
    note: 'Hungaria',
    absoluteMagnitude: 21.12,
    semimajorAxis: 1.8688,
    eccentricity: 0.0845919,
    inclination: 21.66984,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240936',
      },
      {
        userName: 'れお',
        HNames: 'H270882, H273156',
      },
    ],
  },
  {
    provisionalCode: '2019 XB21',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.85747,
    eccentricity: 0.2098523,
    inclination: 6.12879,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200065',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H200066',
      },
    ],
  },
  {
    provisionalCode: '2019 XC21',
    note: '',
    absoluteMagnitude: 18.42,
    semimajorAxis: 3.10093,
    eccentricity: 0.0910669,
    inclination: 12.58107,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H237133, H280152',
      },
      {
        userName: 'ctaka',
        HNames: 'H237133',
      },
      {
        userName: 'kn1cht',
        HNames: 'H280152',
      },
    ],
  },
  {
    provisionalCode: '2012 QX83',
    note: '',
    absoluteMagnitude: 18.66,
    semimajorAxis: 2.58662,
    eccentricity: 0.1980924,
    inclination: 20.25571,
    oppositions: 4,
    uncertainty: 1,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H343404',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H350889',
      },
    ],
  },
  {
    provisionalCode: '2015 VB222',
    note: '',
    absoluteMagnitude: 18.37,
    semimajorAxis: 2.79342,
    eccentricity: 0.1078012,
    inclination: 14.98947,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H333322',
      },
      {
        userName: 's.ozawa',
        HNames: 'H338847',
      },
      {
        userName: 'apollo18',
        HNames: 'H357489',
      },
    ],
  },
  {
    provisionalCode: '2016 AP164',
    note: 'NEO (Amor)',
    absoluteMagnitude: 21.72,
    semimajorAxis: 1.96149,
    eccentricity: 0.3764253,
    inclination: 7.79728,
    oppositions: 1,
    uncertainty: 4,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H354801',
      },
      {
        userName: 'wanko',
        HNames: 'H354955',
      },
    ],
  },
  {
    provisionalCode: '2016 EH367',
    note: '',
    absoluteMagnitude: 18.29,
    semimajorAxis: 2.65728,
    eccentricity: 0.2346233,
    inclination: 15.91352,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'ななし',
        HNames: 'H340765',
      },
      {
        userName: 'nasiar99',
        HNames: 'H345186',
      },
      {
        userName: 'aika',
        HNames: 'H352483',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ367',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 2.52656,
    eccentricity: 0.2795554,
    inclination: 2.56473,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329435',
      },
      {
        userName: 'zorome',
        HNames: 'H358071, H358242',
      },
    ],
  },
  {
    provisionalCode: '2016 ES367',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.6,
    semimajorAxis: 52.78566,
    eccentricity: 0.6816133,
    inclination: 10.7782,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H362232',
      },
    ],
  },
  {
    provisionalCode: '2016 GO370',
    note: '',
    absoluteMagnitude: 17.54,
    semimajorAxis: 3.15142,
    eccentricity: 0.0537185,
    inclination: 13.04569,
    oppositions: 8,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H344421, H345574, H351533',
      },
    ],
  },
  {
    provisionalCode: '2016 GU370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.4,
    semimajorAxis: 46.45797,
    eccentricity: 0.2494451,
    inclination: 8.74629,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H324327',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324327, H361537',
      },
    ],
  },
  {
    provisionalCode: '2016 GV370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.28,
    semimajorAxis: 99.70802,
    eccentricity: 0.7212572,
    inclination: 4.34645,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H327277',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H361547',
      },
    ],
  },
  {
    provisionalCode: '2016 LL106',
    note: '2012 XP157と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'もくは',
        HNames: 'H214623',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H335012',
      },
      {
        userName: 'maria w',
        HNames: 'H350504',
      },
      {
        userName: 'nasiar99',
        HNames: 'H352205',
      },
    ],
  },
  {
    provisionalCode: '2016 LM106',
    note: '',
    absoluteMagnitude: 17.89,
    semimajorAxis: 3.03582,
    eccentricity: 0.0697974,
    inclination: 10.234,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H267583',
      },
      {
        userName: 'ゆうまり',
        HNames: 'H321616',
      },
      {
        userName: 'れお',
        HNames: 'H344744',
      },
    ],
  },
  {
    provisionalCode: '2016 NC193',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 2.5543,
    eccentricity: 0.2244423,
    inclination: 4.48611,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'hirata',
        HNames: 'H233009',
      },
      {
        userName: 'yakuba',
        HNames: 'H254034',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H352197',
      },
      {
        userName: 'ioring',
        HNames: 'H353959',
      },
    ],
  },
  {
    provisionalCode: '2016 PU299',
    note: '',
    absoluteMagnitude: 18.69,
    semimajorAxis: 2.77392,
    eccentricity: 0.0449389,
    inclination: 3.42713,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255803, H318421',
      },
      {
        userName: 'maria w',
        HNames: 'H332699',
      },
      {
        userName: 'kuni',
        HNames: 'H346926',
      },
    ],
  },
  {
    provisionalCode: '2017 BE245',
    note: '',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.64282,
    eccentricity: 0.2539808,
    inclination: 12.47928,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H266574',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H266574',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H272213',
      },
    ],
  },
  {
    provisionalCode: '2017 BF245',
    note: '',
    absoluteMagnitude: 20.98,
    semimajorAxis: 2.4353,
    eccentricity: 0.1285481,
    inclination: 3.16976,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H270651',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H295792, H315193',
      },
    ],
  },
  {
    provisionalCode: '2017 BG245',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.97526,
    eccentricity: 0.0342549,
    inclination: 3.16396,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264246, H264716',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315288',
      },
    ],
  },
  {
    provisionalCode: '2017 BH245',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 2.69013,
    eccentricity: 0.1177397,
    inclination: 12.83893,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264711, H265344',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315649',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ245',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 2.7467,
    eccentricity: 0.122133,
    inclination: 5.13112,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264712',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H314870, H314877',
      },
    ],
  },
  {
    provisionalCode: '2017 BK245',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 20.11,
    semimajorAxis: 6.9367,
    eccentricity: 0.7674651,
    inclination: 5.76343,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H264715, H265451',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315488',
      },
    ],
  },
  {
    provisionalCode: '2017 BN245',
    note: '',
    absoluteMagnitude: 18.52,
    semimajorAxis: 3.16104,
    eccentricity: 0.1417945,
    inclination: 13.32409,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202162, H202219',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296304',
      },
    ],
  },
  {
    provisionalCode: '2017 BO245',
    note: '',
    absoluteMagnitude: 18.06,
    semimajorAxis: 3.24911,
    eccentricity: 0.1719772,
    inclination: 7.80825,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H260341, H263636',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H315197',
      },
    ],
  },
  {
    provisionalCode: '2017 BP245',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 3.04454,
    eccentricity: 0.2297514,
    inclination: 3.35359,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H266047, H266533',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315490',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ245',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.8798,
    eccentricity: 0.0123209,
    inclination: 3.24191,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270008, H270038',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315632',
      },
    ],
  },
  {
    provisionalCode: '2017 BR245',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 3.13898,
    eccentricity: 0.0833046,
    inclination: 11.71611,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270012',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H315654, H316451',
      },
    ],
  },
  {
    provisionalCode: '2017 BS245',
    note: '',
    absoluteMagnitude: 18.69,
    semimajorAxis: 2.8119,
    eccentricity: 0.081098,
    inclination: 2.79509,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296325, H296329, H296333',
      },
    ],
  },
  {
    provisionalCode: '2017 BT245',
    note: '',
    absoluteMagnitude: 18.73,
    semimajorAxis: 2.69826,
    eccentricity: 0.0981907,
    inclination: 7.69065,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202210, H202411',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298368',
      },
    ],
  },
  {
    provisionalCode: '2017 BU245',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.14114,
    eccentricity: 0.149191,
    inclination: 18.41246,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202241',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298299, H303932',
      },
    ],
  },
  {
    provisionalCode: '2017 BV245',
    note: '',
    absoluteMagnitude: 21.27,
    semimajorAxis: 2.28209,
    eccentricity: 0.0884848,
    inclination: 3.21492,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H261851, H298553',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298813',
      },
    ],
  },
  {
    provisionalCode: '2017 BW245',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.98267,
    eccentricity: 0.1488428,
    inclination: 10.31704,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H270926',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H292484, H316704',
      },
    ],
  },
  {
    provisionalCode: '2017 BX245',
    note: '',
    absoluteMagnitude: 21.12,
    semimajorAxis: 2.38293,
    eccentricity: 0.178913,
    inclination: 4.29076,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297486, H297653, H298338',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298825',
      },
    ],
  },
  {
    provisionalCode: '2017 BY245',
    note: '',
    absoluteMagnitude: 18.07,
    semimajorAxis: 3.32946,
    eccentricity: 0.0999044,
    inclination: 9.70849,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298303, H298311, H298319',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ245',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.37569,
    eccentricity: 0.1507323,
    inclination: 2.13121,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298496',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298815, H298817',
      },
    ],
  },
  {
    provisionalCode: '2017 BA246',
    note: '',
    absoluteMagnitude: 17.77,
    semimajorAxis: 2.98744,
    eccentricity: 0.0663482,
    inclination: 2.85496,
    oppositions: 7,
    uncertainty: 0,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H298777, H298807, H298819',
      },
    ],
  },
  {
    provisionalCode: '2017 BB246',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 3.93019,
    eccentricity: 0.4659232,
    inclination: 8.28114,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H203399',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H293991',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H298379',
      },
    ],
  },
  {
    provisionalCode: '2017 BC246',
    note: '',
    absoluteMagnitude: 18.67,
    semimajorAxis: 2.67358,
    eccentricity: 0.0113094,
    inclination: 3.47924,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H201595, H203959',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H301078',
      },
    ],
  },
  {
    provisionalCode: '2017 BD246',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.39373,
    eccentricity: 0.0817487,
    inclination: 8.12234,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H275096, H276764, H302946',
      },
    ],
  },
  {
    provisionalCode: '2017 BE246',
    note: '',
    absoluteMagnitude: 20.25,
    semimajorAxis: 2.28735,
    eccentricity: 0.1731425,
    inclination: 2.0126,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H293285, H293338, H293419',
      },
    ],
  },
  {
    provisionalCode: '2017 BF246',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 2.82601,
    eccentricity: 0.1472184,
    inclination: 7.08566,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294143',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294143, H294478',
      },
    ],
  },
  {
    provisionalCode: '2017 BG246',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 2.77894,
    eccentricity: 0.0114395,
    inclination: 6.05915,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294147, H294170, H294174',
      },
    ],
  },
  {
    provisionalCode: '2017 BH246',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.20897,
    eccentricity: 0.2023028,
    inclination: 10.49562,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H294176',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H294470, H294474',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ246',
    note: '',
    absoluteMagnitude: 18.83,
    semimajorAxis: 3.36381,
    eccentricity: 0.0411639,
    inclination: 16.40424,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H220073, H221292, H221443',
      },
    ],
  },
  {
    provisionalCode: '2017 BK246',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 2.72939,
    eccentricity: 0.1225641,
    inclination: 14.17331,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H221301, H222069, H318868',
      },
    ],
  },
  {
    provisionalCode: '2017 BL246',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 3.16228,
    eccentricity: 0.1612079,
    inclination: 17.83877,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H221639, H222033, H222126',
      },
    ],
  },
  {
    provisionalCode: '2017 CJ60',
    note: '',
    absoluteMagnitude: 19.3,
    semimajorAxis: 2.9984,
    eccentricity: 0.1063817,
    inclination: 2.72408,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H269325, H271009',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H316408',
      },
    ],
  },
  {
    provisionalCode: '2017 CK60',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 2.60323,
    eccentricity: 0.0776389,
    inclination: 2.32193,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271501, H296599',
      },
      {
        userName: 'joshua',
        HNames: 'H271704, H271858',
      },
    ],
  },
  {
    provisionalCode: '2017 CL60',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 2.99345,
    eccentricity: 0.1299511,
    inclination: 3.11626,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H297633, H297651',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H297992',
      },
    ],
  },
  {
    provisionalCode: '2017 CM60',
    note: '',
    absoluteMagnitude: 17.81,
    semimajorAxis: 3.22368,
    eccentricity: 0.0829089,
    inclination: 8.98236,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266238, H274974, H303000',
      },
    ],
  },
  {
    provisionalCode: '2017 CN60',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.82,
    semimajorAxis: 5.19439,
    eccentricity: 0.0739299,
    inclination: 25.72726,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266797, H275185, H302992',
      },
    ],
  },
  {
    provisionalCode: '2017 CO60',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 2.84378,
    eccentricity: 0.1078964,
    inclination: 11.81408,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266860, H275078, H302991',
      },
    ],
  },
  {
    provisionalCode: '2017 CP60',
    note: '',
    absoluteMagnitude: 19.57,
    semimajorAxis: 3.01231,
    eccentricity: 0.1017681,
    inclination: 9.8619,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H300146, H300468, H301535',
      },
    ],
  },
  {
    provisionalCode: '2019 UH178',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 3.04671,
    eccentricity: 0.1337736,
    inclination: 11.24845,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222907, H230041',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287352',
      },
    ],
  },
  {
    provisionalCode: '2019 UJ178',
    note: '',
    absoluteMagnitude: 20.01,
    semimajorAxis: 2.95621,
    eccentricity: 0.1006244,
    inclination: 11.7591,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222931',
      },
      {
        userName: 'れお',
        HNames: 'H272454, H273158',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H272454',
      },
    ],
  },
  {
    provisionalCode: '2019 UK178',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 3.08138,
    eccentricity: 0.1969347,
    inclination: 11.46106,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'すぎうら',
        HNames: 'H225943',
      },
      {
        userName: 'devastrotech',
        HNames: 'H226891',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238794, H250812',
      },
    ],
  },
  {
    provisionalCode: '2019 UL178',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 2.93122,
    eccentricity: 0.1404586,
    inclination: 11.52475,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H235987',
      },
      {
        userName: 'もしもス',
        HNames: 'H238653',
      },
    ],
  },
  {
    provisionalCode: '2019 UM178',
    note: '',
    absoluteMagnitude: 21.31,
    semimajorAxis: 2.63448,
    eccentricity: 0.1736332,
    inclination: 10.00329,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H227121',
      },
      {
        userName: 'もしもス',
        HNames: 'H249990',
      },
      {
        userName: 'れお',
        HNames: 'H267608, H271253',
      },
    ],
  },
  {
    provisionalCode: '2019 UN178',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.93932,
    eccentricity: 0.0388683,
    inclination: 12.03547,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214442',
      },
      {
        userName: 'もしもス',
        HNames: 'H214442',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238005, H269180, H269296',
      },
    ],
  },
  {
    provisionalCode: '2019 UO178',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.07744,
    eccentricity: 0.0357992,
    inclination: 12.09068,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230040',
      },
      {
        userName: 'れお',
        HNames: 'H273175',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287354',
      },
    ],
  },
  {
    provisionalCode: '2019 UP178',
    note: '',
    absoluteMagnitude: 20.17,
    semimajorAxis: 2.55584,
    eccentricity: 0.1122851,
    inclination: 12.35595,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H232370',
      },
      {
        userName: 'kn1cht',
        HNames: 'H242357',
      },
      {
        userName: 'れお',
        HNames: 'H263975',
      },
    ],
  },
  {
    provisionalCode: '2019 UQ178',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 3.13344,
    eccentricity: 0.2439922,
    inclination: 11.38975,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'bny',
        HNames: 'H227735',
      },
      {
        userName: 's.ozawa',
        HNames: 'H238310',
      },
      {
        userName: 'れお',
        HNames: 'H256460',
      },
    ],
  },
  {
    provisionalCode: '2019 UR178',
    note: '',
    absoluteMagnitude: 20.61,
    semimajorAxis: 2.55308,
    eccentricity: 0.1041487,
    inclination: 13.62516,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H238284',
      },
      {
        userName: 'れお',
        HNames: 'H257891',
      },
    ],
  },
  {
    provisionalCode: '2019 US178',
    note: '',
    absoluteMagnitude: 21.66,
    semimajorAxis: 2.60909,
    eccentricity: 0.2177601,
    inclination: 10.2291,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230904, H232789',
      },
      {
        userName: 's.ozawa',
        HNames: 'H282495, H289178',
      },
    ],
  },
  {
    provisionalCode: '2019 UT178',
    note: '',
    absoluteMagnitude: 19.16,
    semimajorAxis: 3.09198,
    eccentricity: 0.1211108,
    inclination: 13.93146,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H250813',
      },
      {
        userName: 'れお',
        HNames: 'H260652, H260710',
      },
    ],
  },
  {
    provisionalCode: '2019 UU178',
    note: '',
    absoluteMagnitude: 20.78,
    semimajorAxis: 3.05962,
    eccentricity: 0.0547698,
    inclination: 12.45714,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H238073',
      },
      {
        userName: 'れお',
        HNames: 'H260644',
      },
    ],
  },
  {
    provisionalCode: '2019 UV178',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 3.19198,
    eccentricity: 0.0796177,
    inclination: 12.21983,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H222930',
      },
      {
        userName: 'れお',
        HNames: 'H273198',
      },
    ],
  },
  {
    provisionalCode: '2019 UW178',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 3.07241,
    eccentricity: 0.1334073,
    inclination: 11.93026,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'starolite',
        HNames: 'H224169',
      },
      {
        userName: 'ngc',
        HNames: 'H228327',
      },
      {
        userName: 's.ozawa',
        HNames: 'H289016, H289782',
      },
    ],
  },
  {
    provisionalCode: '2019 UX178',
    note: '',
    absoluteMagnitude: 19.92,
    semimajorAxis: 3.17017,
    eccentricity: 0.2157244,
    inclination: 12.02767,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230875',
      },
      {
        userName: 'れお',
        HNames: 'H264695',
      },
      {
        userName: '赵经远',
        HNames: 'H288594',
      },
    ],
  },
  {
    provisionalCode: '2019 UY178',
    note: '',
    absoluteMagnitude: 20.46,
    semimajorAxis: 2.96192,
    eccentricity: 0.0486661,
    inclination: 11.39424,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H239000',
      },
      {
        userName: 'ctaka',
        HNames: 'H239000',
      },
      {
        userName: 'れお',
        HNames: 'H277591',
      },
    ],
  },
  {
    provisionalCode: '2019 UZ178',
    note: '',
    absoluteMagnitude: 20.84,
    semimajorAxis: 3.05959,
    eccentricity: 0.2105887,
    inclination: 15.56823,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H244229, H248363, H275332, H275452',
      },
      {
        userName: 'jim',
        HNames: 'H313945',
      },
    ],
  },
  {
    provisionalCode: '2019 UA179',
    note: '',
    absoluteMagnitude: 22.26,
    semimajorAxis: 2.63623,
    eccentricity: 0.2925981,
    inclination: 12.29893,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230720',
      },
      {
        userName: 's.ozawa',
        HNames: 'H248364, H289646',
      },
      {
        userName: 'れお',
        HNames: 'H289646',
      },
    ],
  },
  {
    provisionalCode: '2019 UB179',
    note: '',
    absoluteMagnitude: 20.82,
    semimajorAxis: 2.66773,
    eccentricity: 0.2465347,
    inclination: 12.66473,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H229548',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240969',
      },
      {
        userName: 's.ozawa',
        HNames: 'H296646',
      },
    ],
  },
  {
    provisionalCode: '2019 UC179',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 3.23449,
    eccentricity: 0.0878596,
    inclination: 8.76055,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222156, H222353',
      },
    ],
  },
  {
    provisionalCode: '2019 UD179',
    note: '',
    absoluteMagnitude: 20.99,
    semimajorAxis: 2.53201,
    eccentricity: 0.1907676,
    inclination: 13.45385,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217091',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223154',
      },
      {
        userName: 'kn1cht',
        HNames: 'H223154',
      },
    ],
  },
  {
    provisionalCode: '2019 UE179',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.85362,
    eccentricity: 0.1928925,
    inclination: 19.2862,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H225840',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H313526',
      },
    ],
  },
  {
    provisionalCode: '2019 UF179',
    note: '',
    absoluteMagnitude: 19.76,
    semimajorAxis: 2.66092,
    eccentricity: 0.1046649,
    inclination: 3.72778,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222849',
      },
      {
        userName: 'kn1cht',
        HNames: 'H223666',
      },
      {
        userName: 'さんしろう',
        HNames: 'H231056',
      },
    ],
  },
  {
    provisionalCode: '2019 UG179',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 2.62803,
    eccentricity: 0.0717692,
    inclination: 1.86206,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200087, H219594',
      },
      {
        userName: 'さんしろう',
        HNames: 'H220403',
      },
    ],
  },
  {
    provisionalCode: '2019 UH179',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 3.16034,
    eccentricity: 0.1380292,
    inclination: 7.672,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H207326',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213114, H229835',
      },
    ],
  },
  {
    provisionalCode: '2019 UJ179',
    note: '',
    absoluteMagnitude: 20.53,
    semimajorAxis: 2.60897,
    eccentricity: 0.1913056,
    inclination: 5.18644,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217337, H218224, H218520',
      },
      {
        userName: 'さんしろう',
        HNames: 'H218224',
      },
    ],
  },
  {
    provisionalCode: '2019 UK179',
    note: '',
    absoluteMagnitude: 20.98,
    semimajorAxis: 2.41326,
    eccentricity: 0.1357128,
    inclination: 4.09568,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217526',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H319505',
      },
    ],
  },
  {
    provisionalCode: '2019 UL179',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.55668,
    eccentricity: 0.0883652,
    inclination: 1.54129,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H204614, H229886',
      },
    ],
  },
  {
    provisionalCode: '2019 UM179',
    note: '',
    absoluteMagnitude: 20.38,
    semimajorAxis: 2.40847,
    eccentricity: 0.2030874,
    inclination: 1.18658,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H215087',
      },
      {
        userName: 'さんしろう',
        HNames: 'H219886',
      },
    ],
  },
  {
    provisionalCode: '2019 UN179',
    note: '',
    absoluteMagnitude: 21.0,
    semimajorAxis: 3.01651,
    eccentricity: 0.1321271,
    inclination: 11.30613,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218282, H219388',
      },
      {
        userName: 'zorome',
        HNames: 'H280045',
      },
    ],
  },
  {
    provisionalCode: '2019 UO179',
    note: '',
    absoluteMagnitude: 20.77,
    semimajorAxis: 2.62663,
    eccentricity: 0.0656983,
    inclination: 5.7207,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H222392',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H224397',
      },
    ],
  },
  {
    provisionalCode: '2019 UP179',
    note: '',
    absoluteMagnitude: 21.04,
    semimajorAxis: 2.64428,
    eccentricity: 0.2949993,
    inclination: 13.0573,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214717',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H228054',
      },
    ],
  },
  {
    provisionalCode: '2019 UQ179',
    note: '2017 BF205と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214084, H215658, H216032',
      },
    ],
  },
  {
    provisionalCode: '2019 UG180',
    note: '',
    absoluteMagnitude: 22.47,
    semimajorAxis: 2.28852,
    eccentricity: 0.1521075,
    inclination: 7.20097,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H200090',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H230629',
      },
      {
        userName: 'ms',
        HNames: 'H362032',
      },
    ],
  },
  {
    provisionalCode: '2019 UH180',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 3.09982,
    eccentricity: 0.1705645,
    inclination: 3.14948,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H219762, H229851',
      },
      {
        userName: 'ms',
        HNames: 'H362414, H362437',
      },
    ],
  },
  {
    provisionalCode: '2019 UM180',
    note: '',
    absoluteMagnitude: 20.58,
    semimajorAxis: 3.22583,
    eccentricity: 0.0872929,
    inclination: 8.88144,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304456',
      },
      {
        userName: 'ms',
        HNames: 'H362738, H362756',
      },
    ],
  },
  {
    provisionalCode: '2019 VH55',
    note: '',
    absoluteMagnitude: 18.41,
    semimajorAxis: 3.21748,
    eccentricity: 0.0881967,
    inclination: 11.94221,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ngc',
        HNames: 'H227762',
      },
      {
        userName: 'れお',
        HNames: 'H265324',
      },
      {
        userName: 'chara',
        HNames: 'H320731',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ55',
    note: '',
    absoluteMagnitude: 20.83,
    semimajorAxis: 2.60315,
    eccentricity: 0.1665728,
    inclination: 13.14278,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H228801',
      },
      {
        userName: 'れお',
        HNames: 'H255513, H265336',
      },
    ],
  },
  {
    provisionalCode: '2019 VK55',
    note: '',
    absoluteMagnitude: 21.34,
    semimajorAxis: 2.93227,
    eccentricity: 0.1377151,
    inclination: 11.40125,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H240393, H267223',
      },
      {
        userName: 'もしもス',
        HNames: 'H240393',
      },
    ],
  },
  {
    provisionalCode: '2019 VL55',
    note: '',
    absoluteMagnitude: 20.26,
    semimajorAxis: 2.57517,
    eccentricity: 0.1720182,
    inclination: 10.46976,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H249131',
      },
      {
        userName: 'れお',
        HNames: 'H267159, H267221, H268804, H271252',
      },
    ],
  },
  {
    provisionalCode: '2019 VM55',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 3.06806,
    eccentricity: 0.0876664,
    inclination: 12.45249,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214439',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H302762',
      },
    ],
  },
  {
    provisionalCode: '2019 VN55',
    note: '',
    absoluteMagnitude: 20.97,
    semimajorAxis: 3.07849,
    eccentricity: 0.2530565,
    inclination: 16.79927,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'すぎうら',
        HNames: 'H225942',
      },
      {
        userName: 'れお',
        HNames: 'H225942',
      },
      {
        userName: 's.ozawa',
        HNames: 'H234891, H234901',
      },
    ],
  },
  {
    provisionalCode: '2019 VO55',
    note: '',
    absoluteMagnitude: 17.71,
    semimajorAxis: 3.07071,
    eccentricity: 0.2203364,
    inclination: 17.1942,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H229545, H230699',
      },
      {
        userName: 'もしもス',
        HNames: 'H267110',
      },
      {
        userName: 'れお',
        HNames: 'H292687',
      },
    ],
  },
  {
    provisionalCode: '2019 VP55',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.16453,
    eccentricity: 0.2131127,
    inclination: 11.79348,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230877, H230878, H232080',
      },
      {
        userName: 's.ozawa',
        HNames: 'H273776',
      },
      {
        userName: 'れお',
        HNames: 'H292688',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ55',
    note: '',
    absoluteMagnitude: 21.08,
    semimajorAxis: 3.06363,
    eccentricity: 0.2336158,
    inclination: 10.34898,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H237794, H267179',
      },
      {
        userName: 'このしろ',
        HNames: 'H237794',
      },
    ],
  },
  {
    provisionalCode: '2019 VR55',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 3.02426,
    eccentricity: 0.1042828,
    inclination: 11.63167,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H269179, H282812',
      },
      {
        userName: 'aika',
        HNames: 'H305968',
      },
    ],
  },
  {
    provisionalCode: '2019 VS55',
    note: '',
    absoluteMagnitude: 22.08,
    semimajorAxis: 2.5762,
    eccentricity: 0.1781422,
    inclination: 9.96876,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H226893',
      },
      {
        userName: 'もしもス',
        HNames: 'H226893',
      },
      {
        userName: 's.ozawa',
        HNames: 'H249533',
      },
    ],
  },
  {
    provisionalCode: '2019 VT55',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 3.15618,
    eccentricity: 0.2172232,
    inclination: 11.58331,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H238666',
      },
      {
        userName: 's.ozawa',
        HNames: 'H249540',
      },
    ],
  },
  {
    provisionalCode: '2019 VU55',
    note: '',
    absoluteMagnitude: 20.4,
    semimajorAxis: 2.97234,
    eccentricity: 0.1055217,
    inclination: 11.59747,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H230905, H232085',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281687',
      },
    ],
  },
  {
    provisionalCode: '2019 VV55',
    note: '',
    absoluteMagnitude: 19.11,
    semimajorAxis: 3.01816,
    eccentricity: 0.0755627,
    inclination: 12.36488,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H232948',
      },
      {
        userName: 's.ozawa',
        HNames: 'H281229',
      },
    ],
  },
  {
    provisionalCode: '2019 VX55',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 3.17002,
    eccentricity: 0.1728336,
    inclination: 26.92538,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mitarushi',
        HNames: 'H232949',
      },
      {
        userName: 'れお',
        HNames: 'H264696',
      },
    ],
  },
  {
    provisionalCode: '2019 VY55',
    note: '',
    absoluteMagnitude: 19.81,
    semimajorAxis: 2.964,
    eccentricity: 0.1560756,
    inclination: 11.27014,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H240143',
      },
      {
        userName: 's.ozawa',
        HNames: 'H282802, H294285',
      },
      {
        userName: 'kn1cht',
        HNames: 'H283872',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ55',
    note: '',
    absoluteMagnitude: 21.18,
    semimajorAxis: 3.06829,
    eccentricity: 0.1128359,
    inclination: 11.46285,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H255535, H268295',
      },
    ],
  },
  {
    provisionalCode: '2019 VA56',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 2.47237,
    eccentricity: 0.1122233,
    inclination: 2.32927,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H223511, H225821',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223511',
      },
    ],
  },
  {
    provisionalCode: '2019 VC56',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.81095,
    eccentricity: 0.1238339,
    inclination: 2.59162,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214246, H217593',
      },
      {
        userName: 'えでぃ',
        HNames: 'H318551',
      },
    ],
  },
  {
    provisionalCode: '2019 VD56',
    note: '',
    absoluteMagnitude: 20.02,
    semimajorAxis: 2.52282,
    eccentricity: 0.0995128,
    inclination: 2.466,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214247, H217595',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222059',
      },
    ],
  },
  {
    provisionalCode: '2019 VE56',
    note: '',
    absoluteMagnitude: 20.9,
    semimajorAxis: 2.74844,
    eccentricity: 0.0923558,
    inclination: 3.5231,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217825',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222308, H305753',
      },
    ],
  },
  {
    provisionalCode: '2019 VF56',
    note: '',
    absoluteMagnitude: 19.49,
    semimajorAxis: 2.60698,
    eccentricity: 0.0706944,
    inclination: 14.61121,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H220561',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H220561',
      },
      {
        userName: 'apollo18',
        HNames: 'H221750',
      },
    ],
  },
  {
    provisionalCode: '2019 VG56',
    note: '',
    absoluteMagnitude: 20.94,
    semimajorAxis: 2.76184,
    eccentricity: 0.1180994,
    inclination: 5.99161,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222035, H224701',
      },
      {
        userName: 'さんしろう',
        HNames: 'H229551',
      },
    ],
  },
  {
    provisionalCode: '2019 VH56',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.15129,
    eccentricity: 0.1629172,
    inclination: 3.06266,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H214245',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214245',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223159',
      },
    ],
  },
  {
    provisionalCode: '2019 VJ56',
    note: '',
    absoluteMagnitude: 19.6,
    semimajorAxis: 2.78509,
    eccentricity: 0.1711803,
    inclination: 4.43056,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H209900',
      },
      {
        userName: 'devastrotech',
        HNames: 'H209901',
      },
      {
        userName: 'さんしろう',
        HNames: 'H214272, H234344',
      },
    ],
  },
  {
    provisionalCode: '2019 VK56',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 2.56297,
    eccentricity: 0.1343655,
    inclination: 14.34986,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H214437',
      },
      {
        userName: 'このしろ',
        HNames: 'H214437',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222824',
      },
    ],
  },
  {
    provisionalCode: '2019 VL56',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 3.36647,
    eccentricity: 0.3498074,
    inclination: 12.50502,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H215318, H215339',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229808',
      },
    ],
  },
  {
    provisionalCode: '2019 VM56',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 2.97199,
    eccentricity: 0.1240808,
    inclination: 8.48132,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H218274, H218305',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H304466',
      },
    ],
  },
  {
    provisionalCode: '2019 VN56',
    note: '',
    absoluteMagnitude: 18.93,
    semimajorAxis: 3.19569,
    eccentricity: 0.0364758,
    inclination: 18.05257,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H218272, H218301',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222863',
      },
    ],
  },
  {
    provisionalCode: '2019 VO56',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.70333,
    eccentricity: 0.1068857,
    inclination: 21.71777,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'belleequipe',
        HNames: 'H200036',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217936',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229860',
      },
    ],
  },
  {
    provisionalCode: '2019 VP56',
    note: '',
    absoluteMagnitude: 17.62,
    semimajorAxis: 2.78186,
    eccentricity: 0.0165572,
    inclination: 3.57293,
    oppositions: 9,
    uncertainty: 0,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H204681',
      },
      {
        userName: 'このしろ',
        HNames: 'H215162',
      },
    ],
  },
  {
    provisionalCode: '2019 VQ56',
    note: '',
    absoluteMagnitude: 20.72,
    semimajorAxis: 3.13387,
    eccentricity: 0.1834345,
    inclination: 7.21101,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H217982, H219301, H219385',
      },
    ],
  },
  {
    provisionalCode: '2019 VR56',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.61,
    semimajorAxis: 5.20858,
    eccentricity: 0.0182454,
    inclination: 3.00004,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H206042, H218467, H218729',
      },
    ],
  },
  {
    provisionalCode: '2019 VS56',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.19482,
    eccentricity: 0.2403104,
    inclination: 12.52199,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H215195',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H228438, H228592',
      },
    ],
  },
  {
    provisionalCode: '2019 VT56',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.02151,
    eccentricity: 0.0972976,
    inclination: 1.7618,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217882, H217913',
      },
      {
        userName: 'kn1cht',
        HNames: 'H219586',
      },
    ],
  },
  {
    provisionalCode: '2019 VU56',
    note: '',
    absoluteMagnitude: 18.31,
    semimajorAxis: 3.047,
    eccentricity: 0.0560641,
    inclination: 8.68529,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H217896, H219444, H219446',
      },
    ],
  },
  {
    provisionalCode: '2019 VV56',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 2.73865,
    eccentricity: 0.0260309,
    inclination: 3.62927,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H219075',
      },
      {
        userName: 'aika',
        HNames: 'H219478',
      },
    ],
  },
  {
    provisionalCode: '2019 VW56',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.63126,
    eccentricity: 0.0622846,
    inclination: 8.32899,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H222410, H229093, H229101',
      },
    ],
  },
  {
    provisionalCode: '2019 VX56',
    note: '',
    absoluteMagnitude: 20.5,
    semimajorAxis: 2.73002,
    eccentricity: 0.1933486,
    inclination: 8.30564,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213144, H213891, H213936',
      },
    ],
  },
  {
    provisionalCode: '2019 VY56',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.86982,
    eccentricity: 0.1584491,
    inclination: 15.63986,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H211307, H220237',
      },
    ],
  },
  {
    provisionalCode: '2019 VZ56',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 3.09451,
    eccentricity: 0.1799536,
    inclination: 1.86814,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H210088',
      },
      {
        userName: 'さんしろう',
        HNames: 'H217719',
      },
      {
        userName: 'やすさん',
        HNames: 'H352075',
      },
    ],
  },
  {
    provisionalCode: '2019 VC57',
    note: '',
    absoluteMagnitude: 21.84,
    semimajorAxis: 2.52689,
    eccentricity: 0.1551567,
    inclination: 8.2931,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H362739, H363084, H363151',
      },
    ],
  },
  {
    provisionalCode: '2019 WO39',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 3.09489,
    eccentricity: 0.1313517,
    inclination: 9.67608,
    oppositions: 2,
    uncertainty: 4,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H213932',
      },
      {
        userName: 'このしろ',
        HNames: 'H213932',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341307',
      },
      {
        userName: 'imai',
        HNames: 'H353030',
      },
      {
        userName: '井上 康',
        HNames: 'H354248',
      },
    ],
  },
  {
    provisionalCode: '2016 EV367',
    note: '',
    absoluteMagnitude: 19.38,
    semimajorAxis: 2.62102,
    eccentricity: 0.2665387,
    inclination: 4.93017,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329858',
      },
      {
        userName: 'zorome',
        HNames: 'H358362, H363068',
      },
    ],
  },
  {
    provisionalCode: '2016 EX367',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 2.65153,
    eccentricity: 0.1176929,
    inclination: 11.73917,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344510',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347559',
      },
      {
        userName: 'imai',
        HNames: 'H347775',
      },
      {
        userName: 'ioring',
        HNames: 'H357174',
      },
    ],
  },
  {
    provisionalCode: '2016 EY367',
    note: '2016 AF205と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H351751',
      },
      {
        userName: 'yakuba',
        HNames: 'H352915, H353353',
      },
      {
        userName: 'ioring',
        HNames: 'H357200',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ367',
    note: '2006 VA89と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H335628',
      },
      {
        userName: '井上 康',
        HNames: 'H353778, H354252',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H357154',
      },
    ],
  },
  {
    provisionalCode: '2016 EF368',
    note: '',
    absoluteMagnitude: 19.34,
    semimajorAxis: 2.5538,
    eccentricity: 0.0992918,
    inclination: 13.06384,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H351720',
      },
      {
        userName: 'nasiar99',
        HNames: 'H352980, H356749',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H356535',
      },
    ],
  },
  {
    provisionalCode: '2016 EG368',
    note: '2014 WS188, 2023 TE175と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H351741, H351749, H354762',
      },
      {
        userName: 'nasiar99',
        HNames: 'H352921',
      },
    ],
  },
  {
    provisionalCode: '2016 EH368',
    note: '2016 AG335と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H350941, H351541, H351705, H351757',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ368',
    note: '',
    absoluteMagnitude: 19.47,
    semimajorAxis: 2.65827,
    eccentricity: 0.1141837,
    inclination: 11.91161,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H350940, H351530',
      },
      {
        userName: 'yakuba',
        HNames: 'H353352',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H356413',
      },
    ],
  },
  {
    provisionalCode: '2016 EK368',
    note: '2016 AY353と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H352894',
      },
      {
        userName: 'maria w',
        HNames: 'H353482',
      },
      {
        userName: 'sasaki',
        HNames: 'H354976',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H356426',
      },
    ],
  },
  {
    provisionalCode: '2016 EL368',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 2.57841,
    eccentricity: 0.1118758,
    inclination: 10.91805,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H328372',
      },
      {
        userName: 's.ozawa',
        HNames: 'H354275, H355011, H355218',
      },
    ],
  },
  {
    provisionalCode: '2016 EM368',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.8,
    semimajorAxis: 53.41855,
    eccentricity: 0.5880701,
    inclination: 17.46838,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H352711',
      },
      {
        userName: 'maria w',
        HNames: 'H353954',
      },
    ],
  },
  {
    provisionalCode: '2016 EN368',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.61518,
    eccentricity: 0.1211814,
    inclination: 10.89559,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H328204',
      },
      {
        userName: 'sasaki',
        HNames: 'H354962',
      },
      {
        userName: 's.ozawa',
        HNames: 'H355049',
      },
      {
        userName: 'nasiar99',
        HNames: 'H357297',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ368',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 2.55714,
    eccentricity: 0.1103425,
    inclination: 8.49805,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H322475',
      },
      {
        userName: 'もしもス',
        HNames: 'H322475',
      },
      {
        userName: 'aika',
        HNames: 'H330192',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H357639',
      },
    ],
  },
  {
    provisionalCode: '2016 ER368',
    note: '',
    absoluteMagnitude: 21.24,
    semimajorAxis: 2.55617,
    eccentricity: 0.1658547,
    inclination: 6.66548,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H322478',
      },
      {
        userName: 'aika',
        HNames: 'H323473',
      },
      {
        userName: 'ほたる',
        HNames: 'H346409',
      },
    ],
  },
  {
    provisionalCode: '2016 ET368',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 3.13784,
    eccentricity: 0.2021754,
    inclination: 7.07115,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323314',
      },
      {
        userName: 'もしもス',
        HNames: 'H323314',
      },
      {
        userName: 'えでぃ',
        HNames: 'H326072',
      },
      {
        userName: 'ほたる',
        HNames: 'H344359',
      },
    ],
  },
  {
    provisionalCode: '2016 EW368',
    note: '',
    absoluteMagnitude: 19.43,
    semimajorAxis: 3.12625,
    eccentricity: 0.1847906,
    inclination: 8.8861,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H326085, H362081',
      },
      {
        userName: 'えでぃ',
        HNames: 'H326085',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H361864',
      },
    ],
  },
  {
    provisionalCode: '2016 EX368',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.80346,
    eccentricity: 0.0948807,
    inclination: 7.04875,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323445',
      },
      {
        userName: 'ほたる',
        HNames: 'H332555',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332555, H362100',
      },
    ],
  },
  {
    provisionalCode: '2016 EY368',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.14192,
    eccentricity: 0.0365496,
    inclination: 10.38147,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323290',
      },
      {
        userName: 'ほたる',
        HNames: 'H332556',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332556, H362179',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ368',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.19398,
    eccentricity: 0.2806622,
    inclination: 16.25705,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H330137',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332595, H363101',
      },
    ],
  },
  {
    provisionalCode: '2016 EB369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.6,
    semimajorAxis: 68.67346,
    eccentricity: 0.5277465,
    inclination: 15.80085,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H356422',
      },
    ],
  },
  {
    provisionalCode: '2016 EC369',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.35,
    semimajorAxis: 56.23711,
    eccentricity: 0.346589,
    inclination: 16.77567,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ひお',
        HNames: 'H328301, H328482',
      },
      {
        userName: 'zorome',
        HNames: 'H364457, H364458',
      },
    ],
  },
  {
    provisionalCode: '2016 ED369',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.68,
    semimajorAxis: 5.14135,
    eccentricity: 0.009435,
    inclination: 28.11762,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yossy000',
        HNames: 'H325609',
      },
    ],
  },
  {
    provisionalCode: '2016 EE369',
    note: '',
    absoluteMagnitude: 17.92,
    semimajorAxis: 3.10623,
    eccentricity: 0.0309039,
    inclination: 9.5307,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H329220',
      },
      {
        userName: 'ほたる',
        HNames: 'H346414',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357907',
      },
    ],
  },
  {
    provisionalCode: '2016 EF369',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 3.10716,
    eccentricity: 0.2068035,
    inclination: 6.39326,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331479, H332741',
      },
      {
        userName: 'arda',
        HNames: 'H334564',
      },
    ],
  },
  {
    provisionalCode: '2016 EG369',
    note: '',
    absoluteMagnitude: 18.58,
    semimajorAxis: 3.15359,
    eccentricity: 0.0445035,
    inclination: 7.16846,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H332529',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332529',
      },
      {
        userName: 'ほたる',
        HNames: 'H346977',
      },
    ],
  },
  {
    provisionalCode: '2016 EH369',
    note: '',
    absoluteMagnitude: 20.2,
    semimajorAxis: 2.25786,
    eccentricity: 0.0963723,
    inclination: 5.25479,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332740',
      },
      {
        userName: 'ioring',
        HNames: 'H343063',
      },
      {
        userName: 'ほたる',
        HNames: 'H346978',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ369',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.62616,
    eccentricity: 0.1858002,
    inclination: 13.00527,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H332758',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332758',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357914',
      },
    ],
  },
  {
    provisionalCode: '2016 EK369',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.10417,
    eccentricity: 0.0655783,
    inclination: 8.24863,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332849',
      },
      {
        userName: 'ほたる',
        HNames: 'H339194',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H357662',
      },
    ],
  },
  {
    provisionalCode: '2016 EL369',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 3.17387,
    eccentricity: 0.0940501,
    inclination: 8.01087,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331477',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H332852',
      },
      {
        userName: 'えでぃ',
        HNames: 'H332852',
      },
    ],
  },
  {
    provisionalCode: '2016 EM369',
    note: '',
    absoluteMagnitude: 18.43,
    semimajorAxis: 3.06707,
    eccentricity: 0.0175547,
    inclination: 9.1655,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H335395',
      },
      {
        userName: 'aika',
        HNames: 'H335395',
      },
      {
        userName: 'ほたる',
        HNames: 'H346930',
      },
    ],
  },
  {
    provisionalCode: '2016 EN369',
    note: '2019 UT73と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'alberto76',
        HNames: 'H273544',
      },
      {
        userName: 'arda',
        HNames: 'H333944',
      },
      {
        userName: 'ioring',
        HNames: 'H343092',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H345229',
      },
    ],
  },
  {
    provisionalCode: '2016 EO369',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 3.17472,
    eccentricity: 0.1036274,
    inclination: 22.53779,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H340899, H341061, H341712',
      },
    ],
  },
  {
    provisionalCode: '2016 EP369',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.76015,
    eccentricity: 0.2490304,
    inclination: 12.39932,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H328610',
      },
      {
        userName: 'yossy000',
        HNames: 'H343379',
      },
      {
        userName: 's.ozawa',
        HNames: 'H344003',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ369',
    note: '',
    absoluteMagnitude: 18.33,
    semimajorAxis: 3.08284,
    eccentricity: 0.039727,
    inclination: 13.62577,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H354637, H355120, H355187',
      },
    ],
  },
  {
    provisionalCode: '2016 ER369',
    note: '',
    absoluteMagnitude: 17.81,
    semimajorAxis: 3.24505,
    eccentricity: 0.0268402,
    inclination: 11.83739,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H354948',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H354948',
      },
      {
        userName: 'もしもス',
        HNames: 'H357844',
      },
    ],
  },
  {
    provisionalCode: '2016 ES369',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 2.65063,
    eccentricity: 0.1483717,
    inclination: 17.45713,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H341153, H346793',
      },
      {
        userName: 'zorome',
        HNames: 'H364595',
      },
    ],
  },
  {
    provisionalCode: '2016 ET369',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.39,
    semimajorAxis: 5.26188,
    eccentricity: 0.0332262,
    inclination: 21.80593,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'mutabilitie',
        HNames: 'H355933, H360480',
      },
      {
        userName: 'もしもス',
        HNames: 'H357927',
      },
    ],
  },
  {
    provisionalCode: '2016 EU369',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.71108,
    eccentricity: 0.1322178,
    inclination: 6.03116,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328966, H329638',
      },
      {
        userName: 'zorome',
        HNames: 'H358028',
      },
    ],
  },
  {
    provisionalCode: '2016 EV369',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.04027,
    eccentricity: 0.0571067,
    inclination: 8.82201,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329053, H329092',
      },
      {
        userName: 'zorome',
        HNames: 'H358153',
      },
    ],
  },
  {
    provisionalCode: '2016 EW369',
    note: '',
    absoluteMagnitude: 19.51,
    semimajorAxis: 2.97298,
    eccentricity: 0.0680047,
    inclination: 9.01586,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328946, H329097, H329120',
      },
    ],
  },
  {
    provisionalCode: '2016 EX369',
    note: '',
    absoluteMagnitude: 22.14,
    semimajorAxis: 2.61772,
    eccentricity: 0.2165449,
    inclination: 5.96288,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328866, H329324, H336894',
      },
    ],
  },
  {
    provisionalCode: '2016 EY369',
    note: '',
    absoluteMagnitude: 19.33,
    semimajorAxis: 3.33029,
    eccentricity: 0.1155419,
    inclination: 25.92636,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H336921',
      },
      {
        userName: 'zorome',
        HNames: 'H358249, H358254',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ369',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.65369,
    eccentricity: 0.1730924,
    inclination: 13.2284,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329170, H337654',
      },
      {
        userName: 'zorome',
        HNames: 'H358073',
      },
    ],
  },
  {
    provisionalCode: '2016 EA370',
    note: '',
    absoluteMagnitude: 20.51,
    semimajorAxis: 2.55198,
    eccentricity: 0.0960859,
    inclination: 14.2379,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329795, H337870',
      },
      {
        userName: 'zorome',
        HNames: 'H358051',
      },
    ],
  },
  {
    provisionalCode: '2016 EB370',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.94623,
    eccentricity: 0.0417071,
    inclination: 17.00988,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329300, H359522',
      },
      {
        userName: 'zorome',
        HNames: 'H358131',
      },
    ],
  },
  {
    provisionalCode: '2016 EC370',
    note: '',
    absoluteMagnitude: 20.28,
    semimajorAxis: 2.24002,
    eccentricity: 0.1597473,
    inclination: 6.86864,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H337131',
      },
      {
        userName: 'imai',
        HNames: 'H347832',
      },
      {
        userName: '井上 康',
        HNames: 'H353336',
      },
    ],
  },
  {
    provisionalCode: '2016 ED370',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 2.96851,
    eccentricity: 0.0386582,
    inclination: 9.60827,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'k96e',
        HNames: 'H343257',
      },
      {
        userName: 'nasiar99',
        HNames: 'H345188, H345314',
      },
    ],
  },
  {
    provisionalCode: '2016 EE370',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 2.99511,
    eccentricity: 0.1133705,
    inclination: 9.22739,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345241',
      },
      {
        userName: 'imai',
        HNames: 'H348852',
      },
      {
        userName: 'aika',
        HNames: 'H352498',
      },
    ],
  },
  {
    provisionalCode: '2016 EF370',
    note: '',
    absoluteMagnitude: 19.24,
    semimajorAxis: 3.12953,
    eccentricity: 0.2283929,
    inclination: 12.29786,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ななし',
        HNames: 'H340766',
      },
      {
        userName: 'nasiar99',
        HNames: 'H345377',
      },
      {
        userName: 'imai',
        HNames: 'H348298',
      },
    ],
  },
  {
    provisionalCode: '2016 EG370',
    note: '2016 CZ111と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'yuqing ren',
        HNames: 'H348586',
      },
      {
        userName: 'imai',
        HNames: 'H351921',
      },
      {
        userName: 'sasaki',
        HNames: 'H355687',
      },
    ],
  },
  {
    provisionalCode: '2016 EH370',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.52828,
    eccentricity: 0.2283487,
    inclination: 8.57701,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H322479, H332990',
      },
      {
        userName: 'もしもス',
        HNames: 'H322479',
      },
      {
        userName: 'aika',
        HNames: 'H330188',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ370',
    note: '2022 KC22, 2016 EX362と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H322614',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324264',
      },
      {
        userName: 'ほたる',
        HNames: 'H346406',
      },
    ],
  },
  {
    provisionalCode: '2016 EK370',
    note: '',
    absoluteMagnitude: 21.45,
    semimajorAxis: 2.30533,
    eccentricity: 0.0839288,
    inclination: 5.53158,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324404, H332991',
      },
    ],
  },
  {
    provisionalCode: '2016 EL370',
    note: '',
    absoluteMagnitude: 17.89,
    semimajorAxis: 3.45434,
    eccentricity: 0.1336858,
    inclination: 7.68128,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H325310, H332879, H332988',
      },
      {
        userName: 'yakuba',
        HNames: 'H325310',
      },
    ],
  },
  {
    provisionalCode: '2016 EM370',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.35953,
    eccentricity: 0.0312224,
    inclination: 5.36124,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H325314',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325314',
      },
      {
        userName: 'ほたる',
        HNames: 'H346410',
      },
    ],
  },
  {
    provisionalCode: '2016 EN370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 16.52,
    semimajorAxis: 72.39481,
    eccentricity: 0.9534668,
    inclination: 56.71706,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324183, H330957',
      },
      {
        userName: 'aika',
        HNames: 'H330957',
      },
    ],
  },
  {
    provisionalCode: '2016 EO370',
    note: '',
    absoluteMagnitude: 19.15,
    semimajorAxis: 3.19511,
    eccentricity: 0.1678175,
    inclination: 16.04472,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324182, H332528',
      },
      {
        userName: 'れお',
        HNames: 'H332528',
      },
    ],
  },
  {
    provisionalCode: '2016 EP370',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.13546,
    eccentricity: 0.0575151,
    inclination: 7.24232,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332957, H332989',
      },
      {
        userName: 'ioring',
        HNames: 'H343061',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ370',
    note: '',
    absoluteMagnitude: 19.72,
    semimajorAxis: 3.11645,
    eccentricity: 0.2823288,
    inclination: 11.85935,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325452',
      },
      {
        userName: 'もしもス',
        HNames: 'H325452',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347727',
      },
      {
        userName: 'ほたる',
        HNames: 'H348937',
      },
    ],
  },
  {
    provisionalCode: '2016 ER370',
    note: '2021 PW183と同定',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.39317,
    eccentricity: 0.1914083,
    inclination: 14.70086,
    oppositions: 2,
    uncertainty: 3,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328330',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H362178',
      },
    ],
  },
  {
    provisionalCode: '2016 ES370',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.7195,
    eccentricity: 0.0298312,
    inclination: 18.57462,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332149, H332958',
      },
      {
        userName: 'ioring',
        HNames: 'H343047',
      },
    ],
  },
  {
    provisionalCode: '2016 ET370',
    note: '',
    absoluteMagnitude: 20.19,
    semimajorAxis: 2.68906,
    eccentricity: 0.1973273,
    inclination: 13.66757,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332759, H346973',
      },
      {
        userName: 'ioring',
        HNames: 'H342279',
      },
      {
        userName: 'ほたる',
        HNames: 'H346973',
      },
    ],
  },
  {
    provisionalCode: '2016 EU370',
    note: '',
    absoluteMagnitude: 22.06,
    semimajorAxis: 2.21124,
    eccentricity: 0.0815855,
    inclination: 6.23888,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H322480',
      },
      {
        userName: 'もしもス',
        HNames: 'H322480',
      },
      {
        userName: 'えでぃ',
        HNames: 'H332150',
      },
    ],
  },
  {
    provisionalCode: '2016 EV370',
    note: '',
    absoluteMagnitude: 19.03,
    semimajorAxis: 3.18563,
    eccentricity: 0.0646025,
    inclination: 9.2839,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323505',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324527',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H345064',
      },
    ],
  },
  {
    provisionalCode: '2016 EW370',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.89582,
    eccentricity: 0.1016196,
    inclination: 9.57762,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H324726',
      },
      {
        userName: 'もしもス',
        HNames: 'H324726',
      },
      {
        userName: 'えでぃ',
        HNames: 'H327745',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H362209',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ370',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.25,
    semimajorAxis: 54.58113,
    eccentricity: 0.3598901,
    inclination: 13.82342,
    oppositions: 2,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273341',
      },
      {
        userName: 'もしもス',
        HNames: 'H324001',
      },
    ],
  },
  {
    provisionalCode: '2016 EA371',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 2.6398,
    eccentricity: 0.0930147,
    inclination: 11.45015,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H321587',
      },
      {
        userName: 'apollo18',
        HNames: 'H332772',
      },
      {
        userName: 'arda',
        HNames: 'H333937',
      },
    ],
  },
  {
    provisionalCode: '2016 EB371',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.74409,
    eccentricity: 0.1404319,
    inclination: 8.12495,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H322973',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332689',
      },
      {
        userName: 'ioring',
        HNames: 'H342280',
      },
    ],
  },
  {
    provisionalCode: '2016 EC371',
    note: '',
    absoluteMagnitude: 21.81,
    semimajorAxis: 2.65613,
    eccentricity: 0.2143905,
    inclination: 12.86142,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H322974',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324817',
      },
      {
        userName: 'ioring',
        HNames: 'H343018',
      },
    ],
  },
  {
    provisionalCode: '2016 ED371',
    note: '',
    absoluteMagnitude: 19.01,
    semimajorAxis: 3.00653,
    eccentricity: 0.0807927,
    inclination: 9.6768,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H323998',
      },
      {
        userName: 'aika',
        HNames: 'H326725',
      },
      {
        userName: 'ほたる',
        HNames: 'H344360',
      },
    ],
  },
  {
    provisionalCode: '2016 EE371',
    note: '',
    absoluteMagnitude: 22.53,
    semimajorAxis: 2.51372,
    eccentricity: 0.1935506,
    inclination: 5.28496,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324852',
      },
      {
        userName: 'arda',
        HNames: 'H333938',
      },
      {
        userName: 'ioring',
        HNames: 'H343105',
      },
    ],
  },
  {
    provisionalCode: '2016 EF371',
    note: '',
    absoluteMagnitude: 20.3,
    semimajorAxis: 2.56211,
    eccentricity: 0.1105594,
    inclination: 7.35941,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H330882',
      },
      {
        userName: 'ioring',
        HNames: 'H343075',
      },
      {
        userName: 'sasaki',
        HNames: 'H360837',
      },
    ],
  },
  {
    provisionalCode: '2016 EG371',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.34256,
    eccentricity: 0.1091171,
    inclination: 5.95657,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H322975',
      },
      {
        userName: 'れお',
        HNames: 'H331172',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331172',
      },
    ],
  },
  {
    provisionalCode: '2016 EH371',
    note: '',
    absoluteMagnitude: 19.76,
    semimajorAxis: 3.1566,
    eccentricity: 0.1497073,
    inclination: 12.29101,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332881',
      },
      {
        userName: 'aika',
        HNames: 'H335396',
      },
      {
        userName: 'ioring',
        HNames: 'H342278',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ371',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 3.21243,
    eccentricity: 0.0246902,
    inclination: 7.55449,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H332880, H332935, H332993',
      },
    ],
  },
  {
    provisionalCode: '2016 ER371',
    note: '',
    absoluteMagnitude: 18.67,
    semimajorAxis: 2.80742,
    eccentricity: 0.1733502,
    inclination: 8.79948,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'k96e',
        HNames: 'H328925',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H337269',
      },
      {
        userName: 'imai',
        HNames: 'H353037',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H357114',
      },
    ],
  },
  {
    provisionalCode: '2016 ES371',
    note: '2014 UH142, 2021 TV63と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H323118',
      },
      {
        userName: 'えでぃ',
        HNames: 'H326221, H328252',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H326221',
      },
    ],
  },
  {
    provisionalCode: '2016 ET371',
    note: '',
    absoluteMagnitude: 20.91,
    semimajorAxis: 2.74699,
    eccentricity: 0.1309006,
    inclination: 5.96702,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323288',
      },
      {
        userName: 'yakuba',
        HNames: 'H325311',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325311',
      },
    ],
  },
  {
    provisionalCode: '2016 EU371',
    note: 'Hilda',
    absoluteMagnitude: 18.29,
    semimajorAxis: 3.95265,
    eccentricity: 0.1942071,
    inclination: 8.16374,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H325273',
      },
      {
        userName: 'nasiar99',
        HNames: 'H345201',
      },
      {
        userName: '井上 康',
        HNames: 'H354246',
      },
    ],
  },
  {
    provisionalCode: '2016 EV371',
    note: '2018 PX116と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H325279',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344240',
      },
      {
        userName: 'imai',
        HNames: 'H353041',
      },
    ],
  },
  {
    provisionalCode: '2016 EW371',
    note: '',
    absoluteMagnitude: 20.33,
    semimajorAxis: 2.56066,
    eccentricity: 0.1823343,
    inclination: 9.07987,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331765',
      },
      {
        userName: '井上 康',
        HNames: 'H353825, H354543',
      },
    ],
  },
  {
    provisionalCode: '2016 EX371',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.11018,
    eccentricity: 0.2277658,
    inclination: 10.02216,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331767',
      },
      {
        userName: '井上 康',
        HNames: 'H353594, H353828',
      },
    ],
  },
  {
    provisionalCode: '2016 EY371',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 3.0129,
    eccentricity: 0.0705228,
    inclination: 7.83569,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H333593',
      },
      {
        userName: '井上 康',
        HNames: 'H353216, H353794',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ371',
    note: '',
    absoluteMagnitude: 18.48,
    semimajorAxis: 3.06419,
    eccentricity: 0.1383136,
    inclination: 12.88921,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H335470',
      },
      {
        userName: '井上 康',
        HNames: 'H353176, H353795',
      },
    ],
  },
  {
    provisionalCode: '2016 EA372',
    note: '',
    absoluteMagnitude: 18.82,
    semimajorAxis: 2.91067,
    eccentricity: 0.0425691,
    inclination: 9.28492,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H335471',
      },
      {
        userName: '井上 康',
        HNames: 'H353749, H353839',
      },
    ],
  },
  {
    provisionalCode: '2016 EB372',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 3.13686,
    eccentricity: 0.1909459,
    inclination: 9.30284,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H335811',
      },
      {
        userName: 'imai',
        HNames: 'H353039',
      },
      {
        userName: '井上 康',
        HNames: 'H354703',
      },
    ],
  },
  {
    provisionalCode: '2016 EC372',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 2.88715,
    eccentricity: 0.1066031,
    inclination: 9.93354,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H336326',
      },
      {
        userName: '井上 康',
        HNames: 'H353876, H354628',
      },
    ],
  },
  {
    provisionalCode: '2016 ED372',
    note: '',
    absoluteMagnitude: 18.85,
    semimajorAxis: 3.18813,
    eccentricity: 0.0730469,
    inclination: 9.05743,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H336337',
      },
      {
        userName: 'imai',
        HNames: 'H347836',
      },
      {
        userName: 'nasiar99',
        HNames: 'H352178',
      },
    ],
  },
  {
    provisionalCode: '2016 EE372',
    note: '2014 WG345と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H337154',
      },
      {
        userName: 'imai',
        HNames: 'H353028',
      },
      {
        userName: '井上 康',
        HNames: 'H353674',
      },
    ],
  },
  {
    provisionalCode: '2016 EF372',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.16999,
    eccentricity: 0.0593707,
    inclination: 8.14458,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H337157',
      },
      {
        userName: '井上 康',
        HNames: 'H353835, H354601',
      },
    ],
  },
  {
    provisionalCode: '2016 EG372',
    note: '',
    absoluteMagnitude: 18.61,
    semimajorAxis: 3.05671,
    eccentricity: 0.0317442,
    inclination: 11.21781,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H338088',
      },
      {
        userName: 'imai',
        HNames: 'H347776',
      },
      {
        userName: 'nasiar99',
        HNames: 'H352177',
      },
    ],
  },
  {
    provisionalCode: '2016 EH372',
    note: 'Mars Crosser',
    absoluteMagnitude: 19.85,
    semimajorAxis: 2.6477,
    eccentricity: 0.3963473,
    inclination: 9.21925,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H339354',
      },
      {
        userName: 'imai',
        HNames: 'H353017',
      },
      {
        userName: '井上 康',
        HNames: 'H353857',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ372',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 3.1254,
    eccentricity: 0.1561386,
    inclination: 8.62113,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H339370',
      },
      {
        userName: '井上 康',
        HNames: 'H353862, H354251',
      },
    ],
  },
  {
    provisionalCode: '2016 EK372',
    note: '',
    absoluteMagnitude: 18.14,
    semimajorAxis: 2.92952,
    eccentricity: 0.0701302,
    inclination: 7.87065,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H339371',
      },
      {
        userName: 'yuqing ren',
        HNames: 'H348617',
      },
      {
        userName: '井上 康',
        HNames: 'H353861',
      },
    ],
  },
  {
    provisionalCode: '2016 EL372',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.53854,
    eccentricity: 0.1519108,
    inclination: 7.09543,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H339373',
      },
      {
        userName: 'aika',
        HNames: 'H341835',
      },
      {
        userName: '井上 康',
        HNames: 'H353815',
      },
    ],
  },
  {
    provisionalCode: '2016 EM372',
    note: '',
    absoluteMagnitude: 18.99,
    semimajorAxis: 3.0975,
    eccentricity: 0.0146886,
    inclination: 8.97775,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H340261',
      },
      {
        userName: 'imai',
        HNames: 'H347831',
      },
      {
        userName: '井上 康',
        HNames: 'H353259',
      },
    ],
  },
  {
    provisionalCode: '2016 EN372',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 3.16328,
    eccentricity: 0.1480423,
    inclination: 11.13684,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '憲太星',
        HNames: 'H340999',
      },
      {
        userName: 'imai',
        HNames: 'H353009',
      },
      {
        userName: '井上 康',
        HNames: 'H353697',
      },
    ],
  },
  {
    provisionalCode: '2016 EO372',
    note: 'Hilda',
    absoluteMagnitude: 17.44,
    semimajorAxis: 3.93072,
    eccentricity: 0.073718,
    inclination: 9.61111,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341303',
      },
      {
        userName: 'nasiar99',
        HNames: 'H345200',
      },
      {
        userName: '井上 康',
        HNames: 'H353673',
      },
    ],
  },
  {
    provisionalCode: '2016 EP372',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.14409,
    eccentricity: 0.2117095,
    inclination: 10.19496,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H331352',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H341365',
      },
      {
        userName: 'imai',
        HNames: 'H353025',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ372',
    note: '',
    absoluteMagnitude: 18.19,
    semimajorAxis: 3.13816,
    eccentricity: 0.2138921,
    inclination: 10.26,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'aika',
        HNames: 'H341757',
      },
      {
        userName: 'imai',
        HNames: 'H347835',
      },
      {
        userName: '井上 康',
        HNames: 'H353254',
      },
    ],
  },
  {
    provisionalCode: '2016 ER372',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 2.96767,
    eccentricity: 0.0636564,
    inclination: 8.15625,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342561',
      },
      {
        userName: '井上 康',
        HNames: 'H353322, H353813',
      },
    ],
  },
  {
    provisionalCode: '2016 ES372',
    note: '',
    absoluteMagnitude: 18.81,
    semimajorAxis: 3.0544,
    eccentricity: 0.0840538,
    inclination: 8.33365,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342608',
      },
      {
        userName: 'imai',
        HNames: 'H353027',
      },
      {
        userName: '井上 康',
        HNames: 'H353812',
      },
    ],
  },
  {
    provisionalCode: '2016 ET372',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 3.16274,
    eccentricity: 0.0768106,
    inclination: 8.51395,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342649',
      },
      {
        userName: 'aika',
        HNames: 'H347388',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H355711',
      },
    ],
  },
  {
    provisionalCode: '2016 EU372',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 3.06184,
    eccentricity: 0.0670003,
    inclination: 8.70624,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342778',
      },
      {
        userName: 'aika',
        HNames: 'H351056',
      },
      {
        userName: 'imai',
        HNames: 'H353623',
      },
    ],
  },
  {
    provisionalCode: '2016 EV372',
    note: '',
    absoluteMagnitude: 19.67,
    semimajorAxis: 2.39887,
    eccentricity: 0.092693,
    inclination: 7.54439,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H343492',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344354',
      },
      {
        userName: 'imai',
        HNames: 'H348054',
      },
    ],
  },
  {
    provisionalCode: '2016 EW372',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.64426,
    eccentricity: 0.1140131,
    inclination: 7.67409,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344357',
      },
      {
        userName: 'imai',
        HNames: 'H348053',
      },
      {
        userName: '井上 康',
        HNames: 'H353804',
      },
    ],
  },
  {
    provisionalCode: '2016 EX372',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 2.93659,
    eccentricity: 0.042674,
    inclination: 8.80424,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345222',
      },
      {
        userName: 'aika',
        HNames: 'H352497',
      },
      {
        userName: 'imai',
        HNames: 'H353846',
      },
    ],
  },
  {
    provisionalCode: '2016 EY372',
    note: '',
    absoluteMagnitude: 20.67,
    semimajorAxis: 2.29542,
    eccentricity: 0.0949525,
    inclination: 7.18699,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345266',
      },
      {
        userName: 'arda',
        HNames: 'H354169',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H355674',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ372',
    note: '',
    absoluteMagnitude: 18.94,
    semimajorAxis: 2.84629,
    eccentricity: 0.0528382,
    inclination: 8.54079,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345268',
      },
      {
        userName: 'arda',
        HNames: 'H354160',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H355764',
      },
    ],
  },
  {
    provisionalCode: '2016 EA373',
    note: 'Hilda',
    absoluteMagnitude: 17.68,
    semimajorAxis: 3.97633,
    eccentricity: 0.1239746,
    inclination: 9.4084,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345270',
      },
      {
        userName: 'imai',
        HNames: 'H353034',
      },
      {
        userName: 'arda',
        HNames: 'H354159',
      },
    ],
  },
  {
    provisionalCode: '2016 EB373',
    note: '',
    absoluteMagnitude: 18.3,
    semimajorAxis: 2.97623,
    eccentricity: 0.1000618,
    inclination: 9.17002,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345297',
      },
      {
        userName: 'imai',
        HNames: 'H351910',
      },
      {
        userName: 'sasaki',
        HNames: 'H355584',
      },
    ],
  },
  {
    provisionalCode: '2016 EC373',
    note: '',
    absoluteMagnitude: 18.25,
    semimajorAxis: 3.00394,
    eccentricity: 0.0980598,
    inclination: 9.18257,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345746',
      },
      {
        userName: 'aika',
        HNames: 'H352496',
      },
      {
        userName: 'imai',
        HNames: 'H353845',
      },
    ],
  },
  {
    provisionalCode: '2016 ED373',
    note: '',
    absoluteMagnitude: 20.93,
    semimajorAxis: 2.1949,
    eccentricity: 0.0965329,
    inclination: 6.64812,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H345790',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347543',
      },
      {
        userName: 'imai',
        HNames: 'H347844',
      },
    ],
  },
  {
    provisionalCode: '2016 EE373',
    note: '',
    absoluteMagnitude: 19.1,
    semimajorAxis: 3.16686,
    eccentricity: 0.0436412,
    inclination: 8.4564,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H345798, H347664',
      },
      {
        userName: 'yuqing ren',
        HNames: 'H348300',
      },
    ],
  },
  {
    provisionalCode: '2016 EF373',
    note: '2014 WB584と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H346528',
      },
      {
        userName: 'yuqing ren',
        HNames: 'H348257',
      },
      {
        userName: 'imai',
        HNames: 'H353664',
      },
    ],
  },
  {
    provisionalCode: '2016 EG373',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.76748,
    eccentricity: 0.1859601,
    inclination: 9.15116,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332044',
      },
      {
        userName: '井上 康',
        HNames: 'H352089',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H355783',
      },
    ],
  },
  {
    provisionalCode: '2016 EH373',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.584,
    eccentricity: 0.176813,
    inclination: 14.17499,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'imai',
        HNames: 'H353621',
      },
      {
        userName: 'yakuba',
        HNames: 'H353772',
      },
      {
        userName: 'arda',
        HNames: 'H354236',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ373',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.09,
    semimajorAxis: 5.16841,
    eccentricity: 0.1156835,
    inclination: 21.38196,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H345707',
      },
      {
        userName: 's.ozawa',
        HNames: 'H352140, H352776',
      },
    ],
  },
  {
    provisionalCode: '2016 EK373',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.8,
    semimajorAxis: 5.13286,
    eccentricity: 0.029295,
    inclination: 20.35587,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H346714',
      },
      {
        userName: 's.ozawa',
        HNames: 'H350593, H350907',
      },
    ],
  },
  {
    provisionalCode: '2016 EL373',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.16431,
    eccentricity: 0.0551966,
    inclination: 8.21881,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H324069',
      },
      {
        userName: 'もしもス',
        HNames: 'H324069',
      },
      {
        userName: 'えでぃ',
        HNames: 'H328094',
      },
    ],
  },
  {
    provisionalCode: '2016 EM373',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 2.61637,
    eccentricity: 0.0532506,
    inclination: 8.54281,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324448',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324448',
      },
      {
        userName: 'ほたる',
        HNames: 'H339164',
      },
    ],
  },
  {
    provisionalCode: '2016 EN373',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.6033,
    eccentricity: 0.2673084,
    inclination: 12.52968,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324454, H362802',
      },
      {
        userName: 'れお',
        HNames: 'H324454',
      },
    ],
  },
  {
    provisionalCode: '2016 EO373',
    note: '',
    absoluteMagnitude: 20.96,
    semimajorAxis: 2.71783,
    eccentricity: 0.1419292,
    inclination: 7.36585,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H324486',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324486',
      },
      {
        userName: 'ほたる',
        HNames: 'H339163',
      },
    ],
  },
  {
    provisionalCode: '2016 EP373',
    note: '',
    absoluteMagnitude: 20.11,
    semimajorAxis: 2.75792,
    eccentricity: 0.1350238,
    inclination: 8.88193,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H324487',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324487',
      },
      {
        userName: 'ほたる',
        HNames: 'H339170',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ373',
    note: '',
    absoluteMagnitude: 19.92,
    semimajorAxis: 3.08255,
    eccentricity: 0.0782207,
    inclination: 6.59474,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'れお',
        HNames: 'H324489',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324489',
      },
      {
        userName: 'ほたる',
        HNames: 'H339162',
      },
    ],
  },
  {
    provisionalCode: '2016 ER373',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 2.71265,
    eccentricity: 0.2391437,
    inclination: 11.47162,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324846',
      },
      {
        userName: 'arda',
        HNames: 'H351067',
      },
      {
        userName: 'aika',
        HNames: 'H359978',
      },
    ],
  },
  {
    provisionalCode: '2016 ES373',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.33169,
    eccentricity: 0.2348611,
    inclination: 10.98172,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H325954',
      },
      {
        userName: 'aika',
        HNames: 'H359977',
      },
    ],
  },
  {
    provisionalCode: '2016 ET373',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.20732,
    eccentricity: 0.0794672,
    inclination: 8.79548,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H327799',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331011',
      },
      {
        userName: 'arda',
        HNames: 'H351070',
      },
    ],
  },
  {
    provisionalCode: '2016 EU373',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.96582,
    eccentricity: 0.0740776,
    inclination: 9.09471,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328039, H328156',
      },
    ],
  },
  {
    provisionalCode: '2016 EV373',
    note: '',
    absoluteMagnitude: 21.97,
    semimajorAxis: 2.70529,
    eccentricity: 0.1833702,
    inclination: 7.70456,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328060, H328525',
      },
    ],
  },
  {
    provisionalCode: '2016 EW373',
    note: '',
    absoluteMagnitude: 21.53,
    semimajorAxis: 3.11184,
    eccentricity: 0.0331715,
    inclination: 6.96757,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H328333',
      },
      {
        userName: 'えでぃ',
        HNames: 'H328333',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H362759',
      },
    ],
  },
  {
    provisionalCode: '2016 EX373',
    note: '',
    absoluteMagnitude: 18.9,
    semimajorAxis: 2.80871,
    eccentricity: 0.144405,
    inclination: 8.6063,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H323444',
      },
      {
        userName: 'ほたる',
        HNames: 'H331313',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H331313',
      },
    ],
  },
  {
    provisionalCode: '2016 EY373',
    note: '',
    absoluteMagnitude: 19.25,
    semimajorAxis: 3.44176,
    eccentricity: 0.0018001,
    inclination: 12.06305,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H332594, H362741',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H352532',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ373',
    note: '',
    absoluteMagnitude: 18.85,
    semimajorAxis: 2.86881,
    eccentricity: 0.0471516,
    inclination: 7.1832,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H332779',
      },
      {
        userName: 'ほたる',
        HNames: 'H344401',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H352514',
      },
    ],
  },
  {
    provisionalCode: '2016 EB374',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.34945,
    eccentricity: 0.103158,
    inclination: 4.96633,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329083, H329121',
      },
      {
        userName: 'zorome',
        HNames: 'H358068',
      },
    ],
  },
  {
    provisionalCode: '2016 EC374',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 2.78621,
    eccentricity: 0.0972694,
    inclination: 3.48805,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329409, H329532, H329874',
      },
    ],
  },
  {
    provisionalCode: '2016 ED374',
    note: '',
    absoluteMagnitude: 20.79,
    semimajorAxis: 2.75858,
    eccentricity: 0.1983092,
    inclination: 7.42938,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H330398',
      },
      {
        userName: 'yakuba',
        HNames: 'H332783',
      },
      {
        userName: 'zorome',
        HNames: 'H358049',
      },
    ],
  },
  {
    provisionalCode: '2016 EE374',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.70618,
    eccentricity: 0.0604277,
    inclination: 5.29757,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328869, H337802',
      },
      {
        userName: 'zorome',
        HNames: 'H358052',
      },
    ],
  },
  {
    provisionalCode: '2016 EF374',
    note: '',
    absoluteMagnitude: 20.79,
    semimajorAxis: 2.71318,
    eccentricity: 0.1122661,
    inclination: 3.9963,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H356009',
      },
      {
        userName: 'zorome',
        HNames: 'H358146',
      },
      {
        userName: 'れお',
        HNames: 'H358146',
      },
    ],
  },
  {
    provisionalCode: '2016 EG374',
    note: '',
    absoluteMagnitude: 20.21,
    semimajorAxis: 2.32602,
    eccentricity: 0.1589521,
    inclination: 4.17254,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329169, H356010',
      },
      {
        userName: 'zorome',
        HNames: 'H358145',
      },
    ],
  },
  {
    provisionalCode: '2016 EH374',
    note: '',
    absoluteMagnitude: 18.78,
    semimajorAxis: 3.04992,
    eccentricity: 0.1719731,
    inclination: 5.0002,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329108, H356011',
      },
      {
        userName: 'zorome',
        HNames: 'H358061',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ374',
    note: '',
    absoluteMagnitude: 21.11,
    semimajorAxis: 2.30643,
    eccentricity: 0.1716003,
    inclination: 2.06029,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329556',
      },
      {
        userName: 'zorome',
        HNames: 'H358278, H360839',
      },
    ],
  },
  {
    provisionalCode: '2016 EK374',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.38992,
    eccentricity: 0.1026037,
    inclination: 1.72695,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H358037, H358179, H360840',
      },
    ],
  },
  {
    provisionalCode: '2016 EL374',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 2.69293,
    eccentricity: 0.0783858,
    inclination: 11.44926,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329363, H330961',
      },
      {
        userName: 'zorome',
        HNames: 'H360880',
      },
    ],
  },
  {
    provisionalCode: '2016 EM374',
    note: '2002 TH381と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H352611',
      },
      {
        userName: 's.ozawa',
        HNames: 'H355171',
      },
    ],
  },
  {
    provisionalCode: '2016 EN374',
    note: '',
    absoluteMagnitude: 20.6,
    semimajorAxis: 3.12666,
    eccentricity: 0.2043705,
    inclination: 14.37641,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323506',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324529',
      },
      {
        userName: 'もしもス',
        HNames: 'H324729',
      },
    ],
  },
  {
    provisionalCode: '2016 EO374',
    note: '',
    absoluteMagnitude: 21.16,
    semimajorAxis: 2.38314,
    eccentricity: 0.1634792,
    inclination: 6.0022,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H323534',
      },
      {
        userName: 'arda',
        HNames: 'H323534',
      },
      {
        userName: 'aika',
        HNames: 'H330189',
      },
    ],
  },
  {
    provisionalCode: '2016 EP374',
    note: '',
    absoluteMagnitude: 18.16,
    semimajorAxis: 3.43348,
    eccentricity: 0.1287981,
    inclination: 8.70854,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H323996',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344342',
      },
      {
        userName: 'ほたる',
        HNames: 'H344342',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H352516',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ374',
    note: '',
    absoluteMagnitude: 21.7,
    semimajorAxis: 2.65041,
    eccentricity: 0.2636891,
    inclination: 10.31676,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324025',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324025',
      },
      {
        userName: 'aika',
        HNames: 'H330899',
      },
    ],
  },
  {
    provisionalCode: '2016 ER374',
    note: '',
    absoluteMagnitude: 18.88,
    semimajorAxis: 3.36004,
    eccentricity: 0.0880565,
    inclination: 9.79299,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H324316, H328008',
      },
      {
        userName: 'ほたる',
        HNames: 'H344324',
      },
    ],
  },
  {
    provisionalCode: '2016 ES374',
    note: '',
    absoluteMagnitude: 19.35,
    semimajorAxis: 3.15521,
    eccentricity: 0.0743381,
    inclination: 11.00874,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'れお',
        HNames: 'H324490',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324490',
      },
      {
        userName: 'wanko',
        HNames: 'H357335',
      },
    ],
  },
  {
    provisionalCode: '2016 ET374',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 3.16777,
    eccentricity: 0.0567697,
    inclination: 7.51547,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324610',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324610',
      },
      {
        userName: 'wanko',
        HNames: 'H357336',
      },
    ],
  },
  {
    provisionalCode: '2016 EU374',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 3.16383,
    eccentricity: 0.1287831,
    inclination: 13.83864,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H325388',
      },
      {
        userName: 'もしもス',
        HNames: 'H325388',
      },
      {
        userName: 'aika',
        HNames: 'H330193',
      },
    ],
  },
  {
    provisionalCode: '2016 EV374',
    note: '',
    absoluteMagnitude: 19.86,
    semimajorAxis: 3.01069,
    eccentricity: 0.1402651,
    inclination: 7.43725,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328058',
      },
      {
        userName: 'aika',
        HNames: 'H330900',
      },
    ],
  },
  {
    provisionalCode: '2016 EW374',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.02159,
    eccentricity: 0.1022353,
    inclination: 8.43414,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'えでぃ',
        HNames: 'H328253',
      },
      {
        userName: 'yakuba',
        HNames: 'H332756',
      },
      {
        userName: 'ほたる',
        HNames: 'H344500',
      },
    ],
  },
  {
    provisionalCode: '2016 EX374',
    note: '',
    absoluteMagnitude: 19.77,
    semimajorAxis: 3.24095,
    eccentricity: 0.0827039,
    inclination: 18.86312,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H330194, H332781',
      },
      {
        userName: 'ほたる',
        HNames: 'H332781',
      },
    ],
  },
  {
    provisionalCode: '2016 EY374',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 3.09723,
    eccentricity: 0.1192739,
    inclination: 7.9454,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H348920',
      },
      {
        userName: 'nasiar99',
        HNames: 'H349516',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H363100',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ374',
    note: '',
    absoluteMagnitude: 21.02,
    semimajorAxis: 2.65352,
    eccentricity: 0.2381145,
    inclination: 7.14148,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H321615',
      },
      {
        userName: 'aika',
        HNames: 'H330169',
      },
      {
        userName: 'ioring',
        HNames: 'H343151',
      },
    ],
  },
  {
    provisionalCode: '2016 EA375',
    note: '',
    absoluteMagnitude: 19.73,
    semimajorAxis: 2.87628,
    eccentricity: 0.0497959,
    inclination: 12.68819,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323480',
      },
      {
        userName: 'えでぃ',
        HNames: 'H325955',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H325955',
      },
    ],
  },
  {
    provisionalCode: '2016 EB375',
    note: '',
    absoluteMagnitude: 22.07,
    semimajorAxis: 2.7089,
    eccentricity: 0.2002341,
    inclination: 10.99652,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348931, H363128',
      },
      {
        userName: 'ほたる',
        HNames: 'H348931',
      },
    ],
  },
  {
    provisionalCode: '2016 GA371',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.73,
    semimajorAxis: 42.01391,
    eccentricity: 0.1070532,
    inclination: 15.22524,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H335296',
      },
      {
        userName: 'zorome',
        HNames: 'H345607, H345614, H345623',
      },
      {
        userName: '赵经远',
        HNames: 'H350003',
      },
    ],
  },
  {
    provisionalCode: '2016 GE371',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.3806,
    eccentricity: 0.1228789,
    inclination: 2.10382,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H331589',
      },
      {
        userName: '井上 康',
        HNames: 'H342200',
      },
      {
        userName: 'apollo18',
        HNames: 'H342398',
      },
      {
        userName: 'sasaki',
        HNames: 'H349114',
      },
    ],
  },
  {
    provisionalCode: '2016 GG371',
    note: '',
    absoluteMagnitude: 18.22,
    semimajorAxis: 3.17638,
    eccentricity: 0.0654955,
    inclination: 9.18528,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H326551, H331888, H350761',
      },
    ],
  },
  {
    provisionalCode: '2016 GQ371',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.41,
    semimajorAxis: 5.17096,
    eccentricity: 0.0227202,
    inclination: 8.89021,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H322527, H339846',
      },
      {
        userName: 'apollo18',
        HNames: 'H354462',
      },
    ],
  },
  {
    provisionalCode: '2016 GU371',
    note: 'Mars Crosser',
    absoluteMagnitude: 19.15,
    semimajorAxis: 2.26262,
    eccentricity: 0.2924925,
    inclination: 9.73538,
    oppositions: 6,
    uncertainty: 0,
    detail: [
      {
        userName: 'れお',
        HNames: 'H323234',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H328135',
      },
      {
        userName: 'ほたる',
        HNames: 'H328604',
      },
    ],
  },
  {
    provisionalCode: '2016 GX371',
    note: '',
    absoluteMagnitude: 19.08,
    semimajorAxis: 2.24238,
    eccentricity: 0.1856841,
    inclination: 8.17895,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H342636, H344197, H351875',
      },
    ],
  },
  {
    provisionalCode: '2016 GZ371',
    note: '',
    absoluteMagnitude: 20.92,
    semimajorAxis: 2.26458,
    eccentricity: 0.1570201,
    inclination: 5.41151,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H323967, H325687, H350755',
      },
    ],
  },
  {
    provisionalCode: '2016 GA372',
    note: '2023 TL68と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H324073, H334455, H350802',
      },
    ],
  },
  {
    provisionalCode: '2016 GB372',
    note: '',
    absoluteMagnitude: 20.71,
    semimajorAxis: 2.25332,
    eccentricity: 0.0975356,
    inclination: 6.37779,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323588',
      },
      {
        userName: 'zorome',
        HNames: 'H324077, H350834',
      },
    ],
  },
  {
    provisionalCode: '2016 GC372',
    note: '',
    absoluteMagnitude: 19.61,
    semimajorAxis: 2.22194,
    eccentricity: 0.114365,
    inclination: 7.14499,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H324785, H331307, H350801',
      },
    ],
  },
  {
    provisionalCode: '2016 GD372',
    note: '',
    absoluteMagnitude: 18.96,
    semimajorAxis: 3.07322,
    eccentricity: 0.2337122,
    inclination: 7.58397,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H324904, H334458, H350800',
      },
    ],
  },
  {
    provisionalCode: '2016 GE372',
    note: '',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.61873,
    eccentricity: 0.1381438,
    inclination: 13.36735,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H324905',
      },
      {
        userName: 'arda',
        HNames: 'H335064',
      },
      {
        userName: 'apollo18',
        HNames: 'H349629',
      },
    ],
  },
  {
    provisionalCode: '2016 GF372',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.8542,
    eccentricity: 0.0323576,
    inclination: 8.76083,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323586',
      },
      {
        userName: 'zorome',
        HNames: 'H325671',
      },
      {
        userName: '憲太星',
        HNames: 'H350008',
      },
    ],
  },
  {
    provisionalCode: '2016 GG372',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 2.37741,
    eccentricity: 0.0799749,
    inclination: 7.42267,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H326589, H334525, H352438',
      },
    ],
  },
  {
    provisionalCode: '2016 GH372',
    note: '2017 SA73と同定',
    absoluteMagnitude: 18.84,
    semimajorAxis: 2.56483,
    eccentricity: 0.1150857,
    inclination: 14.05803,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H326590, H334255, H351593',
      },
    ],
  },
  {
    provisionalCode: '2016 GJ372',
    note: '',
    absoluteMagnitude: 18.86,
    semimajorAxis: 3.15969,
    eccentricity: 0.1460439,
    inclination: 18.72932,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H326846, H335094',
      },
      {
        userName: '赵经远',
        HNames: 'H335666',
      },
    ],
  },
  {
    provisionalCode: '2016 GK372',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 3.07994,
    eccentricity: 0.2781848,
    inclination: 17.86709,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H327257, H334524, H350833',
      },
    ],
  },
  {
    provisionalCode: '2016 GL372',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 2.96381,
    eccentricity: 0.0693712,
    inclination: 10.33336,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H328148, H344268, H351855',
      },
    ],
  },
  {
    provisionalCode: '2016 GM372',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 2.76737,
    eccentricity: 0.1521959,
    inclination: 13.72996,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H328292, H351838',
      },
      {
        userName: 'yakuba',
        HNames: 'H341590',
      },
    ],
  },
  {
    provisionalCode: '2016 GN372',
    note: '',
    absoluteMagnitude: 19.9,
    semimajorAxis: 2.56198,
    eccentricity: 0.0752705,
    inclination: 17.48881,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H330918, H335452',
      },
      {
        userName: 'joshua',
        HNames: 'H343706',
      },
    ],
  },
  {
    provisionalCode: '2016 GO372',
    note: '',
    absoluteMagnitude: 18.23,
    semimajorAxis: 3.07464,
    eccentricity: 0.0855938,
    inclination: 10.27053,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H330919, H350799',
      },
      {
        userName: 'arda',
        HNames: 'H335050',
      },
    ],
  },
  {
    provisionalCode: '2016 GP372',
    note: '',
    absoluteMagnitude: 19.04,
    semimajorAxis: 2.92806,
    eccentricity: 0.1568286,
    inclination: 7.45733,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H331489, H352437',
      },
      {
        userName: 'arda',
        HNames: 'H335093',
      },
    ],
  },
  {
    provisionalCode: '2016 GQ372',
    note: '',
    absoluteMagnitude: 18.83,
    semimajorAxis: 2.88822,
    eccentricity: 0.1508167,
    inclination: 15.45672,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H331619, H351192',
      },
      {
        userName: 'arda',
        HNames: 'H335221',
      },
    ],
  },
  {
    provisionalCode: '2016 GR372',
    note: '',
    absoluteMagnitude: 20.6,
    semimajorAxis: 2.21689,
    eccentricity: 0.0302749,
    inclination: 7.01684,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H339416',
      },
      {
        userName: 'zorome',
        HNames: 'H345716, H351605',
      },
    ],
  },
  {
    provisionalCode: '2016 GS372',
    note: '',
    absoluteMagnitude: 20.95,
    semimajorAxis: 2.41585,
    eccentricity: 0.1896801,
    inclination: 6.61743,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H342693, H344194, H351852',
      },
    ],
  },
  {
    provisionalCode: '2016 GT372',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 3.17114,
    eccentricity: 0.0809058,
    inclination: 10.50816,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H343764, H344270, H351851',
      },
    ],
  },
  {
    provisionalCode: '2016 GU372',
    note: '',
    absoluteMagnitude: 18.62,
    semimajorAxis: 3.14543,
    eccentricity: 0.1874025,
    inclination: 16.58075,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H335273',
      },
      {
        userName: 'zorome',
        HNames: 'H344481, H351836',
      },
    ],
  },
  {
    provisionalCode: '2016 GV372',
    note: '',
    absoluteMagnitude: 17.37,
    semimajorAxis: 3.18278,
    eccentricity: 0.0893478,
    inclination: 10.71672,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H323020',
      },
      {
        userName: 'zorome',
        HNames: 'H349250',
      },
      {
        userName: 'ioring',
        HNames: 'H356199',
      },
    ],
  },
  {
    provisionalCode: '2016 GW372',
    note: '',
    absoluteMagnitude: 18.24,
    semimajorAxis: 3.18263,
    eccentricity: 0.0794775,
    inclination: 9.82736,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H334530, H350783, H353389',
      },
    ],
  },
  {
    provisionalCode: '2016 LO106',
    note: '',
    absoluteMagnitude: 18.53,
    semimajorAxis: 3.00438,
    eccentricity: 0.017127,
    inclination: 10.47746,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H267600, H335531',
      },
      {
        userName: 'theoria',
        HNames: 'H323264',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H323264',
      },
      {
        userName: 'れお',
        HNames: 'H335531',
      },
    ],
  },
  {
    provisionalCode: '2016 LP106',
    note: '',
    absoluteMagnitude: 18.45,
    semimajorAxis: 2.63755,
    eccentricity: 0.130986,
    inclination: 8.37045,
    oppositions: 4,
    uncertainty: 0,
    detail: [
      {
        userName: '井上 康',
        HNames: 'H292358',
      },
      {
        userName: 'ゆうまり',
        HNames: 'H321724',
      },
      {
        userName: 'みしま',
        HNames: 'H323144',
      },
      {
        userName: 'junnjunn',
        HNames: 'H344846, H345365',
      },
      {
        userName: 'apollo18',
        HNames: 'H349842',
      },
    ],
  },
  {
    provisionalCode: '2016 LQ106',
    note: '',
    absoluteMagnitude: 18.76,
    semimajorAxis: 3.17224,
    eccentricity: 0.0953455,
    inclination: 9.77899,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'joshua',
        HNames: 'H261619',
      },
      {
        userName: 'maria w',
        HNames: 'H321947',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H335746',
      },
      {
        userName: 'sasaki',
        HNames: 'H358265',
      },
    ],
  },
  {
    provisionalCode: '2016 LR106',
    note: '',
    absoluteMagnitude: 18.64,
    semimajorAxis: 3.1342,
    eccentricity: 0.0346253,
    inclination: 9.27425,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'yuqing ren',
        HNames: 'H243198',
      },
      {
        userName: 'maria w',
        HNames: 'H338383, H340477',
      },
      {
        userName: 'ioring',
        HNames: 'H363782',
      },
    ],
  },
  {
    provisionalCode: '2016 LS106',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.97194,
    eccentricity: 0.0782976,
    inclination: 9.59711,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H267631',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H324309',
      },
      {
        userName: 'maria w',
        HNames: 'H350496',
      },
      {
        userName: 'れお',
        HNames: 'H351012',
      },
    ],
  },
  {
    provisionalCode: '2016 LT106',
    note: '',
    absoluteMagnitude: 18.49,
    semimajorAxis: 3.07941,
    eccentricity: 0.0826148,
    inclination: 10.80305,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'hirata',
        HNames: 'H233010',
      },
      {
        userName: 'joshua',
        HNames: 'H338785',
      },
      {
        userName: 'yakuba',
        HNames: 'H357510',
      },
      {
        userName: 'ioring',
        HNames: 'H363810',
      },
    ],
  },
  {
    provisionalCode: '2016 LU106',
    note: '',
    absoluteMagnitude: 19.96,
    semimajorAxis: 2.60793,
    eccentricity: 0.0179091,
    inclination: 11.9269,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H267283',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H335269',
      },
      {
        userName: 'maria w',
        HNames: 'H338278',
      },
      {
        userName: 'れお',
        HNames: 'H345012',
      },
    ],
  },
  {
    provisionalCode: '2016 LV106',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 2.63717,
    eccentricity: 0.0919118,
    inclination: 5.30885,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H274753',
      },
      {
        userName: 'junnjunn',
        HNames: 'H341460, H346177, H347374',
      },
    ],
  },
  {
    provisionalCode: '2016 LW106',
    note: 'Hilda',
    absoluteMagnitude: 17.34,
    semimajorAxis: 3.95716,
    eccentricity: 0.2518418,
    inclination: 15.96479,
    oppositions: 5,
    uncertainty: 1,
    detail: [
      {
        userName: 'ニックネーム',
        HNames: 'H227030',
      },
      {
        userName: 'kyoukawakawaiidesu',
        HNames: 'H330199',
      },
      {
        userName: 'ioring',
        HNames: 'H352865',
      },
    ],
  },
  {
    provisionalCode: '2016 NF193',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.80313,
    eccentricity: 0.0778636,
    inclination: 4.82775,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253566, H341986',
      },
      {
        userName: 'ms',
        HNames: 'H322669, H363828, H363840, H363861',
      },
    ],
  },
  {
    provisionalCode: '2016 NG193',
    note: '',
    absoluteMagnitude: 17.75,
    semimajorAxis: 3.13945,
    eccentricity: 0.0583864,
    inclination: 10.40488,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'wanko',
        HNames: 'H347723',
      },
      {
        userName: 'apollo18',
        HNames: 'H353094',
      },
      {
        userName: 'ioring',
        HNames: 'H363711',
      },
    ],
  },
  {
    provisionalCode: '2016 NH193',
    note: '',
    absoluteMagnitude: 20.22,
    semimajorAxis: 2.16673,
    eccentricity: 0.2127246,
    inclination: 5.86126,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H338352',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338352',
      },
      {
        userName: 'れお',
        HNames: 'H338352',
      },
      {
        userName: 'joshua',
        HNames: 'H346242',
      },
      {
        userName: 'apollo18',
        HNames: 'H351839',
      },
    ],
  },
  {
    provisionalCode: '2016 NJ193',
    note: '',
    absoluteMagnitude: 18.5,
    semimajorAxis: 3.03903,
    eccentricity: 0.1373364,
    inclination: 2.41981,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H322329, H323543',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H324515',
      },
      {
        userName: 'ioring',
        HNames: 'H344734',
      },
    ],
  },
  {
    provisionalCode: '2016 NK193',
    note: '2014 EV349と同定',
    absoluteMagnitude: 18.45,
    semimajorAxis: 3.08427,
    eccentricity: 0.0598519,
    inclination: 7.89033,
    oppositions: 2,
    uncertainty: 5,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H321719',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329341, H340547',
      },
      {
        userName: 'ioring',
        HNames: 'H351019',
      },
    ],
  },
  {
    provisionalCode: '2016 NL193',
    note: '',
    absoluteMagnitude: 19.88,
    semimajorAxis: 3.17037,
    eccentricity: 0.1366506,
    inclination: 5.22187,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H332718, H337919',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342456',
      },
      {
        userName: 'ioring',
        HNames: 'H350313',
      },
    ],
  },
  {
    provisionalCode: '2016 NM193',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.23059,
    eccentricity: 0.0801538,
    inclination: 2.76224,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H341997',
      },
      {
        userName: 'ms',
        HNames: 'H342592',
      },
      {
        userName: 'nasiar99',
        HNames: 'H348159',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348358',
      },
    ],
  },
  {
    provisionalCode: '2016 NN193',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.69555,
    eccentricity: 0.1998006,
    inclination: 4.34291,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '憲太星',
        HNames: 'H322769',
      },
      {
        userName: 'joshua',
        HNames: 'H346112',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H352329',
      },
      {
        userName: 'やすさん',
        HNames: 'H354820',
      },
    ],
  },
  {
    provisionalCode: '2016 NO193',
    note: '',
    absoluteMagnitude: 17.93,
    semimajorAxis: 3.16654,
    eccentricity: 0.0591682,
    inclination: 11.58966,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H340677, H350342',
      },
      {
        userName: 'joshua',
        HNames: 'H346080',
      },
      {
        userName: 'ioring',
        HNames: 'H351709',
      },
    ],
  },
  {
    provisionalCode: '2016 NP193',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 2.79829,
    eccentricity: 0.0760052,
    inclination: 7.15275,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H274750',
      },
      {
        userName: 'jiashuo zhang',
        HNames: 'H324310',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H335448',
      },
      {
        userName: 'junnjunn',
        HNames: 'H351016',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H351016',
      },
    ],
  },
  {
    provisionalCode: '2016 NQ193',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 2.76544,
    eccentricity: 0.0719695,
    inclination: 4.66102,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'みしま',
        HNames: 'H324737',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332306, H336410',
      },
      {
        userName: 'ioring',
        HNames: 'H344731',
      },
    ],
  },
  {
    provisionalCode: '2016 NR193',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 3.00673,
    eccentricity: 0.0725525,
    inclination: 16.44545,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ひお',
        HNames: 'H327424',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344047',
      },
      {
        userName: 'ioring',
        HNames: 'H344909, H350764',
      },
    ],
  },
  {
    provisionalCode: '2016 NS193',
    note: '',
    absoluteMagnitude: 18.44,
    semimajorAxis: 3.00352,
    eccentricity: 0.0663888,
    inclination: 2.18984,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H324557',
      },
      {
        userName: 'ioring',
        HNames: 'H351152',
      },
      {
        userName: 'bny',
        HNames: 'H352491',
      },
      {
        userName: 'やすさん',
        HNames: 'H352544',
      },
    ],
  },
  {
    provisionalCode: '2016 NT193',
    note: '',
    absoluteMagnitude: 19.53,
    semimajorAxis: 2.38666,
    eccentricity: 0.1469456,
    inclination: 3.76549,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H346293, H347191, H350820',
      },
      {
        userName: 'ぴ',
        HNames: 'H346550',
      },
    ],
  },
  {
    provisionalCode: '2016 NU193',
    note: '2014 HE272と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285167, H345495',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285736, H344120',
      },
      {
        userName: 'wanko',
        HNames: 'H331253',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332412',
      },
    ],
  },
  {
    provisionalCode: '2016 NV193',
    note: '',
    absoluteMagnitude: 19.98,
    semimajorAxis: 2.5377,
    eccentricity: 0.1473982,
    inclination: 13.16762,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'jiashuo zhang',
        HNames: 'H335394',
      },
      {
        userName: 'nasiar99',
        HNames: 'H348843',
      },
      {
        userName: 'maria w',
        HNames: 'H350387',
      },
      {
        userName: 'ioring',
        HNames: 'H363906',
      },
    ],
  },
  {
    provisionalCode: '2016 NW193',
    note: '2019 AY34と同定',
    absoluteMagnitude: 17.37,
    semimajorAxis: 3.19322,
    eccentricity: 0.0632674,
    inclination: 7.6349,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'gorokun56',
        HNames: 'H329787',
      },
      {
        userName: 'ひお',
        HNames: 'H332042, H333314',
      },
      {
        userName: 'ioring',
        HNames: 'H355360',
      },
    ],
  },
  {
    provisionalCode: '2016 NX193',
    note: '',
    absoluteMagnitude: 21.36,
    semimajorAxis: 2.57243,
    eccentricity: 0.226508,
    inclination: 10.57625,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H338354',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H338354',
      },
      {
        userName: 'れお',
        HNames: 'H338354',
      },
      {
        userName: 'nasiar99',
        HNames: 'H346804',
      },
    ],
  },
  {
    provisionalCode: '2016 NY193',
    note: '',
    absoluteMagnitude: 20.55,
    semimajorAxis: 2.27557,
    eccentricity: 0.1545437,
    inclination: 7.81287,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'やすさん',
        HNames: 'H349297',
      },
      {
        userName: 'junnjunn',
        HNames: 'H350963',
      },
      {
        userName: 'apollo18',
        HNames: 'H354548',
      },
      {
        userName: 'ioring',
        HNames: 'H360031',
      },
    ],
  },
  {
    provisionalCode: '2016 NZ193',
    note: '',
    absoluteMagnitude: 18.55,
    semimajorAxis: 2.72359,
    eccentricity: 0.0497042,
    inclination: 5.36235,
    oppositions: 3,
    uncertainty: 3,
    detail: [
      {
        userName: 'ms',
        HNames: 'H322673, H364680',
      },
      {
        userName: 'ほたる',
        HNames: 'H323374',
      },
      {
        userName: 'maria w',
        HNames: 'H325411',
      },
    ],
  },
  {
    provisionalCode: '2016 NA194',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.05612,
    eccentricity: 0.0862609,
    inclination: 10.21285,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H322663, H325838, H364866',
      },
    ],
  },
  {
    provisionalCode: '2016 NF194',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 3.01681,
    eccentricity: 0.0239061,
    inclination: 10.15504,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ms',
        HNames: 'H322664, H325837, H364905, H364925',
      },
    ],
  },
  {
    provisionalCode: '2016 OG16',
    note: '',
    absoluteMagnitude: 19.39,
    semimajorAxis: 2.89902,
    eccentricity: 0.1079359,
    inclination: 6.03024,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'オジンガーz',
        HNames: 'H321817',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H348690',
      },
      {
        userName: 'nasiar99',
        HNames: 'H348690',
      },
      {
        userName: 'maria w',
        HNames: 'H350490',
      },
      {
        userName: 'apollo18',
        HNames: 'H353098',
      },
    ],
  },
  {
    provisionalCode: '2016 OJ16',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.66553,
    eccentricity: 0.165012,
    inclination: 4.16942,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'theoria',
        HNames: 'H323503',
      },
      {
        userName: 'gorokun56',
        HNames: 'H329789',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H350961',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H357441',
      },
    ],
  },
  {
    provisionalCode: '2016 PF300',
    note: '',
    absoluteMagnitude: 19.93,
    semimajorAxis: 2.56071,
    eccentricity: 0.0820429,
    inclination: 3.55824,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232548',
      },
      {
        userName: 'ms',
        HNames: 'H322668, H363477',
      },
      {
        userName: 'maria w',
        HNames: 'H327032',
      },
      {
        userName: 'ioring',
        HNames: 'H346480',
      },
    ],
  },
  {
    provisionalCode: '2016 PH300',
    note: '',
    absoluteMagnitude: 20.48,
    semimajorAxis: 2.47527,
    eccentricity: 0.1992568,
    inclination: 1.93297,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H252742',
      },
      {
        userName: 'ms',
        HNames: 'H322670, H364337',
      },
      {
        userName: 'maria w',
        HNames: 'H327036',
      },
    ],
  },
  {
    provisionalCode: '2016 PK300',
    note: '',
    absoluteMagnitude: 19.17,
    semimajorAxis: 2.60018,
    eccentricity: 0.1168166,
    inclination: 2.54652,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H231276',
      },
      {
        userName: 'ほたる',
        HNames: 'H321726',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H340649',
      },
      {
        userName: 'ioring',
        HNames: 'H345076, H346518',
      },
    ],
  },
  {
    provisionalCode: '2016 PL300',
    note: '2004 VX104と同定',
    absoluteMagnitude: null,
    semimajorAxis: null,
    eccentricity: null,
    inclination: null,
    oppositions: null,
    uncertainty: null,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H231237',
      },
      {
        userName: 'ほたる',
        HNames: 'H321967',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H336525',
      },
      {
        userName: 'ioring',
        HNames: 'H346515',
      },
      {
        userName: 'やすさん',
        HNames: 'H352364',
      },
    ],
  },
  {
    provisionalCode: '2016 PM300',
    note: '2014 EB156と同定',
    absoluteMagnitude: 18.26,
    semimajorAxis: 2.85801,
    eccentricity: 0.0528032,
    inclination: 3.99163,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247131',
      },
      {
        userName: 'maria w',
        HNames: 'H343577',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344049, H344052',
      },
      {
        userName: 'ioring',
        HNames: 'H349369',
      },
    ],
  },
  {
    provisionalCode: '2016 PN300',
    note: '',
    absoluteMagnitude: 19.71,
    semimajorAxis: 2.54605,
    eccentricity: 0.0759451,
    inclination: 8.87438,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255890',
      },
      {
        userName: 'ほたる',
        HNames: 'H321597',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329922, H348439',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347594',
      },
    ],
  },
  {
    provisionalCode: '2016 PO300',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 2.97984,
    eccentricity: 0.0892301,
    inclination: 9.78686,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'さんしろう',
        HNames: 'H212872',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H229789',
      },
      {
        userName: 'maria w',
        HNames: 'H343592',
      },
      {
        userName: 'ioring',
        HNames: 'H347655, H350836',
      },
    ],
  },
  {
    provisionalCode: '2016 PP300',
    note: '',
    absoluteMagnitude: 20.85,
    semimajorAxis: 2.6852,
    eccentricity: 0.1805018,
    inclination: 2.01714,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251290, H251427',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329752',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H348930',
      },
      {
        userName: 'やすさん',
        HNames: 'H352361',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ300',
    note: '',
    absoluteMagnitude: 19.52,
    semimajorAxis: 3.05237,
    eccentricity: 0.1026544,
    inclination: 2.15256,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232015, H251572',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H343481',
      },
      {
        userName: 'ioring',
        HNames: 'H345258, H348602',
      },
      {
        userName: 'ms',
        HNames: 'H364338',
      },
    ],
  },
  {
    provisionalCode: '2016 PR300',
    note: '',
    absoluteMagnitude: 18.56,
    semimajorAxis: 3.00337,
    eccentricity: 0.1222557,
    inclination: 8.83508,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'yuqing ren',
        HNames: 'H243251',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278098',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H335985',
      },
      {
        userName: 'ioring',
        HNames: 'H349357, H351052',
      },
    ],
  },
  {
    provisionalCode: '2016 PS300',
    note: '',
    absoluteMagnitude: 19.55,
    semimajorAxis: 2.69099,
    eccentricity: 0.1886169,
    inclination: 7.95413,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243459',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286935',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H337871',
      },
      {
        userName: 'ioring',
        HNames: 'H344802, H344932',
      },
    ],
  },
  {
    provisionalCode: '2016 PT300',
    note: '',
    absoluteMagnitude: 20.01,
    semimajorAxis: 2.30726,
    eccentricity: 0.1731998,
    inclination: 4.0138,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H319438',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H341960',
      },
      {
        userName: 'ioring',
        HNames: 'H343696, H345233',
      },
      {
        userName: 'ms',
        HNames: 'H363478',
      },
    ],
  },
  {
    provisionalCode: '2016 PV300',
    note: '',
    absoluteMagnitude: 19.0,
    semimajorAxis: 3.18974,
    eccentricity: 0.2547531,
    inclination: 7.88453,
    oppositions: 5,
    uncertainty: 0,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278119',
      },
      {
        userName: 'ms',
        HNames: 'H322671, H364387, H364399, H364420',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H341985',
      },
    ],
  },
  {
    provisionalCode: '2016 PW300',
    note: '',
    absoluteMagnitude: 19.87,
    semimajorAxis: 2.43517,
    eccentricity: 0.1235954,
    inclination: 5.68826,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'コージ',
        HNames: 'H249230',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H253497',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277541',
      },
      {
        userName: 'wanko',
        HNames: 'H349588',
      },
    ],
  },
  {
    provisionalCode: '2016 PX300',
    note: '',
    absoluteMagnitude: 20.43,
    semimajorAxis: 2.24479,
    eccentricity: 0.0954055,
    inclination: 2.43666,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251957, H257853',
      },
      {
        userName: 'ほたる',
        HNames: 'H321860',
      },
      {
        userName: 'やすさん',
        HNames: 'H352567',
      },
    ],
  },
  {
    provisionalCode: '2016 PY300',
    note: '',
    absoluteMagnitude: 19.05,
    semimajorAxis: 3.14694,
    eccentricity: 0.071019,
    inclination: 5.65896,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233773',
      },
      {
        userName: 'maria w',
        HNames: 'H323790, H325360',
      },
      {
        userName: 'ioring',
        HNames: 'H345063',
      },
    ],
  },
  {
    provisionalCode: '2016 PZ300',
    note: '',
    absoluteMagnitude: 20.75,
    semimajorAxis: 2.27126,
    eccentricity: 0.147781,
    inclination: 5.07672,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232525',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332747, H336403',
      },
      {
        userName: 'ms',
        HNames: 'H363493',
      },
    ],
  },
  {
    provisionalCode: '2016 PA301',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 2.64282,
    eccentricity: 0.077505,
    inclination: 4.23868,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H217203',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H236780',
      },
      {
        userName: 'wanko',
        HNames: 'H330558',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H342713',
      },
      {
        userName: 'joshua',
        HNames: 'H346003',
      },
    ],
  },
  {
    provisionalCode: '2016 PB301',
    note: '',
    absoluteMagnitude: 19.64,
    semimajorAxis: 2.7464,
    eccentricity: 0.0390137,
    inclination: 3.01148,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247254',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H253240',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H334028, H343446',
      },
    ],
  },
  {
    provisionalCode: '2016 PC301',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 3.01408,
    eccentricity: 0.0631871,
    inclination: 7.96659,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255968, H257055',
      },
      {
        userName: 'wanko',
        HNames: 'H327282',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H343536',
      },
    ],
  },
  {
    provisionalCode: '2016 PD301',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.23552,
    eccentricity: 0.0722324,
    inclination: 3.00666,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H243436',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278183',
      },
      {
        userName: 'ぴーすぽーと',
        HNames: 'H327356',
      },
      {
        userName: 'maria w',
        HNames: 'H343684',
      },
    ],
  },
  {
    provisionalCode: '2016 PE301',
    note: '',
    absoluteMagnitude: 19.47,
    semimajorAxis: 2.73914,
    eccentricity: 0.0769244,
    inclination: 3.95234,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H209826',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329339, H338072',
      },
      {
        userName: 'joshua',
        HNames: 'H346001',
      },
      {
        userName: 'やすさん',
        HNames: 'H352552',
      },
    ],
  },
  {
    provisionalCode: '2016 PF301',
    note: '',
    absoluteMagnitude: 19.23,
    semimajorAxis: 2.65262,
    eccentricity: 0.0577559,
    inclination: 2.4938,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238200',
      },
      {
        userName: 'ほたる',
        HNames: 'H321728',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329749',
      },
      {
        userName: 'ioring',
        HNames: 'H351394',
      },
    ],
  },
  {
    provisionalCode: '2016 PG301',
    note: '',
    absoluteMagnitude: 21.18,
    semimajorAxis: 2.64752,
    eccentricity: 0.2134084,
    inclination: 3.19197,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232732, H251864',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348321',
      },
      {
        userName: 'ms',
        HNames: 'H349344',
      },
    ],
  },
  {
    provisionalCode: '2016 PH301',
    note: '',
    absoluteMagnitude: 20.24,
    semimajorAxis: 2.73234,
    eccentricity: 0.0783866,
    inclination: 2.16498,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232598',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H233209',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H342585',
      },
      {
        userName: 'ioring',
        HNames: 'H348717',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ301',
    note: '',
    absoluteMagnitude: 20.84,
    semimajorAxis: 2.6674,
    eccentricity: 0.1927108,
    inclination: 12.34756,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233633',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H247453',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H343191',
      },
      {
        userName: 'ioring',
        HNames: 'H348592',
      },
    ],
  },
  {
    provisionalCode: '2016 PK301',
    note: '',
    absoluteMagnitude: 19.98,
    semimajorAxis: 2.42994,
    eccentricity: 0.0967075,
    inclination: 4.65137,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H237853',
      },
      {
        userName: 'ko',
        HNames: 'H322468',
      },
      {
        userName: 'ひお',
        HNames: 'H330183',
      },
      {
        userName: 'ioring',
        HNames: 'H351037',
      },
    ],
  },
  {
    provisionalCode: '2016 PL301',
    note: '',
    absoluteMagnitude: 20.32,
    semimajorAxis: 2.60892,
    eccentricity: 0.2099866,
    inclination: 6.2748,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H236950',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251935',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H336481',
      },
      {
        userName: 'ioring',
        HNames: 'H349381',
      },
    ],
  },
  {
    provisionalCode: '2016 PM301',
    note: '',
    absoluteMagnitude: 18.6,
    semimajorAxis: 3.04485,
    eccentricity: 0.0940247,
    inclination: 14.13302,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H217199',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238277',
      },
      {
        userName: 'kuni',
        HNames: 'H332544',
      },
      {
        userName: 'ioring',
        HNames: 'H350175',
      },
    ],
  },
  {
    provisionalCode: '2016 PN301',
    note: '',
    absoluteMagnitude: 18.91,
    semimajorAxis: 2.72786,
    eccentricity: 0.148665,
    inclination: 7.95067,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H237851',
      },
      {
        userName: 'maria w',
        HNames: 'H343698',
      },
      {
        userName: 'ioring',
        HNames: 'H348039, H349388',
      },
    ],
  },
  {
    provisionalCode: '2016 PO301',
    note: '',
    absoluteMagnitude: 21.06,
    semimajorAxis: 2.65872,
    eccentricity: 0.1494005,
    inclination: 2.44607,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H239467',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251298, H348133',
      },
      {
        userName: 'maria w',
        HNames: 'H327076',
      },
    ],
  },
  {
    provisionalCode: '2016 PP301',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.97502,
    eccentricity: 0.1114811,
    inclination: 11.41923,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H233052, H348132',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247189',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H344762',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ301',
    note: '',
    absoluteMagnitude: 18.77,
    semimajorAxis: 3.21468,
    eccentricity: 0.0966609,
    inclination: 8.98132,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H212607',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251537',
      },
      {
        userName: 'maria w',
        HNames: 'H336089',
      },
      {
        userName: 'ioring',
        HNames: 'H348408',
      },
      {
        userName: 'ms',
        HNames: 'H364389',
      },
    ],
  },
  {
    provisionalCode: '2016 PR301',
    note: '',
    absoluteMagnitude: 20.26,
    semimajorAxis: 2.60291,
    eccentricity: 0.1843043,
    inclination: 2.38439,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238820',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H340113',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H342434',
      },
      {
        userName: 'ioring',
        HNames: 'H348534',
      },
    ],
  },
  {
    provisionalCode: '2016 PS301',
    note: '',
    absoluteMagnitude: 19.07,
    semimajorAxis: 3.1459,
    eccentricity: 0.0873845,
    inclination: 4.32508,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247186',
      },
      {
        userName: 'maria w',
        HNames: 'H335290',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H342863',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H348130',
      },
    ],
  },
  {
    provisionalCode: '2016 PT301',
    note: '',
    absoluteMagnitude: 19.41,
    semimajorAxis: 2.70652,
    eccentricity: 0.1044291,
    inclination: 7.51411,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'たくみ丸',
        HNames: 'H214061',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H251428',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H343524',
      },
      {
        userName: 'ioring',
        HNames: 'H348540',
      },
    ],
  },
  {
    provisionalCode: '2016 PU301',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 2.70962,
    eccentricity: 0.1594218,
    inclination: 5.51888,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260186',
      },
      {
        userName: 'ks34',
        HNames: 'H344246',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H350423',
      },
      {
        userName: 'れお',
        HNames: 'H353149',
      },
    ],
  },
  {
    provisionalCode: '2016 PV301',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 2.40881,
    eccentricity: 0.2161218,
    inclination: 2.99752,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278019, H278216',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H340602',
      },
      {
        userName: 'ioring',
        HNames: 'H350767',
      },
    ],
  },
  {
    provisionalCode: '2016 PW301',
    note: '',
    absoluteMagnitude: 18.59,
    semimajorAxis: 2.97917,
    eccentricity: 0.1053326,
    inclination: 9.09081,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'このしろ',
        HNames: 'H214355',
      },
      {
        userName: 'ほたる',
        HNames: 'H321598, H321703',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347595',
      },
    ],
  },
  {
    provisionalCode: '2016 PX301',
    note: '',
    absoluteMagnitude: 19.14,
    semimajorAxis: 2.94403,
    eccentricity: 0.107254,
    inclination: 3.2458,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238299',
      },
      {
        userName: 'ほたる',
        HNames: 'H321789',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H338074',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347596',
      },
    ],
  },
  {
    provisionalCode: '2016 PY301',
    note: '',
    absoluteMagnitude: 20.05,
    semimajorAxis: 2.77441,
    eccentricity: 0.1926087,
    inclination: 2.33614,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'devastrotech',
        HNames: 'H211765',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H233793',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H340556',
      },
      {
        userName: 'nasiar99',
        HNames: 'H347600',
      },
    ],
  },
  {
    provisionalCode: '2016 PZ301',
    note: '',
    absoluteMagnitude: 19.46,
    semimajorAxis: 2.96742,
    eccentricity: 0.0962788,
    inclination: 8.60076,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238252',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332717, H337988',
      },
      {
        userName: 'nasiar99',
        HNames: 'H346823',
      },
    ],
  },
  {
    provisionalCode: '2016 PA302',
    note: '',
    absoluteMagnitude: 20.41,
    semimajorAxis: 2.81401,
    eccentricity: 0.106181,
    inclination: 3.58826,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H252416, H348360',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H330404, H342009',
      },
    ],
  },
  {
    provisionalCode: '2016 PB302',
    note: '',
    absoluteMagnitude: 19.22,
    semimajorAxis: 2.37009,
    eccentricity: 0.114294,
    inclination: 5.79585,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277530, H277580',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H335983',
      },
      {
        userName: 'maria w',
        HNames: 'H345060',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H348953',
      },
    ],
  },
  {
    provisionalCode: '2016 PC302',
    note: '',
    absoluteMagnitude: 18.09,
    semimajorAxis: 3.13033,
    eccentricity: 0.0716422,
    inclination: 26.39355,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286867',
      },
      {
        userName: 'maria w',
        HNames: 'H322900',
      },
      {
        userName: 'littlefill',
        HNames: 'H325346',
      },
      {
        userName: 'ioring',
        HNames: 'H344742',
      },
    ],
  },
  {
    provisionalCode: '2016 PE302',
    note: '',
    absoluteMagnitude: 19.2,
    semimajorAxis: 3.20915,
    eccentricity: 0.0963291,
    inclination: 8.47536,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H286875, H286954',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332415',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344048',
      },
    ],
  },
  {
    provisionalCode: '2016 PF302',
    note: '',
    absoluteMagnitude: 18.39,
    semimajorAxis: 3.1675,
    eccentricity: 0.0419178,
    inclination: 10.53409,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H236794',
      },
      {
        userName: 'みしま',
        HNames: 'H329619',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344406',
      },
      {
        userName: 'ioring',
        HNames: 'H350518',
      },
    ],
  },
  {
    provisionalCode: '2016 PG302',
    note: '',
    absoluteMagnitude: 19.41,
    semimajorAxis: 2.81136,
    eccentricity: 0.0798533,
    inclination: 3.83969,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H244332',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H255272',
      },
      {
        userName: 'wanko',
        HNames: 'H327281',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344318',
      },
    ],
  },
  {
    provisionalCode: '2016 PH302',
    note: '',
    absoluteMagnitude: 19.44,
    semimajorAxis: 2.71881,
    eccentricity: 0.0450481,
    inclination: 9.55785,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H257661',
      },
      {
        userName: 'maria w',
        HNames: 'H343691',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344365',
      },
      {
        userName: 'ioring',
        HNames: 'H350186',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ302',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 2.71198,
    eccentricity: 0.0574306,
    inclination: 7.60792,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238854',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H254817',
      },
      {
        userName: 'maria w',
        HNames: 'H337888',
      },
      {
        userName: 'ms',
        HNames: 'H354084',
      },
    ],
  },
  {
    provisionalCode: '2016 PK302',
    note: '',
    absoluteMagnitude: 21.29,
    semimajorAxis: 2.21613,
    eccentricity: 0.1496708,
    inclination: 4.93882,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H236457, H237878',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H345265, H346082',
      },
    ],
  },
  {
    provisionalCode: '2016 PM302',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 2.74275,
    eccentricity: 0.0371799,
    inclination: 2.6886,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H232739',
      },
      {
        userName: 'ms',
        HNames: 'H339123',
      },
      {
        userName: 'ioring',
        HNames: 'H347656, H348064',
      },
    ],
  },
  {
    provisionalCode: '2016 PN302',
    note: '',
    absoluteMagnitude: 18.58,
    semimajorAxis: 3.3676,
    eccentricity: 0.0928954,
    inclination: 9.32546,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H282565',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H284243',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H343525',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H344108',
      },
    ],
  },
  {
    provisionalCode: '2016 PP302',
    note: '2015 KW266と同定',
    absoluteMagnitude: 18.76,
    semimajorAxis: 2.79786,
    eccentricity: 0.0709707,
    inclination: 3.27718,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253055',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H282100, H283528, H344031',
      },
      {
        userName: 'れお',
        HNames: 'H344061',
      },
      {
        userName: 'wanko',
        HNames: 'H344061',
      },
    ],
  },
  {
    provisionalCode: '2016 PQ302',
    note: '',
    absoluteMagnitude: 19.65,
    semimajorAxis: 2.78225,
    eccentricity: 0.0454362,
    inclination: 5.17039,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H317980, H319996',
      },
      {
        userName: 'maria w',
        HNames: 'H327058',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H346117',
      },
    ],
  },
  {
    provisionalCode: '2016 PR302',
    note: '',
    absoluteMagnitude: 19.37,
    semimajorAxis: 3.16135,
    eccentricity: 0.1289825,
    inclination: 5.00479,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H236358',
      },
      {
        userName: 'ms',
        HNames: 'H347231',
      },
      {
        userName: 'ioring',
        HNames: 'H348027',
      },
      {
        userName: 'theoria',
        HNames: 'H359873',
      },
    ],
  },
  {
    provisionalCode: '2016 PS302',
    note: '',
    absoluteMagnitude: 19.27,
    semimajorAxis: 2.69764,
    eccentricity: 0.1120324,
    inclination: 1.98509,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238249',
      },
      {
        userName: 'ioring',
        HNames: 'H351150',
      },
      {
        userName: 'bny',
        HNames: 'H354034',
      },
      {
        userName: 'wanko',
        HNames: 'H361178',
      },
    ],
  },
  {
    provisionalCode: '2016 PT302',
    note: '',
    absoluteMagnitude: 17.71,
    semimajorAxis: 3.31368,
    eccentricity: 0.0383089,
    inclination: 4.23138,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H234256',
      },
      {
        userName: 'maria w',
        HNames: 'H323768',
      },
      {
        userName: 'ioring',
        HNames: 'H344855',
      },
      {
        userName: 'ms',
        HNames: 'H357909',
      },
    ],
  },
  {
    provisionalCode: '2016 PU302',
    note: '',
    absoluteMagnitude: 20.2,
    semimajorAxis: 2.55218,
    eccentricity: 0.1509432,
    inclination: 16.633,
    oppositions: 2,
    uncertainty: 2,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H257309',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H260179',
      },
      {
        userName: 'ほたる',
        HNames: 'H270636',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H345944',
      },
    ],
  },
  {
    provisionalCode: '2016 PX302',
    note: '',
    absoluteMagnitude: 19.47,
    semimajorAxis: 2.74129,
    eccentricity: 0.0962815,
    inclination: 3.13948,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H207442',
      },
      {
        userName: 'コージ',
        HNames: 'H251624',
      },
      {
        userName: 'maria w',
        HNames: 'H335500',
      },
      {
        userName: 'ms',
        HNames: 'H364400',
      },
    ],
  },
  {
    provisionalCode: '2016 PY302',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 2.73305,
    eccentricity: 0.1799948,
    inclination: 2.53403,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H229542',
      },
      {
        userName: 'maria w',
        HNames: 'H330379, H330539',
      },
      {
        userName: 'ms',
        HNames: 'H364388',
      },
    ],
  },
  {
    provisionalCode: '2016 PD303',
    note: '',
    absoluteMagnitude: 20.87,
    semimajorAxis: 2.67196,
    eccentricity: 0.1310886,
    inclination: 2.59386,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232153',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H251865',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329249, H338076',
      },
    ],
  },
  {
    provisionalCode: '2016 PE303',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.75372,
    eccentricity: 0.0614815,
    inclination: 5.75161,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H238618',
      },
      {
        userName: 'コージ',
        HNames: 'H250639',
      },
      {
        userName: 'maria w',
        HNames: 'H337886',
      },
      {
        userName: 'ms',
        HNames: 'H354083',
      },
    ],
  },
  {
    provisionalCode: '2016 PF303',
    note: '',
    absoluteMagnitude: 20.1,
    semimajorAxis: 2.64153,
    eccentricity: 0.212398,
    inclination: 5.44261,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285169',
      },
      {
        userName: 'maria w',
        HNames: 'H338398',
      },
      {
        userName: 'れお',
        HNames: 'H344803',
      },
      {
        userName: 'wanko',
        HNames: 'H347013',
      },
    ],
  },
  {
    provisionalCode: '2016 PG303',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.38614,
    eccentricity: 0.1887846,
    inclination: 2.05192,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H232100, H236951',
      },
      {
        userName: 'ほたる',
        HNames: 'H321612',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H329750',
      },
      {
        userName: 'ioring',
        HNames: 'H347616',
      },
      {
        userName: 'やすさん',
        HNames: 'H352566',
      },
    ],
  },
  {
    provisionalCode: '2016 PH303',
    note: '',
    absoluteMagnitude: 18.65,
    semimajorAxis: 3.07457,
    eccentricity: 0.0821535,
    inclination: 8.51473,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H256988',
      },
      {
        userName: 'ほたる',
        HNames: 'H321968',
      },
      {
        userName: 'ms',
        HNames: 'H349925',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H350281',
      },
    ],
  },
  {
    provisionalCode: '2016 PJ303',
    note: '',
    absoluteMagnitude: 20.03,
    semimajorAxis: 2.65421,
    eccentricity: 0.1787622,
    inclination: 12.32038,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'chanleo',
        HNames: 'H204228',
      },
      {
        userName: 'あんこう',
        HNames: 'H204228',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H285173',
      },
      {
        userName: 'maria w',
        HNames: 'H322780',
      },
      {
        userName: 'ms',
        HNames: 'H357911',
      },
    ],
  },
  {
    provisionalCode: '2016 PK303',
    note: '',
    absoluteMagnitude: 20.35,
    semimajorAxis: 2.72687,
    eccentricity: 0.2441067,
    inclination: 8.45849,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H285714, H320403',
      },
      {
        userName: 'wanko',
        HNames: 'H349038',
      },
      {
        userName: 'maria w',
        HNames: 'H350384',
      },
    ],
  },
  {
    provisionalCode: '2016 PL303',
    note: '',
    absoluteMagnitude: 20.47,
    semimajorAxis: 2.16232,
    eccentricity: 0.0774137,
    inclination: 2.50408,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H231198',
      },
      {
        userName: 'maria w',
        HNames: 'H322739, H325147',
      },
      {
        userName: 'ioring',
        HNames: 'H345278',
      },
    ],
  },
  {
    provisionalCode: '2016 QP160',
    note: '',
    absoluteMagnitude: 19.09,
    semimajorAxis: 2.69131,
    eccentricity: 0.122614,
    inclination: 3.46864,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H224770',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277366',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278221',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H342828',
      },
    ],
  },
  {
    provisionalCode: '2016 QQ160',
    note: '',
    absoluteMagnitude: 20.39,
    semimajorAxis: 2.55883,
    eccentricity: 0.2011777,
    inclination: 4.04381,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'あんこう',
        HNames: 'H214683',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238409, H285186',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344570',
      },
    ],
  },
  {
    provisionalCode: '2016 QR160',
    note: '',
    absoluteMagnitude: 20.64,
    semimajorAxis: 2.58561,
    eccentricity: 0.190201,
    inclination: 3.07738,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253015',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H277620, H277643',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344540',
      },
    ],
  },
  {
    provisionalCode: '2016 QS160',
    note: '',
    absoluteMagnitude: 20.69,
    semimajorAxis: 2.53982,
    eccentricity: 0.1940484,
    inclination: 2.88567,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'コージ',
        HNames: 'H251062',
      },
      {
        userName: 'ほたる',
        HNames: 'H265968',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H276981',
      },
      {
        userName: 'mutabilitie',
        HNames: 'H344361',
      },
    ],
  },
  {
    provisionalCode: '2016 QV160',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 2.73603,
    eccentricity: 0.0626888,
    inclination: 3.2091,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253049, H344759',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H278134, H278195',
      },
    ],
  },
  {
    provisionalCode: '2016 RV105',
    note: '',
    absoluteMagnitude: 18.28,
    semimajorAxis: 3.10776,
    eccentricity: 0.131458,
    inclination: 15.34641,
    oppositions: 1,
    uncertainty: 4,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H270126',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H346167, H346200',
      },
      {
        userName: 'apollo18',
        HNames: 'H352310',
      },
      {
        userName: 'ioring',
        HNames: 'H354025',
      },
    ],
  },
  {
    provisionalCode: '2016 RZ105',
    note: '',
    absoluteMagnitude: 19.95,
    semimajorAxis: 2.45345,
    eccentricity: 0.194281,
    inclination: 2.26402,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H238383',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H332766, H336393',
      },
      {
        userName: 'れお',
        HNames: 'H348426',
      },
    ],
  },
  {
    provisionalCode: '2016 RE106',
    note: '',
    absoluteMagnitude: 19.78,
    semimajorAxis: 2.57545,
    eccentricity: 0.1079264,
    inclination: 9.28719,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H270068',
      },
      {
        userName: 'ナカシ',
        HNames: 'H304847',
      },
      {
        userName: 'kn1cht',
        HNames: 'H327637',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H352195',
      },
    ],
  },
  {
    provisionalCode: '2016 RF106',
    note: '',
    absoluteMagnitude: 17.55,
    semimajorAxis: 3.0984,
    eccentricity: 0.214779,
    inclination: 24.30167,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: '小林',
        HNames: 'H257591',
      },
      {
        userName: 'ほたる',
        HNames: 'H303881',
      },
      {
        userName: '中辛あんぱん',
        HNames: 'H348278',
      },
      {
        userName: 'ioring',
        HNames: 'H354788',
      },
    ],
  },
  {
    provisionalCode: '2016 WL86',
    note: '',
    absoluteMagnitude: 18.81,
    semimajorAxis: 3.18559,
    eccentricity: 0.0601411,
    inclination: 8.78791,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'れお',
        HNames: 'H282745',
      },
      {
        userName: 'ほたる',
        HNames: 'H284958',
      },
      {
        userName: 'nasiar99',
        HNames: 'H293633',
      },
    ],
  },
  {
    provisionalCode: '2016 WM86',
    note: '',
    absoluteMagnitude: 18.44,
    semimajorAxis: 3.01653,
    eccentricity: 0.1072542,
    inclination: 10.1325,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267188, H282756',
      },
      {
        userName: 'れお',
        HNames: 'H279932',
      },
      {
        userName: 'もしもス',
        HNames: 'H279932',
      },
    ],
  },
  {
    provisionalCode: '2016 YB41',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.6,
    semimajorAxis: 5.29275,
    eccentricity: 0.0201619,
    inclination: 12.32986,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269703, H271235, H283487, H284096',
      },
    ],
  },
  {
    provisionalCode: '2016 YC41',
    note: '',
    absoluteMagnitude: 19.62,
    semimajorAxis: 3.06203,
    eccentricity: 0.1392635,
    inclination: 12.52851,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H269378',
      },
      {
        userName: 'れお',
        HNames: 'H269380',
      },
      {
        userName: 'zorome',
        HNames: 'H269569',
      },
      {
        userName: 'arda',
        HNames: 'H285091',
      },
    ],
  },
  {
    provisionalCode: '2016 YD41',
    note: '',
    absoluteMagnitude: 19.12,
    semimajorAxis: 2.89378,
    eccentricity: 0.0927087,
    inclination: 11.02115,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H280464, H283659, H284358, H286007',
      },
    ],
  },
  {
    provisionalCode: '2016 YF41',
    note: '',
    absoluteMagnitude: 19.27,
    semimajorAxis: 2.7076,
    eccentricity: 0.0431553,
    inclination: 11.4799,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251449, H278781, H281485',
      },
      {
        userName: 'yakuba',
        HNames: 'H294991',
      },
    ],
  },
  {
    provisionalCode: '2017 AY64',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.52,
    semimajorAxis: 5.16926,
    eccentricity: 0.0784933,
    inclination: 8.55374,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H269511',
      },
      {
        userName: 'arda',
        HNames: 'H269511',
      },
      {
        userName: 'れお',
        HNames: 'H282835',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H363361',
      },
    ],
  },
  {
    provisionalCode: '2017 AC65',
    note: '',
    absoluteMagnitude: 18.75,
    semimajorAxis: 3.19795,
    eccentricity: 0.038557,
    inclination: 7.20613,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'れお',
        HNames: 'H281706',
      },
      {
        userName: 'ほたる',
        HNames: 'H287610',
      },
      {
        userName: 'zorome',
        HNames: 'H294561',
      },
    ],
  },
  {
    provisionalCode: '2017 AD65',
    note: '',
    absoluteMagnitude: 19.42,
    semimajorAxis: 3.16781,
    eccentricity: 0.0808878,
    inclination: 16.55492,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265956',
      },
      {
        userName: 'ほたる',
        HNames: 'H281256',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288710',
      },
    ],
  },
  {
    provisionalCode: '2017 AE65',
    note: '',
    absoluteMagnitude: 19.31,
    semimajorAxis: 3.18334,
    eccentricity: 0.0897615,
    inclination: 16.53469,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'ko',
        HNames: 'H265955',
      },
      {
        userName: 'ms',
        HNames: 'H279295',
      },
      {
        userName: 'nasiar99',
        HNames: 'H289104',
      },
    ],
  },
  {
    provisionalCode: '2017 BM246',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 3.00497,
    eccentricity: 0.1048227,
    inclination: 17.98278,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267725, H282924',
      },
      {
        userName: 'もしもス',
        HNames: 'H267725',
      },
      {
        userName: 'aika',
        HNames: 'H277946',
      },
    ],
  },
  {
    provisionalCode: '2017 BN246',
    note: 'NEO (Amor)',
    absoluteMagnitude: 25.64,
    semimajorAxis: 2.10604,
    eccentricity: 0.4997233,
    inclination: 1.97645,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H201904',
      },
      {
        userName: '42',
        HNames: 'H267935',
      },
      {
        userName: 'もしもス',
        HNames: 'H281109, H291047',
      },
    ],
  },
  {
    provisionalCode: '2017 BO246',
    note: '',
    absoluteMagnitude: 21.05,
    semimajorAxis: 2.56804,
    eccentricity: 0.1625262,
    inclination: 7.02614,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242007, H242359, H248121',
      },
      {
        userName: 'れお',
        HNames: 'H297232',
      },
    ],
  },
  {
    provisionalCode: '2017 BP246',
    note: '',
    absoluteMagnitude: 18.68,
    semimajorAxis: 3.07072,
    eccentricity: 0.2653276,
    inclination: 9.23055,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248378, H250165',
      },
    ],
  },
  {
    provisionalCode: '2017 BQ246',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.57692,
    eccentricity: 0.1607576,
    inclination: 14.39732,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251772',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262342',
      },
      {
        userName: '42',
        HNames: 'H262342',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312396',
      },
    ],
  },
  {
    provisionalCode: '2017 BR246',
    note: '',
    absoluteMagnitude: 21.04,
    semimajorAxis: 2.30737,
    eccentricity: 0.0993685,
    inclination: 7.32649,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H261775',
      },
      {
        userName: 'nasiar99',
        HNames: 'H262345',
      },
      {
        userName: '42',
        HNames: 'H262345',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312397, H312432',
      },
    ],
  },
  {
    provisionalCode: '2017 BS246',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.45854,
    eccentricity: 0.1056796,
    inclination: 5.62672,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H280188, H280189',
      },
      {
        userName: 'arda',
        HNames: 'H281002',
      },
    ],
  },
  {
    provisionalCode: '2017 BT246',
    note: '',
    absoluteMagnitude: 19.21,
    semimajorAxis: 3.0014,
    eccentricity: 0.027614,
    inclination: 11.77059,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'yakuba',
        HNames: 'H263478',
      },
      {
        userName: 'もしもス',
        HNames: 'H263478',
      },
      {
        userName: 'pietro maiorana',
        HNames: 'H270266',
      },
      {
        userName: 'nasiar99',
        HNames: 'H270266',
      },
      {
        userName: 'れお',
        HNames: 'H281237',
      },
    ],
  },
  {
    provisionalCode: '2017 BU246',
    note: '',
    absoluteMagnitude: 18.71,
    semimajorAxis: 2.88179,
    eccentricity: 0.136455,
    inclination: 13.01934,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H247890',
      },
      {
        userName: 'zorome',
        HNames: 'H247890',
      },
      {
        userName: 'れお',
        HNames: 'H247890',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H260331',
      },
      {
        userName: 'nasiar99',
        HNames: 'H260331',
      },
    ],
  },
  {
    provisionalCode: '2017 BV246',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.14829,
    eccentricity: 0.077749,
    inclination: 11.40572,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H248129',
      },
      {
        userName: 'zorome',
        HNames: 'H248129',
      },
      {
        userName: 'aika',
        HNames: 'H271348',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H271348',
      },
    ],
  },
  {
    provisionalCode: '2017 BW246',
    note: '',
    absoluteMagnitude: 20.14,
    semimajorAxis: 2.87788,
    eccentricity: 0.0636395,
    inclination: 11.68895,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'kn1cht',
        HNames: 'H271061',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H281892',
      },
      {
        userName: 'れお',
        HNames: 'H283865',
      },
      {
        userName: 'aika',
        HNames: 'H363855',
      },
    ],
  },
  {
    provisionalCode: '2017 BX246',
    note: '',
    absoluteMagnitude: 18.97,
    semimajorAxis: 2.97212,
    eccentricity: 0.0735054,
    inclination: 9.58527,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: '赵经远',
        HNames: 'H255460',
      },
      {
        userName: 'れお',
        HNames: 'H280165',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H280165',
      },
    ],
  },
  {
    provisionalCode: '2017 BY246',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 2.98263,
    eccentricity: 0.0537524,
    inclination: 9.21678,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242172',
      },
    ],
  },
  {
    provisionalCode: '2017 BZ246',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.57173,
    eccentricity: 0.1446626,
    inclination: 8.24756,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242413, H252196',
      },
    ],
  },
  {
    provisionalCode: '2017 BB247',
    note: '',
    absoluteMagnitude: 19.91,
    semimajorAxis: 2.58192,
    eccentricity: 0.1404529,
    inclination: 6.94465,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H251439',
      },
      {
        userName: 'れお',
        HNames: 'H251439',
      },
      {
        userName: 'arda',
        HNames: 'H265417',
      },
      {
        userName: 'nasiar99',
        HNames: 'H282679',
      },
    ],
  },
  {
    provisionalCode: '2017 BC247',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 2.6794,
    eccentricity: 0.1428113,
    inclination: 9.96282,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269591',
      },
      {
        userName: 'nasiar99',
        HNames: 'H280531',
      },
      {
        userName: 'れお',
        HNames: 'H286477',
      },
    ],
  },
  {
    provisionalCode: '2017 BD247',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 16.03,
    semimajorAxis: 5.27871,
    eccentricity: 0.0783084,
    inclination: 12.00246,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H279277, H282690, H282993',
      },
      {
        userName: 'nasiar99',
        HNames: 'H291574',
      },
    ],
  },
  {
    provisionalCode: '2017 BE247',
    note: '',
    absoluteMagnitude: 20.37,
    semimajorAxis: 2.6555,
    eccentricity: 0.1376332,
    inclination: 12.02386,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H278767, H282885, H284629, H297087',
      },
    ],
  },
  {
    provisionalCode: '2017 BF247',
    note: '',
    absoluteMagnitude: 18.34,
    semimajorAxis: 3.0843,
    eccentricity: 0.041772,
    inclination: 8.64937,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H265517',
      },
      {
        userName: 'zorome',
        HNames: 'H265517',
      },
      {
        userName: 'ほたる',
        HNames: 'H281261',
      },
    ],
  },
  {
    provisionalCode: '2017 BG247',
    note: '',
    absoluteMagnitude: 19.48,
    semimajorAxis: 3.01288,
    eccentricity: 0.1470259,
    inclination: 8.27406,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H269844, H271965',
      },
      {
        userName: 'ほたる',
        HNames: 'H279588',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H279588',
      },
    ],
  },
  {
    provisionalCode: '2017 BJ247',
    note: '',
    absoluteMagnitude: 20.65,
    semimajorAxis: 2.31898,
    eccentricity: 0.1811363,
    inclination: 4.28101,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'watan_rockhand',
        HNames: 'H202429',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H287854, H293798, H299757',
      },
    ],
  },
  {
    provisionalCode: '2017 CQ60',
    note: '',
    absoluteMagnitude: 19.68,
    semimajorAxis: 3.03488,
    eccentricity: 0.1616951,
    inclination: 13.61488,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: '42',
        HNames: 'H262688',
      },
      {
        userName: 'もしもス',
        HNames: 'H267657, H283590',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267657',
      },
    ],
  },
  {
    provisionalCode: '2017 CR60',
    note: '',
    absoluteMagnitude: 18.52,
    semimajorAxis: 3.17455,
    eccentricity: 0.2215123,
    inclination: 8.47299,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: '42',
        HNames: 'H263187',
      },
      {
        userName: 'nasiar99',
        HNames: 'H267724',
      },
      {
        userName: 'もしもス',
        HNames: 'H267724',
      },
      {
        userName: 'yakuba',
        HNames: 'H298029',
      },
    ],
  },
  {
    provisionalCode: '2017 CS60',
    note: '',
    absoluteMagnitude: 19.99,
    semimajorAxis: 2.72249,
    eccentricity: 0.220382,
    inclination: 6.23158,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H267911, H291163',
      },
      {
        userName: '42',
        HNames: 'H267911',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H291163',
      },
    ],
  },
  {
    provisionalCode: '2017 CT60',
    note: '',
    absoluteMagnitude: 19.29,
    semimajorAxis: 3.17638,
    eccentricity: 0.0445663,
    inclination: 7.28733,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'nasiar99',
        HNames: 'H271057, H283010',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H271057',
      },
      {
        userName: 'arda',
        HNames: 'H271057',
      },
    ],
  },
  {
    provisionalCode: '2017 CU60',
    note: '',
    absoluteMagnitude: 18.98,
    semimajorAxis: 3.18932,
    eccentricity: 0.0348294,
    inclination: 9.22577,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242019',
      },
    ],
  },
  {
    provisionalCode: '2017 CV60',
    note: '',
    absoluteMagnitude: 19.82,
    semimajorAxis: 3.08618,
    eccentricity: 0.0514508,
    inclination: 8.94502,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H242020',
      },
    ],
  },
  {
    provisionalCode: '2017 CW60',
    note: '',
    absoluteMagnitude: 18.11,
    semimajorAxis: 3.22218,
    eccentricity: 0.0399646,
    inclination: 8.23216,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H245648',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H245648',
      },
      {
        userName: 'nasiar99',
        HNames: 'H300200',
      },
    ],
  },
  {
    provisionalCode: '2017 CX60',
    note: '',
    absoluteMagnitude: 18.74,
    semimajorAxis: 2.9782,
    eccentricity: 0.0285984,
    inclination: 7.06594,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'aika',
        HNames: 'H263169',
      },
      {
        userName: '42',
        HNames: 'H263169',
      },
      {
        userName: 's.ozawa',
        HNames: 'H312219, H312233',
      },
    ],
  },
  {
    provisionalCode: '2017 CY60',
    note: '',
    absoluteMagnitude: 18.72,
    semimajorAxis: 3.18738,
    eccentricity: 0.0047158,
    inclination: 9.53079,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H248305, H299077',
      },
    ],
  },
  {
    provisionalCode: '2017 CD61',
    note: '',
    absoluteMagnitude: 19.02,
    semimajorAxis: 3.08727,
    eccentricity: 0.2394495,
    inclination: 9.69474,
    oppositions: 1,
    uncertainty: 6,
    detail: [
      {
        userName: 'ryo-k',
        HNames: 'H218188',
      },
      {
        userName: 'zorome',
        HNames: 'H269623, H271140, H312514',
      },
    ],
  },
  {
    provisionalCode: '2017 CE61',
    note: '',
    absoluteMagnitude: 17.91,
    semimajorAxis: 2.98018,
    eccentricity: 0.0647457,
    inclination: 9.75992,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H266204, H274928',
      },
    ],
  },
  {
    provisionalCode: '2017 EC58',
    note: '',
    absoluteMagnitude: 17.57,
    semimajorAxis: 3.06588,
    eccentricity: 0.0961705,
    inclination: 9.93657,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H277891',
      },
    ],
  },
  {
    provisionalCode: '2017 GV37',
    note: '',
    absoluteMagnitude: 20.07,
    semimajorAxis: 3.19065,
    eccentricity: 0.1094922,
    inclination: 9.50127,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H278507',
      },
      {
        userName: 'ms',
        HNames: 'H291492, H292070, H292091',
      },
    ],
  },
  {
    provisionalCode: '2017 HM113',
    note: '',
    absoluteMagnitude: 19.18,
    semimajorAxis: 2.73356,
    eccentricity: 0.1864102,
    inclination: 7.72276,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'aika',
        HNames: 'H267733',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H267733',
      },
      {
        userName: 'kn1cht',
        HNames: 'H274742',
      },
      {
        userName: 'nasiar99',
        HNames: 'H302960',
      },
    ],
  },
  {
    provisionalCode: '2017 HQ113',
    note: '',
    absoluteMagnitude: 19.37,
    semimajorAxis: 2.96661,
    eccentricity: 0.0343476,
    inclination: 8.78794,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'arda',
        HNames: 'H243656',
      },
      {
        userName: 'もしもス',
        HNames: 'H243656',
      },
      {
        userName: 'nasiar99',
        HNames: 'H266011',
      },
      {
        userName: 'ms',
        HNames: 'H290664',
      },
    ],
  },
  {
    provisionalCode: '2019 AX143',
    note: '',
    absoluteMagnitude: 19.59,
    semimajorAxis: 2.71795,
    eccentricity: 0.0322463,
    inclination: 3.83302,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H224884, H240889, H268655',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H240889',
      },
    ],
  },
  {
    provisionalCode: '2019 EX8',
    note: 'Jupiter Trojan',
    absoluteMagnitude: 15.69,
    semimajorAxis: 5.28857,
    eccentricity: 0.0923911,
    inclination: 16.47442,
    oppositions: 1,
    uncertainty: 4,
    detail: [
      {
        userName: 'sasaki',
        HNames: 'H221240',
      },
      {
        userName: 'zorome',
        HNames: 'H225736, H256785, H358280',
      },
    ],
  },
  {
    provisionalCode: '2019 EY8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.27,
    semimajorAxis: 45.82311,
    eccentricity: 0.1065429,
    inclination: 4.19877,
    oppositions: 3,
    uncertainty: 5,
    detail: [
      {
        userName: 'apollo18',
        HNames: 'H243737',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H310262',
      },
    ],
  },
  {
    provisionalCode: '2019 EZ8',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.77,
    semimajorAxis: 44.54827,
    eccentricity: 0.0388137,
    inclination: 4.68367,
    oppositions: 2,
    uncertainty: 6,
    detail: [
      {
        userName: 'maria w',
        HNames: 'H282461',
      },
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H310264',
      },
    ],
  },
  {
    provisionalCode: '2019 UB181',
    note: '',
    absoluteMagnitude: 20.0,
    semimajorAxis: 2.45497,
    eccentricity: 0.0833564,
    inclination: 4.3857,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H218972',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222848, H223209',
      },
      {
        userName: 'ms',
        HNames: 'H363087',
      },
    ],
  },
  {
    provisionalCode: '2019 UN181',
    note: '',
    absoluteMagnitude: 21.3,
    semimajorAxis: 4.62754,
    eccentricity: 0.4882321,
    inclination: 3.79801,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ms',
        HNames: 'H321446, H361954, H364902',
      },
    ],
  },
  {
    provisionalCode: '2019 UM182',
    note: '',
    absoluteMagnitude: 20.7,
    semimajorAxis: 2.6156,
    eccentricity: 0.2488638,
    inclination: 14.20156,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 's.ozawa',
        HNames: 'H247373, H251882',
      },
      {
        userName: 'れお',
        HNames: 'H260651, H260712',
      },
    ],
  },
  {
    provisionalCode: '2019 VH57',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.53,
    semimajorAxis: 43.79544,
    eccentricity: 0.1212301,
    inclination: 8.60956,
    oppositions: 4,
    uncertainty: 5,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames:
          'H222117, H361489, H361490, H361491, H361495, H361497, H361499, H361506',
      },
      {
        userName: 'えでぃ',
        HNames: 'H222117',
      },
    ],
  },
  {
    provisionalCode: '2019 WW39',
    note: '',
    absoluteMagnitude: 19.84,
    semimajorAxis: 2.67451,
    eccentricity: 0.165848,
    inclination: 5.77097,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H210375, H223488, H231364',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H223488',
      },
    ],
  },
  {
    provisionalCode: '2016 EE375',
    note: '',
    absoluteMagnitude: 19.06,
    semimajorAxis: 3.08179,
    eccentricity: 0.1145505,
    inclination: 3.10787,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328892, H329504, H329591',
      },
    ],
  },
  {
    provisionalCode: '2016 EF375',
    note: '',
    absoluteMagnitude: 19.4,
    semimajorAxis: 2.70779,
    eccentricity: 0.0446027,
    inclination: 2.31938,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329508, H329592',
      },
      {
        userName: 'zorome',
        HNames: 'H358097',
      },
    ],
  },
  {
    provisionalCode: '2016 EG375',
    note: '',
    absoluteMagnitude: 19.76,
    semimajorAxis: 2.55557,
    eccentricity: 0.0756916,
    inclination: 16.46886,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329146, H329596',
      },
      {
        userName: 'zorome',
        HNames: 'H358159',
      },
    ],
  },
  {
    provisionalCode: '2016 EH375',
    note: '',
    absoluteMagnitude: 20.06,
    semimajorAxis: 2.76526,
    eccentricity: 0.0713089,
    inclination: 3.78164,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329636',
      },
      {
        userName: 'zorome',
        HNames: 'H358165, H358430',
      },
    ],
  },
  {
    provisionalCode: '2016 EJ375',
    note: '',
    absoluteMagnitude: 20.23,
    semimajorAxis: 2.68522,
    eccentricity: 0.1432278,
    inclination: 1.84671,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329296, H329548, H329879',
      },
    ],
  },
  {
    provisionalCode: '2016 EK375',
    note: 'Hungaria',
    absoluteMagnitude: 22.48,
    semimajorAxis: 1.88602,
    eccentricity: 0.0880605,
    inclination: 23.08676,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H338622',
      },
      {
        userName: 'yakuba',
        HNames: 'H354456',
      },
      {
        userName: 'zorome',
        HNames: 'H358125',
      },
    ],
  },
  {
    provisionalCode: '2016 EL375',
    note: 'Mars Crosser',
    absoluteMagnitude: 20.69,
    semimajorAxis: 2.26813,
    eccentricity: 0.3528448,
    inclination: 4.33807,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328864',
      },
      {
        userName: 'zorome',
        HNames: 'H358282, H360843',
      },
    ],
  },
  {
    provisionalCode: '2016 EM375',
    note: '',
    absoluteMagnitude: 20.57,
    semimajorAxis: 2.2893,
    eccentricity: 0.2485927,
    inclination: 2.35762,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328885, H329322',
      },
      {
        userName: 'zorome',
        HNames: 'H360882',
      },
    ],
  },
  {
    provisionalCode: '2016 EN375',
    note: '',
    absoluteMagnitude: 21.62,
    semimajorAxis: 2.33902,
    eccentricity: 0.0467634,
    inclination: 1.70338,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328888, H329301',
      },
      {
        userName: 'zorome',
        HNames: 'H360884',
      },
    ],
  },
  {
    provisionalCode: '2016 EO375',
    note: '',
    absoluteMagnitude: 20.86,
    semimajorAxis: 2.74204,
    eccentricity: 0.1273169,
    inclination: 2.27004,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328919',
      },
      {
        userName: 'zorome',
        HNames: 'H358359, H363057',
      },
    ],
  },
  {
    provisionalCode: '2016 EP375',
    note: '',
    absoluteMagnitude: 20.35,
    semimajorAxis: 2.99157,
    eccentricity: 0.1712396,
    inclination: 10.37037,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329764',
      },
      {
        userName: 'zorome',
        HNames: 'H358090, H363058',
      },
    ],
  },
  {
    provisionalCode: '2016 EQ375',
    note: '',
    absoluteMagnitude: 19.84,
    semimajorAxis: 2.75533,
    eccentricity: 0.0430301,
    inclination: 3.4608,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328893, H329505',
      },
      {
        userName: 'zorome',
        HNames: 'H363081',
      },
    ],
  },
  {
    provisionalCode: '2016 ER375',
    note: '',
    absoluteMagnitude: 19.74,
    semimajorAxis: 2.67902,
    eccentricity: 0.1174426,
    inclination: 12.03973,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H328868, H331890',
      },
      {
        userName: 'zorome',
        HNames: 'H363082',
      },
    ],
  },
  {
    provisionalCode: '2016 ES375',
    note: '',
    absoluteMagnitude: 20.81,
    semimajorAxis: 2.60983,
    eccentricity: 0.1334106,
    inclination: 2.58099,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329860',
      },
      {
        userName: 'zorome',
        HNames: 'H358363, H363111',
      },
    ],
  },
  {
    provisionalCode: '2016 ET375',
    note: '',
    absoluteMagnitude: 20.04,
    semimajorAxis: 2.78007,
    eccentricity: 0.1505778,
    inclination: 3.78412,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329660',
      },
      {
        userName: 'zorome',
        HNames: 'H358355, H363113',
      },
    ],
  },
  {
    provisionalCode: '2016 EU375',
    note: '',
    absoluteMagnitude: 18.54,
    semimajorAxis: 2.91578,
    eccentricity: 0.2074541,
    inclination: 2.22244,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329498, H329822',
      },
      {
        userName: 'zorome',
        HNames: 'H363132',
      },
    ],
  },
  {
    provisionalCode: '2016 EV375',
    note: '',
    absoluteMagnitude: 19.54,
    semimajorAxis: 2.74412,
    eccentricity: 0.0955008,
    inclination: 2.64978,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'ioring',
        HNames: 'H329181',
      },
      {
        userName: 'zorome',
        HNames: 'H358217, H363144',
      },
    ],
  },
  {
    provisionalCode: '2016 EW375',
    note: '',
    absoluteMagnitude: 19.36,
    semimajorAxis: 3.15246,
    eccentricity: 0.1164633,
    inclination: 8.91304,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323483',
      },
      {
        userName: 'もしもス',
        HNames: 'H325449',
      },
      {
        userName: 'ioring',
        HNames: 'H343162',
      },
    ],
  },
  {
    provisionalCode: '2016 EX375',
    note: '',
    absoluteMagnitude: 20.99,
    semimajorAxis: 2.36847,
    eccentricity: 0.0886951,
    inclination: 5.90519,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324024',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324024',
      },
      {
        userName: 'aika',
        HNames: 'H330136',
      },
    ],
  },
  {
    provisionalCode: '2016 EY375',
    note: '',
    absoluteMagnitude: 20.16,
    semimajorAxis: 3.03456,
    eccentricity: 0.0671123,
    inclination: 9.74572,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324026',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324026',
      },
      {
        userName: 'aika',
        HNames: 'H330141',
      },
    ],
  },
  {
    provisionalCode: '2016 EZ375',
    note: '',
    absoluteMagnitude: 18.07,
    semimajorAxis: 3.15639,
    eccentricity: 0.0103011,
    inclination: 7.43246,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'もしもス',
        HNames: 'H324068',
      },
      {
        userName: 'えでぃ',
        HNames: 'H328548',
      },
      {
        userName: 'ほたる',
        HNames: 'H344376, H346348',
      },
    ],
  },
  {
    provisionalCode: '2016 EA376',
    note: '',
    absoluteMagnitude: 20.26,
    semimajorAxis: 2.87567,
    eccentricity: 0.1609874,
    inclination: 15.11067,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324106',
      },
      {
        userName: 'aika',
        HNames: 'H326766',
      },
      {
        userName: 'ioring',
        HNames: 'H343135',
      },
    ],
  },
  {
    provisionalCode: '2016 EB376',
    note: '',
    absoluteMagnitude: 19.26,
    semimajorAxis: 3.15985,
    eccentricity: 0.03165,
    inclination: 9.14636,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H324128',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324128',
      },
      {
        userName: 'aika',
        HNames: 'H332813',
      },
    ],
  },
  {
    provisionalCode: '2016 EC376',
    note: '',
    absoluteMagnitude: 21.09,
    semimajorAxis: 2.28375,
    eccentricity: 0.1281704,
    inclination: 5.70705,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323482',
      },
      {
        userName: 'aika',
        HNames: 'H324605',
      },
      {
        userName: 'えでぃ',
        HNames: 'H324605',
      },
    ],
  },
  {
    provisionalCode: '2016 ED376',
    note: '',
    absoluteMagnitude: 19.85,
    semimajorAxis: 2.74263,
    eccentricity: 0.2094637,
    inclination: 6.89137,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'aika',
        HNames: 'H324616, H326723',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H324616',
      },
    ],
  },
  {
    provisionalCode: '2016 EE376',
    note: '',
    absoluteMagnitude: 19.75,
    semimajorAxis: 3.20148,
    eccentricity: 0.1392458,
    inclination: 7.72268,
    oppositions: 1,
    uncertainty: 7,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323498',
      },
      {
        userName: 'aika',
        HNames: 'H325453',
      },
      {
        userName: 'もしもス',
        HNames: 'H325453',
      },
    ],
  },
  {
    provisionalCode: '2016 EF376',
    note: '',
    absoluteMagnitude: 18.36,
    semimajorAxis: 3.03545,
    eccentricity: 0.0557286,
    inclination: 16.2044,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'arda',
        HNames: 'H323497',
      },
      {
        userName: 'もしもス',
        HNames: 'H325993',
      },
      {
        userName: 'えでぃ',
        HNames: 'H325993',
      },
    ],
  },
  {
    provisionalCode: '2016 NG194',
    note: '',
    absoluteMagnitude: 19.19,
    semimajorAxis: 2.53137,
    eccentricity: 0.217673,
    inclination: 12.67883,
    oppositions: 2,
    uncertainty: 2,
    detail: [
      {
        userName: 'junnjunn',
        HNames: 'H356434',
      },
      {
        userName: 'しょうたろう',
        HNames: 'H357746',
      },
    ],
  },
  {
    provisionalCode: '2016 QE161',
    note: '',
    absoluteMagnitude: 19.79,
    semimajorAxis: 2.79119,
    eccentricity: 0.0802521,
    inclination: 5.04212,
    oppositions: 1,
    uncertainty: 5,
    detail: [
      {
        userName: 'pietro maiorana',
        HNames: 'H253478',
      },
      {
        userName: 'ms',
        HNames: 'H322672, H365150, H365152',
      },
      {
        userName: 'ioring',
        HNames: 'H345067',
      },
    ],
  },
  {
    provisionalCode: '2017 BL247',
    note: '',
    absoluteMagnitude: 17.97,
    semimajorAxis: 3.13595,
    eccentricity: 0.2642039,
    inclination: 13.30075,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H296267, H297148',
      },
    ],
  },
  {
    provisionalCode: '2017 CF61',
    note: '',
    absoluteMagnitude: 18.05,
    semimajorAxis: 3.15875,
    eccentricity: 0.2949624,
    inclination: 25.32402,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H273190',
      },
      {
        userName: 'nasiar99',
        HNames: 'H288620',
      },
    ],
  },
  {
    provisionalCode: '2017 DT171',
    note: '',
    absoluteMagnitude: 17.74,
    semimajorAxis: 3.14016,
    eccentricity: 0.19724,
    inclination: 17.56181,
    oppositions: 3,
    uncertainty: 2,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H243502',
      },
    ],
  },
  {
    provisionalCode: '2017 ED58',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 9.44,
    semimajorAxis: 42.22481,
    eccentricity: 0.1227257,
    inclination: 19.3463,
    oppositions: 1,
    uncertainty: 8,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H221284, H313161, H365041',
      },
    ],
  },
  {
    provisionalCode: '2019 EA9',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 8.04,
    semimajorAxis: 43.91214,
    eccentricity: 0.1142605,
    inclination: 3.33839,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H309820, H309829, H365039',
      },
    ],
  },
  {
    provisionalCode: '2019 GM188',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.92,
    semimajorAxis: 52.91398,
    eccentricity: 0.3582977,
    inclination: 4.01901,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H290962, H290975, H365037',
      },
    ],
  },
  {
    provisionalCode: '2019 GN188',
    note: 'TNO, Centaur, etc.',
    absoluteMagnitude: 7.55,
    semimajorAxis: 56.7328,
    eccentricity: 0.3606866,
    inclination: 7.38281,
    oppositions: 1,
    uncertainty: 9,
    detail: [
      {
        userName: 'zorome',
        HNames: 'H287633, H365329, H365330',
      },
    ],
  },
];
